import React, { useEffect, useState } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';
import "moment/locale/fr";
import {
    Chip,
    Grid,
    InputAdornment,
    Typography,
    Link,
    Box,
    Tooltip
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import useStyles from './style';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

moment.locale("fr");

function CustomToolbarComponent({ ...props }) {
    const classes = useStyles();

    return (
        <Grid container className={classes.toolbar} justify="space-between" alignItems="center">
            {
                props.views.includes("date") &&
                <>
                    <Grid item>
                        <Typography className={classes.toolbarDate} variant="body2">
                            {props.date.format("dddd DD MMMM")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Chip
                            onClick={() => props.setOpenView("year")}
                            label={<Typography variant="subtitle2">
                                {props.date.format("yyyy")}
                                {props.openView === "year" ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                            </Typography>}
                            size="small"
                            className={classes.yearChip}
                        />
                    </Grid>
                </>
            }
        </Grid>
    );
}

function CustomDatePicker({
    selectedDate,
    onChange,
    label,
    emptyLabel,
    inputVariant = "outlined",
    margin = "dense",
    minDate,
    maxDate,
    disabled = false,
    readOnly = false,
    required = false,
    className,
    InputClassName,
    yearMonthOnly = false,
    style
}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const adornmentRef = React.createRef();
    const [resetOffset, setResetOffset] = useState(0);

    const getResetClassname = () => {
        return inputVariant === "standard"
            ? classes.resetStandard
            : (
                inputVariant === "outlined"
                    ? classes.resetOutlined
                    : classes.resetFilled
            );
    };

    const getResetTopPosition = () => {
        return margin === "dense"
            ? `${8 + resetOffset}px`
            : `${16 + resetOffset}px`;
    };

    useEffect(() => {
        setResetOffset(Math.ceil((adornmentRef?.current?.offsetTop || 0) / 2) * 2);
    }, [adornmentRef]);

    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="fr">
            <Box style={{ position: "relative" }}>
                <DatePicker
                    style={style}
                    className={className}
                    variant="inline"
                    inputVariant={inputVariant}
                    ToolbarComponent={CustomToolbarComponent}
                    format={yearMonthOnly ? "MMMM yyyy" : "DD/MM/yyyy"}
                    views={yearMonthOnly ? ["year", "month"] : ["year", "month", "date"]}
                    margin={margin}
                    label={label}
                    emptyLabel={emptyLabel}
                    value={selectedDate}
                    onChange={onChange}
                    InputProps={{
                        endAdornment: (
                            <div ref={adornmentRef}>
                                <InputAdornment
                                    position="end"
                                    className={(!disabled && selectedDate) ? classes.adornment : null}
                                >
                                    <CalendarTodayIcon />
                                </InputAdornment>
                            </div>
                        ),
                        className: InputClassName
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    autoOk={true}
                    fullWidth
                    disabled={disabled}
                    error={false}
                    helperText={null}
                    readOnly={readOnly}
                />
                {
                    (!disabled && !readOnly && !required) &&
                    selectedDate &&
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => onChange(null)}
                        className={clsx(classes.reset, getResetClassname())}
                        style={{ top: getResetTopPosition(adornmentRef?.current?.offsetTop || 0) }}
                    >
                        <Tooltip
                            title={t('clear')}
                            placement="top"
                        >
                            <CloseIcon />
                        </Tooltip>
                    </Link>
                }
            </Box>
        </MuiPickersUtilsProvider>
    );
}

export default CustomDatePicker;
