import {
    SET_ALL_OPERATIONAL_RT_PROJECT_CONSTRUCTION,
    SET_ALL_OPERATIONAL_RT_PROJECT_PERIMETRE,
    SET_ALL_OPERATIONAL_RT_PROJECT_PLANNING,
    SET_ALL_OPERATIONAL_RT_PROJECT_STAFFING,
    SET_ALL_OPERATIONAL_RT_RELATION_CLIENT,
    SET_ALL_OPERATIONAL_RT_MAITRISE_SOLUTION,
    SET_ALL_RT_ACTION_PRIORITY,
    SET_ALL_RT_ACTION_STATUS,
    SET_ALL_RT_ACTION_TYPES,
    SET_ALL_RT_RISK_PRIORITY,
    SET_ALL_RT_RISK_STATUS,
    SET_ALL_RT_RISK_PROBABILITY,
    SET_ALL_RT_RISK_CATEGORY
} from '../../constants';

const initialState = {
    projectConstruction: [],
    projectPerimetre: [],
    projectPlanning: [],
    projectStaffing: [],
    relationClient: [],
    maitriseSolution: [],
    actionPriorities: [],
    actionStatus: [],
    actionTypes:[],
    riskStatus: [],
    riskPriorities: [],
    riskProbabilities: [],
    riskCategories: []
};

export default (state = initialState, action) => {
    switch(action.type) {
        case SET_ALL_OPERATIONAL_RT_PROJECT_CONSTRUCTION:
            return setAllOperationnalProjectConstruction(state, action.payload);
        case SET_ALL_OPERATIONAL_RT_PROJECT_PERIMETRE:
            return setAllOperationnalProjectPerimetre(state, action.payload);
        case SET_ALL_OPERATIONAL_RT_PROJECT_PLANNING:
            return setAllOperationnalProjectPlanning(state, action.payload);
        case SET_ALL_OPERATIONAL_RT_PROJECT_STAFFING:
            return setAllOperationnalProjectStaffing(state, action.payload);
        case SET_ALL_OPERATIONAL_RT_RELATION_CLIENT:
            return setAllOperationalRelationClient(state, action.payload);
        case SET_ALL_OPERATIONAL_RT_MAITRISE_SOLUTION:
            return setAllOperationalMaitriseSolution(state, action.payload);
        case SET_ALL_RT_ACTION_PRIORITY:
            return setAllActionPriority(state, action.payload);
        case SET_ALL_RT_ACTION_STATUS:
            return setAllActionStatus(state, action.payload);
        case SET_ALL_RT_ACTION_TYPES:
            return setAllActionTypes(state,action.payload)
        case SET_ALL_RT_RISK_STATUS:
            return setAllRiskStatus(state, action.payload);
        case SET_ALL_RT_RISK_PRIORITY:
            return setAllRiskPriority(state, action.payload);
        case SET_ALL_RT_RISK_PROBABILITY:
            return setAllRiskProbability(state, action.payload);
        case SET_ALL_RT_RISK_CATEGORY:
            return setAllRiskCategory(state, action.payload);
        default:
            return state;
    }
};

const setAllOperationnalProjectConstruction = (state, payload) => {
    return {
        ...state,
        projectConstruction: payload
    };
};

const setAllOperationnalProjectPerimetre = (state, payload) => {
    return {
        ...state,
        projectPerimetre: payload
    };
};

const setAllOperationnalProjectPlanning = (state, payload) => {
    return {
        ...state,
        projectPlanning: payload
    };
};

const setAllOperationnalProjectStaffing = (state, payload) => {
    return {
        ...state,
        projectStaffing: payload
    };
};

const setAllOperationalRelationClient = (state, payload) => {
    return {
        ...state,
        relationClient: payload
    };
};

const setAllOperationalMaitriseSolution = (state, payload) => {
    return {
        ...state,
        maitriseSolution: payload
    };
};

const setAllActionPriority = (state, payload) => {
    return {
        ...state,
        actionPriorities: payload
    };
};

const setAllActionStatus = (state, payload) => {
    return {
        ...state,
        actionStatus: payload
    };
};

const setAllActionTypes = (state, payload) => {
    return {
        ...state,
        actionTypes: payload
    };
}
const setAllRiskStatus = (state, payload) => {
    return {
        ...state,
        riskStatus: payload
    };
};

const setAllRiskPriority = (state, payload) => {
    return {
        ...state,
        riskPriorities: payload
    };
};

const setAllRiskProbability = (state, payload) => {
    return {
        ...state,
        riskProbabilities: payload
    };
};

const setAllRiskCategory = (state, payload) => {
    return {
        ...state,
        riskCategories: payload
    };
};