import { getInitialPagination } from "../../../../../utils/common-state";
import {
    CLEAR_OPERATIONAL_PROJECT_ACTIONS,
    CLEAR_OPERATIONAL_PROJECT_ACTIONS_PAGINATION,
    CLEAR_SELECTED_OPERATIONAL_PROJECT_ACTION,
    SET_OPERATIONAL_PROJECT_ACTIONS,
    SET_OPERATIONAL_PROJECT_ACTIONS_PAGINATION,
    SET_SELECTED_OPERATIONAL_PROJECT_ACTION,
    SET_ALL_PROJECTS,
    SET_OPERATIONAL_PROJECT_ACTIONS_SORT,
    CLEAR_OPERATIONAL_PROJECT_ACTIONS_SORT,
    SET_OPERATIONAL_PROJECT_ACTIONS_FILTER,
    CLEAR_OPERATIONAL_PROJECT_ACTIONS_FILTER,
    CLEAR_SELECTED_OPERATIONAL_PROJECT,
    SET_OPERATIONAL_RISK_ACTIONS
} from "../../constants";


const initialState = {
    config: {
        pagination: getInitialPagination(false)
    },
    list: {
        sort: {
            col: { apiName: 'createdAt' },
            type: "desc"
        },
        filter: {
            projects: [],
            search: "",
            responsables: [],
            echeance: "",
            priorities: [],
            types: [],
            status: []
        },
        page: 0,
        size: getInitialPagination(false).size,
        actions: []
    },
    selected: null,
    projects: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_OPERATIONAL_PROJECT_ACTIONS:
            return setOperationalProjectActions(state, action.payload);
        case SET_OPERATIONAL_RISK_ACTIONS:
            return setOperationalRiskActions(state, action.payload);
        case SET_SELECTED_OPERATIONAL_PROJECT_ACTION:
            return setSelectedOperationalProjectAction(state, action.payload);
        case SET_OPERATIONAL_PROJECT_ACTIONS_PAGINATION:
            return setOperationalProjectActionsPagination(state, action.payload);
        case CLEAR_OPERATIONAL_PROJECT_ACTIONS:
            return clearOperationalProjectActions(state);
        case CLEAR_SELECTED_OPERATIONAL_PROJECT_ACTION:
            return clearSelectedOperationalProjectActions(state);
        case CLEAR_OPERATIONAL_PROJECT_ACTIONS_PAGINATION:
            return clearOperationalProjectActionsPagination(state);
        case SET_ALL_PROJECTS:
            return setAllProjects(state, action.payload);
        case SET_OPERATIONAL_PROJECT_ACTIONS_SORT:
            return setActionsSort(state, action.payload);
        case CLEAR_OPERATIONAL_PROJECT_ACTIONS_SORT:
            return clearActionsSort(state);
        case SET_OPERATIONAL_PROJECT_ACTIONS_FILTER:
            return setActionsFilter(state, action.payload);
        case CLEAR_OPERATIONAL_PROJECT_ACTIONS_FILTER:
            return clearActionsFilter(state);
        default: return state;
    }
};

const setOperationalProjectActions = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: {
                ...state.config.pagination,
                page: parseInt(payload.page),
                size: parseInt(payload.size),
                maxLength: parseInt(payload.maxLength)
            }
        },
        list: {
            ...state.list,
            page: parseInt(payload.page),
            size: parseInt(payload.size),
            actions: payload.actions
        }
    };
};

const setOperationalRiskActions = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: {
                ...state.config.pagination,
                page: parseInt(payload.page),
                size: parseInt(payload.size),
                maxLength: parseInt(payload.maxLength)
            }
        },
        list: {
            ...state.list,
            page: parseInt(payload.page),
            size: parseInt(payload.size),
            actions: payload.actions
        }
    };
};
const setSelectedOperationalProjectAction = (state, payload) => {
    return {
        ...state,
        selected: payload
    };
};

const setOperationalProjectActionsPagination = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: payload
        }
    };
};

const clearOperationalProjectActions = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            page: initialState.list.page,
            size: initialState.list.size,
            actions: initialState.list.actions
        }
    };
};

const clearSelectedOperationalProjectActions = (state) => {
    return {
        ...state,
        selected: initialState.selected
    };
};

const clearOperationalProjectActionsPagination = (state) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: getInitialPagination(false)
        }
    };
};

const setAllProjects = (state, payload) => {
    return {
        ...state,
        projects: payload
    };
};

const setActionsSort = (state, sort) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: sort
        }
    };
};

const clearActionsSort = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: initialState.list.sort
        }
    };
};

const setActionsFilter = (state, filter) => {
    return {
        ...state,
        list: {
            ...state.list,
            filter: filter
        }
    };
};

const clearActionsFilter = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            filter: initialState.list.filter
        }
    };
};
