import {
    CLEAR_SELECTED_FINANCIAL_RT_COST_PROFILE,
    SET_ALL_FINANCIAL_COST_PROFILE_SORT,
    SET_ALL_FINANCIAL_RT_COST_PROFILE,
    SET_ALL_SALE_TYPES,
    SET_SELECTED_FINANCIAL_RT_COST_PROFILE
} from '../../constants';

const initialState = {
    costProfiles: [],
    sort: {
        col: { id:0, apiName: 'label' },
        type: "asc"
    },
    selectedCostProfile:[],
    saleTypes: []
};

export default (state = initialState, action) => {
    switch(action.type) {
        case SET_ALL_FINANCIAL_RT_COST_PROFILE:
            return SetAllFinancialRtCostProfile(state, action.payload);
        case SET_SELECTED_FINANCIAL_RT_COST_PROFILE:
            return SetSelectedFinancialRtCostProfile(state, action.payload);
        case SET_ALL_SALE_TYPES:
            return setAllSaleTypes(state, action.payload);
        case SET_ALL_FINANCIAL_COST_PROFILE_SORT:
            return setAllFinancialRtCostProfileSort(state, action.payload);  
        case CLEAR_SELECTED_FINANCIAL_RT_COST_PROFILE:
            return ClearSelectedFinancialRtCostProfile(state, action.payload);
        default:
            return state;
    }
};

const SetAllFinancialRtCostProfile = (state, payload) => {
    return {
        ...state,
        costProfiles: payload
    };
};

const SetSelectedFinancialRtCostProfile = (state, payload) => {
    return {
        ...state,
        selectedCostProfile: payload
    };
};

const setAllSaleTypes = (state, payload) => {
    return {
        ...state,
        saleTypes: payload
    };
};

const setAllFinancialRtCostProfileSort = (state, sort) => {
    return {
        ...state,
        sort
    };
};

const ClearSelectedFinancialRtCostProfile = (state, sort) => {
    return {
        ...state,
        selectedCostProfile: initialState.selectedCostProfile
    };
}