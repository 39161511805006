import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import useStyles from './style';
import NotifModal from '../../../../App/components/NotifModal';
import { getGlobalOperationalIndicators } from '../../redux/actions/indicators';
import ActionsOrRisks from "../../../../App/components/ActionsOrRisks";
import {
    clearOperationalProjectRisksFilter,
    clearOperationalProjectRisksPagination,
    clearSelectedOperationalProjectRisk,
    deleteMultipleOperationalProjectRisks, deleteOperationalProjectRisk,
    exportOperationalProjectRisks,
    getOperationalProjectRisks,
    getSelectedOperationalProjectRisk, postOperationalProjectRisk,
    putOperationalProjectRisk,
    setOperationalProjectRisksFilter,
    setOperationalProjectRisksPagination,
    setOperationalProjectRisksSort,
    setSelectedOperationalProjectRisk
} from "../../redux/actions/risks";
import {columns, globalColumns} from "../../../../App/components/ActionsOrRisks/riskColumns";

const genericModal = {
    type: "error",
    open: false,
    title: null,
    body: null,
    showCloseButton: true,
    onChange: null,
    ok: {
        title: null,
        onClick: null
    },
    confirm: {
        title: null,
        onClick: null
    },
    cancel: {
        title: null,
        onClick: null
    }
};

function GlobalRisks() {

    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [deleteModal, setDeleteModal] = useState(genericModal);
    const riskStatusList = useSelector(({ operational }) => operational.rt.riskStatus);
    const riskFilter = useSelector(({operational}) => operational.risks.list.filter);
    const riskPriorityList = useSelector(({ operational }) => operational.rt.riskPriorities);
    const riskProbabilitiesList = useSelector(({ operational }) => operational.rt.riskProbabilities);
    const riskCategoriesList = useSelector(({ operational }) => operational.rt.riskCategories);
    const currentRisk = useSelector(({ operational }) => operational.risks.selected);


    const handleDeleteModalChange = (open) => {
        setDeleteModal({
            ...deleteModal,
            open: open
        });
    };

    useEffect(() => {
        dispatch(getGlobalOperationalIndicators());
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid container className={classes.container}>
                <Grid container item xs={12}>
                    <Typography className={classes.title}>{t('risks')}</Typography>
                </Grid>
                <Grid item xs={12}>
                   <ActionsOrRisks
                        risk={true}
                        global={true}
                        deleteModal={deleteModal}
                        setDeleteModal={setDeleteModal}
                        handleDeleteModalChange={handleDeleteModalChange}
                        setOperationalProjectFilter={setOperationalProjectRisksFilter}
                        clearSelectedOperationalProject={clearSelectedOperationalProjectRisk}
                        deleteMultipleOperationalProject={deleteMultipleOperationalProjectRisks}
                        getOperationalProject={getOperationalProjectRisks}
                        clearOperationalProjectPagination={clearOperationalProjectRisksPagination}
                        exportOperationalProject={exportOperationalProjectRisks}
                        putOperationalProject={putOperationalProjectRisk}
                        getSelectedOperationalProject={getSelectedOperationalProjectRisk}
                        setOperationalProjectSort={setOperationalProjectRisksSort}
                        setOperationalProjectPagination={setOperationalProjectRisksPagination}
                        setSelectedOperationalProject={setSelectedOperationalProjectRisk}
                        deleteOperationalProject={deleteOperationalProjectRisk}
                        postOperationalProject={postOperationalProjectRisk}
                        clearOperationalProjectFilter={clearOperationalProjectRisksFilter}
                        statusList={riskStatusList}
                        priorityList={riskPriorityList}
                        riskProbabilitiesList={riskProbabilitiesList}
                        riskCategoriesList={riskCategoriesList}
                        filter={riskFilter}
                        currentActionOrRisk={currentRisk}
                        fileName={`${t('risks')} - ${t("all")}`}
                        projectFileName={t('risks')}
                        addRiskOrAction={t('add_risk')}
                        title='Risques'
                        deleteSelectionModal={t('delete_actions_selection_modal')}
                        modalOfDelete={t('delete_risk_modal')}
                        col={t('risks_col')}
                        columns={columns}
                        globalColumns={globalColumns}
                    />
                </Grid>
            </Grid>

            <NotifModal {...deleteModal} />
        </>

    );

}

export default GlobalRisks;