import React from "react";
import { Droppable } from 'react-beautiful-dnd'
import {Typography,Grid} from "@material-ui/core";
import TaskList from "../TaskList";
import useStyles from "../style";
function Column({ column, tasks ,deleteModal,handleDeleteModalChange,setDeleteModal,risk,putOperationalProject}) {
    const classes = useStyles();

    return (
        <Grid
           container
            item
            xs={4}
            alignContent='flex-start'
            style={{minHeight:'300px'}}
        >
            <Droppable droppableId={'column-' + column.id}>
                {(provided) => (
                    <Grid
                        item
                        container
                        direction='row'
                        xs={12}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{backgroundColor:'rgba(238,238,238,0.78)'}}
                        className={classes.columnGrid}
                    >
                        <Grid container item direction='row' xs={12}>
                            <Typography
                                variant='body1'
                                className={classes.columnName}
                            >
                                {column.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.taskListGrid}>
                            <TaskList  tasks={tasks} columnTitle={column.name}
                                       deleteModal={deleteModal}
                                       setDeleteModal={setDeleteModal}
                                       handleDeleteModalChange={handleDeleteModalChange}
                                       putOperationalProject={putOperationalProject}
                                       risk={risk}
                            />
                        </Grid>
                        {provided.placeholder}
                    </Grid>
                )}
            </Droppable>
        </Grid>
    )
}

export default Column
