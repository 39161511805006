import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogRisks from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {Box, Button, CircularProgress, Dialog, Grid, IconButton, Link, MenuItem, TextField, Tooltip, Typography} from '@material-ui/core';
import useStyles from './style';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import {Link as RouterLink} from 'react-router-dom';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import ProjectSelect from "../../../ProjectSelect";
import ResponsableSelect from "../../../ResponsableSelect";
import CustomDatePicker from "../../../CustomDatePicker";
import getColorForPercent from "../../../../../utils/getColorForPercent";
import AddActionComment from "../../../AddActionComment";
import {STATUS_COMPLETED} from "../actionsStatuses";
import {STATUS_CLOSED} from "../riskStatuses";
import { isRiskHasActions } from '../../../../../views/Operational/redux/actions/risks';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {children}
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const Dialogrisks = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogRisks);


function ActionOrRiskModal({
                               global,
                               isRisk,
                               riskField,
                               references,
                               updateRiskActions,
                               riskName,
                               setModalOpen,
                               modalOpen,
                               handleModalClose,
                               add = false,
                               deleteModal,
                               setDeleteModal,
                               handleDeleteModalChange,
                               risk,
                               postOperationalProject,
                               clearOperationalProjectFilter,
                               deleteOperationalProject,
                               putOperationalProject,
                               modalOfDelete, addActionOrRisk, col,
                               priorityList, statusList, riskProbabilitiesList, currentActionOrRisk, actionTypeList,
                               riskCategoriesList,haveActionsOrRisksUpdated
                           }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation();
    const project = useSelector(({operational}) => operational.projects.selected);
    const [edit, setEdit] = useState(false);
    const [create, setCreate] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [comment, setComment] = useState('');
    const [actionOrRiskComment, setActionOrRiskComment] = useState('');
    const [actionOrRiskStatus, setActionOrRiskStatus] = useState('');
    const [openAddCommentDialog, setAddCommentDialog] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    const getActionOrRiskPriorityDefault = () => {
        if (priorityList?.length > 0) {
            return priorityList.sort((a, b) => a.orderPercent - b.orderPercent)[0];
        }
        return null;
    };

    const getRiskProbabilityDefault = () => {
        if (riskProbabilitiesList?.length > 0) {
            return riskProbabilitiesList?.sort((a, b) => a.orderPercent - b.orderPercent)[0];
        }
        return null;
    };

    const getRiskCategoryDefault = () => {
        if (riskCategoriesList?.length > 0) {
            return riskCategoriesList?.sort((a, b) => a.orderPercent - b.orderPercent)[0];
        }
        return null;
    };

    const getActionTypeDefault = () => {
        if (!risk && actionTypeList.length > 0) {
            return actionTypeList.sort((a, b) => a.orderPercent - b.orderPercent)[0];
        }
        return null;
    };

    const getActionsOrRiskStatusDefault = () => {
        if (statusList?.filter(s => s.notify === true).length > 0) {
            return statusList.filter(s => s.notify === true)[0];
        } else if (statusList?.length > 0) {
            return statusList[0];
        }
        return null;
    };
    const emptyActionOrRisk = {
        name: "",
        description: "",
        responsable: null,
        comment:null,
        echeance: null,
        priority: getActionOrRiskPriorityDefault(),
        status: getActionsOrRiskStatusDefault(),
        probability: risk ? getRiskProbabilityDefault() : null,
        category: risk ? getRiskCategoryDefault() : null,
        reminder: true,
        type: risk ? null : getActionTypeDefault(),
        project: global ? null : { id: project?.id },
        risk: { name: '' }
    };
    const [actionOrRisk, setActionOrRisk] = useState(emptyActionOrRisk);

    useEffect(()=>{
        if(references)
            setActionOrRisk(emptyActionOrRisk)
    },[references])

    const openEdit = () => {
        setEdit(true);
        setCreate(false);
    };
    const closeEdit = () => {
        handleModalClose()
        setEdit(false);
        setActionOrRisk(currentActionOrRisk);
        setDisabled(false);
    };

    const openCreate = () => {
        setCreate(true);
        setEdit(false);
        setActionOrRisk({...currentActionOrRisk , comment: null});
        setActionOrRiskComment(null);
    };
    const closeCreate = () => {
        if (create) {
            setCreate(false);
            handleModalClose();
        }
        setDisabled(false);
    };

    const createActionOrRisk = () => {

        dispatch(postOperationalProject({...actionOrRisk,
            status: (actionOrRisk.status.code === STATUS_COMPLETED || actionOrRisk.status.code === STATUS_CLOSED)
                ? emptyActionOrRisk.status : actionOrRisk.status},
            global));
        dispatch(clearOperationalProjectFilter());
        closeModal();
    };

    const deleteActionOrRisk = () => {
        setLoadingDelete(true) 
        const deleteActionOrRiskPromise = risk ? dispatch(isRiskHasActions(actionOrRisk.id)) : Promise.resolve({ data: true })
        deleteActionOrRiskPromise.then(({data})=>{
            setLoadingDelete(false)
            let hasActions = !data
            
            setDeleteModal({
                ...deleteModal,
                open: true,
                type: "error",
                title: t('suppression'),
                body: <span>{modalOfDelete} <b>{actionOrRisk.name}</b> {hasActions && t("delete_risk_that_have_actions_modal")}, {t('continue_question')}</span>,
                showCloseButton: true,
                onChange: handleDeleteModalChange,
                cancel: {
                    title: t('cancel'),
                    onClick: null
                },
                confirm: {
                    title: t('confirm'),
                    onClick: () => {
                        closeModal();
                        handleDeleteModalChange(false);
                        dispatch(deleteOperationalProject(actionOrRisk.id, !global && actionOrRisk.project.id)).then(()=>{
                            haveActionsOrRisksUpdated(true)

                        });
                    }
                }
            });
        })
    };

    const closeModal = () => {
        setEdit(false);
        setCreate(false);
        handleModalClose();
    };

    const updateActionOrRisk = () => {
        if((actionOrRisk.status.code === STATUS_COMPLETED && actionOrRiskStatus !== STATUS_COMPLETED) ||
            (actionOrRisk.status.code === STATUS_CLOSED && actionOrRiskStatus !== STATUS_CLOSED)){
            setAddCommentDialog(true);
            setActionOrRiskComment(actionOrRisk.comment);
        }
        else
        {
            setActionOrRiskStatus(actionOrRisk.status.code);
            if(JSON.stringify(actionOrRisk) !== JSON.stringify(currentActionOrRisk))
                dispatch(putOperationalProject(actionOrRisk, global)).then(()=>{
                    haveActionsOrRisksUpdated(true);
                });
            
        }
        setEdit(false);
        setModalOpen(false);
    };


    const handleResponsableChange = (value) => {
        setActionOrRisk({...actionOrRisk, responsable: value});
    };

    const handleProjectChange = (value) => {
        setActionOrRisk({...actionOrRisk, project: value});
        setDisabled(value === null || actionOrRisk.name === "");
    };

    const onClose = ()=>{
        dispatch(putOperationalProject(actionOrRisk, global)).then(()=>{
            haveActionsOrRisksUpdated(true);
        });
        setAddCommentDialog(false);
    }

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleAddComment = () => {
        setActionOrRiskComment(comment);
        dispatch(putOperationalProject({...actionOrRisk,comment:comment}, global)).then(()=>{
            haveActionsOrRisksUpdated(true);
        });
        setAddCommentDialog(false)
        setComment("")
    };

    useEffect(() => {
        if (currentActionOrRisk) {
            setActionOrRiskStatus(currentActionOrRisk.status?.code);
            setActionOrRiskComment(currentActionOrRisk.comment);
            setActionOrRisk(currentActionOrRisk);
        }
        setCreate(add);
        if (add) {
            setActionOrRiskComment('');
            setActionOrRisk(emptyActionOrRisk);
        }
    }, [currentActionOrRisk, add]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
        <Dialog
            fullWidth={true}
            maxWidth="md"
            onClose={handleModalClose}
            open={modalOpen}
            disableBackdropClick
        >
            <DialogTitle onClose={closeModal} style={{backgroundColor: "#f5ffff"}}>
                <Grid container spacing={2} alignItems="center">
                 
                    <Grid item>
                        {
                            create
                                ? <Typography variant="h6" color="primary"> {addActionOrRisk} </Typography>
                                : <Typography variant="h6" color="primary"> {actionOrRisk.name} </Typography>
                        }
                        {
                            !global &&
                            <Typography color="textSecondary">{project && project.name}</Typography>
                        }
                  
                    </Grid>
                    
                    {
                        !create && !edit && !global &&
                        <Grid item>
                            <Tooltip title={addActionOrRisk}>
                                <IconButton color="primary" onClick={openCreate}>
                                    <AddIcon/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    }
                </Grid>
            </DialogTitle>
            <DialogContent style={{padding: 0}}>
                <Grid container spacing={0} className={classes.riskDetails}>
                    {
                        !isRisk && global ? 
                        <Grid container item xs={12} alignItems="center"
                              style={{borderBottom: "2px solid #eee", padding: "15px"}}>
                            <Grid item xs={2}>
                                <Typography className={classes.colName}> {t('project_col')} </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                {
                                    create || edit
                                        ? <ProjectSelect
                                            value={actionOrRisk.project}
                                            variant="outlined"
                                            size="small"
                                            handleValueChange={handleProjectChange}
                                        />
                                        : <Typography>
                                            <Link to={`/operational/projects/${actionOrRisk.project?.id}`}
                                                  component={RouterLink}>
                                                {actionOrRisk.project?.name || ""}
                                            </Link>
                                        </Typography>
                                }
                            </Grid>
                        </Grid>:<></>
                    }
                    {isRisk?
                    <Grid container item xs={12} alignItems="center" style={{borderBottom: "2px solid #eee", padding: "0 15px"}}>
                        <Grid container item xs={6} alignItems="center" style={{padding: "15px 0",borderRight: "2px solid rgb(238, 238, 238)"}}>
                            <Grid item xs={4}>
                                <Typography className={classes.colName}> {col} </Typography>
                            </Grid>
                            <Grid item xs={8} style={{paddingRight:"25px"}}>
                            {
                                create || edit
                                    ? <TextField
                                        defaultValue={actionOrRisk.name}
                                        placeholder={col}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => {
                                            setActionOrRisk({...actionOrRisk, name: e.target.value});
                                            setDisabled(e.target.value === "" || (!actionOrRisk.project && !isRisk));
                                        }}
                                        fullWidth
                                    />
                                    : <Typography> {actionOrRisk.name} </Typography>
                            }
                            </Grid>
                        </Grid>
                        <Grid container item xs={6} alignItems="center" style={{padding: "15px 0px 15px 25px"}}>
                            <Grid item xs={4}>
                                <Typography className={classes.colName}> {t("risk")} </Typography>
                            </Grid>
                            <Grid item xs={8} style={{paddingRight:"25px"}}>
                            {
                                <Typography> { riskName || actionOrRisk.risk?.name} </Typography>
                            }
                            </Grid>
                        </Grid>
                        

                        
                    </Grid>:
                    <Grid container item xs={12} alignItems="center" style={{borderBottom: "2px solid #eee", padding: "15px"}}>
                        <Grid item xs={2}>
                            <Typography className={classes.colName}> {col} </Typography>
                        </Grid>
                        <Grid item xs={10}>
                        {
                         create || edit
                         ? <TextField
                             defaultValue={actionOrRisk.name}
                             placeholder={col}
                             variant="outlined"
                             size="small"
                             onChange={(e) => {
                                 setActionOrRisk({...actionOrRisk, name: e.target.value});
                                 setDisabled(e.target.value === "" || (!actionOrRisk.project && !isRisk));
                             }}
                             fullWidth
                         />
                         : <Typography>{actionOrRisk.name}</Typography>
                    }
                        </Grid>
                    </Grid>
                    }
                    <Grid container item xs={12} alignItems="center"
                          style={{borderBottom: "2px solid #eee", padding: "15px"}}>
                        <Grid item xs={2}>
                            <Typography className={classes.colName}> {t('description_col')} </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            {
                                create || edit
                                    ? <TextField
                                        defaultValue={actionOrRisk.description}
                                        placeholder={t('description_col')}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => setActionOrRisk({...actionOrRisk, description: e.target.value})}
                                        fullWidth
                                        multiline
                                    />
                                    : <Typography> {actionOrRisk.description} </Typography>
                            }
                        </Grid>
                    </Grid>
                    {actionOrRiskComment && <Grid container item xs={12} alignItems="center"
                           style={{borderBottom: "2px solid #eee", padding: "15px"}}>
                        <Grid item xs={2}>
                            <Typography className={classes.colName}> {t('close_comment')} </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            {
                                edit
                                    ? <TextField
                                        defaultValue={actionOrRisk.comment}
                                        placeholder={t('comment_label')}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) =>
                                            setActionOrRisk({...actionOrRisk, comment: e.target.value})}
                                        fullWidth
                                        multiline
                                    />
                                    : <Typography> {actionOrRiskComment} </Typography>
                            }
                        </Grid>
                    </Grid>}
                    <Grid container item xs={12} alignItems="center"
                          style={{borderBottom: "2px solid #eee", padding: "0 15px"}}>
                        <Grid container item xs={6} alignItems="center"
                              style={{borderRight: "2px solid #eee", padding: "15px 0"}}>
                            <Grid item xs={4}>
                                <Typography className={classes.colName}> {t('responsable_col')} </Typography>
                            </Grid>
                            <Grid item xs={8} style={{paddingRight: 25}}>
                                {
                                    create || edit
                                        ? <ResponsableSelect
                                            usage={edit ? "edit" : "create"}
                                            variant="outlined"
                                            size="small"
                                            value={actionOrRisk.responsable}
                                            handleValueChange={handleResponsableChange}
                                        />
                                        :
                                        <Typography> {actionOrRisk.responsable && `${actionOrRisk.responsable.firstName} ${actionOrRisk.responsable.lastName}`} </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Grid container item xs={6} alignItems="center" style={{padding: "15px 0 15px 25px"}}>
                            <Grid item xs={4}>
                                <Typography className={classes.colName}> {t('echeances_col')} </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {
                                    create || edit
                                        ? <CustomDatePicker
                                            selectedDate={actionOrRisk.echeance}
                                            onChange={value => setActionOrRisk({
                                                ...actionOrRisk,
                                                echeance: value ? moment(value).format("yyyy-MM-DD") : null
                                            })}
                                            emptyLabel={t('echeances_col')}
                                            inputVariant="outlined"
                                        />
                                        :
                                        <Typography> {actionOrRisk.echeance && moment(actionOrRisk.echeance).format("DD-MM-yyyy")} </Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} alignItems="center" style={{padding: "0 15px"}}>
                        <Grid container item xs={6} alignItems="center"
                              style={{borderRight: "2px solid #eee", padding: "15px 0"}}>
                            <Grid item xs={4}>
                                <Typography className={classes.colName}> {t('priorite_col')} </Typography>
                            </Grid>
                            <Grid item xs={8} style={{paddingRight: 25}}>
                                {create || edit ? (
                                    <TextField
                                        select
                                        value={actionOrRisk.priority ? actionOrRisk.priority.name : ""}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={(e) =>
                                            setActionOrRisk({
                                                ...actionOrRisk,
                                                priority: priorityList.find(({name}) => name === e.target.value),
                                            })
                                        }
                                    >
                                        {priorityList.map((item) => (
                                            <MenuItem key={item.id} value={item.name}>
                                                <FiberManualRecordIcon
                                                    style={{color: getColorForPercent(item.orderPercent)}}
                                                    className={classes.priorityIcon}
                                                /> {item.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                ) : (
                                    <Typography>
                                        {actionOrRisk.priority?.name}
                                    </Typography>
                                )}

                            </Grid>
                        </Grid>
                        <Grid container item xs={6} alignItems="center" style={{padding: "15px 0 15px 25px"}}>
                            <Grid item xs={4}>
                                <Typography className={classes.colName}> {t('status_col')} </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {create || edit ? (
                                    <TextField
                                        select
                                        value={actionOrRisk.status ? (create ? emptyActionOrRisk.status.name : actionOrRisk.status.name) : ""}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={(e) =>
                                            setActionOrRisk({
                                                ...actionOrRisk,
                                                status: statusList.find(({name}) => name === e.target.value),
                                            })
                                        }
                                    >
                                        {statusList.map((item) => (
                                            <MenuItem key={item.id} value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                ) : (
                                    <Typography>
                                        {actionOrRisk.status?.name}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} alignItems="center" style={{padding: "0 15px"}}>
                        {!riskField && <Grid container item xs={6} alignItems="center"
                               style={{borderRight: "2px solid #eee", padding: "15px 0"}}>
                            <Grid item xs={4}>
                                <Typography className={classes.colName}> {t('action_type')} </Typography>
                            </Grid>
                            <Grid item xs={8} style={{paddingRight: 25}}>
                                {create || edit ? (
                                    <TextField
                                        select
                                        value={actionOrRisk.type ? actionOrRisk.type.name : ""}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={(e) =>
                                            setActionOrRisk({
                                                ...actionOrRisk,
                                                type: actionTypeList.find(({name}) => name === e.target.value),
                                            })
                                        }
                                    >
                                        {actionTypeList.map((item) => (
                                            <MenuItem key={item.id} value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                ) : (
                                    <Typography>
                                        {actionOrRisk.type?.name}
                                    </Typography>
                                )}

                            </Grid>
                        </Grid>}
                    </Grid>
                    <Grid container item xs={12} alignItems="center" style={{padding: "0 15px"}}>
                        {riskField && <Grid container item xs={6} alignItems="center"
                               style={{borderRight: "2px solid #eee", padding: "15px 0"}}>
                            <Grid item xs={4}>
                                <Typography className={classes.colName}> {t('probability_col')} </Typography>
                            </Grid>
                            <Grid item xs={8} style={{paddingRight: 25}}>
                                {create || edit ? (
                                    <TextField
                                        select
                                        value={actionOrRisk.probability ? actionOrRisk.probability.name : ""}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={(e) =>
                                            setActionOrRisk({
                                                ...actionOrRisk,
                                                probability: riskProbabilitiesList.find(({name}) => name === e.target.value),
                                            })
                                        }
                                    >
                                        {riskProbabilitiesList.map((item) => (
                                            <MenuItem key={item.id} value={item.name}>
                                                <FiberManualRecordIcon
                                                    style={{color: getColorForPercent(item.orderPercent)}}
                                                    className={classes.priorityIcon}
                                                /> {item.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                ) : (
                                    <Typography>
                                        {actionOrRisk.probability?.name}
                                    </Typography>
                                )}

                            </Grid>
                        </Grid>}
                        {riskField && <Grid container item xs={6} alignItems="center" style={{padding: "15px 0 15px 25px"}}>
                             <Grid item xs={4}>
                                 <Typography className={classes.colName}> {t('category_col')} </Typography>
                             </Grid>
                             <Grid item xs={8}>
                                 {create || edit ? (
                                     <TextField
                                         select
                                         value={actionOrRisk.category ? actionOrRisk.category.name : ""}
                                         variant="outlined"
                                         size="small"
                                         fullWidth
                                         onChange={(e) =>
                                             setActionOrRisk({
                                                 ...actionOrRisk,
                                                 category: riskCategoriesList.find(({name}) => name === e.target.value),
                                             })
                                         }
                                     >
                                         {riskCategoriesList.map((item) => (
                                             <MenuItem key={item.id} value={item.name}>
                                                {item.name}
                                             </MenuItem>
                                         ))}
                                     </TextField>
                                 ) : (
                                     <Typography>
                                         {actionOrRisk.category?.name}
                                     </Typography>
                                 )}
                             </Grid>
                         </Grid>}
                    </Grid>
                    <Grid container item xs={12} alignItems="center" style={{padding: "0 15px"}}>
                        <Grid item>
                            {actionOrRisk.responsable && actionOrRisk.echeance &&
                                <Box display="flex" mt={2} alignItems="center">
                                    {
                                        <>
                                            {
                                                actionOrRisk.reminder
                                                    ? <NotificationsActiveIcon fontSize="small" color="primary"/>
                                                    : <NotificationsOffIcon fontSize="small" color="error"/>
                                            }
                                            <Typography variant="subtitle2" color="textSecondary"
                                                        style={{marginLeft: "8px"}}>
                                                {actionOrRisk.reminder ? t('action_reminders_enabled') : t('action_reminders_disabled')}
                                            </Typography>
                                        </>
                                    } {
                                    (create || edit) &&
                                    <Button
                                        variant="outlined"
                                        onClick={() => setActionOrRisk({
                                            ...actionOrRisk,
                                            reminder: !actionOrRisk.reminder
                                        })}
                                        style={{marginLeft: "16px"}}
                                    >
                                        <Typography variant="subtitle2">
                                            {actionOrRisk.reminder ? t('disable') : t('enable')}
                                        </Typography>
                                    </Button>
                                }
                                </Box>}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <Dialogrisks style={{backgroundColor: "#f5ffff"}}>
                {
                    create
                        ? <>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={createActionOrRisk}
                                disabled={disabled || actionOrRisk.name === "" || !actionOrRisk.responsable}
                            >
                                {t('save')}
                            </Button>
                            <Button variant="contained" disableElevation onClick={closeCreate}>
                                {t('cancel')}
                            </Button>
                        </>
                        : edit
                            ? <>
                                <Button variant="contained" disableElevation color="primary" onClick={updateActionOrRisk}
                                        disabled={disabled || actionOrRisk.name === "" || !actionOrRisk.responsable}>
                                    {t('save')}
                                </Button>
                                <Button variant="contained" disableElevation onClick={closeEdit}>
                                    {t('cancel')}
                                </Button>
                                <Button variant="contained" disableElevation className={classes.deleteButton} onClick={deleteActionOrRisk}>
                                    {loadingDelete && <CircularProgress size={19} className={classes.circularProgress}/>} {t('delete')}
                                </Button>
                            </>
                            : <>
                                <Button variant="contained" disableElevation color="primary" onClick={openEdit}>
                                    {t('edit')}
                                </Button>
                                <Button variant="contained" disableElevation className={classes.deleteButton}
                                        onClick={deleteActionOrRisk}>
                                     {loadingDelete && <CircularProgress size={18} className={classes.circularProgress}/>} {t('delete')}
                                </Button>
                            </>

                }
            </Dialogrisks>
        </Dialog>
            <AddActionComment
                open={openAddCommentDialog}
                actionOrRisk={actionOrRisk}
                onClose={onClose}
                handleCommentChange={handleCommentChange}
                handleAddComment={handleAddComment}
                dialogTitle={risk ? t('add_comment_to_risk') : t('add_comment_to_action')}
            />
            </>
    );
}

export default ActionOrRiskModal;
