import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const AddActionComment = ({open,actionOrRisk,handleCommentChange,handleAddComment,onClose,dialogTitle}) => {

    const { t } = useTranslation();

    return(
      <Dialog open={open} fullWidth PaperProps={{ style: { minHeight: '27vh' } }}>
          <DialogTitle>{`${dialogTitle} : ${actionOrRisk?.name}`}</DialogTitle>
          <DialogContent>
              <TextField
                  autoFocus
                  margin="dense"
                  label={t('comment_label')}
                  fullWidth
                  onChange={handleCommentChange}
              />
          </DialogContent>
          <DialogActions>
              <Button variant='contained' onClick={()=>onClose()}>
                  {t('cancel')}
              </Button>
              <Button variant='contained' onClick={handleAddComment} color="primary">
                  {t('add_comment')}
              </Button>
          </DialogActions>
      </Dialog>
  );
}
export default AddActionComment;