import {errorNotification, successNotification} from "../../../../../utils/notification";
import {popUpError} from "../../../../Operational/redux/actions/rt/popUpError";
import {
    DELETE_SALE_TYPE,
    EXPORT_SALE_TYPES,
    GET_ALL_SALE_TYPES,
    IMPORT_SALE_TYPES,
    POST_SALE_TYPE,
    PUT_SALE_TYPE,
    SET_ALL_SALE_TYPES
} from "../../constants";
import {FINANCIAL_RT_ENDPOINT} from "../../constants/endpoint";

export const getAllSaleTypes = () => {
    return {
        type: GET_ALL_SALE_TYPES,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/saleTypes`
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllSaleTypes(response.data));
                return response;
            }
        }
    };
};

export const setAllSaleTypes = (data) => {
    return {
        type: SET_ALL_SALE_TYPES,
        payload: data
    };
};

export const postSaleType = (saleType) => {
    return {
        type: POST_SALE_TYPE,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/saleTypes`,
            method: 'post',
            data: saleType
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "create_success");
                store.dispatch(getAllSaleTypes());
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "create_error");
                throw error;
            }
        }
    };
};

export const putSaleType= (id, saleType) => {
    return {
        type: PUT_SALE_TYPE,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/saleTypes/${id}`,
            method: 'put',
            data: saleType
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "update_success");
                store.dispatch(getAllSaleTypes());
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "update_error");
                throw error;
            }
        }
    };
};

export const deleteSaleType = (id) => {
    return {
        type: DELETE_SALE_TYPE,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/saleTypes/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "delete_success");
                store.dispatch(getAllSaleTypes());
                return response;
            },
            onError: (error, requestAction, store) => {
                popUpError(error,"delete_action_status_error");
            }
        }
    };
};

export const exportSaleTypes = (type) => {
    return {
        type: EXPORT_SALE_TYPES,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/saleTypes/export`,
            responseType: 'arraybuffer'
        },
        meta: {
            onRequest: (request) => {
                if(type)
                    request.url=request.url+"?type="+type
                return request;
            },
            onSuccess: (response) => {
                return response.data;
            }
        }
    };
};
export const importSaleTypes = (data) => {
    return {
        type: IMPORT_SALE_TYPES,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/saleTypes/import`,
            method:"post",
            data:data
        },
        meta: {
            onSuccess: (response,requestAction,store) => {
                store.dispatch(getAllSaleTypes());
                return response.data;
            }
        }
    };
};
