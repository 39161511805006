import {cloneDeep} from "lodash-es";
import {LocalFavoriteProjects} from "../../../../../utils/localStorage";
import {
    CLEAR_FINANCIAL_PROJECTS_FILTER,
    CLEAR_FINANCIAL_PROJECTS_PAGINATION,
    CLEAR_FINANCIAL_PROJECTS_SORT,
    CLEAR_FINANCIAL_SUBPROJECTS_PAGINATION,
    CLEAR_FINANCIAL_SUBPROJECTS_SORT,
    CLEAR_SELECTED_FINANCIAL_PROJECT,
    EXPORT_FINANCIAL_PROJECT_DASHBOARD,
    EXPORT_FINANCIAL_PROJECTS,
    GET_FINANCIAL_PROJECTS,
    GET_FINANCIAL_SUBPROJECTS,
    GET_SELECTED_FINANCIAL_PROJECT,
    SET_FINANCIAL_PROJECTS,
    SET_FINANCIAL_PROJECTS_FILTER,
    SET_FINANCIAL_PROJECTS_GRID_VIEW,
    SET_FINANCIAL_PROJECTS_PAGINATION,
    SET_FINANCIAL_PROJECTS_SORT,
    SET_FINANCIAL_SUBPROJECTS,
    SET_FINANCIAL_SUBPROJECTS_PAGINATION,
    SET_FINANCIAL_SUBPROJECTS_SORT,
    SET_SELECTED_FINANCIAL_PROJECT,
    UPDATE_FINANCIAL_PROJECT,
    UPDATE_FINANCIAL_SUB_PROJECT
} from "../../constants";
import {FINANCIAL_DASHBOARD_PROJECTS_ENDPOINT} from "../../constants/endpoint";
import { updateIsError } from "../../../../../utils/updateIsError500";

const adjustFilter = (filter) => {
    let f = cloneDeep(filter);
    Object.keys(f).forEach(k => {
        if(f[k] === "" || f[k] === null || f[k] === undefined || (Array.isArray(f[k]) && f[k].length === 0) || (typeof f[k] === "object" && Object.keys(f[k]).length === 0)){
            delete f[k];
        }else{
            switch(k){
                case 'risks':
                    f[k] = f[k].join(',');
                    break;
                case 'types':
                    f[k] = f[k].join(',');
                    break;
                case 'clients':
                    f[k] = f[k].map(c => c.name).join(',');
                    break;
                case 'status':
                    f[k] = f[k].join(',');
                    break;
                default:
                    break;
            }
        }
    });


    return f;
};

export const getFinancialProjects = () => {
    return {
        type: GET_FINANCIAL_PROJECTS,
        request: {
            url: FINANCIAL_DASHBOARD_PROJECTS_ENDPOINT
        },
        meta: {
            onRequest: (request, requestAction, store) => {
                const state = store.getState();
                const pagination = state.financial.projects.config.pagination;
                const sort = state.financial.projects.list.sort;
                const filter = adjustFilter(state.financial.projects.list.filter);

                if(filter.search){
                    if(filter.favorites){
                        request.url = `${request.url}/search?name=${filter.search}`;
                        request.params = {
                            favorites: true,
                            parentOnly: false
                        };
                    }else{
                        request.url = `${request.url}/search?sort=${(sort.col && sort.col.apiName) || "name"},${sort.type}&page=${pagination.page}&size=${pagination.size}&name=${filter.search}`;
                        request.params = {
                            favorites: false,
                            parentOnly: filter.parentOnly
                        };
                    }
                }else{
                    if(filter.favorites){
                        request.params = {
                            favorites: true,
                            parentOnly: false
                        };
                    }else{
                        request.url = `${request.url}?sort=${(sort.col && sort.col.apiName) || "name"},${sort.type}&page=${pagination.page}&size=${pagination.size}`;
                        request.params = filter;
                    }
                }
                                
                return request;
            },
            onSuccess: (response, requestAction, store) => {
                const isFavorite = store.getState().financial.projects.list.filter.favorites;
                const favorites = store.getState().favorites.favoriteprojects;
                let projects = [];
                let payload = {};
                
                if(isFavorite){
                    
                    favorites.forEach(({id:favoriteId}, index) => {
                        const matchingProject = response.data.projects.find(({id:projectId}) => projectId === favoriteId);
                        if (matchingProject) {
                          projects.push({ ...matchingProject, favoriteOrder: index });
                        }
                      });
                    
                    payload = {
                        ...response.data,
                        page: 0,
                        size: projects.length,
                        maxLength: projects.length,
                        projects: projects
                    };
                }else{
                    projects = response.data.projects.map(p => ({ ...p, favoriteOrder: favorites.findIndex(f => f.id == p.id)}));
                                        payload = {
                                            ...response.data,
                                            projects: projects
                    };
                }

                store.dispatch(setFinancialProjects(payload));

                return {
                    ...response,
                    data: payload
                };
            }
        }
    };
};

export const setFinancialProjects = (data) => {
    return {
        type: SET_FINANCIAL_PROJECTS,
        payload: data
    };
};
export const getFinancialSubProjects = (projectId) => {
    return {
        type: GET_FINANCIAL_SUBPROJECTS,
        request: {
            url: FINANCIAL_DASHBOARD_PROJECTS_ENDPOINT,
        },
        meta: {
            onRequest: (request, requestAction, store) => {
                const state = store.getState();
                const pagination = state.financial.projects.config.subProjectsPagination;
                const sort = state.financial.projects.subProjects.sort;

                request.url = `${request.url}/${projectId}/subProjects?sort=${(sort.col && sort.col.apiName) || "id"},${sort.type}&page=${pagination.page}&size=${pagination.size}`;
                return request;
            },
            onSuccess: (response, requestAction, store) => {
                let subProjects = [];
                let payload = {};
                
                const favorites = store.getState().favorites.favoriteprojects;
                subProjects = response.data.subProjects.map(p => ({ ...p, favoriteOrder: favorites.findIndex(f => f.id == p.id)}));
                payload = {
                    ...response.data,
                    subProjects
                };
                store.dispatch(setFinancialSubProjects(payload));
                return response;
            }
        }
    };
};

export const updateFinancialProject = (data) => {
    return {
        type: UPDATE_FINANCIAL_PROJECT,
        payload: data
    };
};

export const updateFinancialSubProject = (data) => {
    return {
        type: UPDATE_FINANCIAL_SUB_PROJECT,
        payload: data
    };
};


export const setFinancialSubProjects = (data) => {
    return {
        type: SET_FINANCIAL_SUBPROJECTS,
        payload: data
    };
};

export const getSelectedFinancialProject = (id) => {
    return {
        type: GET_SELECTED_FINANCIAL_PROJECT,
        request: {
            url: `${FINANCIAL_DASHBOARD_PROJECTS_ENDPOINT}/${id}`,
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setSelectedFinancialProject(response.data));
                return response;
            },
            onError: (error,requestAction, store) => {
                updateIsError(error, store)
                throw error;
            }
        }
    };
};

export const setSelectedFinancialProject = (data) => {
    return {
        type: SET_SELECTED_FINANCIAL_PROJECT,
        payload: data
    };
};

export const clearSelectedFinancialProject = () => {
    return {
        type: CLEAR_SELECTED_FINANCIAL_PROJECT
    };
};

export const clearFinancialSubProjectsPagination = () => {
    return {
        type: CLEAR_FINANCIAL_SUBPROJECTS_PAGINATION
    };
};

export const clearFinancialSubProjectsSort = () => {
    return {
        type: CLEAR_FINANCIAL_SUBPROJECTS_SORT
    };
};

export const setFinancialSubProjectsSort = (sort) => {
    return {
        type: SET_FINANCIAL_SUBPROJECTS_SORT,
        payload: sort
    };
};

export const setFinancialSubProjectsPagination = (pagination) => {
    return {
        type: SET_FINANCIAL_SUBPROJECTS_PAGINATION,
        payload: pagination
    };
};

export const setFinancialProjectsPagination = (pagination) => {
    return {
        type: SET_FINANCIAL_PROJECTS_PAGINATION,
        payload: pagination
    };
};

export const clearFinancialProjectsPagination = () => {
    return {
        type: CLEAR_FINANCIAL_PROJECTS_PAGINATION,
    };
};

export const setFinancialProjectsGridView = (gridView) => {
    return {
        type: SET_FINANCIAL_PROJECTS_GRID_VIEW,
        payload: {
            gridView: gridView
        }
    };
};

export const setFinancialProjectsFilter = (filter) => {
    return {
        type: SET_FINANCIAL_PROJECTS_FILTER,
        payload: filter
    };
};

export const clearFinancialProjectsFilter = () => {
    return {
        type: CLEAR_FINANCIAL_PROJECTS_FILTER
    };
};

export const setFinancialProjectsSort = (sort) => {
    return {
        type: SET_FINANCIAL_PROJECTS_SORT,
        payload: sort
    };
};

export const clearFinancialProjectsSort = () => {
    return {
        type: CLEAR_FINANCIAL_PROJECTS_SORT
    };
};

export const exportFinancialProjects = (filter) => {
    Object.keys(filter).forEach(f => (filter[f] === "") && delete filter[f]);
    return {
        type: EXPORT_FINANCIAL_PROJECTS,
        // request: {
        //     url: `${FINANCIAL_DASHBOARD_PROJECTS_ENDPOINT}/export`,
        //     params: filter,
        //     responseType: 'arraybuffer'
        // },
        // meta: {
        //     onSuccess: (response, requestAction, store) => {
        //         return response.data;
        //     }
        // }
    }; 
};

export const exportFinancialProjectDashboard = (projectId, evolutionChartBase64, evolutionChartStartDate, evolutionChartEndDate) => {
    return {
        type: EXPORT_FINANCIAL_PROJECT_DASHBOARD,
        // request: {
        //     url: `${FINANCIAL_DASHBOARD_PROJECTS_ENDPOINT}/${projectId}/exportPDF`,
        //     responseType: 'arraybuffer',
        //     method: 'post',
        //     data: {
        //         base64: evolutionChartBase64,
        //         startDate: evolutionChartStartDate,
        //         endDate: evolutionChartEndDate
        //     }
        // },
        // meta: {
        //     onSuccess: (response, requestAction, store) => {
        //         return response.data;
        //     }
        // }
    };
};
