import { GET_ALL_RT_AGENCIES, SET_ALL_RT_AGENCIES } from '../../constants';
import { RT_ENDPOINT } from '../../constants/endpoint';


export const getAllAgencies = () => {
    return {
        type: GET_ALL_RT_AGENCIES,
        request: {
            url: `${RT_ENDPOINT}/agency`
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllRtAgencies(response.data));
                return response;
            }
        }
    };
};

export const setAllRtAgencies = (data) =>{
    return {
        type: SET_ALL_RT_AGENCIES,
        payload: data
    }
}
export * from './agency';
