import React from "react";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { ADMIN_ROLE } from "../../../utils/constants";
import {LabelImportant} from "@material-ui/icons";

export default [
    {
        name: "projects_list",
        path: "/operational/projects",
        pathFormat: "/operational/projects*",
        icon: <FormatListBulletedIcon />,
        permission: "pilotage.managePilotage",
        roles: []
    },
    {
        name: "snapshots",
        path: "/operational/snapshots",
        pathFormat: "/operational/snapshots*",
        icon: <SettingsBackupRestoreIcon />,
        permission: "pilotage.managePilotage",
        roles: []
    },
    {
        name: "actions",
        path: "/operational/actions",
        pathFormat: "/operational/actions*",
        icon: <AssignmentIcon />,
        permission: "pilotage.managePilotage",
        roles: []
    },
    {
        name: "risks",
        path: "/operational/risks",
        pathFormat: "/operational/risks*",
        icon: <LabelImportant />,
        permission: "pilotage.managePilotage",
        roles: []
    },
    {
        name: "administration",
        path: "/administration",
        pathFormat: "/administration*",
        icon: <SettingsIcon />,
        permission: "pilotage.managePilotage",
        roles: [ADMIN_ROLE]
    },
];
