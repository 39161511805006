import {
    GET_ALL_OPERATIONAL_RT_PROJECT_PLANNING,
    POST_OPERATIONAL_RT_PROJECT_PLANNING,
    SET_ALL_OPERATIONAL_RT_PROJECT_PLANNING,
    PUT_OPERATIONAL_RT_PROJECT_PLANNING,
    DELETE_OPERATIONAL_RT_PROJECT_PLANNING,
    EXPORT_ALL_OPERATIONAL_RT_PROJECT_PLANNING,
    IMPORT_ALL_OPERATIONAL_RT_PROJECT_PLANNING
} from '../../constants';
import {errorNotification, successNotification} from "../../../../../utils/notification";
import { OPERATIONAL_RT_ENDPOINT } from '../../constants/endpoint';
import {popUpError} from "./popUpError";

export const getAllOperationalProjectPlanning = () => {
    return {
        type: GET_ALL_OPERATIONAL_RT_PROJECT_PLANNING,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/projectPlanning`
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllOperationalProjectPlanning(response.data));
                return response;
            }
        }
    };
};

export const setAllOperationalProjectPlanning = (data) => {
    return {
        type: SET_ALL_OPERATIONAL_RT_PROJECT_PLANNING,
        payload: data
    };
};

export const postOperationalProjectPlanning = (projectPlanning) => {
    return {
        type: POST_OPERATIONAL_RT_PROJECT_PLANNING,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/projectPlanning`,
            method: 'post',
            data: projectPlanning
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "create_success");
                store.dispatch(getAllOperationalProjectPlanning());
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "create_error");
                throw error;
            }
        }
    };
};

export const putOperationalProjectPlanning = (id, projectPlanning) => {
    return {
        type: PUT_OPERATIONAL_RT_PROJECT_PLANNING,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/projectPlanning/${id}`,
            method: 'put',
            data: projectPlanning
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "update_success");
                store.dispatch(getAllOperationalProjectPlanning());
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "update_error");
                throw error;
            }
        }
    };
};

export const deleteOperationalProjectPlanning = (id) => {
    return {
        type: DELETE_OPERATIONAL_RT_PROJECT_PLANNING,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/projectPlanning/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "delete_success");
                store.dispatch(getAllOperationalProjectPlanning());
                return response;
            },
            onError: (error, requestAction, store) => {
                popUpError(error,"delete_project_planning_error");
            }
        }
    };
};

export const exportprojectPlanning = (type) => {
    return {
        type: EXPORT_ALL_OPERATIONAL_RT_PROJECT_PLANNING,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/projectPlanning/export`,
            responseType: 'arraybuffer'
        },
        meta: {
            onRequest: (request) => {
                if(type)
                    request.url=request.url+"?type="+type
                return request;
            },
            onSuccess: (response) => {
                return response.data;
            }
        }
    };
};

export const importprojectPlanning= (data) => {
    return {
        type: IMPORT_ALL_OPERATIONAL_RT_PROJECT_PLANNING,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/projectPlanning/import`,
            method:"post",
            data:data
        },
        meta: {
            onSuccess: (response,requestAction,store) => {
                store.dispatch(getAllOperationalProjectPlanning());
                return response.data;
            }
        }
    };
};