import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Tooltip,
    Typography
} from "@material-ui/core";
import {useTranslation} from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import useStyles from './style';
import {useDispatch} from "react-redux";
import moment from "moment";
import {CachedOutlined, KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import getColorForPercent from "../../../../../utils/getColorForPercent";
import ResponsableSelect from "../../../ResponsableSelect";
import ProjectSelect from "../../../ProjectSelect";
import CustomDatePicker from "../../../CustomDatePicker";
import RiskActions from "../../../../../views/Operational/components/Risks/components/RiskActions";
import {
    clearSelectedOperationalProjectAction,
    deleteOperationalProjectAction,
    deleteOperationalRiskAction,
    getOperationalRiskActions,
    postOperationalRiskAction, putOperationalRiskAction
} from "../../../../../views/Operational/redux/actions/actions";
import ActionModal from "../../../../../views/Operational/components/Actions/components/ActionModal";
import AddActionComment from "../../../AddActionComment";
import {isRiskHasActions} from "../../../../../views/Operational/redux/actions/risks";
import { STATUS_CLOSED } from "../riskStatuses";
import { STATUS_COMPLETED } from "../actionsStatuses";


function ActionOrRiskCard({
                      risk,
                      global,
                      actionData,
                      isItemSelected,
                      handleSelect,
                      handleModalOpen,
                      deleteModal,
                      setDeleteModal,
                      handleDeleteModalChange,
                      setSelectedOperationalProject,
                      putOperationalProject,
                      deleteOperationalProject,
                      modalOfDelete,
                      priorityList,
                      statusList,
                      actionTypesList,
                      riskCategoriesList,
                      haveActionsOrRisksUpdated,
                      notifyCommentDialog,
                      selectedActionInRisk,
                      setRiskField,
                  }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const [quickEdit, setQuickEdit] = useState(false);
    const openQuickEdit = () => setQuickEdit(true);
    const [disabled, setDisabled] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [add, setAdd] = useState(false);
    const [riskActions, setRiskActions] = useState([]);
    const [loading,setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const [openAddCommentDialog, setAddCommentDialog] = useState(false);
    const [hasActionsLoading, setHasActionsLoading] = useState(false);
    const [action, setAction] = useState(!risk ? (actionData?.risk === null) && actionData : actionData);
    const [quickAction, setQuickAction] = useState(action ?? action);
    const [errors, setErrors] = useState({ name: false, project: false, responsable: false });
    const [isRiskAction, setIsRiskAction] = useState({
        isTrue : false,
        riskAction : null
    });
    const [actionInRiskPopUp, setActionInRiskPopUp] = useState(false);

    

    const cancelQuickEdit = () => {
        setQuickEdit(false);
        setQuickAction(action);
        setDisabled(false);
    };

    const handleCardClick = () => {
        if (!quickEdit) {
            dispatch(setSelectedOperationalProject(action));
            handleModalOpen();
        }
        return null;
    };

    const updateActionOrRisk = () => {
            if (action && quickAction && (action?.status?.code !== STATUS_COMPLETED && quickAction?.status?.code === STATUS_COMPLETED) ||
                action?.status?.code !== STATUS_CLOSED && quickAction?.status?.code === STATUS_CLOSED){
                    setAddCommentDialog(true)
            } 
            else {

                if(JSON.stringify(quickAction) !== JSON.stringify(action))
                    dispatch(putOperationalProject(quickAction, global)).then(()=>haveActionsOrRisksUpdated(true));
            }
            setQuickEdit(false);
        };

    const deleteActionOrRisk = (actionId, riskId, actionRiskName) => {
        setHasActionsLoading(true);
        const isRiskActionDelete = Boolean(actionId && riskId);

        const actionsPromise = (risk && !isRiskActionDelete) ? dispatch(isRiskHasActions(quickAction.id))
            : Promise.resolve({ data: true });

        actionsPromise.then(({ data }) => {
            const hasActions = (data !== true);
            setHasActionsLoading(false);
            const bodyText = isRiskActionDelete ?
                <span>{t('delete_action_modal')} <b>{actionRiskName}</b>, {t('continue_question')}</span> :
                <span>{modalOfDelete} <b>{quickAction.name}</b> {hasActions ? t('delete_risk_that_have_actions_modal') : null}, {t('continue_question')}</span>;

            setDeleteModal({
                ...deleteModal,
                open: true,
                type: "error",
                title: t('suppression'),
                body: bodyText,
                showCloseButton: true,
                onChange: handleDeleteModalChange,
                cancel: {
                    title: t('cancel'),
                    onClick: null
                },
                confirm: {
                    title: t('confirm'),
                    onClick: () => {
                        setQuickEdit(false);
                        handleDeleteModalChange(false);

                        if (isRiskActionDelete) {
                            dispatch(deleteOperationalRiskAction(actionId, riskId)).then(() => getRiskActionsData());
                        }else {
                            dispatch(deleteOperationalProject(quickAction.id, !global && quickAction.project.id));
                        }
                    }
                }
            });
        });
    };


    const handleResponsableChange = (value) => {
        setErrors({...errors, responsable : value === null})
        setQuickAction({ ...quickAction, responsable: value });
    };

    const handleProjectChange = (value) => {
        setErrors({...errors, project : value === null})
        setQuickAction({...quickAction,project: value});
    };

    function getRiskActionsData() {
        dispatch(getOperationalRiskActions(quickAction.id)).then(({data}) => {
            setLoading(true);
            if (data) {
                setRiskActions(data.actions);
            }
        });
        setLoading(false);
    }

    const getRiskActions = () => {
        setOpen(!open);
        getRiskActionsData();
    }

    const updateRiskActions = ()=>{
        getRiskActionsData();
    }

    const openCreateModal = () => {
        setAdd(true);
        setModalOpen(true);
    }

    const closeModal = () => {
        setModalOpen(false);
        setAdd(false);
        window.history.replaceState(null, "Actions"," ");
        dispatch(clearSelectedOperationalProjectAction())

    }

    const addActionForRisk = (action,riskId)=>{
        dispatch(postOperationalRiskAction(action,riskId)).then(()=>getRiskActionsData());
    }

    const updateActionForRisk = (action)=>{
        dispatch(putOperationalRiskAction(action)).then(()=>getRiskActionsData());
    }
    
    const deleteActionForRisk = (actionId)=>{
        dispatch(deleteOperationalRiskAction(actionId)).then(()=>getRiskActionsData());
    }



    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleAddComment = () => {
        if (isRiskAction.isTrue){
            dispatch(putOperationalRiskAction({...isRiskAction.riskAction, comment: comment},true)).then(()=>{
                getRiskActionsData();
            });
        }
        else {
            dispatch(putOperationalProject({...quickAction, comment:comment},true)).then(()=>{
                haveActionsOrRisksUpdated(true);
            })
        }
        setAddCommentDialog(false);

    };

    const onClose = ()=>{
        
        if (isRiskAction.isTrue){
            dispatch(putOperationalRiskAction(isRiskAction.riskAction,true)).then(()=>{
                getRiskActionsData();
            });
        }
        else {
            dispatch(putOperationalProject(quickAction, global)).then(()=>haveActionsOrRisksUpdated(true));

        }
        setAddCommentDialog(false);
    }

    const addCommentToAction = (riskAction) => {
      if (riskAction){
          setIsRiskAction({
              isTrue: true,
              riskAction: riskAction
          });
          setAddCommentDialog(true);
      }
    }

    useEffect(()=>{
        setDisabled(!Object.values(errors).every(v => v === false))
    },[errors])

    useEffect(()=>{
        if (notifyCommentDialog.hasChanged && notifyCommentDialog.changedQuickActionId === quickAction.id) {
            setAddCommentDialog(true);
        }else
            setAddCommentDialog(false);
    }, [notifyCommentDialog])

    useEffect(() => {
        setQuickAction(action);
    }, [action]);

    return (
    <>
        {quickAction && <Card
            key={quickAction?.id}
            className={classes.root}
            style={{
                flexWrap: "inherit",
                borderLeft: `6px solid ${quickAction.priority ? getColorForPercent(quickAction.priority.orderPercent) : "#000"}`
            }}
            elevation={1}
        >
            {(risk && open) && <IconButton style={{backgroundColor:"transparent"}} aria-label="expand row" size="small" onClick={() =>setOpen(!open)}>
                <KeyboardArrowUp/>
            </IconButton>}
            {(risk && !open) && <IconButton  style={{backgroundColor:"transparent"}} aria-label="expand row" size="small" onClick={()=>{getRiskActions()}}>
                <KeyboardArrowDown/>
            </IconButton>}
            <Grid container alignItems="center" justify="center">
                <Grid item xs={10}>
                    <CardContent className={classes.content} style={{ paddingBottom: "16px" }} onClick={handleCardClick}>
                        <Grid container>
                            <Grid container item xs={2} alignItems="center" justify="center">
                                <Grid item xs={12}>
                                    {
                                        quickEdit
                                            ? <Box mr={1}>
                                                <TextField
                                                    defaultValue={quickAction?.name}
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={(e) => {
                                                        setQuickAction({ ...quickAction, name: e.target.value });
                                                        setErrors({...errors, name: e.target.value === ""})
                                                    }}
                                                    fullWidth
                                                    error={errors.name}
                                                />
                                            </Box>
                                            : <Typography className={classes.actionName}>
                                                {quickAction?.name}
                                            </Typography>
                                    }
                                </Grid>
                            </Grid>
                            {
                                global &&
                                <Grid container item xs={2} alignItems="center" justify="center">
                                    <Grid item xs={12}>
                                        {
                                            quickEdit
                                                ? <Box mr={1}>
                                                    <ProjectSelect
                                                        value={quickAction?.project}
                                                        handleValueChange={handleProjectChange} variant="outlined"
                                                        size="small"
                                                        error={errors.project}
                                                    />
                                                </Box>
                                                :
                                                <Typography align="left">
                                                    {
                                                        quickAction?.project?.name
                                                    }
                                                </Typography>
                                        }
                                    </Grid>
                                </Grid>

                            }
                            <Grid container item xs={2} alignItems="center" justify="center">
                                <Grid item xs={12}>
                                    {
                                        quickEdit
                                            ? <Box mr={1}>
                                                <ResponsableSelect
                                                    usage="edit"
                                                    value={quickAction.responsable}
                                                    handleValueChange={handleResponsableChange}
                                                    variant="outlined"
                                                    size="small"
                                                    error={errors.responsable}
                                                />
                                            </Box>
                                            : < Typography style={{textAlign: 'center',marginLeft:risk && !global ? '-50px' : null}}>
                                                {quickAction.responsable && `${quickAction.responsable.firstName} ${quickAction.responsable.lastName}`}
                                            </Typography>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container item xs={2} alignItems="center" justify="center">
                                <Grid item xs={12}>
                                    {
                                        quickEdit
                                            ? <Box mr={1} mt={-0.5}>
                                                <CustomDatePicker
                                                    selectedDate={quickAction.echeance}
                                                    onChange={value => setQuickAction({
                                                        ...quickAction,
                                                        echeance: value ? moment(value).format("yyyy-MM-DD") : null
                                                    })}
                                                    emptyLabel={t('echeance')}
                                                />
                                            </Box>
                                            : <Typography style={{textAlign: 'center',marginLeft:risk && !global ? '-50px' : null}}>
                                                {quickAction.echeance && moment(quickAction.echeance).format("DD-MM-yyyy")}
                                            </Typography>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container item xs={global ? 1 : 2} alignItems="center" justify="center">
                                <Grid item xs={12}>
                                    {
                                        quickEdit
                                            ? <Box mr={1}>
                                                <TextField
                                                    select
                                                    value={quickAction.priority ? quickAction.priority.name : ""}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    onChange={e => setQuickAction({
                                                        ...quickAction,
                                                        priority: priorityList.find(p => p.name === e.target.value)
                                                    })}
                                                >
                                                    {
                                                        priorityList.map(item =>
                                                            <MenuItem
                                                                key={item.id}
                                                                value={item.name}
                                                            >
                                                                <FiberManualRecordIcon
                                                                    style={{color: getColorForPercent(item.orderPercent)}}
                                                                    className={classes.priorityIcon}
                                                                /> {item.name}
                                                            </MenuItem>)
                                                    }
                                                </TextField>
                                            </Box>
                                            : <Typography style={{textAlign: "center",marginLeft:risk && !global ? '-10px' : null}}>
                                                {quickAction.priority && quickAction.priority.name}
                                            </Typography>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container item xs={global ? 1 : 2} alignItems="center" justify="center">
                                <Grid item xs={12}>
                                    {
                                        quickEdit
                                            ? <Box mr={1}>
                                                <TextField
                                                    select
                                                    value={quickAction.status ? quickAction.status.name : ""}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    onChange={e => setQuickAction({
                                                        ...quickAction,
                                                        status: statusList.find(({name}) => name === e.target.value)
                                                    })}
                                                >
                                                    {
                                                        statusList.map(item =>
                                                            <MenuItem
                                                                key={item.id}
                                                                value={item.name}
                                                            >
                                                                {item.name}
                                                            </MenuItem>)
                                                    }
                                                </TextField>
                                            </Box>
                                            : <Typography style={{textAlign: "center",marginLeft:risk && !global ? '-10px' : null}}>
                                                {quickAction.status && quickAction.status.name}
                                            </Typography>
                                    }
                                </Grid>
                            </Grid>
                            {!risk &&
                                <Grid container item xs={2} alignItems="center" justify="center">
                                    <Grid item xs={12}>
                                        {
                                            quickEdit
                                                ? <Box mr={1}>
                                                    <TextField
                                                        select
                                                        value={quickAction.type ? quickAction.type.name : ""}
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        onChange={e => setQuickAction({
                                                            ...quickAction,
                                                            type: actionTypesList.find(({name}) => name === e.target.value)
                                                        })}
                                                    >
                                                        {
                                                            actionTypesList.map(item =>
                                                                <MenuItem
                                                                    key={item.id}
                                                                    value={item.name}
                                                                >
                                                                    {item.name}
                                                                </MenuItem>)
                                                        }
                                                    </TextField>
                                                </Box>
                                                : <Typography style={{textAlign: "center"}}>
                                                    {quickAction.type && quickAction.type.name}
                                                </Typography>
                                        }
                                    </Grid>
                                </Grid>
                            }
                            {risk &&
                                <Grid container item xs={2} alignItems="center" justify="center">
                                    <Grid item xs={12}>
                                        {
                                            quickEdit
                                                ? <Box mr={1}>
                                                    <TextField
                                                        select
                                                        value={quickAction.category ? quickAction.category.name : ""}
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        onChange={e => setQuickAction({
                                                            ...quickAction,
                                                            category: riskCategoriesList.find(({name}) => name === e.target.value)
                                                        })}
                                                    >
                                                        {
                                                            riskCategoriesList.map(item =>
                                                                <MenuItem
                                                                    key={item.id}
                                                                    value={item.name}
                                                                >
                                                                    {item.name}
                                                                </MenuItem>)
                                                        }
                                                    </TextField>
                                                </Box>
                                                : <Typography style={{textAlign: "center"}}>
                                                    {quickAction.category && quickAction.category.name}
                                                </Typography>
                                        }
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </CardContent>
                </Grid>
                <Grid item xs={2}>
                    <CardActions>
                        <Grid container alignItems="center" justify="center">
                            <Grid item>
                                <Tooltip title={quickEdit ? t('save_quick_edit') : t('quick_edit')} placement="top">
                                    {
                                        quickEdit
                                            ? <IconButton color="primary" onClick={updateActionOrRisk}
                                                          disabled={disabled}>
                                                <DoneIcon/>
                                            </IconButton>
                                            : <IconButton onClick={openQuickEdit}>
                                                <EditIcon/>
                                            </IconButton>
                                    }

                                </Tooltip>
                            </Grid>
                            {
                                quickEdit &&
                                <Grid item>
                                    <Tooltip title={t('cancel')} placement="top">
                                        <IconButton onClick={cancelQuickEdit}>
                                            <ClearIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            }
                            <Grid item>
                                <Tooltip title={t('delete')} placement="top">
                                    <IconButton onClick={deleteActionOrRisk}>
                                        {hasActionsLoading ? <CachedOutlined/>  : <DeleteIcon className={classes.deleteIcon}/>}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Checkbox
                                    checked={isItemSelected}
                                    onChange={(event) => handleSelect(event, quickAction.id)}
                                />
                            </Grid>
                        </Grid>
                    </CardActions>
                </Grid>
            </Grid>
        </Card>}
        <Grid item xs={12}>
            <RiskActions
                riskName={action?.name}
                riskId={quickAction?.id}
                selectedActionInRisk={selectedActionInRisk}
                handleModalOpen={()=>setModalOpen(true)}
                setRiskField={setRiskField}
                setActionInRiskPopUp={setActionInRiskPopUp}
                open={open}
                riskActions={riskActions}
                openCreateModal={openCreateModal}
                deleteAction={deleteActionOrRisk}
                loading={loading}
                updateRiskActions={updateRiskActions}
                addCommentToAction={addCommentToAction}
            />
        </Grid>
        <ActionModal
            riskId={quickAction?.id}
            riskName= {quickAction?.name}
            global={global}
            risk={true}
            add={add}
            open={open}
            modalOpen={modalOpen}
            handleModalClose={closeModal}
            updateRiskActions={updateActionForRisk}
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            handleDeleteModalChange={handleDeleteModalChange}
            addActionForRisk={addActionForRisk}
            deleteActionForRisk={deleteActionForRisk}
        />
        <AddActionComment
            open={openAddCommentDialog}
            actionOrRisk={isRiskAction.isTrue ? isRiskAction.riskAction : quickAction}
            onClose={onClose}
            handleCommentChange={handleCommentChange}
            handleAddComment={handleAddComment}
            dialogTitle={risk && !isRiskAction.isTrue ? t('add_comment_to_risk') : t('add_comment_to_action')}
        />
    </>

    );
}

export default ActionOrRiskCard;
