import React, {useEffect, useState} from "react";
import {Box, IconButton, MenuItem, TableCell, TableRow, TextField, Typography} from "@material-ui/core";
import moment from "moment";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import {useDispatch} from "react-redux";
import useStyles from "../../RiskCard/style";
import {putOperationalRiskAction} from "../../../../../redux/actions/actions";
import ResponsableSelect from "../../../../../../../App/components/ResponsableSelect";
import CustomDatePicker from "../../../../../../../App/components/CustomDatePicker";
import {t} from "../../../../../../../i18n";
import getColorForPercent from "../../../../../../../utils/getColorForPercent";
import {STATUS_COMPLETED} from "../../../../../../../App/components/ActionsOrRisks/components/actionsStatuses";

const Actions = ({action,handleSelectAction,riskId,statusList,priorityList,actionTypesList,deleteAction,updateActions,addCommentToAction})=>{

    const classes = useStyles();
    const dispatch = useDispatch();
    const [editMode,setEditMode] = useState(false);
    const [selectedActionId,setSelectedActionId] = useState(null);
    const [editAction,setEditAction] = useState(action ?? action);
    const [editEcheance,setEditEcheance] = useState(action?.echeance);

    const openQuickEdit= (actionId) => {
        setSelectedActionId(actionId)
        setEditMode(true);
    }

    const handleResponsableChange = (value) => {
        setEditAction({ ...editAction, responsable: value });
    };

    function updateActionOrRisk() {
        if (editAction.status.code === STATUS_COMPLETED && action.status.code !== STATUS_COMPLETED){
            addCommentToAction(editAction);
        }
        else
        {
            if(JSON.stringify(editAction) !== JSON.stringify(action)){
                dispatch(putOperationalRiskAction(editAction)).then(()=>{
                    updateActions();
                    
            });}
        }
    
        setEditMode(false);
    }

    const cancelQuickEdit = () => {
        setEditMode(false);
    } 

    useEffect(()=>{
        setEditAction({...editAction,echeance:editEcheance})
    },[editEcheance])


    return(
        <>
            {action && <TableRow key={action.id} style={{"cursor": "pointer"}}>
                <TableCell align="center">
                    {(editMode && selectedActionId === action.id) ?
                        <TextField
                            defaultValue={action?.name}
                            variant="outlined"
                            size="small"
                            onChange={(e) => {
                                setEditAction({...editAction, name: e.target.value});
                            }}
                            fullWidth/>
                        : <Typography onClick={() => {
                            handleSelectAction(action)
                        }}>
                            {action?.name}
                        </Typography>}
                </TableCell>
                <TableCell align="center">  {
                    (editMode && selectedActionId === action.id)
                        ? <Box width='250px'>
                            <ResponsableSelect
                                usage="edit"
                                value={editAction.responsable ?? editAction.responsable}
                                handleValueChange={handleResponsableChange}
                                variant="outlined"
                                size="small"
                            />
                        </Box>

                        : < Typography align="center" onClick={() => {
                            handleSelectAction(action)
                        }}>
                            {action?.responsable && `${action.responsable?.firstName} ${action.responsable?.lastName}`}
                        </Typography>
                }</TableCell>
                <TableCell align="center"> {
                    (editMode && selectedActionId === action.id)
                        ? <Box mr={1} mt={-0.5}>
                            <CustomDatePicker
                                selectedDate={editEcheance ?? editEcheance}
                                onChange={value => {
                                    setEditEcheance(value ? moment(value).format("yyyy-MM-DD") : null);
                                }}
                                emptyLabel={t('echeance')}
                            />
                        </Box>
                        : <Typography align="center" onClick={() => {
                            handleSelectAction(action)
                        }}>
                            {action.echeance && moment(action.echeance).format("DD-MM-yyyy")}
                        </Typography>
                }</TableCell>
                <TableCell align="center"> {
                    (editMode && selectedActionId === action.id)
                        ? <Box>
                            <TextField
                                select
                                defaultValue={action.priority ? action.priority.name : ""}
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={e => setEditAction({
                                    ...editAction,
                                    priority: priorityList.find(({name}) => name === e.target.value)
                                })}
                            >
                                {
                                    priorityList.map(item =>
                                        <MenuItem
                                            key={item.id}
                                            value={item.name}
                                        >
                                            <FiberManualRecordIcon
                                                style={{color: getColorForPercent(item.orderPercent)}}
                                                className={classes.priorityIcon}
                                            /> {item.name}
                                        </MenuItem>)
                                }
                            </TextField>
                        </Box>
                        : <Typography align="center" onClick={() => {
                            handleSelectAction(action)
                        }}>
                            {action.priority && action.priority.name}
                        </Typography>
                }</TableCell>
                <TableCell align="center">{
                    (editMode && selectedActionId === action.id)
                        ? <Box maxWidth='100px'>
                            <TextField
                                select
                                defaultValue={action.status ? action.status.name : ""}
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={e => setEditAction({
                                    ...editAction,
                                    status: statusList.find(({name}) => name === e.target.value)
                                })}
                            >
                                {
                                    statusList.map(item =>
                                        <MenuItem
                                            key={item.id}
                                            value={item.name}
                                        >
                                            {item.name}
                                        </MenuItem>)
                                }
                            </TextField>
                        </Box>
                        : <Typography align="center" onClick={() => {
                            handleSelectAction(action)
                        }}>
                            {action.status && action.status.name}
                        </Typography>
                }</TableCell>
                <TableCell align="center">{
                    (editMode && selectedActionId === action.id)
                        ? <Box>
                            <TextField
                                select
                                defaultValue={action.type ? action.type.name : ""}
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={e => setEditAction({
                                    ...editAction,
                                    type: actionTypesList.find(({name}) => name === e.target.value)
                                })}
                            >
                                {
                                    actionTypesList.map(item =>
                                        <MenuItem
                                            key={item.id}
                                            value={item.name}
                                        >
                                            {item.name}
                                        </MenuItem>)
                                }
                            </TextField>
                        </Box>
                        : <Typography align="center" onClick={() => {
                            handleSelectAction(action)
                        }}>
                            {action.type && action.type.name}
                        </Typography>
                }</TableCell>
                {(!editMode || selectedActionId !== action.id) && <TableCell align="center">
                    <IconButton onClick={() => {
                        openQuickEdit(action.id);
                    }}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => deleteAction(action.id, riskId, action.name)}>
                        <DeleteIcon className={classes.deleteIcon}/>
                    </IconButton>
                </TableCell>}
                {(editMode && selectedActionId === action.id) && <TableCell align="center">
                    <IconButton color="primary" onClick={updateActionOrRisk}>
                        <DoneIcon/>
                    </IconButton>
                    <IconButton onClick={cancelQuickEdit}>
                        <ClearIcon/>
                    </IconButton>
                </TableCell>}
            </TableRow>}
        </>
    );
}
export default Actions;