import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { setIsFinancialView } from '../../App/appSlice';
import { PrivateRoute } from '../../routes';
import { getGlobalOperationalIndicators } from './redux/actions/indicators';
import routes from './routes';
import useStyles from './style';

function Operational(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const user = useSelector(state => state.Auth.user);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getGlobalOperationalIndicators());

        return () => {
            dispatch(setIsFinancialView(window.location.pathname.startsWith('/financial')));
        };
    }, []);

    
    return (
        <Fragment>
            <div className={classes.container}>
                <Switch>
                    <Redirect exact path="/operational" to="/operational/projects" />
                    {
                        routes.map((route, idx) => {
                            return route.component && route.private ? (
                                <PrivateRoute
                                    {...props}
                                    key={idx}
                                    path={route.path}
                                    exact={route.exact}
                                    name={t(route.name)}
                                    user={user}
                                    history={props.history}
                                    component={route.component}
                                    permission={route.permission}
                                    roles={route.roles}
                                />
                            ) : null;
                        })
                    }
                </Switch>
            </div>
        </Fragment>
    );
}

export default Operational;