import {errorNotification, successNotification} from '../../../utils/notification';
import {
    GET_ALL_RT_PROJECT_RISK,
    SET_ALL_RT_PROJECT_RISK,
    POST_RT_PROJECT_RISK,
    PUT_RT_PROJECT_RISK,
    DELETE_RT_PROJECT_RISK,
    EXPORT_ALL_RT_PROJECT_RISK,
    IMPORT_ALL_RT_PROJECT_RISK
} from '../../constants';
import { RT_ENDPOINT } from '../../constants/endpoint';
import {popUpError} from "../../../views/Operational/redux/actions/rt/popUpError";


export const getAllProjectRisk = () => {
    return {
        type: GET_ALL_RT_PROJECT_RISK,
        request: {
            url: `${RT_ENDPOINT}/projectRisk`
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllProjectRisk(response.data));
                return response;
            }
        }
    };
};

export const setAllProjectRisk = (data) => {
    return {
        type: SET_ALL_RT_PROJECT_RISK,
        payload: data
    };
};

export const postProjectRisk = (projectRisk) => {
    return {
        type: POST_RT_PROJECT_RISK,
        request: {
            url: `${RT_ENDPOINT}/projectRisk`,
            method: 'post',
            data: projectRisk
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "create_success");
                store.dispatch(getAllProjectRisk());
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "create_error");
                throw error;
            }
        }
    };
};

export const putProjectRisk = (id, projectRisk) => {
    return {
        type: PUT_RT_PROJECT_RISK,
        request: {
            url: `${RT_ENDPOINT}/projectRisk/${id}`,
            method: 'put',
            data: projectRisk
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "update_success");
                store.dispatch(getAllProjectRisk());
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "update_error");
                throw error;
            }
        }
    };
};

export const deleteProjectRisk = (id) => {
    return {
        type: DELETE_RT_PROJECT_RISK,
        request: {
            url: `${RT_ENDPOINT}/projectRisk/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "delete_success");
                store.dispatch(getAllProjectRisk());
                return response;
            },
            onError: (error, requestAction, store) => {
                popUpError(error,"delete_project_risk_error");
            }
        }
    };
};

export const exportprojectRisk = (type) => {
    return {
        type: EXPORT_ALL_RT_PROJECT_RISK,
        request: {
            url: `${RT_ENDPOINT}/projectRisk/export`,
            responseType: 'arraybuffer'
        },
        meta: {
            onRequest: (request) => {
                if(type)
                    request.url=request.url+"?type="+type
                return request;
            },
            onSuccess: (response) => {
                return response.data;
            }
        }
    };
};

export const importProjectRisk= (data) => {
    return {
        type: IMPORT_ALL_RT_PROJECT_RISK,
        request: {
            url: `${RT_ENDPOINT}/projectRisk/import`,
            method:"post",
            data:data
        },
        meta: {
            onSuccess: (response,requestAction,store) => {
                store.dispatch(getAllProjectRisk());
                return response.data;
            }
        }
    };
};