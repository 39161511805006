import { errorNotification, successNotification } from '../../../utils/notification';
import { popUpError } from '../../../views/Operational/redux/actions/rt/popUpError';
import {DELETE_RT_ENTITY_AGENCY, EXPORT_RT_ENTITY_AGENCY, GET_ALL_RT_ENTITY_AGENCIES, IMPORT_RT_ENTITY_AGENCY, POST_RT_ENTITY_AGENCY, PUT_RT_ENTITY_AGENCY, SET_ALL_RT_ENTITY_AGENCIES } from '../../constants';
import { RT_ENDPOINT } from '../../constants/endpoint';


export const getAllEntityAgencies = () => {
    return {
        type: GET_ALL_RT_ENTITY_AGENCIES,
        request: {
            url: `${RT_ENDPOINT}/entityAgency`
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllEntityAgencies(response.data));
                return response;
            }
        }
    };
};

export const postEntityAgency = (entityAgency) => {
    return {
        type: POST_RT_ENTITY_AGENCY,
        request: {
            url: `${RT_ENDPOINT}/entityAgency`,
            method: 'post',
            data: entityAgency
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "create_success");
                store.dispatch(getAllEntityAgencies());
                return response;
            },
            onError: (error) => {
                errorNotification('', "create_error");
                throw error;
            }
        }
    };
};

export const putEntityAgency = (id, entityAgency) => {
    return {
        type: PUT_RT_ENTITY_AGENCY,
        request: {
            url: `${RT_ENDPOINT}/entityAgency/${id}`,
            method: 'put',
            data: entityAgency
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "update_success");
                store.dispatch(getAllEntityAgencies());
                return response;
            },
            onError: (error) => {
                errorNotification('', "update_error");
                throw error;
            }
        }
    };
};

export const deleteEntityAgency = (id) => {
    return {
        type: DELETE_RT_ENTITY_AGENCY,
        request: {
            url: `${RT_ENDPOINT}/entityAgency/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "delete_success");
                store.dispatch(getAllEntityAgencies());
                return response;
            },
            onError: (error, requestAction, store) => {
                popUpError(error,"delete_entity_agency_error");
            }
        }
    };
};

export const exportEntityAgencies = (type) => {
    return {
        type: EXPORT_RT_ENTITY_AGENCY,
        request: {
            url: `${RT_ENDPOINT}/entityAgency/export`,
            responseType: 'arraybuffer'
        },
        meta: {
            onRequest: (request) => {
                if(type)
                    request.url=request.url+"?type="+type
                return request;
            },
            onSuccess: (response) => {
                return response.data;
            }
        }
    };
};

export const importEntityAgencies = (data) => {
    return {
        type: IMPORT_RT_ENTITY_AGENCY,
        request: {
            url: `${RT_ENDPOINT}/entityAgency/import`,
            method:"post",
            data:data
        },
        meta: {
            onSuccess: (response,requestAction,store) => {
                store.dispatch(getAllEntityAgencies());
                return response.data;
            }
        }
    };
};

export const setAllEntityAgencies = (data) =>{
    return {
        type: SET_ALL_RT_ENTITY_AGENCIES,
        payload: data
    }
}

export * from './entityAgency';
