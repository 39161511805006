import { onError, onSuccess } from "../../../../../utils/http";
import { errorNotification, successNotification } from "../../../../../utils/notification";
import {
    GET_OPERATIONAL_CONFIG,
    PUT_OPERATIONAL_CONFIG,
    RESET_SYNCHRO,
    SET_OPERATIONAL_CONFIG
} from "../../constants";
import { OPERATIONAL_CONFIG_ENDPOINT } from "../../constants/endpoint";


export const getOperationalConfig = () => {
    return {
        type: GET_OPERATIONAL_CONFIG,
        request: {
            url: `${OPERATIONAL_CONFIG_ENDPOINT}`,
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setOperationalConfig(response.data));
                return response;
            })
        }
    };
};

export const putOperationalConfig = (data) => {
    return {
        type: PUT_OPERATIONAL_CONFIG,
        request: {
            url: `${OPERATIONAL_CONFIG_ENDPOINT}`,
            method: "put",
            data: data
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "update_success");
                store.dispatch(setOperationalConfig(response.data));
                return response;
            }),
            onError: onError(({ response }) => {
                errorNotification('', "update_error");
                return response;
            })
        }
    };
};

export const resetSynchro = () => {
    return {
        type: RESET_SYNCHRO,
        request: {
            url: `${OPERATIONAL_CONFIG_ENDPOINT}/resetSynchro`,
            method: "post",
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(getOperationalConfig())
                successNotification('', "success_initialization_request");
                return response;
            }),
            onError: onError(({ response }) => {
                errorNotification('', "error_initialization_request");
                return response;
            })
        }
    };
};

export const setOperationalConfig = (data) => {
    return {
        type: SET_OPERATIONAL_CONFIG,
        payload: data
    };
};