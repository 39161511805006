import React from "react";
import useStyles from "./style";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { getDefaultOperationalRiskConfig, putDefaultOperationalRiskConfig } from "../../redux/actions/risk";
import { roundCharges } from "../../../../utils/format";


const riskEelemnts = [
    {
        id: 0,
        name: "construction",
        apiName: "constructionCoeff",
    },
    {
        id: 1,
        name: "perimetre",
        apiName: "perimetreCoeff",
    },
    {
        id: 2,
        name: "planning",
        apiName: "planningCoeff",
    },
    {
        id: 3,
        name: "staffing",
        apiName: "staffingCoeff",
    },
    {
        id: 4,
        name: "relation_client",
        apiName: "relationClientCoeff",
    },
    {
        id: 5,
        name: "maitrise_solution",
        apiName: "maitriseSolutionCoeff",
    }
];

export default function DefaultRiskConfig() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [riskConfig, setRiskConfig] = React.useState(null);
    const [edit, setEdit] = React.useState(false);
    const defaultRiskConfig = useSelector(({ operational }) => operational.riskConfig?.riskConfig.default);

    React.useEffect(() => {
        setRiskConfig(defaultRiskConfig);
    }, [defaultRiskConfig]);

    React.useEffect(() => {
        dispatch(getDefaultOperationalRiskConfig());
    }, [dispatch]);

    const cancelEdit = () => {
        setRiskConfig(defaultRiskConfig);
        setEdit(false);
    };

    const save = () => {
        if(JSON.stringify(riskConfig) !== JSON.stringify(defaultRiskConfig)){
            dispatch(putDefaultOperationalRiskConfig(riskConfig)).then((res) => {
                if (res.status === 200) {
                    dispatch(getDefaultOperationalRiskConfig());
                    setEdit(false);
                }
            });
        }
        else
            setEdit(false);
    };

    return (
        <Paper className={classes.paper}>
            <Grid container direction="column">
                <Grid item container justify="space-between" alignItems="flex-start">
                    <Grid item>
                        <Typography variant="h6">{t('default_risks_weights')}</Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title={edit ? t('save') : t('edit')} placement="top">
                            {
                                edit
                                    ? (
                                        <IconButton color="primary" onClick={save}>
                                            <DoneIcon />
                                        </IconButton>
                                    )
                                    : (
                                        <IconButton onClick={() => setEdit(true)}>
                                            <EditIcon />
                                        </IconButton>
                                    )
                            }
                        </Tooltip>
                        {
                            edit &&
                            <Tooltip title={t('cancel')} placement="top">
                                <IconButton onClick={cancelEdit}>
                                    <ClearIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    </Grid>
                </Grid>
                <Grid item>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {
                                        riskEelemnts.map(e =>
                                            <TableCell key={e.id} align="center">{t(e.name)}</TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    {
                                        riskConfig && riskEelemnts.map(el =>
                                            <TableCell key={el.id} align="center">
                                                {
                                                    edit
                                                        ? <TextField
                                                            type="number"
                                                            variant="outlined"
                                                            size="small"
                                                            defaultValue={riskConfig[el.apiName]}
                                                            onChange={(e) => setRiskConfig({ ...riskConfig, [el.apiName]: e.target.value })}
                                                            inputProps={{
                                                                min: 0,
                                                                required: true
                                                            }}
                                                            error={riskConfig[el.apiName] === "" || riskConfig[el.apiName] < 0}
                                                        />
                                                        : t(roundCharges(riskConfig[el.apiName]))
                                                }
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Paper>
    );
}
