export const KEYCLOAK_DISABLED = process.env.REACT_APP_KEYCLOAK_DISABLED;
export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM;
export const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;
export const KEYCLOAK_SSL_REQUIRED =
  process.env.REACT_APP_KEYCLOAK_SSL_REQUIRED;
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
export const KEYCLOAK_PUBLIC_CLIENT =
  process.env.REACT_APP_KEYCLOAK_PUBLIC_CLIENT;

export const API_KEYCLOAK_NAME = process.env.REACT_APP_API_KEYCLOAK_NAME;
