import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        boxShadow: theme.shadows[25],
        padding: theme.spacing(3)
    },
    resetButton: {
       color: theme.palette.primary.main,
       fontSize: 23
    }
}));