import { fade, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root:{
        height: theme.custom.variables.headerHeight,
        backgroundColor: theme.palette.background.darkTransparent,
        // backgroundImage: `linear-gradient(to top, ${theme.palette.background.main} 10%, ${theme.palette.background.darkTransparent}, ${theme.palette.background.darkTransparent}, ${theme.palette.background.darkTransparent})`
    },
    logo:{
        width: 150
    },
    title:{
        lineHeight: 1,
        color: "white"
    },
    toolbar: {
        paddingRight: 24,
        justifyContent:"space-between",
    },
    appBar: {
        borderTopLeftRadius: 5,
        marginLeft: theme.spacing(9),
        width: `calc(100% - ${theme.spacing(9)}px)`,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    appBarShift: {
        marginLeft: theme.custom.variables.menuLeftWidth,
        width: `calc(100% - ${theme.custom.variables.menuLeftWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarFolded:{
        top: "0%"
    },
    menuButton: {
        marginRight: 10,
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.grey[200]}`,
        "&:hover": {
            borderColor: fade(theme.palette.primary.main, 0.25),
        },
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(1),
            width: "auto",
        },
    },
    searchIcon: {
        padding: theme.spacing(1),
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex:1,
        top: -2
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "30ch",
        },
    },
    rootSticky: {
        height: theme.custom.variables.headerHeight - 5,
    },
    gridContainer: {
        padding: theme.spacing(0, 4),
        height: "100%",
    },
    popupItems: {
        margin: "5px 10px",
        width: "120px",
        textAlign: "center",
    },
}));
