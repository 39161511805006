import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

import { toggleView } from "../../../appSlice";
import useStyles from "./style.js";
import { useTranslation } from "react-i18next";
import views from "../../../views";
import clsx from "clsx";

const getTransformedViews = () => {
    const transformedViews = [];
    views.forEach(({ name, icon, path, component, financial, items }) => {
        if (items) {
            items.forEach(({ name, icon, path, component }) => {
                transformedViews.push({ name, icon, path, component, financial });
            });
        } else transformedViews.push({ name, icon, path, component, financial });
    });
    return transformedViews;
};

export default function ({ className }) {
    const isFinancialView = useSelector(({ appState }) => appState.isFinancialView);
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    
    const changeSite = () => {
        dispatch(toggleView());
    };

    const getPath = (path) =>
    {
        if(window.location.hash != "" && (window.location.pathname.startsWith("/operational/projects") || window.location.pathname.startsWith("/financial/projects")))
        {
            const id = window.location.pathname.split("/").pop()
            path = path+"/projects/"+id+"#synthese"
        }
        return path
    }

    return (
        <ButtonGroup variant="contained" color="secondary" className={clsx(classes.container, className)}>
            {
                getTransformedViews().map(({ name, icon, path, financial }, index) => (
                    <Button
                        key={index}
                        size="medium"
                        startIcon={icon}
                        className={clsx(isFinancialView ? (financial ? classes.enabled : classes.disabled) : (financial ? classes.disabled : classes.enabled))}
                        onClick={changeSite}
                        component={Link}
                        to={getPath(path)}
                    >
                        <p className={classes.buttonText}>{t(name)}</p>
                    </Button>
                ))
            }
        </ButtonGroup>
    );
}