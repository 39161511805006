import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {DialogContent, IconButton, Typography, Grid, Dialog, TextField, MenuItem, Chip} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import {AccessTime, Clear, CloseOutlined, Done, Person} from "@material-ui/icons";
import useStyles from "../style";
import EditIcon from "@material-ui/icons/Edit";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import {
    getAllActionPriority, getAllActionTypes
} from "../../../../views/Operational/redux/actions/rt";
import CustomDatePicker from "../../CustomDatePicker";
import ResponsableSelect from "../../ResponsableSelect";


function PopIn({
                   open,
                   onClose,
                   task,
                   borderLeftColor,
                   taskEcheance,
                   putOperationalProject,
                   risk,
                   projectBgColor,
                   columnTitle,
               }) {
    const { t } = useTranslation()

    const dispatch = useDispatch();

    const classes = useStyles();
    const priorityList = useSelector(({ operational }) => risk ? operational.rt.riskPriorities : operational.rt.actionPriorities);
    const actionTypesList = useSelector(({ operational }) => operational.rt.actionTypes);
    const riskProbabilityList = useSelector(({ operational }) => operational.rt.riskProbabilities);
    const riskCategoriesList = useSelector(({ operational }) => operational.rt.riskCategories);
    const [editTaskName,setEditTaskName] = useState(false);
    const [editTaskDescription,setEditTaskDescription] = useState(false);
    const [editActionComment,setEditActionComment] = useState(false);
    const [editActionType,setEditActionType] = useState(false);
    const [editRiskProbability,setEditRiskProbability] = useState(false);
    const [editRiskCategory,setEditRiskCategory] = useState(false);
    const [editTaskPriority,setEditTaskPriority] = useState(false);
    const [editTaskResponsable,setEditTaskResponsable] = useState(false);
    const [editTaskEcheance,setEditTaskEcheance] = useState(false);
    const [editResponsableValue , setEditResponsableValue ] = useState(task.responsable)
    const [editEcheance , setEditEcheance ] = useState(task.echeance);
    const [disabled, setDisabled] = useState(false);
    const [editTask,setEditTask] = useState({});

    const updateTask = ()=>{
        if(JSON.stringify(task) !== JSON.stringify(editTask))
            dispatch(putOperationalProject(editTask,true));
    }

    useEffect(()=>{
        setEditTask(task);
    },[task]);

    useEffect(()=>{
        setEditTask((prev) => ({
            ...prev,
            echeance:editEcheance
        }));
    },[editEcheance])

    useEffect(()=>{
        setEditTask((prev) => ({
            ...prev,
            responsable:editResponsableValue
        }));
    },[editResponsableValue])


    useEffect(()=>{
        dispatch(getAllActionPriority());
        dispatch(getAllActionTypes());
    },[])

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent style={{borderLeft: '5px solid ' + borderLeftColor}} className={classes.dialogContent}>
                <Grid container spacing={task.description ? 2 : 1} justify="center" alignItems="center">
                        <Grid item xs={12} className={classes.avatarGrid}>
                            <Avatar sx={{fontSize: '1rem'}}>
                                {task?.responsable && `${task?.responsable?.firstName?.charAt(0)}${task?.responsable?.lastName?.charAt(0)}`}
                            </Avatar>
                            <Grid item xs={9} style={{marginLeft:'10px'}}>
                                <Typography variant='caption'>
                                    {!editTaskEcheance && <AccessTime style={{marginRight:'3px'}} titleAccess={`${t('echeance')}`}/>}
                                    {!editTaskEcheance && task?.echeance!==null && taskEcheance}
                                    {!editTaskEcheance && <IconButton size='small' onClick={() => setEditTaskEcheance(true)}>
                                        <EditIcon fontSize='small'/>
                                    </IconButton>}
                                </Typography>
                                {editTaskEcheance &&
                                    <Grid item xs={7} style={{display:"flex"}}>
                                        <CustomDatePicker
                                            selectedDate={editEcheance}
                                            onChange={value => {setEditEcheance( value ? moment(value).format("yyyy-MM-DD") : null);}}
                                            emptyLabel={t('echeance')}
                                        />
                                        <IconButton size='small' onClick={() => setEditTaskEcheance(false)}>
                                            <Clear color='error' fontSize='small'/>
                                        </IconButton>
                                        <IconButton size='small' onClick={() => {
                                            setEditTaskEcheance(false);
                                            updateTask();
                                        }}>
                                            <Done color='primary' fontSize='small'/>
                                        </IconButton>
                                    </Grid>
                                }
                                <Typography variant='subtitle2'>
                                    {!task.responsable && <Person style={{marginRight:'3px'}}/>}
                                    {!editTaskResponsable && task?.responsable && `${task.responsable.firstName} ${task.responsable.lastName}`}
                                    {!editTaskResponsable && <IconButton size='small' onClick={() => setEditTaskResponsable(true)}>
                                        <EditIcon fontSize='small'/>
                                    </IconButton>}
                                </Typography>
                                {editTaskResponsable &&
                                    <Grid item xs={12} style={{display:"flex",marginTop:'5px'}}>
                                        <ResponsableSelect
                                            usage="edit"
                                            value={editTask.responsable ?? editTask.responsable}
                                            handleValueChange={setEditResponsableValue}
                                            variant="outlined"
                                            size="small"
                                            popInNotif={true}
                                            error={editResponsableValue === null}
                                        />
                                        <IconButton size='small' onClick={() => setEditTaskResponsable(false)}>
                                            <Clear color='error' fontSize='small'/>
                                        </IconButton>
                                        <IconButton size='small' disabled={editResponsableValue === null}  onClick={() => {
                                            setEditTaskResponsable(false);
                                            updateTask();
                                        }}>
                                            <Done  fontSize='small'/>
                                        </IconButton>
                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={2} className={classes.dataToRight}>
                                <IconButton color='primary' size='small' aria-label="close" onClick={(e) => onClose(false)}>
                                    <CloseOutlined />
                                </IconButton>
                            </Grid>
                    </Grid>
                    <Grid item xs={12} style={{display:'flex'}}>
                        <Grid item xs={9} alignItems="center">
                            {!editTaskName
                                ?
                                <Grid item xs={12}>
                                    <Typography color='secondary' variant="h6">{task?.name}
                                        <IconButton onClick={() => setEditTaskName(true)}>
                                            <EditIcon fontSize='small'/>
                                        </IconButton>
                                    </Typography>
                                </Grid>
                                :
                                <Grid item xs={12} style={{display:'flex'}}>
                                    <TextField
                                        defaultValue={task?.name}
                                        type="text"
                                        variant="outlined"
                                        label="Action Name"
                                        onChange={(e) => {
                                            setEditTask({ ...editTask, name: e.target.value });
                                            setDisabled(e.target.value === "");
                                        }}
                                        error={disabled}
                                    />
                                    <IconButton size='small' onClick={() => setEditTaskName(false)}>
                                        <Clear color='error' fontSize='small'/>
                                    </IconButton>
                                    <IconButton size='small' disabled={disabled} onClick={() => {
                                        setEditTaskName(false);
                                        updateTask();
                                    }}>
                                        <Done color={disabled ? 'grey' : 'primary'} fontSize='small'/>
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={3} className={classes.centerStatus}>
                            <Chip label={task.status.name}
                                  style={{ backgroundColor: borderLeftColor, borderRadius: 15, color:'#000000' }} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} alignItems="center">
                        <Grid container alignItems="center" justify="space-between" spacing={1}>
                            <Grid item xs={12}>
                                <Typography color='secondary' variant='subtitle1' className={classes.descriptionText}>
                                    {t('description')}
                                    {!editTaskDescription &&
                                        <IconButton onClick={() => setEditTaskDescription(true)}>
                                            <EditIcon fontSize='small'/>
                                        </IconButton>}
                                    {editTaskDescription &&
                                        <>
                                            <IconButton size='small' onClick={() => setEditTaskDescription(false)}>
                                                <Clear color='error' fontSize='small'/>
                                            </IconButton>
                                            <IconButton size='small' onClick={() => {
                                                setEditTaskDescription(false);
                                                updateTask();
                                            }}>
                                                <Done color='primary' fontSize='small'/>
                                            </IconButton>
                                        </>
                                    }
                                </Typography>
                                {!editTaskDescription && <Typography  className={classes.typographyDescription} style={{marginTop:'-20px'}}>{task?.description}</Typography>}
                            </Grid>
                        </Grid>
                        {editTaskDescription && <Grid container alignItems="flex-end" justify="space-between" spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    defaultValue={task?.description}
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label={risk ? t('risk_description') : t('action_description')}
                                    multiline
                                    rows={8}
                                    onChange={(e) => {
                                        setEditTask({ ...editTask, description: e.target.value });
                                    }}
                                />
                            </Grid>
                        </Grid>}
                    </Grid>
                    <Grid item xs={12} alignItems="center">
                        {task?.comment && <Grid container alignItems="center" justify="space-between" spacing={1}>
                            <Grid item xs={12}>
                                <Typography color='secondary' variant='subtitle1'
                                            className={classes.descriptionText}>
                                    {t('close_comment')}
                                    {!editActionComment &&
                                        <IconButton onClick={() => setEditActionComment(true)}>
                                            <EditIcon fontSize='small'/>
                                        </IconButton>}
                                    {editActionComment &&
                                        <>
                                            <IconButton size='small'
                                                        onClick={() => setEditActionComment(false)}>
                                                <Clear color='error' fontSize='small'/>
                                            </IconButton>
                                            <IconButton size='small' onClick={() => {
                                                setEditActionComment(false);
                                                updateTask();
                                            }}>
                                                <Done color='primary' fontSize='small'/>
                                            </IconButton>
                                        </>
                                    }
                                </Typography>
                                {!editActionComment && <Typography className={classes.typographyDescription}
                                                                   style={{marginTop: '-20px'}}>{task?.comment}</Typography>}
                            </Grid>
                        </Grid>}
                        {editActionComment &&
                            <Grid container alignItems="flex-end" spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        defaultValue={task?.comment}
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        label={t('close_comment')}
                                        multiline
                                        rows={2}
                                        onChange={(e) => {
                                            setEditTask({...editTask, comment: e.target.value});
                                        }}
                                    />
                                </Grid>
                            </Grid>}
                    </Grid>
                    <Grid item xs={12} alignItems="center" style={{display:'flex'}}>
                        <Grid item xs={2} className={classes.centerData} style={{marginRight:'-10px'}}>
                            <Typography variant="body1" className={classes.taskPriority}>
                                {`${t('priority')} :`}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            {!editTaskPriority
                                ?
                                <Grid item xs={12}>
                                    <Typography variant="body1">{task?.priority?.name}
                                        <IconButton onClick={() => setEditTaskPriority(true)}>
                                            <EditIcon fontSize='small'/>
                                        </IconButton>
                                    </Typography>
                                </Grid>
                                :
                                <Grid item xs={12}  style={{display:"flex"}}>
                                    <Grid item xs={7}>
                                        <TextField
                                            select
                                            defaultValue={task.priority ? task.priority.name : ""}
                                            variant="outlined"
                                            size="small"
                                            onChange={e => setEditTask({
                                                ...editTask,
                                                priority: priorityList.find(({name}) => name === e.target.value)
                                            })}
                                        >
                                            {
                                                priorityList.map(item =>
                                                    <MenuItem
                                                        key={item.id}
                                                        value={item.name}
                                                    >
                                                        {item.name}
                                                    </MenuItem>)
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={2} style={{display: "flex"}}>
                                        <IconButton size='small' onClick={() => setEditTaskPriority(false)}>
                                            <Clear color='error' fontSize='small'/>
                                        </IconButton>
                                        <IconButton size='small' onClick={() => {
                                            setEditTaskPriority(false);
                                            updateTask();
                                        }}>
                                            <Done color='primary' fontSize='small'/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {!risk && <Grid item xs={12} style={{display:'flex'}}>
                        <Grid item xs={2} className={classes.centerData} style={{marginRight:'-30px'}}>
                            <Typography variant="body1" className={classes.actionType}>
                                {`${t('type_col')} :`}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {!editActionType
                                ?
                                <Grid item xs={12}>
                                    <Typography variant="body1">{task?.type?.name}
                                        <IconButton onClick={() => setEditActionType(true)}>
                                            <EditIcon fontSize='small'/>
                                        </IconButton>
                                    </Typography>
                                </Grid>
                                :
                                <Grid item xs={12} style={{display: "flex"}}>
                                    <Grid item xs={12} style={{display: "flex"}}>
                                        <TextField
                                            select
                                            defaultValue={task.type ? task.type.name : ""}
                                            variant="outlined"
                                            size="small"
                                            onChange={e => setEditTask({
                                                ...editTask,
                                                type: actionTypesList.find(({name}) => name === e.target.value)
                                            })}
                                        >
                                            {
                                                actionTypesList.map(item =>
                                                    <MenuItem
                                                        key={item.id}
                                                        value={item.name}
                                                    >
                                                        {item.name}
                                                    </MenuItem>)
                                            }
                                        </TextField>
                                        <Grid  style={{display: "flex"}}>
                                            <IconButton size='small' onClick={() => setEditActionType(false)}>
                                                <Clear color='error' fontSize='small'/>
                                            </IconButton>
                                            <IconButton size='small' onClick={() => {
                                                setEditActionType(false);
                                                updateTask();
                                            }}>
                                                <Done color='primary' fontSize='small'/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>}
                    {risk && <Grid item xs={12} style={{display:'flex'}}>
                        <Grid item xs={3} className={classes.centerData} style={{marginRight:'-30px'}}>
                            <Typography variant="body1" className={classes.taskPriority}>
                                {`${t('probability_col')} :`}</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            {!editRiskProbability
                                ?
                                <Grid item xs={12}>
                                    <Typography variant="body1">{task?.probability?.name}
                                        <IconButton onClick={() => setEditRiskProbability(true)}>
                                            <EditIcon fontSize='small'/>
                                        </IconButton>
                                    </Typography>
                                </Grid>
                                :
                                <Grid item xs={12} style={{display: "flex",alignItems:'center'}}>
                                    <Grid item xs={12} style={{display: "flex"}}>
                                        <TextField
                                            select
                                            defaultValue={task.probability ? task?.probability.name : ""}
                                            variant="outlined"
                                            size="small"
                                            onChange={e => setEditTask({
                                                ...editTask,
                                                probability: riskProbabilityList.find(({name}) => name === e.target.value)
                                            })}
                                        >
                                            {
                                                riskProbabilityList.map(item =>
                                                    <MenuItem
                                                        key={item.id}
                                                        value={item.name}
                                                    >
                                                        {item.name}
                                                    </MenuItem>)
                                            }
                                        </TextField>
                                        <Grid style={{display: "flex"}}>
                                            <IconButton size='small' onClick={() => setEditRiskProbability(false)}>
                                                <Clear color='error' fontSize='small'/>
                                            </IconButton>
                                            <IconButton size='small' onClick={() => {
                                                setEditRiskProbability(false);
                                                updateTask();
                                            }}>
                                                <Done color='primary' fontSize='small'/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>}
                    {risk && <Grid item xs={12} style={{display:'flex'}}>
                        <Grid item xs={2} style={{alignSelf:'center' ,marginRight:'5px'}}>
                            <Typography variant="body1" className={classes.taskPriority}>
                                {`${t('categorie_col')} :`}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {!editRiskCategory
                                ?
                                <Grid item xs={12}>
                                    <Typography variant="body1">{task?.category?.name}
                                        <IconButton onClick={() => setEditRiskCategory(true)}>
                                            <EditIcon fontSize='small'/>
                                        </IconButton>
                                    </Typography>
                                </Grid>
                                :
                                <Grid item xs={12} style={{display: "flex",alignItems:'center'}}>
                                    <Grid item xs={12} style={{display: "flex"}}>
                                        <TextField
                                            select
                                            defaultValue={task.category ? task?.category.name : ""}
                                            variant="outlined"
                                            size="small"
                                            onChange={e => setEditTask({
                                                ...editTask,
                                                category: riskCategoriesList.find(({name}) => name === e.target.value)
                                            })}
                                        >
                                            {
                                                riskCategoriesList.map(item =>
                                                    <MenuItem
                                                        key={item.id}
                                                        value={item.name}
                                                    >
                                                        {item.name}
                                                    </MenuItem>)
                                            }
                                        </TextField>
                                        <Grid style={{display: "flex"}}>
                                            <IconButton size='small' onClick={() => setEditRiskCategory(false)}>
                                                <Clear color='error' fontSize='small'/>
                                            </IconButton>
                                            <IconButton size='small' onClick={() => {
                                                setEditRiskCategory(false);
                                                updateTask();
                                            }}>
                                                <Done color='primary' fontSize='small'/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                        </Grid>
                    </Grid>}
                </Grid>
             </DialogContent>
        </Dialog>
    )
}
export default PopIn