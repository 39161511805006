import { Box, Grid, IconButton, TablePagination } from '@material-ui/core';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import useStyles from './style';
import { useTranslation } from 'react-i18next';
import getPaginationConfig from '../../../utils/common-state';


function TablePaginationActions(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
        <div className={classes.paginationAction}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                title={t("first_page")}
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton 
                onClick={handleBackButtonClick} 
                disabled={page === 0} 
                title={t("previous_page")}
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                title={t("next_page")}
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                title={t("last_page")}
            >
                <LastPageIcon />
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const listPageSizeOptions = getPaginationConfig(false).rowsPerPage;
const gridPageSizeOptions = getPaginationConfig(true).rowsPerPage;

function Pagination({ loading = false, appendMode = false, gridView = false, getPagination, setPagination, onChange }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const pagination = useSelector(getPagination);
    const dispatchPagination = (p) => dispatch(setPagination(p));

    useEffect(() => {
        if(!loading){
            onChange();
        }
    }, [pagination.page, pagination.size]); //eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <Grid container justify={appendMode ? "center" : "flex-end"} className={classes.root}>
            {
                !loading && (
                    appendMode 
                        ? (
                            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                                {/* <Box mt={2}>
                                    <Typography>
                                        {`${pagination.size < pagination.maxLength ? pagination.size : pagination.maxLength} of ${pagination.maxLength}`}
                                    </Typography>
                                </Box>
                                <Box m={1}>
                                    <Button 
                                        className={classes.seeMoreBtn}
                                        disableElevation
                                        disableRipple
                                        variant="outlined"
                                        onClick={() => dispatchPagination({...pagination, page: 0, size: pagination.size + (gridView ? gridPageSizeOptions[0] : listPageSizeOptions[0])})}
                                        disabled={pagination.page >= Math.ceil(pagination.maxLength / pagination.size) - 1}
                                    >
                                        {t("show_more")}
                                    </Button>
                                </Box> */}
                            </Box>
                        ) 
                        : (
                            <TablePagination
                                onChangePage={(e, page) => dispatchPagination({...pagination, page: page})}
                                onChangeRowsPerPage={(e) => dispatchPagination({...pagination, page: 0, size: e.target.value})}
                                rowsPerPageOptions={gridView ? gridPageSizeOptions : listPageSizeOptions}
                                component="div"
                                count={pagination.maxLength}
                                rowsPerPage={pagination.size}
                                page={pagination.page}
                                labelRowsPerPage={t('labelRowsPerPage')}
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('pagination_of')} ${count}`}
                                hidden={pagination.maxLength === 0}
                                ActionsComponent={TablePaginationActions}
                            />
                        )
                )
            }
        </Grid>
    );
}

export default React.memo(Pagination);