import React, { Fragment, Suspense, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { Switch, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import navs from "./navs";
import AppBar from "./components/AppBar";
import MenuLeft from "./components/MenuLeft";
import useStyles from "./style.js";
import views from './views';
import { ThemeProvider } from "@material-ui/styles";
import getTheme from "../style/theme";
import AccessDeniedWarning from "./components/AccessDeniedWarning";
import Loading from "./components/Loading";
import { PrivateRoute } from "../routes";
import { getCurrentUser } from "../redux/actions/auth";
import Swal from "sweetalert2";
import Breadcrumbs from './components/Breadcrumbs';
import appRoutes from './routes';
import operationalViewRoutes from '../views/Operational/routes';
import financialViewRoutes from '../views/Financial/routes';
import Settings from "./pages/Settings";
import ScrollToTop from "./components/ScrollToTop";
import ThemeColorPicker from "./components/ThemeColorPicker";
import { Hidden, useMediaQuery } from "@material-ui/core";
import NotSupportedIcon from "../not_supported.png";
import LogoColorful from "./logo_colorful.png";
import { setScreenMedia } from "./appSlice";
import { getCurrentCollaborator } from "../redux/actions/collaborators";
import { ADMIN_ROLE } from "../utils/constants";

const routes = [
    ...appRoutes,
    ...operationalViewRoutes,
    ...financialViewRoutes
];

const Copyright = () => {
    const { t } = useTranslation();
    return (
        <Box pb={2}>
            <hr></hr>
            <Typography variant="body2" color="textSecondary" align="center">
                {t("Copyright")} © 
                <Link color="inherit" variant="body2" href="https://novelis.io" target="_blank">
                    Novelis Innovation
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
            </Typography>
        </Box>
    );
};

const getTransformedViews = () => {
    const transformedViews = [];
    views.forEach(({ name, icon, path, component, financial, permissions, roles, items }) => {
        if (items) {
            items.forEach(({ name, icon, path, component, permissions, roles }) => {
                transformedViews.push({ name, icon, path, component, financial, permissions, roles });
            });
        } else transformedViews.push({ name, icon, path, component, financial, permissions, roles });
    });
    return transformedViews;
};

function Main({ user, ...props }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const authorized = user
        && user.permissions
        && user.permissions.modulePermissions.pilotage
        && user.permissions.modulePermissions.pilotage.managePilotage;

    if (user && user.permissions && !authorized) {
        Swal.fire({
            title: t('unauthorized_access_title'),
            text: t('you_are_not_authorized_to_access'),
            reverseButtons: true,
            confirmButtonText: t('ok'),
        }).then(() => {
            window.location.href = process.env.REACT_APP_NOVY_WEBAPP_URL; // eslint-disable-line no-undef
        });
    }

    return (
        <>
            <Hidden mdUp>
                <Container maxWidth="sm">
                    <Box pt={10} textAlign="center">
                        <img 
                            src={NotSupportedIcon} 
                            alt="" 
                            width="150px"
                            style={{paddingBottom: "10px"}}
                        ></img>
                        <Typography 
                            variant="h5" 
                            gutterBottom={true}
                            style={{paddingBottom: "5px"}}
                        >
                            {t("screen_size_not_supported_title")}
                        </Typography>
                        <Typography 
                            variant="body2" 
                            color="textSecondary"
                        >
                            {t("screen_size_not_supported_description1")}
                        </Typography>
                        <Typography 
                            variant="body2" 
                            color="textSecondary"
                        >
                            {t("screen_size_not_supported_description2")}
                        </Typography>
                        <hr style={{margin: "20px ​0px"}} />
                        <img 
                            src={LogoColorful} 
                            alt="" 
                            width="75px"
                        ></img>
                    </Box>
                </Container>
            </Hidden>
            <Hidden smDown>
                <Fragment>
                    <CssBaseline />
                    <ThemeColorPicker />
                    <div className={classes.root}>
                        {authorized
                            && <>
                                <AppBar />
                                <div className={classes.wrapper}>
                                    <MenuLeft menu={navs} />
                                    <main
                                        className={classes.content}
                                    >
                                        <Container maxWidth={false} className={classes.container}>
                                            <Breadcrumbs
                                                className="bg-transparent"
                                                appRoutes={routes}
                                            />
                                            <Suspense fallback={<Loading />}>
                                                <ScrollToTop />
                                                <Switch>
                                                    <Redirect exact path="/" to="/operational" />
                                                    {
                                                        getTransformedViews().map(({ name, path, component, permissions, roles }, index) => (
                                                            <PrivateRoute
                                                                {...props}
                                                                key={index}
                                                                path={path}
                                                                exact={false}
                                                                name={t(name)}
                                                                user={user}
                                                                history={props.history}
                                                                component={component}
                                                                permission={permissions}
                                                                roles={roles}
                                                            />
                                                        ))
                                                    }
                                                    <PrivateRoute
                                                        {...props}
                                                        key={0}
                                                        path="/administration"
                                                        exact={true}
                                                        name={t("administration")}
                                                        user={user}
                                                        history={props.history}
                                                        component={Settings}
                                                        permission="pilotage.managePilotage"
                                                        roles={[ADMIN_ROLE]}
                                                    />
                                                </Switch>
                                            </Suspense>
                                        </Container>
                                        <div className={classes.copyright}>
                                            <Copyright />
                                        </div>
                                    </main>
                                </div>
                            </>
                        }
                    </div>
                </Fragment>
            </Hidden>
        </>
    );
}

function App() {
    const dispatch = useDispatch();
    const themeColor = useSelector(({ appState }) => appState.themeColor);
    const isKeycloakAuthenticated = useSelector(state => state.keycloak.authenticated);
    const tokenParsed = useSelector((state) => state.keycloak.tokenParsed);
    const {
        user,
        loading,
        userNotFound
    } = useSelector(state => state.Auth);

    const { t } = useTranslation();
    const isFinancialView = useSelector(({ appState }) => appState.isFinancialView);
    const direction = t('ltr');
    const theme = getTheme(themeColor, direction, isFinancialView);
    const xlMatch = useMediaQuery(theme.breakpoints.up('xl'));
    const lgMatch = useMediaQuery(theme.breakpoints.up('lg'));
    const mdMatch = useMediaQuery(theme.breakpoints.up('md'));
    const smMatch = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        if (isKeycloakAuthenticated) {
            dispatch(getCurrentUser());
            dispatch(getCurrentCollaborator(tokenParsed.email));
        }
    }, [isKeycloakAuthenticated]); //eslint-disable-line react-hooks/exhaustive-deps

    const renderPilotageLayout = () => {
        if (userNotFound) return <AccessDeniedWarning />;
        if (loading && isKeycloakAuthenticated) return <Loading />;

        return (
            <Main user={user} />
        );
    };

    useEffect(() => {
        if(xlMatch){
            dispatch(setScreenMedia("xl"));
        }else if(lgMatch){
            dispatch(setScreenMedia("lg"));
        }else if(mdMatch){
            dispatch(setScreenMedia("md"));
        }else if(smMatch){
            dispatch(setScreenMedia("sm"));
        }else{
            dispatch(setScreenMedia("xs"));
        }
    }, [xlMatch, lgMatch, mdMatch, smMatch]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ThemeProvider theme={theme} >
            {renderPilotageLayout()}
        </ThemeProvider>
    );
}

export default App;
