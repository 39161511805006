import {
    CLEAR_SELECTED_FINANCIAL_RT_COST_PROFILE,
    DELETE_FINANCIAL_RT_COST_PROFILE,
    EXPORT_RT_COST_PROFILE,
    GET_ALL_FINANCIAL_RT_COST_PROFILE,
    GET_ALL_FINANCIAL_RT_COST_PROFILE_BY_CODE,
    GET_ALL_FINANCIAL_RT_COST_PROFILE_BY_ENTITY,
    IMPORT_RT_COST_PROFILE,
    POST_FINANCIAL_RT_COST_PROFILE,
    PUT_FINANCIAL_RT_COST_PROFILE,
    SET_ALL_FINANCIAL_COST_PROFILE_SORT,
    SET_ALL_FINANCIAL_RT_COST_PROFILE,
    SET_SELECTED_FINANCIAL_RT_COST_PROFILE
} from '../../constants';
import {errorNotification, successNotification} from "../../../../../utils/notification";
import {FINANCIAL_RT_ENDPOINT} from '../../constants/endpoint';
import {popUpError} from "../../../../Operational/redux/actions/rt/popUpError";

export const getAllCostProfiles = () => {
    return {
        type: GET_ALL_FINANCIAL_RT_COST_PROFILE,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/CostProfile`
        },
        meta: {
            onRequest: (request, requestAction, store) => {
                const state = store.getState();
                const sort = state.financial.rt.sort
                request.url = `${request.url}?sort=${(sort.col && sort.col.apiName) || "id"},${sort.type}`
                return request
            },
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllCostProfiles(response.data));
                return response;
            }
        }
    };
};

export const getAllCostProfilesByEntity = (collabId) => {
    return {
        type: GET_ALL_FINANCIAL_RT_COST_PROFILE_BY_ENTITY,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/CostProfile`
        },
        meta: {
            onRequest: (request, requestAction, store) => {
                request.url = `${request.url}?collabId=${collabId}`
                return request
            },
            onSuccess: (response, requestAction, store) => {
                return response;
            }
        }
    };
};

export const getCostProfilesByCodes = (codet) => {
    return {
        type: GET_ALL_FINANCIAL_RT_COST_PROFILE_BY_CODE,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/CostProfile/history`,
            method: 'post',
            data: {code: codet}
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setSelectedCostProfiles(response.data));
                return response;
            }
        }
    };
};
export const setAllCostProfiles = (data) => {
    return {
        type: SET_ALL_FINANCIAL_RT_COST_PROFILE,
        payload: data
    };
};

export const setSelectedCostProfiles = (data) => {
    return {
        type: SET_SELECTED_FINANCIAL_RT_COST_PROFILE,
        payload: data
    };
};

export const clearSelectedCostProfiles = () => {
    return {
        type: CLEAR_SELECTED_FINANCIAL_RT_COST_PROFILE
    };
};

export const postCostProfiles = (CostProfiles) => {
    return {
        type: POST_FINANCIAL_RT_COST_PROFILE,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/CostProfile`,
            method: 'post',
            data: CostProfiles
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "create_success");
                store.dispatch(getAllCostProfiles());
                return response;
            },
            onError: (error) => {
                errorNotification('', "create_error");
                throw error;
            }
        }
    };
};

export const putCostProfiles = (id, CostProfiles) => {
    return {
        type: PUT_FINANCIAL_RT_COST_PROFILE,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/CostProfile/${id}`,
            method: 'put',
            data: CostProfiles
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "update_success");
                store.dispatch(getAllCostProfiles());
                return response;
            },
            onError: (error) => {
                errorNotification('', "update_error");
                throw error;
            }
        }
    };
};

export const deleteCostProfiles = (id) => {
    return {
        type: DELETE_FINANCIAL_RT_COST_PROFILE,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/CostProfile/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "delete_success");
                store.dispatch(getAllCostProfiles());
                return response;
            },
            onError: (error) => {
                popUpError(error,"delete_cost_profile_error");
            }
        }
    };
};

export const exportRtCostProfile = (type) => {
    return {
        type: EXPORT_RT_COST_PROFILE,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/CostProfile/export`,
            responseType: 'arraybuffer'
        },
        meta: {
            onRequest: (request) => {
                if(type)
                    request.url=request.url+"?type="+type
                return request;
            },
            onSuccess: (response) => {
                return response.data;
            }
        }
    };
};

export const importRtCostProfile= (data) => {
    return {
        type: IMPORT_RT_COST_PROFILE,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/CostProfile/import`,
            method:"post",
            data:data
        },
        meta: {
            onSuccess: (response,requestAction,store) => {
                store.dispatch(getAllCostProfiles());
                return response.data;
            }
        }
    };
};

export const setAllRtCostProfileSort = (sort) => {
    return {
        type: SET_ALL_FINANCIAL_COST_PROFILE_SORT,
        payload: sort
    };
};


export * from './SaleType';
