import { getInitialPagination } from "../../../../../utils/common-state";
import {
    SET_FINANCIAL_COLLABORATOR_COST_HISTORY ,
    SET_FINANCIAL_COLLABORATOR_COST_HISTORY_PAGINATION ,
    SET_FINANCIAL_COLLABORATOR_COST_HISTORY_SORT ,
    SET_FINANCIAL_COLLABORATOR_COST_HISTORY_FILTER,
    CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY ,
    CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY_PAGINATION ,
    CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY_SORT,
    CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY_FILTER,
    SET_FINANCIAL_COLLABORATOR_COST_HISTORY_BY_ID

} from "../../constants";


const initialState = {
    config: {
        pagination: getInitialPagination(false)
    },
    list: {
        sort: {
            col: { apiName: 'createdAt' },
            type: "desc"
        },
        filter: {
            search: "",
            collaborators: [],
            starting_date: "",
            ending_date: "",
            costHistories: []
        },
        page: 0,
        size: getInitialPagination(false).size,
        collaboratorCostHistory: []
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FINANCIAL_COLLABORATOR_COST_HISTORY:
            return setFinancialCollaboratorCostHistory(state, action.payload);
        case SET_FINANCIAL_COLLABORATOR_COST_HISTORY_BY_ID:
            return setFinancialCollaboratorCostHistoryById(state, action.payload)
        case SET_FINANCIAL_COLLABORATOR_COST_HISTORY_SORT:
            return setFinancialCollaboratorCostHistorySort(state, action.payload);
        case SET_FINANCIAL_COLLABORATOR_COST_HISTORY_PAGINATION:
            return setFinancialCollaboratorCostHistoryPagination(state, action.payload);
        case CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY:
            return clearFinancialCollaboratorCostHistory(state);
        case CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY_SORT:
            return clearFinancialCollaboratorCostHistorySort(state);
        case CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY_PAGINATION:
            return clearFinancialCollaboratorCostHistoryPagination(state);
        case SET_FINANCIAL_COLLABORATOR_COST_HISTORY_FILTER:
            return setFinancialCollaboratorCostHistoryFilter(state, action.payload);
        case CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY_FILTER:
            return clearFinancialCollaboratorCostHistoryFilter(state);
        default: return state;
    }
};

const setFinancialCollaboratorCostHistory = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: {
                ...state.config.pagination,
                page: parseInt(payload.page),
                size: parseInt(payload.size),
                maxLength: parseInt(payload.maxLength)
            }
        },
        list: {
            ...state.list,
            page: parseInt(payload.page),
            size: parseInt(payload.size),
            collaboratorCostHistory: payload.costHistories
        }
    };
};

const setFinancialCollaboratorCostHistoryById = (state, payload) => {
    return {
        ...state,
        list: {
            ...state.list,
            collaboratorCostHistory: payload
        }
    };
};

const setFinancialCollaboratorCostHistoryPagination = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: payload
        }
    };
};

const clearFinancialCollaboratorCostHistory = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            page: initialState.list.page,
            size: initialState.list.size,
            collaboratorCostHistory: initialState.list.collaboratorCostHistory
        }
    };
};

const clearFinancialCollaboratorCostHistoryPagination = (state) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: getInitialPagination(false)
        }
    };
};

const setFinancialCollaboratorCostHistorySort = (state, sort) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: sort
        }
    };
};

const clearFinancialCollaboratorCostHistorySort = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: initialState.list.sort
        }
    };
};

const setFinancialCollaboratorCostHistoryFilter = (state, filter) => {
    return {
        ...state,
        list: {
            ...state.list,
            filter: filter
        }
    };
};

const clearFinancialCollaboratorCostHistoryFilter = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            filter: initialState.list.filter
        }
    };
};
