import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './style';

function NoData({ condition, loading }) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (

        condition && !loading &&
        <Grid container justify="center">
            <Grid item xs={12} className={classes.noDataContainer}>
                    <Typography variant="subtitle2" align="center" color="textSecondary">{t('no_data')}</Typography>
            </Grid>
        </Grid>

    );

}

export default NoData;