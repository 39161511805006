
export const columns = [
    {
        id: 0,
        name: 'risks_col',
        apiName: 'name',
        width: 2,
        sort: true
    },
    {
        id: 1,
        name: 'responsable_col',
        apiName: 'responsable',
        width: 2,
        sort: true
    },
    {
        id: 2,
        name: 'echeances_col',
        apiName: 'echeance',
        width: 2,
        sort: true
    },
    {
        id: 3,
        name: 'priorite_col',
        apiName: 'priority',
        width: 2,
        sort: false
    },
    {
        id: 4,
        name: 'status_col',
        apiName: 'status',
        width: 2,
        sort: false
    },
    {
        id: 5,
        name: 'categorie_col',
        apiName: 'category',
        width: 2,
        sort: false
    }
];

export const globalColumns = [
    {
        id: 0,
        name: 'risks_col',
        apiName: 'name',
        width: 2,
        sort: true
    },
    {
        id: 1,
        name: 'project_col',
        apiName: 'projectName',
        width: 2,
        sort: true
    },
    {
        id: 2,
        name: 'responsable_col',
        apiName: 'responsable',
        width: 2,
        sort: true
    },
    {
        id: 3,
        name: 'echeances_col',
        apiName: 'echeance',
        width: 2,
        sort: true
    },
    {
        id: 4,
        name: 'priorite_col',
        apiName: 'priority',
        width: 1,
        sort: false
    },
    {
        id: 5,
        name: 'status_col',
        apiName: 'status',
        width: 1,
        sort: false
    },
    {
        id: 6,
        name: 'categorie_col',
        apiName: 'category',
        width: 2,
        sort: false
    }
];