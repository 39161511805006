import { cloneDeep } from "lodash-es";
import isEmpty from 'lodash/isEmpty';
import { errorNotification, successNotification } from "../../../../../utils/notification";
import {
    CLEAR_SELECTED_OPERATIONAL_PROJECT,
    GET_OPERATIONAL_PROJECT_RISKS,
    SET_OPERATIONAL_PROJECT_RISKS,
    GET_SELECTED_OPERATIONAL_PROJECT_RISK,
    POST_OPERATIONAL_PROJECT_RISK,
    DELETE_OPERATIONAL_PROJECT_RISK,
    PUT_OPERATIONAL_PROJECT_RISK,
    DELETE_MULTIPLE_OPERATIONAL_PROJECT_RISKS,
    SET_SELECTED_OPERATIONAL_PROJECT_RISK,
    SET_OPERATIONAL_PROJECT_RISKS_PAGINATION,
    CLEAR_OPERATIONAL_PROJECT_RISKS,
    CLEAR_SELECTED_OPERATIONAL_PROJECT_RISK,
    CLEAR_OPERATIONAL_PROJECT_RISKS_PAGINATION,
    SET_OPERATIONAL_PROJECT_RISKS_SORT,
    CLEAR_OPERATIONAL_PROJECT_RISKS_FILTER,
    SET_OPERATIONAL_PROJECT_RISKS_FILTER,
    CLEAR_OPERATIONAL_PROJECT_RISKS_SORT,
    EXPORT_OPERATIONAL_PROJECT_RISKS,
    IS_RISK_HAS_ACTIONS,
} from "../../constants";
import {OPERATIONAL_PROJECT_RISKS_ENDPOINT} from "../../constants/endpoint";

const adjustFilter = (filter) => {
    let f = cloneDeep(filter);
    Object.keys(f).forEach(k => {
        if(isEmpty(f[k])){
            delete f[k];
        }else{
            switch(k){
                case 'priorities':
                    f['priorities'] = f[k].join(',');
                    break;
                case 'status':
                    f['status'] = f[k].join(',');
                    break;
                case 'probabilities':
                    f['probabilities'] = f[k].join(',');
                    break;
                case 'categories':
                    f['categories'] = f[k].join(',');
                    break;
                case 'projects':
                    f['projectIds'] = f[k].map(p => p.id).join(',');
                    delete f[k];
                    break;
                case 'responsables':
                    f['responsableIds'] = f[k].map(r => r.id).join(',');
                    delete f[k];
                    break;
                default:
                    break;
            }
        }
    });

    return f;
};

export const getOperationalProjectRisks = (projectId) => {
    return {
        type: GET_OPERATIONAL_PROJECT_RISKS,
        request: {
            url: OPERATIONAL_PROJECT_RISKS_ENDPOINT
        },
        meta: {
            onRequest: (request, requestAction, store) => {
                const state = store.getState();
                const pagination = state.operational.risks.config.pagination;
                const sort = state.operational.risks.list.sort;
                const filter = adjustFilter(state.operational.risks.list.filter);

                if(filter.search){
                    request.url = `${request.url}/search?sort=${(sort.col && sort.col.apiName) || "id"},${sort.type}&page=${pagination.page}&size=${pagination.size}`;
                    request.params = {
                        name: filter.search
                    };
                }else{
                    request.url = `${request.url}?sort=${(sort.col && sort.col.apiName) || "id"},${sort.type}&page=${pagination.page}&size=${pagination.size}`;
                    request.params = filter;
                }

                if(projectId){
                    request.params = {...request.params, projectIds: projectId , projectId: projectId};
                }
                return request;
            },
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setOperationalProjectRisks(response.data));
                return response;
            }
        }
    };
};

export const exportOperationalProjectRisks = (projectId) => {
    return {
        type: EXPORT_OPERATIONAL_PROJECT_RISKS,
        request: {
            url: `${OPERATIONAL_PROJECT_RISKS_ENDPOINT}/export`,
            responseType: 'arraybuffer'
        },
        meta: {
            onRequest: (request, requestAction, store) => {
                const state = store.getState();
                const filter = adjustFilter(state.operational.risks.list.filter);

                request.params = filter;

                if(projectId) {
                    request.params.projectIds = projectId;
                }
                return request;
            },
            onSuccess: (response, requestAction, store) => {
                return response.data;
            }
        }
    };
};

export const getSelectedOperationalProjectRisk = (id) => {
    return {
        type: GET_SELECTED_OPERATIONAL_PROJECT_RISK,
        request: {
            url: `${OPERATIONAL_PROJECT_RISKS_ENDPOINT}/${id}`
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setSelectedOperationalProjectRisk(response.data));
                return response;
            }
        }
    };
};

export const postOperationalProjectRisk = (risk, global) => {
    const projectId = global ? null : risk.project.id;
    return {
        type: POST_OPERATIONAL_PROJECT_RISK,
        request: {
            url: OPERATIONAL_PROJECT_RISKS_ENDPOINT,
            method: 'post',
            data: risk
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "create_success");
                store.dispatch(getOperationalProjectRisks(projectId));
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "create_error");
                throw error;
            }
        }
    };
};

export const deleteOperationalProjectRisk = (riskId, projectId) => {
    return {
        type: DELETE_OPERATIONAL_PROJECT_RISK,
        request: {
            url: `${OPERATIONAL_PROJECT_RISKS_ENDPOINT}/${riskId}`,
            method: 'delete'
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "delete_success");
                store.dispatch(getOperationalProjectRisks(projectId));
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "delete_error");
                throw error;
            }
        }
    };
};

export const putOperationalProjectRisk = (risk, global) => {
    const projectId = global ? null : risk.project.id;
    return {
        type: PUT_OPERATIONAL_PROJECT_RISK,
        request: {
            url: `${OPERATIONAL_PROJECT_RISKS_ENDPOINT}/${risk.id}`,
            method: 'put',
            data: risk
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "update_success");
                store.dispatch(getOperationalProjectRisks(projectId));
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "update_error");
                throw error;
            }
        }
    };
};

export const deleteMultipleOperationalProjectRisks = (selection, projectId) => {
    return {
        type: DELETE_MULTIPLE_OPERATIONAL_PROJECT_RISKS,
        request: {
            url: `${OPERATIONAL_PROJECT_RISKS_ENDPOINT}`,
            method: 'delete',
            params: { selection: selection.join(",") }
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "delete_success");
                store.dispatch(getOperationalProjectRisks(projectId));
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "delete_error");
                throw error;
            }
        }
    };
};

export const setOperationalProjectRisks = (data) => {
    return {
        type: SET_OPERATIONAL_PROJECT_RISKS,
        payload: data
    };
};

export const setSelectedOperationalProjectRisk = (data) => {
    return {
        type: SET_SELECTED_OPERATIONAL_PROJECT_RISK,
        payload: data
    };
};

export const setOperationalProjectRisksPagination = (pagination) => {
    return {
        type: SET_OPERATIONAL_PROJECT_RISKS_PAGINATION,
        payload: pagination
    };
};

export const clearOperationalProjectRisks = () => {
    return {
        type: CLEAR_OPERATIONAL_PROJECT_RISKS
    };
};

export const clearSelectedOperationalProjectRisk = () => {
    return {
        type: CLEAR_SELECTED_OPERATIONAL_PROJECT_RISK
    };
};

export const clearOperationalProjectRisksPagination = () => {
    return {
        type: CLEAR_OPERATIONAL_PROJECT_RISKS_PAGINATION
    };
};

export const setOperationalProjectRisksSort = (sort) => {
    return {
        type: SET_OPERATIONAL_PROJECT_RISKS_SORT,
        payload: sort
    };
};

export const clearOperationalProjectRisksSort = () => {
    return {
        type: CLEAR_OPERATIONAL_PROJECT_RISKS_SORT
    };
};

export const setOperationalProjectRisksFilter = (filter) => {
    return {
        type: SET_OPERATIONAL_PROJECT_RISKS_FILTER,
        payload: filter
    };
};

export const clearOperationalProjectRisksFilter = () => {
    return {
        type: CLEAR_OPERATIONAL_PROJECT_RISKS_FILTER
    };
};

export const isRiskHasActions = (riskId) => {
    return {
        type: IS_RISK_HAS_ACTIONS,
        request: {
            url: `${OPERATIONAL_PROJECT_RISKS_ENDPOINT}/hasActions/${riskId}`
        },
        meta: {
            onSuccess: (response) => {
                return response;
            }
        }
    };
};

export const isMultipleRiskHasActions = (risksIds) => {
    return {
        type: IS_RISK_HAS_ACTIONS,
        request: {
            url: `${OPERATIONAL_PROJECT_RISKS_ENDPOINT}/hasActions`,
            params: { ids: risksIds.join(",") }
        }
    };
};