import React from 'react';
import {Route} from 'react-router-dom';
import {t} from '../i18n';
import { Alert } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import useStyles from './style';
import { hasPermission, hasRoles } from '../utils/authorization';
import { ADMIN_ROLE } from '../utils/constants';
import Error500 from '../App/components/Error500';
import Error403 from '../App/components/Error403';

export const PrivateRouteComponent = props => {
    const classes = useStyles();
    const resourceRoles = useSelector(({ keycloak }) => keycloak.resourceAccess.novyclient?.roles);
    const isError500 = useSelector(({ appState }) => appState.isError500);
    const isError403 = useSelector(({ appState }) => appState.isError403);

    const {
        component: Component,
        user,
        permission,
        roles,
        ...rest
    } = props;

    const isAuthorized = hasPermission(user.permissions, permission) && hasRoles(resourceRoles, roles);
    const checkRoles = props =>
        isAuthorized ? (
            isError500 ?                                                                     
                <Error500/>
            :
            isError403 ?
                <Error403/>
            :
                <Component {...props} />
        ) : (
            <Alert 
                severity="error"
                className={classes.errorBanner}
            >
                {t('you_are_not_authorized_to_access')}
            </Alert>
        );

    const RenderRedirect = props => {
        return checkRoles(props);
    };
    
    localStorage.setItem("_admin", hasRoles(resourceRoles, [ADMIN_ROLE]) ? 1 : 0);

    if (!Component)
        throw new Error(
            `A component needs to be specified for private route for path ${
                rest.path
            }`
        );

    return <Route {...rest} render={() => <RenderRedirect {...props} />} />;
};

export const PrivateRoute = PrivateRouteComponent;

export default PrivateRoute;
