import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    actionDetails: {
        width: "100%",
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: "white",
        borderTop: "1px solid #eee",
        borderBottom: "1px solid #eee"
    },
    deleteButton: {
        backgroundColor: theme.palette.error.main,
        color: "white",
        "&:hover": {
            backgroundColor: theme.palette.error.dark,
        }
    },
    priorityIcon: {
        fontSize: "1rem",
        marginRight: theme.spacing(1)
    },
    colName: {
        fontWeight: "500"
    },
    circularProgress : { 
        color: 'white',
        marginRight:"11px",
        marginLeft:"-5px" 
    }
}));