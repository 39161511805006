import { onError, onSuccess } from "../../../../../utils/http";
import { errorNotification, successNotification } from "../../../../../utils/notification";
import {
    GET_ALL_OPERATIONAL_RISK_CONFIG,
    SET_ALL_OPERATIONAL_RISK_CONFIG,
    PUT_OPERATIONAL_RISK_CONFIG,
    GET_DEFAULT_OPERATIONAL_RISK_CONFIG,
    SET_DEFAULT_OPERATIONAL_RISK_CONFIG,
    PUT_DEFAULT_OPERATIONAL_RISK_CONFIG
} from "../../constants";
import { OPERATIONAL_RISK_ENDPOINT } from "../../constants/endpoint";


export const getAllOperationalRiskConfig = () => {
    return {
        type: GET_ALL_OPERATIONAL_RISK_CONFIG,
        request: {
            url: OPERATIONAL_RISK_ENDPOINT,
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setAllOperationalRiskConfig(response.data));
                return response;
            })
        }
    };
};

export const getDefaultOperationalRiskConfig = () => {
    return {
        type: GET_DEFAULT_OPERATIONAL_RISK_CONFIG,
        request: {
            url: `${OPERATIONAL_RISK_ENDPOINT}/default`,
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setDefaultOperationalRiskConfig(response.data));
                return response;
            })
        }
    };
};

export const putOperationalRiskConfig = (data, projectId) => {
    return {
        type: PUT_OPERATIONAL_RISK_CONFIG,
        request: {
            url: `${OPERATIONAL_RISK_ENDPOINT}/${projectId}`,
            method: "put",
            data: data
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "update_success");
                return response;
            }),
            onError: onError(({ response }) => {
                errorNotification('', "update_error");
                return response;
            })
        }
    };
};

export const putDefaultOperationalRiskConfig = (data) => {
    return {
        type: PUT_DEFAULT_OPERATIONAL_RISK_CONFIG,
        request: {
            url: `${OPERATIONAL_RISK_ENDPOINT}/default`,
            method: "put",
            data: data
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "update_success");
                return response;
            }),
            onError: onError(({ response }) => {
                errorNotification('', "update_error");
                return response;
            })
        }
    };
};

export const setAllOperationalRiskConfig = (data) => {
    return {
        type: SET_ALL_OPERATIONAL_RISK_CONFIG,
        payload: data
    };
};

export const setDefaultOperationalRiskConfig = (data) => {
    return {
        type: SET_DEFAULT_OPERATIONAL_RISK_CONFIG,
        payload: data
    };
};


