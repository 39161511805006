import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        boxShadow: theme.shadows[25],
        borderBottom: "1px solid #CCCCCC",
    },
    content: {
        display: "flex",
        width: "100%",
    },
    deleteIcon: {
        color: theme.palette.error.main,
    },
    endAdornment: {
        '& p': {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            fontSize: "16px",
            textAlign: "center",
            height: "38px",
            width: "30px",
            borderTopRightRadius: "5px",
            borderBottomRightRadius: "5px",
            position: "relative",
            left: "14px",
            padding: "8px 0px"
        }
    },
    multipleSelect: {
        width: "50%",
    }
}));