import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
   taskDiv:{
       marginTop: '8px',
       padding: '16px',
       boxShadow: '1px 1px 1px rgba(0,0,0,0.1)',
       alignItems: 'center',
       justifyContent: 'space-between',
       flexDirection: 'row',
       maxWidth:'inherit',
       width:'inherit',
       borderRadius: '5px',
       minHeight:'26vh',
   },
    responsableInfoGrid:{
        marginTop: '15px',
        marginLeft: '13px'
    },
    responsableInfoTypography:{
        textAlign:'left',
        paddingTop:1,
        direction:'column',
        color:'#010101',
        fontSize:'0.9rem',
        fontWeight:'500'
    },
    taskNameTypography:{
        color:'#6c6c6c',
        fontSize:'1rem',
        fontWeight:'500',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    taskDescriptionTypography:{
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    gridPopInItems:{
        paddingLeft: '1px',
        paddingRight: '1px'
    },
    typographyDescription: {
        hyphens: 'auto',
        wordBreak: 'break-word',
        textAlign: 'justify',
        textJustify: 'inter-word',
        letterSpacing: '0.05em'
    },
    projectNameTypography:{
        marginLeft:'2px',
        fontSize:'0.8rem',
        fontWeight:'bold'
    },
    informationAndResapnsablePopIn:{
        fontSize:'0.8rem',
        fontWeight:'bold'
    },
    columnName:{
        fontSize: '1rem',
        textAlign: 'left',
        fontWeight: 750,
        paddingLeft: '5px',
        paddingRight: '5px',
        color: '#565555',
    },
    columnGrid:{
        padding:'20px',
        borderRadius:'10px'
    },
    motherContainer:{
        display: 'flex',
        columnGap: '10px',
    },
    viewKanban:{
        marginTop:'10px',
        marginBottom:'20px'
    },
    dialogContent:{
        minWidth:'fit-content',
        minHeight:"fit-content",
        overflowY:'auto'
    },
    avatarGrid:{
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    dataToRight:{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end"
    },
    descriptionText:{
        marginRight:'5px' ,
        marginBottom:'10px'
    },
    taskOuterGrid:{
        display:"flex",
        marginTop:'30px',
    },
    taskPriority:{
        borderLeft: "8px solid #01579b",
        paddingLeft:'5px',
        marginRight:'2px'
    },
    taskPriorityIcons:{
        display:"flex",
        justifyContent:"flex-end",
    },
    actionType:{
        borderLeft: "8px solid #01579b",
        paddingLeft: '5px',
        marginRight: '2px'
    },
    centerData:{
        display: 'flex',
        alignItems: 'center'
    },
    taskListGrid:{
        display:'flex',
        flexDirection:'column',
        rowGap:'10px'
    },
    taskElements:{
        display:'flex',
        flexDirection:'column',
        rowGap:'2vh'
    },
    centerStatus:{
        alignSelf:'center',
        textAlign:'center'
    },
    cardHeader:{
        padding:0,
        width:"100%"
    },
    cardAction:{
        paddingTop: "18%"
    }
}));
