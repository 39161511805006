import {Box, Button, Checkbox, CircularProgress, Grid, IconButton, Tooltip, Typography} from "@material-ui/core";
import ProjectSelect from "../ProjectSelect";
import AddIcon from "@material-ui/icons/Add";
import Export from "../Export";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined';
import {PlaylistAddCheck} from "@material-ui/icons";
import ListColsHeader from "../ListColsHeader";
import {
    clearOperationalProjectRisks,
    clearOperationalProjectRisksFilter,
    clearOperationalProjectRisksPagination,
    clearSelectedOperationalProjectRisk,
    isMultipleRiskHasActions,
} from "../../../views/Operational/redux/actions/risks";
import DeleteIcon from "@material-ui/icons/Delete";
import NoData from "../NoData";
import {Skeleton} from "@material-ui/lab";
import MotherContainer from "../KanbanView/MotherContainer";
import Pagination from "../Pagination";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import useStyles from "./style";
import {useTranslation} from "react-i18next";
import ActionOrRiskCard from "./components/ActionOrRiskCard";
import ActionOrRiskModal from "./components/ActionOrRiskModal";
import {clearSelectedOperationalProject, getAllProjects} from "../../../views/Operational/redux/actions/projects";
import {
    getAllActionPriority,
    getAllActionStatus,
    getAllActionTypes,
    getAllRiskCategories,
    getAllRiskPriority,
    getAllRiskProbability,
    getAllRiskStatus
} from "../../../views/Operational/redux/actions/rt";
import {getAllResponsables} from "../../../redux/actions/collaborators";
import {
    clearOperationalProjectActions,
    clearOperationalProjectActionsFilter,
    clearOperationalProjectActionsPagination,
    clearSelectedOperationalProjectAction,
    deleteOperationalProjectAction,
    getSelectedOperationalProjectAction,
    putOperationalRiskAction,
} from "../../../views/Operational/redux/actions/actions";
import ActionOrRiskFilters from "./components/ActionOrRiskFilters";
import {STATUS_COMPLETED} from "./components/actionsStatuses";
import {STATUS_CLOSED} from "./components/riskStatuses";
import {RadarChart} from "../RadarChart";

const ActionsOrRisks = ({risk, global, deleteModal, setDeleteModal, handleDeleteModalChange,
    setOperationalProjectFilter,deleteMultipleOperationalProject,getOperationalProject,
    clearOperationalProjectPagination,exportOperationalProject,putOperationalProject,
    getSelectedOperationalProject,setOperationalProjectSort,setOperationalProjectPagination,
    fileName,addRiskOrAction,title,projectFileName,setSelectedOperationalProject,deleteOperationalProject,
    postOperationalProject,clearOperationalProjectFilter,deleteSelectionModal,modalOfDelete,col,
    statusList,priorityList,riskProbabilitiesList,riskCategoriesList,filter,currentActionOrRisk,actionTypeList,columns,globalColumns
})=> {

    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const [selected, setSelected] = useState([]);
    const actionsOrRisks = useSelector(({ operational }) => risk ? operational.risks.list.risks : operational.actions.list.actions);
    const selectedActionOrRisk = useSelector(({ operational }) => risk ? operational.risks.selected : operational.actions.selected);
    const paginationSize = useSelector(({ operational }) => risk ? operational.risks.config.pagination.size : operational.actions.config.pagination.size);
    const maxLength = useSelector(({ operational }) => risk ? operational.risks.config.pagination.maxLength : operational.actions.config.pagination.maxLength);
    const selectedProject = useSelector(({operational}) => operational.projects.selected);
    const currentCollaborator = useSelector(({ collaborators }) => collaborators.currentCollaborator);
    const [gridView ,setGridView] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [add, setAdd] = useState(false);
    const [loading, setLoading] = useState(true);
    const [render, setRender] = useState(false);
    const [deleteLoading,setDeleteLoading]=useState(false)
    const [categoryView,setCategoryView]=useState(false)
    const [notifyCommentDialog, setNotifyComment] = useState({
        hasChanged: false,
        changedQuickActionId: null
    });
    const selectedActionInRisk= useSelector(({ operational }) => operational.actions.selected);
    const [actionInRiskPopUp,setActionInRiskPopUp] = useState(false)
    const [riskField,setRiskField] = useState(false)
    const [searchValue, setSearchValue] =useState("")

    const handleSelectedProjectChange = (selected) => {
        if(global) {
            dispatch(setOperationalProjectFilter({...filter, projects: selected}));
        } else {
            dispatch(setSelectedOperationalProject(selected));
        }
    };
    const [references, setReferences] =useState(false)
 

    const openModal = () => {setModalOpen(true)};

    const openCreateModal = () => {
        setAdd(true);
        openModal();
    };

    const closeModal = () => {
        setModalOpen(false);
        setAdd(false);
        if(global)
        {
            window.history.replaceState(null, title, " ");
            if(risk)
                dispatch(clearSelectedOperationalProjectAction())
            else
                dispatch(clearSelectedOperationalProjectRisk())
        }
        if(actionInRiskPopUp)
        {
            setActionInRiskPopUp(false)
            setRiskField(true)
        }
    };
    const statusListpopUp = useSelector(({ operational }) => operational.rt.actionStatus);
    const priorityListpopUp = useSelector(({ operational }) => operational.rt.actionPriorities);
    const actionTypeListpopUp = useSelector(({ operational }) => operational.rt.actionTypes);

    useEffect(()=>{
        setRiskField(risk)
    },[risk])

    useEffect(()=>{
        if(actionInRiskPopUp)
        {
            dispatch(getAllActionPriority());
            dispatch(getAllActionStatus());
            dispatch(getAllActionTypes());
        }
     },[actionInRiskPopUp])
 

    const isSelected = (id) => selected.indexOf(id) !== -1;
    const handleSelect = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const newSelects = actionsOrRisks?.map(({id}) => id);
            setSelected(newSelects);
            return;
        }
        setSelected([]);
    };

    const deleteSelection = async () => {
        setDeleteLoading(true)
        const res = await dispatch(isMultipleRiskHasActions(selected))
        let risksHasAction = res.data
        let bodyText;
        if (selected.length > 1){
            bodyText =
                risksHasAction ?
                    <span>
                        {t('delete_risks_that_have_actions_modal')}, {t('continue_question')}
                    </span>
                :
                    <span>{deleteSelectionModal}
                        <b>{`${selected.length} `}</b>
                        {title}, {t('continue_question')}
                    </span>
        }
        else {
            let element = actionsOrRisks.find((item) => item.id === selected[0]);
            
            bodyText =
            risksHasAction?
                    <span>{modalOfDelete} <b>{element.name}</b> {t('delete_risk_that_have_actions_modal')}, {t('continue_question')}</span>
                :
                    <span>{modalOfDelete}
                        <b>{element?.name}</b>,
                        {t('continue_question')}
                    </span>
        }

        setDeleteModal({
            ...deleteModal,
            open: true,
            type: "error",
            title: t('suppression'),
            body: bodyText,
            showCloseButton: true,
            onChange: handleDeleteModalChange,
            cancel: {
                title: t('cancel'),
                onClick: ()=>{
                    handleDeleteModalChange(false);
                    setSelected([]);
                }
            },
            confirm: {
                title: t('confirm'),
                onClick: () => {
                    handleDeleteModalChange(false);
                    dispatch(deleteMultipleOperationalProject(selected, !global && selectedProject.id)).then(
                        () => { setSelected([]); }
                    );
                }
            }
        
        });   
        setDeleteLoading(false)       
    };

    const clearFetch = () => {
        dispatch(clearOperationalProjectPagination());
        fetch();
    };

    const fetch = async () => {
        if(!loading)
            setLoading(true)
        const projectId = !global && selectedProject?.id;
        dispatch(getOperationalProject(projectId, global)).then((res)=>{
            if(res.data)
                setLoading(false);
        });
    };

    const exportActionsOrRisks = () => {
        const projectId = selectedProject?.id;
        return dispatch(exportOperationalProject(projectId));
    };

    const toggleGridView= ()=>{
        setCategoryView(false);
        setGridView(!gridView);
    };

    const handleUpdateActionOrRiskStatus = (actionOrRisk)=>{
        dispatch(putOperationalProject(actionOrRisk,global));
    };

    const haveActionsOrRisksUpdated = (hasUpdated,action,quickAction) => {
        if (hasUpdated) {
            fetch();
            if (action && quickAction && (action?.status?.code !== STATUS_COMPLETED && quickAction?.status?.code === STATUS_COMPLETED) ||
                action?.status?.code !== STATUS_CLOSED && quickAction?.status?.code === STATUS_CLOSED){
                setNotifyComment({
                    hasChanged: true,
                    changedQuickActionId: quickAction.id
                });
            }else {
                setNotifyComment({
                    hasChanged: false,
                    changedQuickActionId: null
                });
            }
        }
    };

    useEffect(() => {
        const hash=window.location.hash.substring(1);
        if(hash==="kanban")
            setGridView(!gridView);
        if(hash==="category")
            setCategoryView(!categoryView);
        return ()=>{
            dispatch(clearOperationalProjectFilter())
        }
    }, []);
    

    useEffect(() => {
        if(!global)
        {
            if(risk)
            {
                dispatch(clearOperationalProjectActionsFilter())
            }
            else
                dispatch(clearOperationalProjectRisksFilter())
        }
        if (risk){
            dispatch(getAllProjects());
            dispatch(getAllResponsables());
            Promise.all([dispatch(getAllRiskPriority()),dispatch(getAllRiskStatus()),dispatch(getAllRiskProbability()),dispatch(getAllRiskCategories())])
            .then(()=>{
                setReferences(true)
            })

        }else {
            dispatch(getAllProjects());
            dispatch(getAllResponsables());
            Promise.all([dispatch(getAllActionPriority()),dispatch(getAllActionStatus()),dispatch(getAllActionTypes())])
            .then(()=>{
                setReferences(true)
            })
        }
        setSearchValue("")
    }, [risk]);

    useEffect(() => {
        if(gridView)
            window.history.replaceState(null, title, "#kanban");
    }, [gridView]);

    useEffect(() => {
        if(categoryView)
            window.history.replaceState(null, title, "#category");
    }, [categoryView]);
    
    useEffect(() => {  
        if(selectedProject && render){
            fetch();
        }   
    }, [selectedProject,risk]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if(currentCollaborator)
            clearFetch()
    },[filter, currentCollaborator])
    
    useEffect(() => {
        if (global) {
            const actionIdOrRiskId = window.location.hash.substring(1);

            if(actionIdOrRiskId)
            {
                const splitHash = actionIdOrRiskId.split("/")
                if(splitHash.length == 2)
                {
                    setRiskField(false)
                    setActionInRiskPopUp(true)
                    dispatch(getSelectedOperationalProjectAction(splitHash[1],"risk")).then((res)=>{
                        setActionInRiskPopUp(true)
                        setModalOpen(true)
                    })
                }
                else
                    if(!isNaN(+actionIdOrRiskId)) {
                        dispatch(getSelectedOperationalProject(actionIdOrRiskId)).then(res => {
                            if (res.data) {
                                setModalOpen(true);
                            }
                        });
                    }
               
            }
        }
        setRender(true);
        return () => {
            if (risk){
                dispatch(clearOperationalProjectRisksPagination());
                dispatch(clearOperationalProjectRisks());
                dispatch(clearSelectedOperationalProjectRisk());

            }else {
                dispatch(clearOperationalProjectActionsPagination());
                dispatch(clearOperationalProjectActions());
                dispatch(clearSelectedOperationalProjectAction());
            }
        };
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (global) {
            if(selectedActionOrRisk)
                window.history.replaceState(null, title,`#${selectedActionOrRisk.id}`);
            else
                if(actionInRiskPopUp)
                    window.history.replaceState(null, title,`#action/${selectedActionInRisk.id}`);

        }
        
    }, [selectedActionOrRisk,selectedActionInRisk]); //eslint-disable-line react-hooks/exhaustive-deps

    const deleteActionInRisk = (actionId, project ) =>
    {
        return deleteOperationalProjectAction(actionId, project, true)
    }
    return(
        <Grid container>
            <Grid item xs={12} style={{marginBottom:"17px"}}>
                <Typography variant="h6">
                  {maxLength} { risk ? ( maxLength > 1 ? t("risques") : t('risque') ): ( maxLength > 1 ? t("actions") : t("action"))}
                </Typography>
            </Grid>
            {
                global
                    ? <Grid container alignItems="center" justify="space-between">
                        <Grid item xs={3}>
                            <ProjectSelect
                                value={global ? filter.projects : (selectedProject ?? null)}
                                handleValueChange={handleSelectedProjectChange}
                                variant="outlined"
                                size="small"
                                inputStyle={{
                                    backgroundColor: "#fbfbfb",
                                    borderRadius: 4,
                                }}
                                inputLabel={t('projects')}
                                multiselect={global}
                                checkmark={true}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2}>
                                {!categoryView && <Grid item>
                                    <Button
                                        color="primary"
                                        startIcon={<AddIcon/>}
                                        variant="outlined"
                                        size="medium"
                                        onClick={openCreateModal}
                                        className={classes.addActionButton}

                                    >
                                        {addRiskOrAction}
                                    </Button>
                                </Grid>}
                                <Grid item>
                                    <Export
                                        action={exportActionsOrRisks}
                                        fileName={fileName}
                                    />
                                </Grid>
                                <Grid item>
                                    <Tooltip title={gridView ? t("list_view") : t("kanban_view")} placement="top" arrow>
                                        <IconButton onClick={toggleGridView} className={classes.iconButtonNoHoverBG} disableRipple>
                                            {gridView ? <FormatListBulletedIcon fontSize="default" /> : <PlaylistAddCheck fontSize="default" />}
                                        </IconButton>
                                    </Tooltip>
                                    {risk && (
                                        <Tooltip title={t("category_view")} placement="top" arrow>
                                            <IconButton className={classes.iconButtonNoHoverBG} onClick={()=>setCategoryView(true)}>
                                                <ClassOutlinedIcon fontSize="default" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    : <Grid container alignItems="center" justify="space-between">
                        <Grid item>
                            <Button
                                color="primary"
                                startIcon={<AddIcon />}
                                variant="outlined"
                                size="medium"
                                onClick={openCreateModal}
                                className={classes.addActionButton}
                            >
                                {addRiskOrAction}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Export
                                action={exportActionsOrRisks}
                                fileName={`${projectFileName} - ${selectedProject.name}`}
                            />
                        </Grid>
                    </Grid>
            }
            
            <Grid container className={classes.filtersContainer}>
                <ActionOrRiskFilters
                    risk={risk}
                    setOperationalProjectFilter={setOperationalProjectFilter}
                    statusList={statusList}
                    priorityList={priorityList}
                    riskProbabilitiesList={riskProbabilitiesList}
                    filter={filter}
                    actionTypeList={actionTypeList}
                    riskCategoriesList={riskCategoriesList}
                    categoryView={categoryView}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
            </Grid>
            {!gridView && !categoryView && <Grid container>
                <Grid item xs={10}>
                    <ListColsHeader
                        type="columns"
                        className={classes.tableCardHeader}
                        columns={global ? globalColumns : columns}
                        onChange={render && clearFetch}
                        getSort={({operational}) => risk ? operational.risks.list.sort : operational.actions.list.sort}
                        setSort={setOperationalProjectSort}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Grid container justify="center" alignItems="center" className={classes.groupedActions}>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item>
                            <Tooltip title={t('delete')} placement="top">
                                <span>
                                    {
                                        selected.length === 0
                                            ? <IconButton disabled>
                                                <DeleteIcon />
                                            </IconButton>
                                            : <IconButton onClick={deleteSelection}>
                                                 {deleteLoading ? 
                                                    <CircularProgress size={23} sx={{ color: 'black' }}/>
                                                 :
                                                    <DeleteIcon className={classes.deleteIcon} />
                                                 }
                                            </IconButton>
                                    }
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Checkbox
                                indeterminate={selected.length > 0 && selected.length < actionsOrRisks.length}
                                checked={actionsOrRisks?.length > 0 && selected.length === actionsOrRisks?.length}
                                onChange={handleSelectAll}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            }
            <NoData condition={actionsOrRisks?.length === 0 || actionsOrRisks.every(element => element === null)} loading={loading} />

            <Grid container>
                <Grid item xs={12}>
                    {
                        loading ? [...Array(paginationSize)].map((_, index) => (
                            <Box mb={2} key={index}>
                                <Skeleton height={62.5} variant="rect" animation="wave" className={classes.skeleton} />
                            </Box>
                        ))
                            : !gridView && !categoryView && actionsOrRisks?.map(actionOrRisk => {
                                const isItemSelected = isSelected(actionOrRisk?.id);
                                return (
                                    actionOrRisk && 
                                    <ActionOrRiskCard
                                        selectedActionInRisk={selectedActionInRisk}
                                        setRiskField={setRiskField}
                                        setActionInRiskPopUp={setActionInRiskPopUp}
                                        risk={risk}
                                        global={global}
                                        actionData={actionOrRisk}
                                        key={actionOrRisk?.id}
                                        isItemSelected={isItemSelected}
                                        handleSelect={handleSelect}
                                        handleModalOpen={openModal}
                                        deleteModal={deleteModal}
                                        setDeleteModal={setDeleteModal}
                                        handleDeleteModalChange={handleDeleteModalChange}
                                        setSelectedOperationalProject={setSelectedOperationalProject}
                                        putOperationalProject={putOperationalProject}
                                        deleteOperationalProject={deleteOperationalProject}
                                        modalOfDelete={modalOfDelete}
                                        priorityList={priorityList}
                                        statusList={statusList}
                                        actionTypesList={actionTypeList}
                                        riskCategoriesList={riskCategoriesList}
                                        haveActionsOrRisksUpdated={haveActionsOrRisksUpdated}
                                        notifyCommentDialog={notifyCommentDialog}
                                    />
                                );
                            })
                    }
                </Grid>
                <Grid container item xs={12}>
                    {
                        gridView && !categoryView && actionsOrRisks &&
                        <MotherContainer
                            gridView={gridView}
                            tasks={actionsOrRisks}
                            taskStatusList={statusList}
                            getUpdatedTask={handleUpdateActionOrRiskStatus}
                            deleteModal={deleteModal}
                            setDeleteModal={setDeleteModal}
                            handleDeleteModalChange={handleDeleteModalChange}
                            putOperationalProject={putOperationalProject}
                            risk={risk}
                        />
                    }
                </Grid>
            </Grid>

            {!gridView && !categoryView &&
            <Pagination
                loading={loading}
                appendMode={false}
                gridView={false}
                getPagination={({operational}) => risk ? operational.risks.config.pagination : operational.actions.config.pagination}
                setPagination={setOperationalProjectPagination}
                onChange={fetch}
            />
            }

            {categoryView && risk && (actionsOrRisks.length !== 0) && <RadarChart risks={actionsOrRisks} categoryView={categoryView}/>}

            <ActionOrRiskModal
                riskField={riskField}
                risk={risk}
                global={global}
                add={add}
                references={references}
                isRisk={actionInRiskPopUp}
                setModalOpen={setModalOpen}
                modalOpen={modalOpen}
                handleModalClose={closeModal}
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                handleDeleteModalChange={handleDeleteModalChange}
                postOperationalProject={postOperationalProject}
                clearOperationalProjectFilter={clearOperationalProjectFilter}
                deleteOperationalProject={actionInRiskPopUp ? deleteActionInRisk : deleteOperationalProject}
                putOperationalProject={actionInRiskPopUp ? putOperationalRiskAction : putOperationalProject}
                addActionOrRisk={addRiskOrAction}
                col={col}
                statusList={actionInRiskPopUp ? statusListpopUp : statusList}
                priorityList={actionInRiskPopUp ? priorityListpopUp : priorityList}
                riskProbabilitiesList={riskProbabilitiesList}
                currentActionOrRisk={actionInRiskPopUp ? selectedActionInRisk : currentActionOrRisk}
                modalOfDelete={modalOfDelete}
                actionTypeList={actionInRiskPopUp ? actionTypeListpopUp : actionTypeList}
                riskCategoriesList={riskCategoriesList}
                haveActionsOrRisksUpdated={haveActionsOrRisksUpdated}
            />

        </Grid>
    );
};
export default ActionsOrRisks;