import {
    GET_ALL_RT_ACTION_STATUS,
    SET_ALL_RT_ACTION_STATUS,
    POST_RT_ACTION_STATUS,
    PUT_RT_ACTION_STATUS,
    DELETE_RT_ACTION_STATUS,
    EXPORT_RT_ACTION_Status,
    IMPORT_RT_ACTION_STATUS,
} from '../../constants';
import {errorNotification, successNotification} from "../../../../../utils/notification";
import { OPERATIONAL_RT_ENDPOINT } from '../../constants/endpoint';
import {popUpError} from "./popUpError";

export const getAllActionStatus = () => {
    return {
        type: GET_ALL_RT_ACTION_STATUS,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/actionStatus`
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllActionStatus(response.data));
                return response;
            }
        }
    };
};

export const setAllActionStatus = (data) => {
    return {
        type: SET_ALL_RT_ACTION_STATUS,
        payload: data
    };
};

export const postActionStatus = (actionStatus) => {
    return {
        type: POST_RT_ACTION_STATUS,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/actionStatus`,
            method: 'post',
            data: actionStatus
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "create_success");
                store.dispatch(getAllActionStatus());
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "create_error");
                throw error;
            }
        }
    };
};

export const putActionStatus = (id, actionStatus) => {
    return {
        type: PUT_RT_ACTION_STATUS,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/actionStatus/${id}`,
            method: 'put',
            data: actionStatus
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "update_success");
                store.dispatch(getAllActionStatus());
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "update_error");
                throw error;
            }
        }
    };
};

export const deleteActionStatus = (id) => {
    return {
        type: DELETE_RT_ACTION_STATUS,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/actionStatus/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "delete_success");
                store.dispatch(getAllActionStatus());
                return response;
            },
            onError: (error, requestAction, store) => {
                popUpError(error,"delete_action_status_error");
            }
        }
    };
};

export const exportRtActionStatus = (type) => {
    return {
        type: EXPORT_RT_ACTION_Status,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/actionStatus/export`,
            responseType: 'arraybuffer'
        },
        meta: {
            onRequest: (request) => {
                if(type)
                    request.url=request.url+"?type="+type
                return request;
            },
            onSuccess: (response) => {
                return response.data;
            }
        }
    };
};
export const importRtActionStatus = (data) => {
    return {
        type: IMPORT_RT_ACTION_STATUS,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/actionStatus/import`,
            method:"post",
            data:data
        },
        meta: {
            onSuccess: (response,requestAction,store) => {
                store.dispatch(getAllActionStatus());
                return response.data;
            }
        }
    };
};
