import React, { useEffect } from 'react';
import {
    Grid,
    Input,
    FormControl,
    InputAdornment
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import useStyles from './style';
import ResponsableSelect from "../../../ResponsableSelect";
import CustomDatePicker from "../../../CustomDatePicker";
import MultiSelect from "../../../MultiSelect";
import getColorForPercent from "../../../../../utils/getColorForPercent";
import { useState } from 'react';


let searchTimeout = 0;

function ActionOrRiskFilters({ risk , setOperationalProjectFilter,
    priorityList, statusList, riskProbabilitiesList,filter,actionTypeList, riskCategoriesList,categoryView, searchValue, setSearchValue}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const dispatchFilter = (f) => dispatch(setOperationalProjectFilter(f));

    const search = (value) => {
        setSearchValue(value)
        if(searchTimeout) clearTimeout(searchTimeout);

        searchTimeout = setTimeout(() => {
            dispatchFilter({ ...filter, search: value});
        }, 600);
    };

    return (
        <Grid container justify={!categoryView ? "space-between" : "flex-end"} alignItems="center">
            {!categoryView && <Grid container item xs={risk ? 2 : 3}>
                <Grid item xs={risk ? 8 : 9}>
                    <FormControl fullWidth>
                        <Input
                            type="text"
                            placeholder={t('search_placeholder')}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon color="primary"/>
                                </InputAdornment>
                            }
                            value={searchValue}
                            onChange={e => search(e.target.value)}
                        />
                    </FormControl>
                </Grid>
            </Grid>}

            <Grid item xs={ risk ? 10 : 8 }>
                <Grid container spacing={risk ? 3 : 6}>
                    <Grid item xs={ risk ? 2 : 3}>
                        <ResponsableSelect
                            usage="filter"
                            disabled={filter?.search !== ""}
                            value={filter?.search !== "" ? [] : filter.responsables}
                            handleValueChange={(value) => dispatchFilter({...filter, responsables: value})}
                            checkmark={true}
                            isRisk={risk}
                        />
                    </Grid>
                    <Grid item xs={risk ? 2 : 3}>
                        <CustomDatePicker
                            selectedDate={filter?.echeance !== "" ? filter?.echeance : null}
                            onChange={value => dispatchFilter({ ...filter, echeance: value ? moment(value).format("yyyy-MM-DD") : null })}
                            emptyLabel={t('echeance_before')}
                            inputVariant="standard"
                            margin="normal"
                            disabled={filter?.search !== ""}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <MultiSelect
                            label={t('priorite_col')}
                            noOptionsLabel={t('no_priority_found')}
                            value={filter?.search !== "" ? [] : filter.priorities}
                            onChange={e => dispatchFilter({...filter, priorities: e.target.value})}
                            disabled={filter?.search !== ""}
                            list={priorityList?.map(p => ({id: p.id, label: p.name, orderPercent: p.orderPercent}))}
                            checkmark={true}
                            renderLabel={(p) => (
                                <>
                                    <FiberManualRecordIcon
                                        style={{ color: getColorForPercent(p.orderPercent) }}
                                        className={classes.priorityIcon}
                                    />
                                    {p.label}
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <MultiSelect
                            label={t('status_col')}
                            noOptionsLabel={t('no_status_found')}
                            value={filter?.search !== "" ? [] : filter.status}
                            onChange={e => dispatchFilter({...filter, status: e.target.value})}
                            disabled={filter?.search !== ""}
                            list={statusList?.map(s => ({id: s.id, label: s.name}))}
                            checkmark={true}
                        />
                    </Grid>
                    {risk && <Grid item xs={2}>
                        <MultiSelect
                            label={t('probability_col')}
                            noOptionsLabel={t('no_probability_found')}
                            value={filter?.search !== "" ? [] : filter.probabilities}
                            onChange={e => dispatchFilter({...filter, probabilities: e.target.value})}
                            disabled={filter?.search !== ""}
                            list={riskProbabilitiesList?.map(s => ({id: s.id, label: s.name}))}
                            checkmark={true}
                        />
                    </Grid>}
                    {risk &&
                        <Grid item xs={2}>
                            <MultiSelect
                                label={t('category_col')}
                                noOptionsLabel={t('no_category_found')}
                                value={filter?.search !== "" ? [] : filter.categories}
                                onChange={e => dispatchFilter({...filter, categories: e.target.value})}
                                disabled={filter?.search !== ""}
                                list={riskCategoriesList?.map(s => ({id: s.id, label: s.name}))}
                                checkmark={true}
                            />
                        </Grid>
                    }
                    {!risk && <Grid item xs={2}>
                        <MultiSelect
                            label={t('type_col')}
                            noOptionsLabel={t('no_type_found')}
                            value={filter?.search !== "" ? [] : filter.types}
                            onChange={e => dispatchFilter({...filter, types: e.target.value})}
                            disabled={filter?.search !== ""}
                            list={actionTypeList?.map(s => ({id: s.id, label: s.name}))}
                            checkmark={true}
                        />
                    </Grid>}
                </Grid>
            </Grid>

        </Grid>
    );

}

export default ActionOrRiskFilters;