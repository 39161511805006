const GRID_PAGINATION_CONFIG = {
    rowsPerPage: [12, 24]
};

const LIST_PAGINATION_CONFIG = {
    rowsPerPage: [5, 15, 30, 50]
};

export const MIN_INVOICING_YEAR = 2017;
export const MAX_INVOICING_YEAR = new Date().getFullYear() + 1;

export const PROJECTS_GRID_ITEMS_PER_ROW = {
    xl: 2,
    lg: 3,
    md: 4
};

export default function getPaginationConfig(gridView) {
    return gridView ? GRID_PAGINATION_CONFIG : LIST_PAGINATION_CONFIG;
}

export const getInitialPagination = (gridView) => {
    return gridView
        ? {
            page: 0,
            size: GRID_PAGINATION_CONFIG.rowsPerPage[0],
            maxLength: 0,
        }
        : {
            page: 0,
            size: LIST_PAGINATION_CONFIG.rowsPerPage[2],
            maxLength: 0,
        };
};