import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        border: "2px solid",
        "&:hover": {
            border: "2px solid",
        },
        textTransform: "none"
    }
}));