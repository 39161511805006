import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import fr from "./fr";
import us from "./en";
import operational_fr from "../views/Operational/i18n/fr";
import operational_us from "../views/Operational/i18n/en";
import financial_fr from "../views/Financial/i18n/fr";
import financial_us from "../views/Financial/i18n/en";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
    fr: {
        translation: {...fr, ...operational_fr, ...financial_fr}
    },
    us: {
        translation: {...us, ...operational_us, ...financial_us}
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "fr",
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
});

export const t=(key, options) => {
    return options ? i18n.t(key, options) : i18n.t(key);
};

export default i18n;