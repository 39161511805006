import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    filterInput: {
        padding: theme.spacing(0, 3)
    },
    priorityIcon: {
        fontSize: "1rem",
        marginRight: theme.spacing(1)
    },
    deleteIcon: {
        color: theme.palette.error.main,
    },
}));
