import {
    GET_ALL_RT_RISK_PROBABILITY,
    SET_ALL_RT_RISK_PROBABILITY,
    POST_RT_RISK_PROBABILITY,
    PUT_RT_RISK_PROBABILITY, DELETE_RT_RISK_PROBABILITY, EXPORT_RT_RISK_PRIORITY, EXPORT_RT_RISK_PROBABILITY, IMPORT_RT_RISK_PROBABILITY
} from '../../constants';
import {errorNotification, successNotification} from "../../../../../utils/notification";
import { OPERATIONAL_RT_ENDPOINT } from '../../constants/endpoint';
import {popUpError} from "./popUpError";

export const getAllRiskProbability = () => {
    return {
        type: GET_ALL_RT_RISK_PROBABILITY,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/riskProbability`
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllRiskProbability(response.data));
                return response;
            }
        }
    };
};

export const setAllRiskProbability = (data) => {
    return {
        type: SET_ALL_RT_RISK_PROBABILITY,
        payload: data
    };
};

export const postRiskProbability = (riskProbability) => {
    return {
        type: POST_RT_RISK_PROBABILITY,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/riskProbability`,
            method: 'post',
            data: riskProbability
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "create_success");
                store.dispatch(getAllRiskProbability());
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "create_error");
                throw error;
            }
        }
    };
};

export const putRiskProbability = (id, riskProbability) => {
    return {
        type: PUT_RT_RISK_PROBABILITY,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/riskProbability/${id}`,
            method: 'put',
            data: riskProbability
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "update_success");
                store.dispatch(getAllRiskProbability());
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "update_error");
                throw error;
            }
        }
    };
};

export const deleteRiskProbability = (id) => {
    return {
        type: DELETE_RT_RISK_PROBABILITY,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/riskProbability/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "delete_success");
                store.dispatch(getAllRiskProbability());
                return response;
            },
            onError: (error, requestAction, store) => {
                popUpError(error,"delete_risk_probability_error");
            }
        }
    };
};

export const exportRtRiskProbability = (type) => {
    return {
        type: EXPORT_RT_RISK_PROBABILITY,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/riskProbability/export`,
            responseType: 'arraybuffer'
        },
        meta: {
            onRequest: (request) => {
                if(type)
                    request.url=request.url+"?type="+type
                return request;
            },
            onSuccess: (response) => {
                return response.data;
            }
        }
    };
};


export const importRtRiskProbability = (data) => {
    return {
        type: IMPORT_RT_RISK_PROBABILITY,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/riskProbability/import`,
            method:"post",
            data:data
        },
        meta: {
            onSuccess: (response,requestAction,store) => {
                store.dispatch(getAllRiskProbability());
                return response.data;
            }
        }
    };
};
