import React from 'react';
import { BREADCRUMBS } from '../../../utils/format';
import GlobalRisks from "../pages/GlobalRisks";

const OperationalProjectsList = React.lazy(() => import('../pages/OperationalProjectsList'));
const ProjectDashboard = React.lazy(() => import('../pages/ProjectDashboard'));
const GlobalActions = React.lazy(() => import('../pages/GlobalActions'));
const ManualOpSnapshots = React.lazy(() => import('../pages/ManualOpSnapshots'));

export default [
    {
        path: '/operational/projects',
        exact: true,
        name: 'projects_list',
        component: OperationalProjectsList,
        permission: 'pilotage.managePilotage',
        roles: [],
        private: true,
        className: 'breadcrumb-operational',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: false
        }
    },
    {
        path: '/operational/projects/favorites',
        exact: true,
        name: 'favorites',
        component: OperationalProjectsList,
        permission: 'pilotage.managePilotage',
        roles: [],
        private: true,
        className: 'breadcrumb-operational',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: true
        }
    },
    {
        path: '/operational/projects/:id([0-9]+)',
        exact: true,
        name: 'project',
        component: ProjectDashboard,
        permission: 'pilotage.managePilotage',
        roles: [],
        private: true,
        className: 'breadcrumb-operational',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: true
        }
    },
    {
        path: '/operational/projects/favorites/:id([0-9]+)',
        exact: true,
        name: 'project',
        component: ProjectDashboard,
        permission: 'pilotage.managePilotage',
        roles: [],
        private: true,
        className: 'breadcrumb-operational',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: true
        }
    },
    {
        path: "/operational/snapshots",
        exact: true,
        name: "snapshots",
        component: ManualOpSnapshots,
        permission: "pilotage.managePilotage",
        roles: [],
        private: true,
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: false
        }
    },
    {
        path: '/operational/actions',
        exact: true,
        name: 'actions',
        component: GlobalActions,
        permission: 'pilotage.managePilotage',
        roles: [],
        private: true,
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: false
        }
    },
    {
        path: '/operational/risks',
        exact: true,
        name: 'risks',
        component: GlobalRisks,
        permission: 'pilotage.managePilotage',
        roles: [],
        private: true,
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: false
        }
    },
];
