import {getInitialPagination} from "../../../../../utils/common-state";
import {projectStatus} from "../../../../../utils/projectStatus";
import {
    CLEAR_FINANCIAL_PROJECTS_FILTER,
    CLEAR_FINANCIAL_PROJECTS_PAGINATION,
    CLEAR_FINANCIAL_PROJECTS_SORT,
    CLEAR_FINANCIAL_SUBPROJECTS_PAGINATION,
    CLEAR_FINANCIAL_SUBPROJECTS_SORT,
    CLEAR_SELECTED_FINANCIAL_PROJECT,
    SET_FINANCIAL_PROJECTS,
    SET_FINANCIAL_PROJECTS_FILTER,
    SET_FINANCIAL_PROJECTS_GRID_VIEW,
    SET_FINANCIAL_PROJECTS_PAGINATION,
    SET_FINANCIAL_PROJECTS_SORT,
    SET_FINANCIAL_SUBPROJECTS,
    SET_FINANCIAL_SUBPROJECTS_PAGINATION,
    SET_FINANCIAL_SUBPROJECTS_SORT,
    SET_SELECTED_FINANCIAL_PROJECT,
    UPDATE_FINANCIAL_PROJECT,
    UPDATE_FINANCIAL_SUB_PROJECT
} from "../../constants";


const initialState = {
    config: {
        gridView: false,
        pagination: getInitialPagination(false),
        subProjectsPagination: getInitialPagination(false)
    },
    list: {
        sort: {
            col: { id: 0 },
            type: "asc"
        },
        filter: {
            search: "",
            types: [],
            status: [projectStatus.EN_COURS],
            risks: [],
            parentOnly: true,
            clients: [],
            favorites: false
        },
        page: 0,
        size: getInitialPagination(false).size,
        projects: []
    },
    selected: null,
    subProjects: {
        sort: {
            col: { id: 0 },
            type: "asc"
        },
        page: 0,
        size: getInitialPagination(false).size,
        subProjects: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FINANCIAL_PROJECTS:
            return setFinancialProjects(state, action.payload);
        case SET_SELECTED_FINANCIAL_PROJECT:
            return setSelectedFinancialProject(state, action.payload);
        case SET_FINANCIAL_SUBPROJECTS:
            return setFinancialSubProjects(state, action.payload);
        case SET_FINANCIAL_SUBPROJECTS_PAGINATION:
            return setFinancialSubProjectsPagination(state,action.payload)
        case CLEAR_SELECTED_FINANCIAL_PROJECT:
            return clearSelectedFinancialProject(state);
        case SET_FINANCIAL_PROJECTS_PAGINATION:
            return setFinancialProjectsPagination(state, action.payload);
        case UPDATE_FINANCIAL_PROJECT:
            return updateFinancialProject(state, action.payload);
        case UPDATE_FINANCIAL_SUB_PROJECT:
            return updateFinancialSubProject(state, action.payload);
        case CLEAR_FINANCIAL_PROJECTS_PAGINATION:
            return clearFinancialProjectsPagination(state);
        case SET_FINANCIAL_PROJECTS_GRID_VIEW:
            return setGridView(state, action.payload.gridView);
        case SET_FINANCIAL_PROJECTS_FILTER:
            return setProjectsFilter(state, action.payload);
        case CLEAR_FINANCIAL_PROJECTS_FILTER:
            return clearProjectsFilter(state);
        case SET_FINANCIAL_PROJECTS_SORT:
            return setProjectsSort(state, action.payload);
        case CLEAR_FINANCIAL_PROJECTS_SORT:
            return clearProjectsSort(state, action.payload);
        case SET_FINANCIAL_SUBPROJECTS_SORT:
            return setSubProjectsSort(state, action.payload);
        case CLEAR_FINANCIAL_SUBPROJECTS_PAGINATION:
            return clearSubProjectsPagination(state);
        case CLEAR_FINANCIAL_SUBPROJECTS_SORT:
            return clearSubProjectsSort(state);
        default:
            return state;
    }
};

const setFinancialProjects = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: {
                ...state.config.pagination,
                page: parseInt(payload.page ?? 0),
                size: parseInt(payload.size ?? payload.maxLength),
                maxLength: parseInt(payload.maxLength)
            }
        },
        list: {
            ...state.list,
            page: parseInt(payload.page ?? 0),
            size: parseInt(payload.size ?? payload.maxLength),
            projects: payload.projects
        }
    };
};

const setSelectedFinancialProject = (state, data) => {
    return {
        ...state,
        selected: data
    };
};

const updateFinancialProject = (state, data) => {
    return {
        ...state,
        list: {
            ...state.list,
            projects: data
        }
    }
}

const updateFinancialSubProject = (state, data) => {
    return {
        ...state,
        subProjects: {
            ...state.subProjects,
            subProjects: data
        }
    }
}

const clearSelectedFinancialProject = (state) => {
    return {
        ...state,
        selected: initialState.selected,
        subProjects: {
            ...state.subProjects,
            page: initialState.subProjects.page,
            size: initialState.subProjects.size,
            subProjects: initialState.subProjects.subProjects
        }
    };
};

const setFinancialProjectsPagination = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: payload
        }
    };
};

const clearSubProjectsPagination = (state) => {
    return {
        ...state,
        config: {
            ...state.config,
            subProjectsPagination: getInitialPagination(false)
        }
    };
};



const clearFinancialProjectsPagination = (state) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: getInitialPagination(state.config.gridView)
        },
        list: {
            ...state.list,
            page: initialState.list.page,
            size: initialState.list.size,
            projects: initialState.list.projects
        }
    };
};

const setGridView = (state, gridView) => {
    return {
        ...state,
        config: {
            ...state.config,
            gridView: gridView,
            pagination: getInitialPagination(gridView)
        }
    };
};

const setProjectsFilter = (state, filter) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: (state.list.filter.favorites === filter.favorites) ? state.config.pagination : getInitialPagination(state.config.gridView)
        },
        list: {
            ...state.list,
            filter: filter
        }
    };
};

const clearProjectsFilter = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            filter: initialState.list.filter
        }
    };
};

const setProjectsSort = (state, sort) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: sort
        }
    };
};

const clearProjectsSort = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: initialState.list.sort
        }
    };
};

const setSubProjectsSort = (state, sort) => {
    return {
        ...state,
        subProjects: {
            ...state.subProjects,
            sort: sort
        }
    };
};

const setFinancialSubProjectsPagination = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            subProjectsPagination: payload
        }
    };
};

const clearSubProjectsSort = (state) => {
    return {
        ...state,
        subProjects: {
            ...state.subProjects,
            sort: initialState.subProjects.sort
        }
    };
};



const setFinancialSubProjects = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            subProjectsPagination: {
                ...state.config.subProjectsPagination,
                page: parseInt(payload.page),
                size: parseInt(payload.size),
                maxLength: parseInt(payload.maxLength)
            }
        },
        subProjects: {
            ...state.subProjects,
            page: parseInt(payload.page),
            size: parseInt(payload.size),
            subProjects: payload.subProjects
        }
    };
};
