import Swal from 'sweetalert2';
import {t} from '../i18n';

const infoOptions = {
    icon: 'info',
    position: 'top-right',
    toast: true,
    showConfirmButton: false,
    timer: 5000
};

const successOptions = {
    icon: 'success',
    position: 'top-right',
    toast: true,
    showConfirmButton: false,
    timer: 5000
};

const errorOptions = {
    icon: 'error',
    position: 'top-right',
    toast: true,
    showConfirmButton: false,
    timer: 5000
};

const canNotDeleteErrorOptions = {
    icon: 'error',
    position: 'center',
    toast: false,
    showConfirmButton: false,
    showCancelButton: true,
    cancelButtonText: t('cancel'),
    timer: 5000
};

export const infoNotification = (title, text, options = {}) => {
    Swal.fire(
        {
            title: title,
            html: `<span style="padding-left: 20px">${t(text)}</span>`,
            ...infoOptions,
            ...options
        }
    );
};

export const successNotification = (title, text, options = {}) =>{
    Swal.fire(
        {
            title: title,
            html: `<span style="padding-left: 20px">${t(text)}</span>`,
            ...successOptions,
            ...options
        }
    );
};

export const errorNotification = (title, text, options = {}) =>{
    Swal.fire(
        {
            title: title,
            html: `<span style="padding-left: 20px">${t(text)}</span>`,
            ...errorOptions,
            ...options
        }
    );
};

export const canNotDeleteThisError = (title, text, options = {}) =>{
    Swal.fire(
        {
            title: title,
            html: `<span style="padding-left: 20px">${t(text)}</span>`,
            customClass: {
                icon: 'swal2-icon swal2-error swal2-icon-show',
                popup: 'swal2-popup swal2-modal swal2-show',
                content: 'swal2-content',
                confirmButton: 'swal2-button',
                cancelButton: 'swal2-cancel-button'
            },
            ...canNotDeleteErrorOptions ,
            ...options
        }
    );
};


