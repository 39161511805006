import React from 'react';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Chip,
    Grid,
    IconButton,
    Tooltip,
    Typography
} from '@material-ui/core';
import useStyles from './style';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';


function AccessControlCard({ collaborator, openEdit, handleDelete }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const clientsList = useSelector(({ rt }) => rt.projectClient);
    const projectsList = useSelector(({ rt }) => rt.projects);
    const isFinancialView = useSelector(({ appState }) => appState.isFinancialView);

    const renderClients = () => {
        return (
            collaborator.accessClients.map((c, idx) =>
                <Chip
                    key={idx}
                    label={clientsList.find(i => i.id === c)?.name}
                    size="small"
                    className={classes.chip}
                    style={{marginBottom: "4px"}}
                />
            )
        );
    };

    const renderFnClients = () => {
        return (
            collaborator.accessFnClients.map((c, idx) =>
                <Chip
                    key={idx}
                    label={clientsList.find(i => i.id === c)?.name}
                    size="small"
                    className={classes.chip}
                    style={{marginBottom: "4px"}}
                />
            )
        );
    };

    const renderProjects = () => {
        return (
            collaborator.accessProjects.map((p, idx) =>
                <Chip
                    key={idx}
                    label={projectsList.find(i => i.id === p)?.name}
                    size="small"
                    className={classes.chip}
                    style={{marginBottom: "4px"}}
                />
            )
        );
    };

    const renderFnProjects = () => {
        return (
            collaborator.accessFnProjects.map((p, idx) =>
                <Chip
                    key={idx}
                    label={projectsList.find(i => i.id === p)?.name}
                    size="small"
                    className={classes.chip}
                    style={{marginBottom: "4px"}}
                />
            )
        );
    };

    return (
        <Card
            className={classes.root}
            elevation={1}
        >
            <Grid container alignItems="center" justify="center">
                <Grid item xs={10}>
                    <CardContent className={classes.content} style={{ paddingBottom: "16px" }}>
                        <Grid container>
                            <Grid container item xs={2} alignItems="center" justify="center">
                                <Grid item>
                                    <Typography className={classes.collabName}> {`${collaborator.firstName} ${collaborator.lastName}`} </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={5} alignItems="center" justify="center">
                                <Grid item>
                                    <Box> {isFinancialView ? renderFnClients() : renderClients()} </Box>
                                </Grid>
                            </Grid>
                            <Grid container item xs={5} alignItems="center" justify="center">
                                <Grid item>
                                    <Box> {isFinancialView ? renderFnProjects() : renderProjects()} </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Grid>
                <Grid item xs={2}>
                    <CardActions>
                        <Grid container alignItems="center" justify="center">
                            <Grid item>
                                <Tooltip title={t('edit')} placement="top">
                                    <IconButton color="primary" onClick={() => openEdit(collaborator)} >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={t('delete')} placement="top">
                                    <IconButton color="primary" onClick={() => handleDelete(collaborator)} >
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Grid>
            </Grid>
        </Card>
    );

}

export default AccessControlCard;