import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import {configureStore} from "./redux/store";

import "./index.css";
import "./style/utilities.css";

import {
  initOptions,
} from './keycloak/config';

import 'bootstrap/dist/css/bootstrap.min.css';

const { store, persistor, axios, kc } = configureStore();

const AppContainer = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router>
                <App />
            </Router>
        </PersistGate>
    </Provider>
);

const rootElement = document.getElementById('root');

try {
    kc.init(initOptions).then((authenticated) => {
        if (authenticated) {
            store.getState().keycloak = kc;
            ReactDOM.render(<AppContainer />, rootElement);
        } else {
            kc.login();
        }
    });
} catch (error) {
    ReactDOM.render(<div></div>, rootElement);
}

export {store, axios};
