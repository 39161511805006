import ReactDOM from 'react-dom';
import html2canvas from 'html2canvas';
import Axios from 'axios';

const exportComponentAsPNG = (node) => {
    if(!node.current) {
        throw new Error("'node' must be a RefObject");
    }

    const element = ReactDOM.findDOMNode(node.current);
    return html2canvas(element, { scrollY: -window.scrollY, useCORS: true});
};

const downloadResponsePDF = (response, title) => {
    const base64String = Buffer.from(response, 'binary').toString('base64'); //eslint-disable-line
    const link = document.createElement("a");
    link.download = `${title}.pdf`;
    link.target = '_blank';
    link.href = `data:application/pdf;base64,${base64String}`;
    link.click();
};

const downloadResponseXLSX = (response, title) => {
    const base64String = Buffer.from(response, 'binary').toString('base64'); //eslint-disable-line
    const link = document.createElement("a");
    link.download = `${title}.xlsx`;
    link.target = '_blank';
    link.href = `data:attachement/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
    link.click();
};

const downloadBinaryFile = (path, name, mime) => {
    Axios({
        url: path,
        method: "GET",
        responseType: "blob"
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `${name}.${mime}`;
        link.click();
    });
};

export {
    exportComponentAsPNG,
    downloadResponsePDF,
    downloadResponseXLSX,
    downloadBinaryFile
};

