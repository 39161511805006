import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Radar } from 'react-chartjs-2';
import {Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import {getAllRiskCategories} from "../../../views/Operational/redux/actions/rt";
import useStyles from "./style";

export function RadarChart({ categoryView, risks }) {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const riskCategoriesList = useSelector(({ operational }) => operational.rt.riskCategories);
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
           if(categoryView){
               dispatch(getAllRiskCategories(categoryView)).then((response)=>{
                   if (response) {
                       setLoading(false);
                   }
               });
           }
    }, [risks]);

    useEffect(() => {
        const filteredCategories = riskCategoriesList.filter(cat => cat.riskCount !== 0 );
        setCategories(filteredCategories)
    }, [riskCategoriesList]);

    const options = {
        responsive: true,
        scale: {
            angleLines: {
                display: true,
                fontColor: 'rgba(0, 0, 0, 0.6)',
                lineWidth: 1,
            },
            gridLines: {
                display: true,
                color: 'rgba(0,0,0,0.49)',
            },
            pointLabels: {
                display: true,
                fontSize: 16,
                fontColor: 'rgba(0, 0, 0, 0.6)',
                fontStyle: 'bold',
            },
            ticks: {
                display: true,
                beginAtZero: true,
                fontSize: 15,
                fontColor: 'rgba(0, 0, 0, 0.6)',
                fontStyle: 'bold',
                stepSize: 2,
            }
        },
        legend: {
            display: false,
            position: 'top',
            labels: {
                fontSize: 20,
                fontColor: 'rgba(0,0,0,0.97)',
            },
        },
        tooltips: {
            enabled: true,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            titleFontColor: '#ffffff',
            bodyFontColor: '#ffffff',
            callbacks: {
                title: () => ''
            }
        },
    };

     const data = {
        labels: categories.map(cat => cat.name),
        datasets: [
            {
                label: t('number_of_categories_text'),
                data: categories.map(cat => cat.riskCount),
                backgroundColor: 'rgba(55,43,108,0)',
                borderColor: 'rgb(55,43,108)',
                borderWidth: 5,
            },
        ],
    };

    return (
        <>
            <Grid container item xs={12} className={classes.title}>
                <Typography variant="h5">{t('category_view')}</Typography>
            </Grid>
            <Grid container item xs={12} justify='center'>
                <Grid container item xs={2}></Grid>
                { !loading && <Grid container item xs={8}>
                    <Radar data={data} options={options}/>
                </Grid>}
                <Grid container item xs={2}></Grid>
            </Grid>
        </>
    )
}