/************* AUTH **************/
export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_USER = 'GET_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

/************* RT **************/
export const GET_RT_PROJECT_TYPE = 'GET_RT_PROJECT_TYPE';
export const GET_ALL_RT_PROJECT_TYPE = 'GET_ALL_RT_PROJECT_TYPE';
export const SET_ALL_RT_PROJECT_TYPE = 'SET_ALL_RT_PROJECT_TYPE';

export const GET_RT_PROJECT_STATUS = 'GET_RT_PROJECT_STATUS';
export const GET_ALL_RT_PROJECT_STATUS = 'GET_ALL_RT_PROJECT_STATUS';
export const SET_ALL_RT_PROJECT_STATUS = 'SET_ALL_RT_PROJECT_STATUS';

export const GET_RT_PROJECT_CLIENT = 'GET_RT_PROJECT_CLIENT';
export const GET_ALL_RT_PROJECT_CLIENT = 'GET_ALL_RT_PROJECT_CLIENT';
export const SET_ALL_RT_PROJECT_CLIENT = 'SET_ALL_RT_PROJECT_CLIENT';

export const GET_RT_PROJECT_RISK = 'GET_RT_PROJECT_RISK';
export const GET_ALL_RT_PROJECT_RISK = 'GET_ALL_RT_PROJECT_RISK';
export const POST_RT_PROJECT_RISK = 'POST_RT_PROJECT_RISK';
export const PUT_RT_PROJECT_RISK = 'PUT_RT_PROJECT_RISK';
export const DELETE_RT_PROJECT_RISK = 'DELETE_RT_PROJECT_RISK';
export const SET_ALL_RT_PROJECT_RISK = 'SET_ALL_RT_PROJECT_RISK';
export const EXPORT_ALL_RT_PROJECT_RISK='EXPORT_ALL_RT_PROJECT_RISK'
export const IMPORT_ALL_RT_PROJECT_RISK='IMPORT_ALL_RT_PROJECT_RISK'

export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS';
export const SET_ALL_PROJECTS = 'SET_ALL_PROJECTS';

export const GET_ALL_RT_AGENCIES = 'GET_ALL_RT_AGENCIES'
export const SET_ALL_RT_AGENCIES = 'SET_ALL_RT_AGENCIES'

export const GET_ALL_RT_ENTITY_AGENCIES = 'GET_ALL_RT_ENTITY_AGENCIES'
export const SET_ALL_RT_ENTITY_AGENCIES = 'SET_ALL_RT_ENTITY_AGENCIES'
export const POST_RT_ENTITY_AGENCY = 'POST_RT_ENTITY_AGENCY'
export const PUT_RT_ENTITY_AGENCY = 'PUT_RT_ENTITY_AGENCY'
export const DELETE_RT_ENTITY_AGENCY = 'DELETE_RT_ENTITY_AGENCY'
export const EXPORT_RT_ENTITY_AGENCY = 'EXPORT_RT_ENTITY_AGENCY'
export const IMPORT_RT_ENTITY_AGENCY = 'IMPORT_RT_ENTITY_AGENCY'

export const GET_ALL_RT_JOBS = 'GET_ALL_RT_JOBS'
export const SET_ALL_RT_JOBS = 'SET_ALL_RT_JOBS'

/************* Collaborators *************/
export const GET_ALL_RESPONSABLES = "GET_ALL_RESPONSABLES";
export const SET_ALL_RESPONSABLES = "SET_ALL_RESPONSABLES";
export const GET_CURRENT_COLLABORATOR = "GET_CURRENT_COLLABORATOR";
export const SET_CURRENT_COLLABORATOR = "SET_CURRENT_COLLABORATOR";
export const PUT_COLLABORATOR = "PUT_COLLABORATOR";
export const PUT_COLLABORATOR_ACCESS_CONTROL = "PUT_COLLABORATOR_ACCESS_CONTROL";

/************* favoriteProjects *************/
export const SET_ALL_FAVORITE_PROJECTS = "SET_ALL_FAVORITE_PROJECTS";
export const GET_ALL_FAVORITE_PROJECTS = "GET_ALL_FAVORITE_PROJECTS"
export const POST_FAVORITE_PROJECT = "POST_FAVORITE_PROJECT";
export const UPDATE_FAVORITE_PROJECTS_ORDER = "UPDATE_FAVORITE_PROJECTS_ORDER";
export const DELETE_FAVORITE_PROJECT = "DELETE_FAVORITE_PROJECT";
export const SET_FAVORITE_PROJECTS_SORT = "SET_FAVORITE_PROJECTS_SORT";
export const CLEAR_FAVORITE_PROJECTS_SORT = "CLEAR_FAVORITE_PROJECTS_SORT";
