import React, { useCallback, useEffect, useState } from 'react';
import {
    Grid,
    Button,
    Box,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import useStyles from "./style";
import { getAllResponsables, putCollaboratorAccessControl } from '../../../../redux/actions/collaborators';
import { getAllProjectClient } from '../../../../redux/actions/rt/projectClient';
import { getAllProjects } from '../../../../views/Operational/redux/actions/projects';
import ListColsHeader from '../../../components/ListColsHeader';
import { Skeleton } from '@material-ui/lab';
import AccessControlCard from './AccessControlCard';
import AccessControlDialog from './AccessControlDialog';
import AddIcon from '@material-ui/icons/Add';
import NotifModal from '../../../components/NotifModal';
import NoData from '../../../components/NoData';


const columns = [
    {
        id: 0,
        name: 'collaborator',
        width: 2,
    },
    {
        id: 1,
        name: 'assigned_clients',
        width: 5,
    },
    {
        id: 2,
        name: 'assigned_projects',
        width: 5,
    }
];

function AccessControl() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [collaborator, setCollaborator] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const collaborators = useSelector(({ collaborators }) => collaborators.responsables);
    const isFinancialView = useSelector(({ appState }) => appState.isFinancialView);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            dispatch(getAllResponsables()),
            dispatch(getAllProjectClient()),
            dispatch(getAllProjects())
        ]).then(() => setLoading(false));
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const getData = () => {
        const data = isFinancialView ? collaborators.filter(c => c.accessFnClients.length > 0 || c.accessFnProjects.length > 0) : collaborators.filter(c => c.accessClients.length > 0 || c.accessProjects.length > 0);
        return data
    };

    const openEdit = useCallback((collaborator) => {
        setDialogOpen(true);
        setCollaborator(collaborator);
    }, []);

    const handleDelete = (collab) => {
        setDeleteModal({
            open: true,
            type: "error",
            title: t('suppression'),
            body: <span>{t('confirm_delete_access_control', { collaboratorName: `${collab.firstName} ${collab.lastName}` })}</span>,
            showCloseButton: true,
            onChange: () => setDeleteModal(false),
            cancel: {
                title: t('cancel'),
                onClick: null
            },
            confirm: {
                title: t('confirm'),
                onClick: () => dispatch(putCollaboratorAccessControl(isFinancialView ? { ...collab, accessFnClients: [], accessFnProjects: [] } : { ...collab, accessClients: [], accessProjects: [] }))
                    .then(() => {
                        dispatch(getAllResponsables()).then(() => setDeleteModal({ ...deleteModal, open: false }));
                    })
            }
        });
    };

    return (
        <div className={classes.container}>
            <Grid container>
                <Grid item xs={10}>
                    <ListColsHeader
                        type="columns"
                        className={classes.listHeader}
                        columns={columns}
                    />
                </Grid>
                <Grid item xs={2} className={classes.addButtonBox}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.addButton}
                        startIcon={<AddIcon />}
                        onClick={() => setDialogOpen(true)}
                    >
                        {t('add')}
                    </Button>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <NoData condition={getData().length === 0} loading={loading} />
                    {
                        loading
                            ? [...Array(5)].map((_, index) => (
                                <Box mb={2} key={index}>
                                    <Skeleton height={62.5} variant="rect" animation="wave" className={classes.skeleton} />
                                </Box>
                            ))
                            : getData().map(collab =>
                                <AccessControlCard
                                    key={collab.id}
                                    collaborator={collab}
                                    openEdit={openEdit}
                                    handleDelete={handleDelete}
                                />
                            )
                    }
                </Grid>
            </Grid>

            <AccessControlDialog
                open={dialogOpen}
                setOpen={setDialogOpen}
                collaborator={collaborator}
                setCollaborator={setCollaborator}
            />

            <NotifModal {...deleteModal} />
        </div>
    );

}

export default AccessControl;