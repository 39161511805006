import React, { useEffect } from "react";
import useStyles from "./style.js";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { clearIsError500 } from "../../appSlice.js";

function Error500()
{
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(()=>{
        return ()=>{
            dispatch((clearIsError500()))
        }
    },[])

    return (
        <Box className={classes.root}>
            <Box className={classes.container}>
                <Box className= {classes.content}>
                    <Typography variant="h6" color="primary" className={classes.alertTitle} align="center">
                        {t('error_alert_title')}
                    </Typography>
                    
                    <Typography variant="body1" color="primary" align="center">
                        {t('error_alert_text')}
                    </Typography>
                </Box>
            </Box>
        </Box> 
    )

}

export default Error500;