import Auth from "./auth";
import appSlice from "../../App/appSlice";
import rtReducer from "./rt";
import collaboratorsReducer from './collaborators';
import favoritesReducer from './favorites';

import rootOperationalReducer from "../../views/Operational/redux/reducers/rootReducer";
import rootFinancialReducer from "../../views/Financial/redux/reducers/rootReducer";

export default {
    Auth: Auth,
    appState: appSlice,
    keycloak: (keycloak = {}) => keycloak,
    operational: rootOperationalReducer,
    financial: rootFinancialReducer,
    collaborators: collaboratorsReducer,
    rt: rtReducer,
    favorites: favoritesReducer
};
