import React, { Fragment, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import { Link, useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ChevronRight from "@material-ui/icons/ChevronRightOutlined";
import ExpandMore from "@material-ui/icons/ExpandMoreOutlined";

import clsx from "clsx";
import { useSelector } from "react-redux";
import logo from "../../logo.png";

import useStyles from "./style.js";
import { useTranslation } from "react-i18next";
import { hasPermission, hasRoles } from "../../../utils/authorization";
import { Tooltip } from "@material-ui/core";

export default function ({ menu }) {
    const user = useSelector(({ Auth }) => Auth.user);
    const resourceRoles = useSelector(({ keycloak }) => keycloak.resourceAccess.novyclient?.roles);
    const menuExpanded = useSelector(({ appState }) => appState.menuExpanded);
    const headerExpanded = useSelector(({ appState }) => appState.headerExpanded);
    const isFinancialView = useSelector(({ appState }) => appState.isFinancialView);
    const { t } = useTranslation();
    const classes = useStyles(menuExpanded, headerExpanded);

    const [openSubMenu, setOpenSubMenu] = useState(1);
    const { pathname } = useLocation();

    const onListClick = (items, index) => {
        if (items) {
            setOpenSubMenu(openSubMenu === index ? -1 : index);
        }
    };

    const authorizedMenu = () => {
        return (isFinancialView ? menu.financial : menu.operational)
            .filter(m => hasPermission(user.permissions, m.permission) && hasRoles(resourceRoles, m.roles));
    };

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            anchor="left"
            classes={{
                paper: clsx(
                    classes.menuLeft,
                    !menuExpanded && classes.menuLeftClose,
                    headerExpanded && classes.menuLeftExpanded
                ),
            }}
            open={menuExpanded}
        >
            <div className={classes.container}>
                <div className={classes.logo}>
                    {!headerExpanded && <img src={logo} alt="Logo" />}
                </div>
                <List>
                    {authorizedMenu().map(({ name, icon, path = "", pathFormat, items }, index) => (
                        <Fragment key={index}>
                            <ListItem
                                button
                                component={Link}
                                to={path}
                                onClick={() => onListClick(items, index)}
                                className={clsx(classes.menuLeftItem, matchPath(pathname, {path: pathFormat || path, exact: true, strict: false})?.isExact && classes.selected)}
                            >
                                <Tooltip title={t(name)}>
                                    <ListItemIcon className={classes.icon}>        
                                            {icon}
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={t(name)} />
                                {items &&
                                (openSubMenu !== index ? <ChevronRight /> : <ExpandMore />)}
                            </ListItem>
                            {items && (
                                <Collapse in={openSubMenu === index} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding className={classes.nested}>
                                        {items.map(({ name, icon, path }) => (
                                            <ListItem
                                                key={path}
                                                button
                                                component={Link}
                                                to={path}
                                                className={clsx(path === pathname && classes.selected)}
                                            >
                                                <ListItemIcon className={classes.icon}>
                                                    {icon}
                                                </ListItemIcon>
                                                <ListItemText primary={t(name)} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            )}
                        </Fragment>
                    ))}
                </List>
            </div>
        </Drawer>
    );
}
