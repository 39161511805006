import React from "react";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import EuroIcon from '@material-ui/icons/Euro';
import Operational from '../../views/Operational';
import Financial from '../../views/Financial';

export default [
    {
        name: "operational_view",
        path: "/operational",
        component: () => <Operational />,
        icon: <PeopleAltIcon />,
        financial: false,
        permissions: "pilotage.managePilotage",
        roles: []
    },
    {
        name: "financial_view",
        path: "/financial",
        component: () => <Financial />,
        icon: <EuroIcon />,
        financial: true,
        permissions: "pilotage.managePilotage",
        roles: []
    }
];
