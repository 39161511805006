import React from 'react';
import { ADMIN_ROLE } from '../../utils/constants';
import { BREADCRUMBS } from '../../utils/format';

const Settings = React.lazy(() => import('../pages/Settings'));

export default [
    { 
        path: '/administration',
        exact: true, 
        name: 'administration', 
        component: Settings, 
        permission: 'pilotage.managePilotage',
        roles: [ADMIN_ROLE],
        private: true, 
        className:'breadcrumb-settings',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: false
        }
    }
];
