import React, { useEffect, useState } from 'react';
import CustomDialog from '../CustomDialog';
import useStyles from './style';
import TuneIcon from '@material-ui/icons/Tune';
import {
    Grid,
    Typography,
    Button,
    Box,
    Switch
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { frWeekDays } from '../../../utils/constants';
import { useSelector, useDispatch } from 'react-redux';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import NotificationsOffOutlinedIcon from '@material-ui/icons/NotificationsOffOutlined';
import { getCurrentCollaborator, putCollaborator } from '../../../redux/actions/collaborators';


function MySettings({ open, setOpen }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const currentCollaborator = useSelector(({ collaborators }) => collaborators.currentCollaborator);
    const [data, setData] = useState(null);
    const [saving, setSaving] = useState(false);

    const renderTitle = () => {
        return (
            <>
                <TuneIcon style={{ marginRight: "8px" }} />
                <Typography variant="h6" display="inline">
                    {t('my_settings')}
                </Typography>
            </>
        );
    };

    useEffect(() => {
        if (currentCollaborator) {
            setData(currentCollaborator);
        }
    }, [currentCollaborator]); //eslint-disable-line react-hooks/exhaustive-deps

    const isDayIncluded = (i) => {
        return data?.actionReminderDays.indexOf(i) >= 0;
    };

    const toggleDay = (i) => {
        isDayIncluded(i)
            ? setData({ ...data, actionReminderDays: data.actionReminderDays.replace(i, '') })
            : setData({ ...data, actionReminderDays: `${data.actionReminderDays}${i}` });
    };

    const save = () => {
        setSaving(true);
        dispatch(putCollaborator(data)).then(() => {
            dispatch(getCurrentCollaborator(data.email));
            setSaving(false);
        });
    };

    const disableSubmit = () => {
        return data && data.actionReminder === currentCollaborator.actionReminder
            && data.actionReminderDays.split('').every(i => currentCollaborator.actionReminderDays.includes(i))
            && data.actionReminderDays.length === currentCollaborator.actionReminderDays.length;
    };

    const renderContent = () => {
        return (
            <Box py={1}>
                <Grid container>
                    <Grid item xs={12}>
                        <Switch
                            checked={data?.actionReminder}
                            onChange={e => setData({...data, actionReminder: e.target.checked})}
                            color="primary"
                        />
                        <Typography display="inline">{t('receive_action_reminders')}</Typography>
                    </Grid>
                </Grid>
                <Grid container direction="column" style={{ marginTop: "16px" }}>
                    <Grid item xs={12}>
                        <Typography>{t('action_reminder_days')}:</Typography>
                    </ Grid>
                    <Grid container item xs={12} alignItems="center" direction="column" style={{ marginTop: "8px" }}>
                        <Grid item>
                            {
                                frWeekDays.map((day, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            variant="outlined"
                                            size="small"
                                            onClick={() => toggleDay(index + 1)}
                                            className={classes.dayButton}
                                            endIcon={
                                                isDayIncluded(index + 1)
                                                    ? <NotificationsActiveOutlinedIcon fontSize="small" color="primary" />
                                                    : <NotificationsOffOutlinedIcon fontSize="small" color="error" />
                                            }
                                        >
                                            <Typography variant="subtitle2"> {day} </Typography>
                                        </Button>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    const renderActions = () => {
        return (
            <>
                <Button
                    variant="contained"
                    disableElevation
                    onClick={() => setOpen(false)}
                >{t('cancel')}</Button>
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={save}
                    disabled={disableSubmit()}
                >{t('save')}</Button>
            </>
        );
    };

    return (
        <CustomDialog
            open={open}
            onClose={() => setOpen(false)}
            title={renderTitle()}
            renderContent={renderContent}
            renderActions={renderActions}
            classes={{
                paper: classes.dialogPaper,
                scrollPaper: classes.topScrollPaper,
                paperScrollBody: classes.topPaperScrollBody
            }}
            saving={saving}
            disableBackdropClick={true}
        />
    );

}

export default MySettings;