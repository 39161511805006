import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setHeaderExpanded } from "../../appSlice";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowDropDown from "@material-ui/icons/ArrowDropDownOutlined";
import Popover from "@material-ui/core/Popover";
import ListItemText from "@material-ui/core/ListItemText";
import CardActionArea from "@material-ui/core/CardActionArea";
import AppBar from "@material-ui/core/AppBar";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import clsx from "clsx";
import HeaderToolbar from "./HeaderToolbar";
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TuneIcon from '@material-ui/icons/Tune';
import logo from "../../logo.png";
import CardHeader from "./CardHeader";
import { logout } from "../../../redux/actions/auth";
import MySettings from "../MySettings";
import { ADMIN_ROLE } from "../../../utils/constants";
import { hasRoles } from "../../../utils/authorization";
import useStyles from "./style";
import { roundCharges } from "../../../utils/format";


export default function () {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const keycloak = useSelector(({ keycloak }) => keycloak);
    const resourceRoles = useSelector(({ keycloak }) => keycloak.resourceAccess.novyclient?.roles);
    const headerExpanded = useSelector(({ appState }) => appState.headerExpanded);
    const isFinancialView = useSelector(({ appState }) => appState.isFinancialView);
    const globalOperationalIndicators = useSelector(({ operational }) => operational.indicators.global);
    const globalFinancialIndicators = useSelector(({ financial }) => financial.indicators.global);
    const { firstName, lastName } = useSelector(({ Auth }) => Auth.user);
    const avatarColor = useSelector(({ appState }) => appState.avatarColor);
    const [anchorProfileEl, setAnchorProfileEl] = React.useState(null);
    const [openMySettings, setOpenMySettings] = React.useState(false);

    const operationalCards = () => {
        if (hasRoles(resourceRoles, [ADMIN_ROLE])) {
            return (
                <Grid item className="d-flex">
                    <CardHeader title={`${roundCharges(globalOperationalIndicators.raf)} J/H`} content={t("raf_full")} />
                    <CardHeader title={`${Number((globalOperationalIndicators.derive * 100).toFixed(2))} %`} content={t("derive")} />
                    <CardHeader title={`${roundCharges(globalOperationalIndicators.risqueMoyen)}`} content={t("risque_moyen")} />
                </Grid>
            );
        }
        return (
            <Grid item className="d-flex">
                <CardHeader title="---" content="" />
                <CardHeader title="---" content="" />
                <CardHeader title="---" content="" />
            </Grid>
        );
    };

    const financialCards = () => {
        return (
            <Grid item className="d-flex">
                <CardHeader title="---" content="" />
                <CardHeader title="---" content=""/>
                <CardHeader title="---" content="" />
            </Grid>
        );
    };

    const profile = () => {
        return (
            <Grid item className="text-center p-2 pb-3">
                <IconButton onClick={(e) => setAnchorProfileEl(e.currentTarget)}>
                    <Badge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        badgeContent={<ArrowDropDown color="primary" />}
                    >
                        <Avatar style={{ backgroundColor: avatarColor }}>{`${firstName.charAt(0)}${lastName.charAt(0)}`}</Avatar>
                    </Badge>
                </IconButton>
                <Typography
                    variant="body2"
                    align="center"
                    className={classes.title}
                >
                    {`${firstName} ${lastName}`}
                </Typography>
            </Grid>
        );
    };

    useEffect(() => {
        const onScroll = () => {
            let st = window.pageYOffset || document.documentElement.scrollTop;
            if (headerExpanded && (st > 120)) {
                dispatch(setHeaderExpanded(false));
            } else if (!headerExpanded && (st <= 120)) {
                dispatch(setHeaderExpanded(true));
            }
        };

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };

    }, [headerExpanded]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <AppBar
                position="relative"
                color="transparent"
                className={clsx(classes.root, !headerExpanded && classes.rootSticky)}
                elevation={24}
            >
                <Grid container className={classes.gridContainer} alignItems="center" justify="space-between">
                    <Grid item>
                        <img src={logo} alt="Logo" className={classes.logo} />
                    </Grid>
                    <Grid item className="d-flex">
                        {
                            isFinancialView
                                ? financialCards()
                                : operationalCards()
                        }
                    </Grid>
                    {
                        profile()
                    }
                    <Popover
                        id="profile-popover"
                        open={Boolean(anchorProfileEl)}
                        anchorEl={anchorProfileEl}
                        onClose={() => setAnchorProfileEl(null)}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        PaperProps={{
                            style: {
                                padding: "6px 0"
                            }
                        }}
                    >
                        <CardActionArea onClick={() => {
                            setAnchorProfileEl(null);
                            window.open(`${process.env.REACT_APP_NOVY_COLLAB_WEBAPP_URL}/collaborators/CollaboratorDetails`, '_blank')
                        }}
                        >
                            <ListItemText
                                primary={<Typography variant="body2"><PersonIcon fontSize="small" /> {t("my_novy_account")}</Typography>}
                                style={{
                                    margin: "5px 10px",
                                    minWidth: "150px",
                                    textAlign: "left",
                                }}
                            />
                        </CardActionArea>
                        <CardActionArea onClick={() => {
                            setOpenMySettings(true);
                            setAnchorProfileEl(null);
                        }}
                        >
                            <ListItemText
                                primary={
                                    <Typography variant="body2">
                                        <TuneIcon fontSize="small" />{" "}
                                        {t("my_settings")}
                                    </Typography>
                                }
                                style={{
                                    margin: "5px 10px",
                                    minWidth: "150px",
                                    textAlign: "left",
                                }}
                            />
                        </CardActionArea>
                        <CardActionArea onClick={() => dispatch(logout(keycloak))}>
                            <ListItemText
                                primary={<Typography variant="body2"><ExitToAppIcon fontSize="small" /> {t("logout")}</Typography>}
                                style={{
                                    margin: "5px 10px",
                                    minWidth: "150px",
                                    textAlign: "left",
                                }}
                            />
                        </CardActionArea>
                    </Popover>
                </Grid>
                <HeaderToolbar setOpenMySettings={setOpenMySettings} />
            </AppBar>

            <MySettings
                open={openMySettings}
                setOpen={setOpenMySettings}
            />
        </>
    );
}
