import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(3),
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    title: {
        fontSize: "1.8rem",
        color: theme.palette.primary.main,
        fontWeight: "500",
        marginBottom: theme.spacing(2)
    }
}));