import { getInitialPagination } from "../../../../../utils/common-state";
import {
    SET_FINANCIAL_COLLABORATOR_MONTHLY_COST ,
    SET_FINANCIAL_COLLABORATOR_MONTHLY_COST_PAGINATION ,
    SET_FINANCIAL_COLLABORATOR_MONTHLY_COST_SORT ,
    SET_FINANCIAL_COLLABORATOR_MONTHLY_COST_FILTER,
    CLEAR_FINANCIAL_COLLABORATOR_MONTHLY_COST ,
    CLEAR_FINANCIAL_COLLABORATOR_MONTHLY_COST_PAGINATION ,
    CLEAR_FINANCIAL_COLLABORATOR_MONTHLY_COST_SORT,
    CLEAR_FINANCIAL_COLLABORATOR_MONTHLY_COST_FILTER

} from "../../constants";


const initialState = {
    config: {
        pagination: getInitialPagination(false)
    },
    list: {
        sort: {
            col: { id: 0 },
            type: "asc"
        },
        filter: {
            search: "",
            collaborators: [],
            costProfiles: [],
            agencies: [],
            jobs: [],
            active: true,
            withoutProfile: false,
            periode: ""
        },
        page: 0,
        size: getInitialPagination(false).size,
        collaboratorMonthlyCosts: []
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FINANCIAL_COLLABORATOR_MONTHLY_COST:
            return setFinancialCollaboratorMonthlyCost(state, action.payload);
        case SET_FINANCIAL_COLLABORATOR_MONTHLY_COST_SORT:
            return setFinancialCollaboratorMonthlyCostSort(state, action.payload);
        case SET_FINANCIAL_COLLABORATOR_MONTHLY_COST_PAGINATION:
            return setFinancialCollaboratorMonthlyCostPagination(state, action.payload);
        case CLEAR_FINANCIAL_COLLABORATOR_MONTHLY_COST:
            return clearFinancialCollaboratorMonthlyCost(state);
        case CLEAR_FINANCIAL_COLLABORATOR_MONTHLY_COST_SORT:
            return clearFinancialCollaboratorMonthlyCostSort(state);
        case CLEAR_FINANCIAL_COLLABORATOR_MONTHLY_COST_PAGINATION:
            return clearFinancialCollaboratorMonthlyCostPagination(state);
        case SET_FINANCIAL_COLLABORATOR_MONTHLY_COST_FILTER:
            return setFinancialCollaboratorMonthlyCostFilter(state, action.payload);
        case CLEAR_FINANCIAL_COLLABORATOR_MONTHLY_COST_FILTER:
            return clearFinancialCollaboratorMonthlyCostFilter(state);
        default: return state;
    }
};

const setFinancialCollaboratorMonthlyCost = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: {
                ...state.config.pagination,
                page: parseInt(payload.page),
                size: parseInt(payload.size),
                maxLength: parseInt(payload.maxLength)
            }
        },
        list: {
            ...state.list,
            page: parseInt(payload.page),
            size: parseInt(payload.size),
            collaboratorMonthlyCosts: payload.monthlyCosts
        }
    };
};

const setFinancialCollaboratorMonthlyCostPagination = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: payload
        }
    };
};

const clearFinancialCollaboratorMonthlyCost = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            page: initialState.list.page,
            size: initialState.list.size,
            collaboratorMonthlyCost: initialState.list.collaboratorMonthlyCost
        }
    };
};

const clearFinancialCollaboratorMonthlyCostPagination = (state) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: getInitialPagination(false)
        },
        list: {
            ...state.list,
            page: initialState.list.page,
            size: initialState.list.size,
            collaboratorMonthlyCosts: initialState.list.collaboratorMonthlyCosts
        }
    };
};

const setFinancialCollaboratorMonthlyCostSort = (state, sort) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: sort
        }
    };
};

const clearFinancialCollaboratorMonthlyCostSort = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: initialState.list.sort
        }
    };
};

const setFinancialCollaboratorMonthlyCostFilter = (state, filter) => {
    return {
        ...state,
        list: {
            ...state.list,
            filter: filter
        }
    };
};

const clearFinancialCollaboratorMonthlyCostFilter = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            filter: initialState.list.filter
        }
    };
};
