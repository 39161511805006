import { Popover } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import { setThemeColor } from '../../appSlice';
import useStyles from './style';

export default function ThemeColorPicker() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorThemeColor, setAnchorThemeColor] = useState(null);
    const themeColor = useSelector(({appState}) => appState.themeColor);
    const isFinancialView = useSelector(({appState}) => appState.isFinancialView);
    const [color, setColor] = useState("#fff");
    const changeColor = (color) => {
        setColor(color.hex);
    };

    const changeThemeColor = (color) => {
        let newTheme = {};
        if(isFinancialView){
            newTheme = {
                ...themeColor,
                financial: {
                    ...color
                }
            };
        }else{
            newTheme = {
                ...themeColor,
                operational: {
                    ...color
                }
            };
        }
        
        dispatch(setThemeColor(newTheme));
    };

    useEffect(() => {
        setColor(isFinancialView ? themeColor.financial.hex : themeColor.operational.hex);
    }, [themeColor, isFinancialView]);

    return (
        <>
            <div className={classes.colorPicker} onClick={(e) => setAnchorThemeColor(e.currentTarget)}>
                <img src="https://cdn2.iconfinder.com/data/icons/stationary-lineal-color-set/512/Color_Palette-512.png" width="80%" alt="palette" />
            </div>

            <Popover
                id="theme-color-popover"
                open={Boolean(anchorThemeColor)}
                anchorEl={anchorThemeColor}
                onClose={() => setAnchorThemeColor(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >
                <SketchPicker 
                    color={color}
                    onChangeComplete={changeThemeColor}
                    onChange={changeColor}
                /> 
            </Popover>
        </>
    );
}
