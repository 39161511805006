import {
    GET_ALL_OPERATIONAL_RT_RELATION_CLIENT,
    SET_ALL_OPERATIONAL_RT_RELATION_CLIENT,
    POST_OPERATIONAL_RT_RELATION_CLIENT,
    PUT_OPERATIONAL_RT_RELATION_CLIENT,
    DELETE_OPERATIONAL_RT_RELATION_CLIENT,
    EXPORT_ALL_OPERATIONAL_RT_RELATION_CLIENT,
    IMPORT_ALL_OPERATIONAL_RT_RELATION_CLIENT
} from '../../constants';
import {errorNotification, successNotification} from "../../../../../utils/notification";
import { OPERATIONAL_RT_ENDPOINT } from '../../constants/endpoint';
import {popUpError} from "./popUpError";

export const getAllOperationalRelationClient = () => {
    return {
        type: GET_ALL_OPERATIONAL_RT_RELATION_CLIENT,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/relationClient`
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllOperationalRelationClient(response.data));
                return response;
            }
        }
    };
};

export const setAllOperationalRelationClient = (data) => {
    return {
        type: SET_ALL_OPERATIONAL_RT_RELATION_CLIENT,
        payload: data
    };
};

export const postOperationalRelationClient = (relationClient) => {
    return {
        type: POST_OPERATIONAL_RT_RELATION_CLIENT,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/relationClient`,
            method: 'post',
            data: relationClient
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "create_success");
                store.dispatch(getAllOperationalRelationClient());
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "create_error");
                throw error;
            }
        }
    };
};

export const putOperationalRelationClient = (id, relationClient) => {
    return {
        type: PUT_OPERATIONAL_RT_RELATION_CLIENT,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/relationClient/${id}`,
            method: 'put',
            data: relationClient
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "update_success");
                store.dispatch(getAllOperationalRelationClient());
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "update_error");
                throw error;
            }
        }
    };
};

export const deleteOperationalRelationClient = (id) => {
    return {
        type: DELETE_OPERATIONAL_RT_RELATION_CLIENT,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/relationClient/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "delete_success");
                store.dispatch(getAllOperationalRelationClient());
                return response;
            },
            onError: (error, requestAction, store) => {
                popUpError(error,"delete_client_relation_error");
            }
        }
    };
};

export const exportRelationClient = (type) => {
    return {
        type: EXPORT_ALL_OPERATIONAL_RT_RELATION_CLIENT,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/relationClient/export`,
            responseType: 'arraybuffer'
        },
        meta: {
            onRequest: (request) => {
                if(type)
                    request.url=request.url+"?type="+type
                return request;
            },
            onSuccess: (response) => {
                return response.data;
            }
        }
    };
};

export const importRelationClient= (data) => {
    return {
        type: IMPORT_ALL_OPERATIONAL_RT_RELATION_CLIENT,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/relationClient/import`,
            method:"post",
            data:data
        },
        meta: {
            onSuccess: (response,requestAction,store) => {
                store.dispatch(getAllOperationalRelationClient());
                return response.data;
            }
        }
    };
};