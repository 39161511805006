import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '165px',
        minHeight: '70px',
        padding: theme.spacing(1, 1),
        margin: theme.spacing(1),
        backgroundColor: "#FBFBFB",
        borderRadius: '3px',
        boxShadow: '0px 1px 1px #CCCCCC'
    },
    fullWidth: {
        width: '100%'
    }
}));
