import { makeStyles } from "@material-ui/core/styles";
import bg from '../background.jpg';

export default makeStyles((theme) => ({
    root: {
        height: "100vh",
        backgroundImage: `url(${bg})`,
        backgroundSize: "contain",
        backgroundRepeat: "repeat"
    },
    wrapper: {
        display: "flex",
        backgroundColor: theme.palette.background.darkTransparent,
        minHeight: `calc(100% - ${theme.custom.variables.headerHeight}px)`
    },
    content: {
        flexGrow: 1,
        paddingLeft: theme.spacing(9),
        transition: theme.transitions.create("padding", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        minWidth: 0
    },
    smallContent: {
        paddingLeft: theme.custom.variables.menuLeftWidth,
    },
    headerExpanded: {
        marginTop: theme.custom.variables.headerHeight,
    },
    appBarSpacer: {
        paddingBottom: theme.spacing(2),
    },
    container: {
        paddingLeft: theme.spacing(5),
        paddingTop: "10px",
        backgroundColor: theme.palette.background.default,
        minHeight: "100%"
    }
}));
