import React from "react";
import Task from "../Task";

function TaskList({ tasks, columnTitle,deleteModal,setDeleteModal,handleDeleteModalChange,risk,putOperationalProject }) {

    return (
        <>
            {tasks?.map((task, index) => (
                <Task
                    key={task?.id}
                    task={task}
                    columnTitle={columnTitle}
                    index={index}
                    deleteModal={deleteModal}
                    setDeleteModal={setDeleteModal}
                    handleDeleteModalChange={handleDeleteModalChange}
                    putOperationalProject={putOperationalProject}
                    risk={risk}
                />
            ))}
        </>
    )
}

export default TaskList