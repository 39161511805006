export default {
    charge_consommee: "Charge consommée (j/h)",
    charge_consommee_info_bulle: "La somme de toutes les imputations sur les tâches terminées ou en cours de réalisation du projet, à la date du jour (elle est calculée)",
    raf_full: "Reste à faire (j/h)",
    raf_full_info_bulle: "Ou RAF, c'est la charge qui reste à produire pour terminer le projet, à la date du jour. Elle est la somme du Reste à faire de toutes les tâches en cours de réalisation ou non encore commencées, à la date du jour (elle est calculée)",
    risque_moyen: "Moyen de risque",
    cava_mensuel: "CAVA mensuel",
    mbth_mensuel: "MBth mensuel",
    mbth_cumule: "MBth cumulé",
    responsable_col: "Responsable",
    priorite_col: "Priorité",
    status_col: "Statut",
    categorie_col: "Catégorie",
    probability_col: "Probabilité",
    action_type :"Type d'action",
    action_faible: "Faible",
    action_moyenne: "Moyenne",
    action_haute: "Haute",
    action_ouverte: "Ouverte",
    action_en_cours: "En cours",
    action_traitee: "Traitée",
    delete_action_modal: "Vous êtes sur le point de supprimer l'action ",
    delete_risk_action_modal: "Vous êtes sur le point de supprimer une action de risque ",
    delete_risk_that_have_actions_modal: " qui est associé à des actions",
    continue_question: " voulez-vous continuer ?",
    add_action: "Ajouter une Action",
    actions_col: "Action",
    description_col: "Description",
    echeances_col: "Echéance",
    delete_actions_selection_modal: "Cette action entraîne la suppression multiple de ",
    actions_title: "Actions",
    plan_de_charge: "Plan de charge",
    his_date_validation_col: "Date de validation",
    his_periode_col: "Période",
    his_charge_vendue_col: "Charge vendue",
    his_charge_consommee_col: "Charge consommée",
    his_raf_col: "RAF",
    his_risque_col: "Risque",
    his_actions_col: "Actions",
    evolution_commun: "Evolution commun",
    periode: "Période",
    periode_info_bulle: "L'intervalle de temps entre la dernière date à laquelle les indicateurs du projet ont été figés et la date du jour",
    charge_vendue: "Charge vendue (j/h)",
    charge_vendue_info_bulle: "La charge de réalisation sur projet issue de la proposition commerciale initiale NOVELIS (elle est saisie à la création du projet)",
    charge_initiale_info_bulle: "C'est la somme des charges vendues des tâches unitaires, elle ne peut pas dépasser la charge vendue globale",
    charge_actualisee: "Charge actualisée (j/h)",
    charge_actualisee_info_bulle: "La charge somme de la Charge Consommée et du Reste à faire (elle est calculée)",
    charge_produite_info_bulle: "Ou charge acquise, elle est là charge produite réelle sur le projet et elle sert à calculer le pourcentage d'avancement du projet, i.e. elle est une fraction de la Charge Vendue et ne peut jamais la dépasser (elle est calculée)",
    etat_avancement: "Etat d'avancement",
    etat_avancement_info_bulle: "Représente le pourcentage d'avancement sur projet qui est la division de la charge produite par la charge ventilée (il est calculé)",
    derive: "Dérive",
    derive_info_bulle: "Représente la surconsommation de charge du projet. Elle se calcule en faisant le ratio entre la Charge actualisée et la charge ventilée (elle est calculée)",
    construction: "Construction",
    construction_info_bulle: "Permet de suivre l'état d'avancement de la construction du projet afin de mieux comprendre les progrès réalisés et d'identifier les problèmes nécessitant une attention particulière.",
    perimetre: "Périmètre",
    perimetre_info_bulle: "Le niveau de définition et de stabilité des besoins du projet.",
    planning: "Planning",
    planning_info_bulle: "L'état d'avancement du projet par rapport au calendrier initial.",
    staffing: "Staffing",
    staffing_info_bulle: "Le niveau auquel le projet possédant les meilleurs profils capables de mener à bien les missions dont ils ont la charge.",
    global_risk: "Risque global",
    global_risk_info_bulle: "Le niveau de risque potentiel pouvant nuire à la réussite du projet, il est calculé en fonction du poids des différents risques identifiés.",
    statut: "Statut",
    statut_info_bulle: "Représente les niveaux de la maitrise de la solution technique ainsi que la relation client.",
    relation_client: "Relation client",
    maitrise_solution: "Maîtrise de la solution",
    avancement_col: "Avancement",
    derive_col: "Dérive",
    no_collaborator_found: "Aucun collaborateur trouvé",
    sub_projects: "Sous projets",
    op_evolution_chart_title: "Évolution Charge consommée / RAF/ Charge produite",
    op_evolution_chart_infos: "Vous pouvez cliquer sur une légende pour afficher ou masquer sa courbe correspondante",
    chartTitle_chargeConsommee: "Charge consommée",
    chartTitle_raf: "Reste à faire",
    chartTitle_chargeProduite: "Charge produite",
    not_specified: "Non spécifié",
    echeance_before: "Echéance avant",
    collab_archived: "Archivé.e",
    all_projects: "Tous les projets",
    action_reminders_enabled: "Rappels d'échéance activés",
    action_reminders_disabled: "Rappels d'échéance désactivés",
    enable: "Activer",
    disable: "Désactiver",
    actions_reminders: "Rappels des actions",
    send_reminders_at: "Envoyer les rappels à",
    reminder_time_config_info: "La modification ne sera prise en compte qu'après le prochain envoi",
    action_reminder_config_text_1: "Envoyer les rappels des actions à partir de",
    action_reminder_config_text_2: "jours avant la date d'échéance",
    no_priority_found: "Aucune priorité trouvée",
    no_status_found: "Aucun statut trouvé",
    no_category_found: "Aucune catégorie trouvée",
    no_probability_found: "Aucune probabilité trouvée",
    add_notes: "Ajouter des notes",
    no_notes: "Pas de notes.",
    open_in_window_to_edit: "Ouvrir en fenêtre pour éditer.",
    risks_weights: "Poids des risques",
    default_risks_weights: "Poids des risques par défaut",
    or_user_an_existing_configuration: "Ou utiliser une configuration existante",
    used_by_n_projects: "Utilisé par {{n}} projets",
    default_risk_config_info: "Celle-ci est la configuration par défaut. Elle peut être modifiée par les administrateurs.",
    snapshots: "Snapshots",
    manual_snapshots_title: "Snapshots manuels des projets",
    taken_by: "Prise par",
    risks : "Risques",
    add_risk: "Ajouter un Risque",
    risks_col: "Risque",
    delete_risk_modal: "Vous êtes sur le point de supprimer le risque ",
    description:"Description",
    risk:"Risque :",
    project:"Projet :",
    operational_monitoring_title:"Suivi opérationnel",
    operational_monitoring_snapshot_title:"Historique du suivi opérationnel",
    financial_monitoring_snapshot_title:"Historique du suivi financier",
    created_at:'Date de création',
    charge_produite: "Charge produite (j/h)",
    charge_initiale: "Charge ventilée",
    action: "Action",
    risque: "Risque",
    reset_synchro: "Réinitialiser la synchronisation",
    success_initialization_request : "Réinitialisation de synchronisation en cours ...",
    error_initialization_request : "Réinitialisation de synchronisation a échoué.",
    last_sync_date : "Date du dernière synchronisation",
    sync_info_message: "Vous devez attendre au moins 8 heures à partir du début de la synchronisation jusqu'à ce qu'elle soit terminée."
};