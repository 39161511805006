import { CLEAR_ALL_OPERATIONAL_PROJECT_COLLABORATORS_SNAPSHOTS, CLEAR_OPERATIONAL_MONITORING_FILTER, CLEAR_OPERATIONAL_PROJECT_COLLABORATORS_SNAPSHOTS, SET_OPERATIONAL_PROJECT_COLLABORATORS, SET_OPERATIONAL_PROJECT_COLLABORATORS_FILTER, SET_OPERATIONAL_PROJECT_COLLABORATORS_SNAPSHOTS, SET_OPERATIONAL_PROJECT_COLLABORATORS_SORT, SET_OPERTIONAL_PROJECT_COLLABORATORS_PAGINATION } from "../../constants";
import { getInitialPagination } from "../../../../../utils/common-state";

const initialState = {
    config: {
        pagination: getInitialPagination(false)
    },
    list: {
        sort: {
            col: { id: 0 },
            type: "asc"
        },
        filter: {
            search: "",
        },
        page: 0,
        size: getInitialPagination(false).size,
        collaborators: [],
        collaboratorsSnapshots:[]
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_OPERATIONAL_PROJECT_COLLABORATORS:
            return setOperationalProjectCollaborators(state, action.payload);
        case SET_OPERATIONAL_PROJECT_COLLABORATORS_SORT:
            return setCollaboratorsSort(state,action.payload);
        case SET_OPERTIONAL_PROJECT_COLLABORATORS_PAGINATION:
            return setCollaboratorsPagination(state,action.payload);
        case SET_OPERATIONAL_PROJECT_COLLABORATORS_FILTER:
            return setCollaboratorsFilter(state,action.payload)
        case SET_OPERATIONAL_PROJECT_COLLABORATORS_SNAPSHOTS:
            return setOperationalProjectCollaboratorsSnapshots(state,action.payload)
        case CLEAR_OPERATIONAL_PROJECT_COLLABORATORS_SNAPSHOTS:
            return clearOperationalMonitoringSnapshotsByCollabortor(state)
        case CLEAR_ALL_OPERATIONAL_PROJECT_COLLABORATORS_SNAPSHOTS:
            return clearOperationalMonitoringSnapshots(state)
        case CLEAR_OPERATIONAL_MONITORING_FILTER:
            return clearOperationalMonitoringFilter(state)
        default:
            return state;
    }
};  

const setOperationalProjectCollaborators = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: {
                ...state.config.pagination,
                page: parseInt(payload.page),
                size: parseInt(payload.size),
                maxLength: parseInt(payload.maxLength)
            }
        },
        list: {
            ...state.list,
            page: parseInt(payload.page),
            size: parseInt(payload.size),
            collaborators: payload.opMonitoring
        }
    };
};

const setCollaboratorsSort = (state, sort) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: sort
        }
    };
};

const setCollaboratorsPagination = (state,pagination) => {
    return {
        ...state,
        config: {
            pagination
        }
    }
}

const setCollaboratorsFilter = (state,filter) => {
    return{
        ...state,
        list: {
            ...state.list,
            filter
        }
    }
}

const setOperationalProjectCollaboratorsSnapshots = (state,collabSnapshots) => {
    return {
        ...state,
        list: {
            ...state.list,
            collaboratorsSnapshots:collabSnapshots
        }
    }
}

const clearOperationalMonitoringSnapshotsByCollabortor = (state) =>
{
    return {
        ...state,
        list: {
            ...state.list,
            collaboratorsSnapshots:initialState.list.collaboratorsSnapshots
        }
    }
}

const clearOperationalMonitoringSnapshots = (state) =>
{
    return {
        ...state,
        list: {
            ...state.list,
            collaborators:initialState.list.collaborators
        }
    }
}

const clearOperationalMonitoringFilter = (state) =>
{
    return {
        ...state,
        list: {
            ...state.list,
            filter:initialState.list.filter
        }
    }
}