import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";
import { clearIsError403 } from "../../appSlice.js";
import useStyles from "./style.js";

function Error403()
{
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(()=>{
        return ()=>{
            dispatch((clearIsError403()))
        }
    },[])

    return (
        <Alert
            severity="error"
            className={classes.errorBanner}
        >
            {t('you_are_not_authorized_to_access_this_project')}
        </Alert>
    )

}

export default Error403;