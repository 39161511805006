import React, { useEffect, useState } from "react";
import {
    Grid,
    Paper,
    TextField,
    Button,
    Typography,
    InputAdornment,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    TimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { getOperationalConfig, putOperationalConfig, resetSynchro } from "../../redux/actions/config";
import DefaultRiskConfig from "./defaultRiskConfig";
import useStyles from './style';

moment.locale("fr");

function OpConfig() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const opConfig = useSelector(({ operational }) => operational.config);
    const [data, setData] = useState({
        actionReminderTime: "00:00",
        daysBeforeActionReminder: 0
    });
    const [loadingConfig , setLoadingConfig] = useState(true)

    const [loadingSynchro , setLoadingSynchro] = useState(false)
    const [synchroErrorMessage, setSynchroErrorMessage] = useState(false)

    useEffect(() => {
        dispatch(getOperationalConfig()).then(()=>setLoadingConfig(false));
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Object.keys(opConfig).length > 0) {
            setData(opConfig);
        }
    }, [opConfig]);

    const save = () => {
        dispatch(putOperationalConfig(data));
    };

    const resetSynchronisation =()=>{

        if(opConfig.lastSynchronization && calcPeriodeOfSync() < 8)
            setSynchroErrorMessage(true)
        else
        { 
            setLoadingSynchro(true)
            dispatch(resetSynchro()).finally(()=>{
                setLoadingSynchro(false)
            })
        }
    }

    const disableSubmit = () => {
        return opConfig && data.actionReminderTime === opConfig.actionReminderTime
            && data.daysBeforeActionReminder === opConfig.daysBeforeActionReminder;
    };

    const calcPeriodeOfSync = () =>{
       const timeDiff = new Date() - Date.parse(opConfig.lastSynchronization);
       var hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
       return hoursDiff
    }

    return (
        <>
            <Paper className={classes.paper} style={{padding: "8px 24px"}}>
                <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Typography  gutterBottom>
                                {t('reset_synchro')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton disabled={loadingSynchro || loadingConfig} onClick={resetSynchronisation}>
                                {!loadingSynchro ? 
                                    <RotateLeftIcon className={classes.resetButton}/>
                                :
                                    <CircularProgress size={20}/>
                                }
                            </IconButton>
                        </Grid>
                        <Grid item>
                            {
                               synchroErrorMessage &&
                                    <Typography variant="body2" style={{color : "#de7500"}} >
                                        {t("sync_info_message")}
                                    </Typography>
                            }
                        </Grid>
                </Grid>
                {opConfig.lastSynchronization &&
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Typography  gutterBottom >
                                {t("last_sync_date")} {moment(opConfig.lastSynchronization).format("DD/MM/yyyy hh:mm:ss")} 
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </Paper>
            <Paper className={classes.paper}>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <Typography variant="h6">{t('actions_reminders')}</Typography>
                    </Grid>
                    <Grid container item alignItems="center" spacing={2}>
                        <Grid item>
                            <Typography>{t('send_reminders_at')} </Typography>
                        </Grid>
                        <Grid item style={{ width: "9rem" }}>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="fr">
                                <TimePicker
                                    variant="inline"
                                    openTo="hours"
                                    forma="HH:mm"
                                    value={moment(data.actionReminderTime, "HH:mm")}
                                    onChange={val => setData({ ...data, actionReminderTime: moment(val).format("HH:mm") })}
                                    autoOk
                                    ampm={false}
                                    disableToolbar
                                    inputVariant="outlined"
                                    InputProps={{
                                        style: { height: "36px" },
                                        endAdornment: <InputAdornment style={{ margin: 0 }} position="end">
                                            <ScheduleIcon />
                                        </InputAdornment>
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">({t('reminder_time_config_info')})</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item alignItems="center" spacing={2}>
                        <Grid item>
                            <Typography>{t('action_reminder_config_text_1')} </Typography>
                        </Grid>
                        <Grid item style={{ width: "6rem" }}>
                            <TextField
                                type="number"
                                variant="outlined"
                                size="small"
                                value={data.daysBeforeActionReminder}
                                onChange={e => setData({ ...data, daysBeforeActionReminder: Number(e.target.value) })}
                                inputProps={{
                                    min: 0
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>{t('action_reminder_config_text_2')}.</Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={save}
                            disabled={disableSubmit()}
                        >{t('save')}</Button>
                    </Grid>
                </Grid>
            </Paper>

            <DefaultRiskConfig />
        </>
    );

}

export default OpConfig;