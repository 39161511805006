// NAMING CONVENTION: 
// + endpoints must follow RESTful naming principles:
//    + GET "/examples" to query list of examples
//    + GET "/examples/:id" to query one example by id
//    + POST "/examples" to create an example
//    + PUT "/examples/:id" to update an example by id
//    + DELETE "/examples/:id" to delete an example by id 
// + group endpoints by module/service/domain
// + all endpoints should mention 'OPERATIONAL'
// + all endpoints should end with '_ENDPOINT'

/************* INDICATORS **************/
export const GLOBAL_OPERATIONAL_INDICATORS_ENDPOINT = '/pilotage-bff/api/operational/indicators/global';
export const PROJECT_OPERATIONAL_INDICATORS_ENDPOINT = '/pilotage-bff/api/operational/indicators/project';

/************* PROJECTS **************/
export const OPERATIONAL_DASHBOARD_PROJECTS_ENDPOINT = '/pilotage-bff/api/operational/projects';

/************* COLLABORATORS **************/
export const OPERATIONAL_PROJECT_COLLABORATORS_ENDPOINT = '/pilotage-bff/api/operational/projects/collaborators';

/************* ACTIONS *************/
export const OPERATIONAL_PROJECT_ACTIONS_ENDPOINT = '/pilotage-bff/api/operational/projects/actions';

/************* SNAPSHOTS *************/
export const OPERATIONAL_PROJECT_SNAPSHOTS_ENDPOINT = '/pilotage-bff/api/operational/projects/snapshots';

/************* RT **************/
export const OPERATIONAL_RT_ENDPOINT = '/pilotage-bff/api/operational/rt';

/************* CONFIG **********/
export const OPERATIONAL_CONFIG_ENDPOINT = '/pilotage-bff/api/operational/config';

/************ PROJECT RISK_CONFIG ***********/
export const OPERATIONAL_RISK_ENDPOINT = '/pilotage-bff/api/operational/riskConfig';

/************* PROJECT RISKS *************/
export const OPERATIONAL_PROJECT_RISKS_ENDPOINT = '/pilotage-bff/api/operational/projects/risks';