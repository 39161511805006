export const columns = {
    title:"cost_profiles_history",
    fields: {
        field1:"cost_profile",
        field2:"CJM",
        field3:"TJM",
        field4:"tjm_Plancher",
        field5:"lower_cost_Threshold",
        field6:"upper_cost_Threshold",
        field7: "state",
        field8: "entity",
        field9:"updated_at",
        field10:"updated_by"
    }
};