import Swal from "sweetalert2"
import { setIsError403, setIsError500 } from "../App/appSlice"

export const updateIsError = (error, store) =>{
    if(error.response.status === 500){
        Swal.close()
        store.dispatch(setIsError500())
    }
    else{

        if(error.response.status === 403){
            store.dispatch(setIsError403())
        }
    }
}