import { combineReducers } from "redux";
import indicatorsReducer from "./indicators";
import projectsReducer from "./projects";
import actionsReducer from "./actions";
import snapshotsReducer from './snapshots';
import rtReducer from './rt';
import configReducer from './config';
import riskConfigReducer from './risk';
import risksReducer from './risks';
import collaboratorsReducer from "./collaborators";

export default combineReducers({ 
    indicators: indicatorsReducer, 
    projects: projectsReducer,
    actions: actionsReducer,
    snapshots: snapshotsReducer,
    rt: rtReducer,
    config: configReducer,
    riskConfig: riskConfigReducer,
    risks: risksReducer,
    collaborators:collaboratorsReducer
});