import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
    list: {
        width: 400,
    },
    boxS:{
        justifyContent:"",
        border: '2px solid',
        borderColor:'rgba(211, 211, 211, 0.84)',
        padding: '20px',
        margin: '5px',
        width: '95%',
        borderRadius: '5px',
        backgroundColor: 'rgba(211, 211, 211, 0.1)',
    },
    con:{
        alignItems: "center",
        justify:"center",
        '& > *': {
            margin: '10px',
            fontWeight: 'bold',
        },
    },

}));