import { SET_ALL_OPERATIONAL_RISK_CONFIG, SET_DEFAULT_OPERATIONAL_RISK_CONFIG } from "../../constants";

const initialState = {
    riskConfig: {
        list: [],
        default: null
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_OPERATIONAL_RISK_CONFIG:
            return setRiskConfig(state, action.payload);
        case SET_DEFAULT_OPERATIONAL_RISK_CONFIG:
            return setDefaultRiskConfig(state, action.payload);
        default:
            return state;
    }
};

const setRiskConfig = (state, data) => {
    return {
        ...state,
        riskConfig: {
            ...state.riskConfig,
            list: data
        }
    };
};

const setDefaultRiskConfig = (state, data) => {
    return {
        ...state,
        riskConfig: {
            ...state.riskConfig,
            default: data
        }
    };
};