export const sendFile=(event, importFile, updateImportStates)=>
{  
    const file=event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file);
    reader.onloadend = () =>{
       const base64Data = reader.result.split(',')[1];
       const data={
           base64File:base64Data
        }
        importFile(data).finally(()=>updateImportStates())
    };
}