import { Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Modal } from '@material-ui/core';
import React from 'react';
import useStyles from './style';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

export default function NotifModal({ 
    title, 
    body, 
    open = false, 
    onChange = null,
    ok = {title: null, onClick: null}, 
    confirm = {title: null, onClick: null}, 
    cancel = {title: null, onClick: null}, 
    type = "default", 
    showCloseButton }) {
    const classes = useStyles();

    let btnOutlinedClass = "";
    let btnContainedClass = "";
    switch(type){
        case "primary": {
            btnOutlinedClass = classes.btnOutlinedPrimary;
            btnContainedClass = classes.btnContainedPrimary;
            break;
        }
        case "secondary": {
            btnOutlinedClass = classes.btnOutlinedSecondary;
            btnContainedClass = classes.btnContainedSecondary;
            break;
        }
        case "success": {
            btnOutlinedClass = classes.btnOutlinedSuccess;
            btnContainedClass = classes.btnContainedSuccess;
            break;
        }
        case "warning": {
            btnOutlinedClass = classes.btnOutlinedWarning;
            btnContainedClass = classes.btnContainedWarning;
            break;
        }
        case "error": {
            btnOutlinedClass = classes.btnOutlinedError;
            btnContainedClass = classes.btnContainedError;
            break;
        }
        default: {
            btnOutlinedClass = "";
            btnContainedClass = "";
            break;
        }
    }

    return (
        <Modal
            open={open}
            onClose={() => onChange && onChange(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Card className={classes.modal}>
                <CardHeader 
                    classes={{title: classes.cardTitle}}
                    title={title}
                    titleTypographyProps={{
                        variant: "h6",
                    }}
                    action={
                        showCloseButton && (
                            <IconButton
                                className={classes.icon}
                                onClick={() => onChange && onChange(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                        )
                    }
                />
                <CardContent>
                    {body}
                </CardContent>
                <CardActions>
                    <Grid container justify="space-around">
                        {
                            ok && ok.title &&
                            <Grid container item xs={6} justify="center">
                                <Button 
                                    variant="outlined" 
                                    color="default" 
                                    className={clsx(btnOutlinedClass, classes.cardAction)} 
                                    onClick={ok.onClick}
                                >
                                    {ok.title}
                                </Button>
                            </Grid>
                        }
                        {
                            (!ok || !ok.title) && cancel && cancel.title &&
                            <Grid container item xs={6} justify={confirm && confirm.title ? "flex-start" : "center"}>
                                <Button 
                                    variant="outlined" 
                                    color="default" 
                                    className={clsx(btnOutlinedClass, classes.cardAction)}
                                    onClick={cancel.onClick ?? (() => onChange && onChange(false))}
                                >
                                    {cancel.title}
                                </Button>
                            </Grid>
                        }
                        {
                            (!ok || !ok.title) && confirm && confirm.title &&
                            <Grid container item xs={6} justify={cancel && cancel.title ? "flex-end" : "center"}>
                                <Button 
                                    variant="contained"
                                    color="default" 
                                    className={clsx(btnContainedClass, classes.cardAction)}
                                    onClick={confirm.onClick}
                                >
                                    {confirm.title}
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </CardActions>
            </Card>
        </Modal>
    );
}
