import {
    deleteActionPriority,
    deleteActionStatus,
    deleteActionTypes,
    deleteOperationalMaitriseSolution,
    deleteOperationalProjectConstruction,
    deleteOperationalProjectPerimetre,
    deleteOperationalProjectPlanning,
    deleteOperationalProjectStaffing,
    deleteOperationalRelationClient,
    deleteRiskCategories,
    deleteRiskPriority,
    deleteRiskProbability,
    deleteRiskStatus,
    exportMaitriseSolution,
    exportprojectConstruction,
    exportprojectPerimetre,
    exportprojectPlanning,
    exportprojectStaffing,
    exportRelationClient,
    exportRtActionPriority,
    exportRtActionStatus,
    exportRtActionTypes,
    exportRtRiskCategory,
    exportRtRiskPriority,
    exportRtRiskProbability,
    exportRtRiskStatus,
    getAllActionPriority,
    getAllActionStatus,
    getAllActionTypes,
    getAllOperationalMaitriseSolution,
    getAllOperationalProjectConstruction,
    getAllOperationalProjectPerimetre,
    getAllOperationalProjectPlanning,
    getAllOperationalProjectStaffing,
    getAllOperationalRelationClient,
    getAllRiskCategories,
    getAllRiskPriority,
    getAllRiskProbability,
    getAllRiskStatus,
    importMaitriseSolution,
    importProjectConstruction,
    importprojectPerimetre,
    importprojectPlanning,
    importprojectStaffing,
    importRelationClient,
    importRtActionPriority,
    importRtActionStatus,
    importRtActionTypes,
    importRtRiskCategory,
    importRtRiskPriority,
    importRtRiskProbability,
    importRtRiskStatus,
    postActionPriority,
    postActionStatus,
    postActionTypes,
    postOperationalMaitriseSolution,
    postOperationalProjectConstruction,
    postOperationalProjectPerimetre,
    postOperationalProjectPlanning,
    postOperationalProjectStaffing,
    postOperationalRelationClient,
    postRiskCategories,
    postRiskPriority,
    postRiskProbability,
    postRiskStatus,
    putActionPriority,
    putActionStatus,
    putActionType,
    putOperationalMaitriseSolution,
    putOperationalProjectConstruction,
    putOperationalProjectPerimetre,
    putOperationalProjectPlanning,
    putOperationalProjectStaffing,
    putOperationalRelationClient,
    putRiskCategories,
    putRiskPriority,
    putRiskProbability,
    putRiskStatus,
} from '../../../../views/Operational/redux/actions/rt';

import {
    deleteProjectRisk,
    exportprojectRisk,
    getAllProjectRisk,
    importProjectRisk,
    postProjectRisk,
    putProjectRisk,
} from '../../../../redux/actions/rt';

import {
    deleteCostProfiles,
    deleteSaleType,
    exportRtCostProfile,
    exportSaleTypes,
    getAllCostProfiles,
    getAllSaleTypes,
    importRtCostProfile,
    importSaleTypes,
    postCostProfiles,
    postSaleType,
    putCostProfiles,
    putSaleType,
    setAllRtCostProfileSort
} from '../../../../views/Financial/redux/actions/rt';
import { deleteEntityAgency, exportEntityAgencies, getAllEntityAgencies, importEntityAgencies, postEntityAgency, putEntityAgency } from '../../../../redux/actions/rt/entityAgency';

export const RtablesDef = [
    {
        id: 0,
        name: 'project_risk',
        columns: [
            {
                id: 0,
                name: 'label',
                apiName: 'label',
                width: 5,
                type: 'text'
            },
            {
                id: 1,
                name: 'threshold',
                width: 5,
                apiName: 'threshold',
                type: 'number',
                percent: true
            }
        ],
        storeName: 'projectRisk',
        orderBy: 'threshold',
        hasColor: true,
        filename:"ProjectRisk",
        export: exportprojectRisk,
        import:importProjectRisk,
        listAll: getAllProjectRisk,
        post: postProjectRisk,
        put: putProjectRisk,
        delete: deleteProjectRisk
    },
    {
        id: 1,
        name: 'project_construction',
        columns: [
            {
                id: 0,
                name: 'label',
                apiName: 'label',
                width: 5,
                type: 'text'
            },
            {
                id: 1,
                name: 'value',
                apiName: 'value',
                width: 5,
                type: 'number',
                percent: true
            }
        ],
        hasColor: false,
        storeName: 'projectConstruction',
        orderBy: 'value',
        filename:"ProjectConstruction",
        export: exportprojectConstruction,
        import: importProjectConstruction,
        listAll: getAllOperationalProjectConstruction,
        post: postOperationalProjectConstruction,
        put: putOperationalProjectConstruction,
        delete: deleteOperationalProjectConstruction
    },
    {
        id: 2,
        name: 'project_perimetre',
        columns: [
            {
                id: 0,
                name: 'label',
                apiName: 'label',
                width: 5,
                type: 'text'
            },
            {
                id: 1,
                name: 'value',
                apiName: 'value',
                width: 5,
                type: 'number',
                percent: true
            }
        ],
        hasColor: false,
        storeName: 'projectPerimetre',
        orderBy: 'value',
        filename:"projectPerimetre",
        export: exportprojectPerimetre,
        import:importprojectPerimetre,
        listAll: getAllOperationalProjectPerimetre,
        post: postOperationalProjectPerimetre,
        put: putOperationalProjectPerimetre,
        delete: deleteOperationalProjectPerimetre
    },
    {
        id: 3,
        name: 'project_planning',
        columns: [
            {
                id: 0,
                name: 'label',
                apiName: 'label',
                width: 5,
                type: 'text'
            },
            {
                id: 1,
                name: 'value',
                apiName: 'value',
                width: 5,
                type: 'number',
                percent: true
            }
        ],
        hasColor: false,
        storeName: 'projectPlanning',
        orderBy: 'value',
        filename:"ProjectPlanning",
        export: exportprojectPlanning,
        import:importprojectPlanning,
        listAll: getAllOperationalProjectPlanning,
        post: postOperationalProjectPlanning,
        put: putOperationalProjectPlanning,
        delete: deleteOperationalProjectPlanning
    },
    {
        id: 4,
        name: 'project_staffing',
        columns: [
            {
                id: 0,
                name: 'label',
                apiName: 'label',
                width: 5,
                type: 'text'
            },
            {
                id: 1,
                name: 'value',
                apiName: 'value',
                width: 5,
                type: 'number',
                percent: true
            }
        ],
        hasColor: false,
        storeName: 'projectStaffing',
        orderBy: 'value',
        filename:"ProjectStaffing",
        export: exportprojectStaffing,
        import:importprojectStaffing,
        listAll: getAllOperationalProjectStaffing,
        post: postOperationalProjectStaffing,
        put: putOperationalProjectStaffing,
        delete: deleteOperationalProjectStaffing
    },
    {
        id: 5,
        name: 'relation_client',
        columns: [
            {
                id: 1,
                name: 'description',
                apiName: 'description',
                width: 5,
                type: 'text'
            },
            {
                id: 0,
                name: 'value',
                apiName: 'value',
                width: 5,
                type: 'number',
                percent: true
            },
        ],
        hasColor: true,
        storeName: 'relationClient',
        orderBy: 'value',
        filename:"relationClient",
        export: exportRelationClient,
        import: importRelationClient,
        listAll: getAllOperationalRelationClient,
        post: postOperationalRelationClient,
        put: putOperationalRelationClient,
        delete: deleteOperationalRelationClient
    },
    {
        id: 6,
        name: 'maitrise_solution',
        columns: [
            {
                id: 1,
                name: 'description',
                apiName: 'description',
                width: 5,
                type: 'text'
            },
            {
                id: 0,
                name: 'value',
                apiName: 'value',
                width: 5,
                type: 'number',
                percent: true
            },
        ],
        hasColor: true,
        storeName: 'maitriseSolution',
        orderBy: 'value',
        filename:"maitrise_solution",
        export: exportMaitriseSolution,
        listAll: getAllOperationalMaitriseSolution,
        import: importMaitriseSolution,
        post: postOperationalMaitriseSolution,
        put: putOperationalMaitriseSolution,
        delete: deleteOperationalMaitriseSolution
    },
    {
        id: 7,
        name: 'action_priority',
        columns: [
            {
                id: 0,
                name: 'name',
                apiName: 'name',
                width: 5,
                type: 'text'
            },
            {
                id: 1,
                name: 'value',
                apiName: 'value',
                width: 5,
                type: 'number'
            }
        ],
        hasColor: true,
        storeName: 'actionPriorities',
        orderBy: 'value',
        filename:"rtActionPriority",
        export: exportRtActionPriority,
        listAll: getAllActionPriority,
        import: importRtActionPriority,
        post: postActionPriority,
        put: putActionPriority,
        delete: deleteActionPriority
    },
    {
        id: 8,
        name: 'action_status',
        columns: [
            {
                id: 0,
                name: 'name',
                apiName: 'name',
                width: 5,
                type: 'text'
            },
            {
                id: 1,
                name: 'notify_actions',
                apiName: 'notify',
                width: 5,
                type: 'boolean'
            }
        ],
        hasColor: false,
        storeName: 'actionStatus',
        filename:"rtActionStatus",
        export: exportRtActionStatus,
        listAll: getAllActionStatus,
        import: importRtActionStatus,
        post: postActionStatus,
        put: putActionStatus,
        delete: deleteActionStatus
    },
    {
        id: 9,
        name: 'action_type',
        columns: [
            {
                id: 0,
                name: 'name',
                apiName: 'name',
                width: 5,
                type: 'text'
            },
            {
                id: 1,
                name: 'notify_actions',
                apiName: 'notify',
                width: 5,
                type: 'boolean'
            }
        ],
        hasColor: false,
        storeName: 'actionTypes',
        filename:"rtActionTypes",
        export: exportRtActionTypes,
        import: importRtActionTypes,
        listAll: getAllActionTypes,
        post: postActionTypes,
        put: putActionType,
        delete: deleteActionTypes
    },
    {
        id: 10,
        name: 'risk_status',
        columns: [
            {
                id: 0,
                name: 'name',
                apiName: 'name',
                width: 5,
                type: 'text'
            },
            {
                id: 1,
                name: 'notify_actions',
                apiName: 'notify',
                width: 5,
                type: 'boolean'
            }
        ],
        hasColor: false,
        storeName: 'riskStatus',
        filename:"rtRiskStatus",
        export: exportRtRiskStatus,
        import:importRtRiskStatus,
        listAll: getAllRiskStatus,
        post: postRiskStatus,
        put: putRiskStatus,
        delete: deleteRiskStatus
    },
    {
        id: 11,
        name: 'risk_priority',
        columns: [
            {
                id: 0,
                name: 'name',
                apiName: 'name',
                width: 5,
                type: 'text'
            },
            {
                id: 1,
                name: 'value',
                apiName: 'value',
                width: 5,
                type: 'number'
            }
        ],
        hasColor: true,
        storeName: 'riskPriorities',
        orderBy: 'value',
        filename:"rtRiskPriority",
        export: exportRtRiskPriority,
        import:importRtRiskPriority,
        listAll: getAllRiskPriority,
        post: postRiskPriority,
        put: putRiskPriority,
        delete: deleteRiskPriority
    },
    {
        id: 12,
        name: 'risk_probability',
        columns: [
            {
                id: 0,
                name: 'name',
                apiName: 'name',
                width: 5,
                type: 'text'
            },
            {
                id: 1,
                name: 'value',
                apiName: 'value',
                width: 5,
                type: 'number'
            }
        ],
        hasColor: true,
        storeName: 'riskProbabilities',
        orderBy: 'value',
        filename:"rtRiskProbability",
        export: exportRtRiskProbability,
        import:importRtRiskProbability,
        listAll: getAllRiskProbability,
        post: postRiskProbability,
        put: putRiskProbability,
        delete: deleteRiskProbability
    },
    {
        id: 13,
        name: 'risk_category',
        columns: [
            {
                id: 0,
                name: 'name',
                apiName: 'name',
                width: 10,
                type: 'text'
            }
        ],
        hasColor: true,
        storeName: 'riskCategories',
        orderBy: 'value',
        filename:'rtRiskCategory',
        export:exportRtRiskCategory,
        import:importRtRiskCategory,
        listAll: getAllRiskCategories,
        post: postRiskCategories,
        put: putRiskCategories,
        delete: deleteRiskCategories
    },
    {
        id: 14,
        name: 'cost_profile',
        columns: [
            {
                id: 0,
                name: 'PROFIL',
                apiName: 'label',
                width: 2,
                type: 'text',
                sort: true
            },
            {
                id: 1,
                name: 'CJM',
                apiName: 'cjm',
                width: 1,
                type: 'number',
                isAmount: true,
                sort: true
            },
            {
                id: 2,
                name: 'TJM',
                apiName: 'tjm',
                width: 1,
                type: 'number',
                isAmount: true,
                sort: true
            },
            {
                id: 3,
                name: 'tjm_Plancher',
                apiName: 'tjmPlancher',
                width: 1,
                type: 'number',
                isAmount: true,
                sort: true
            },
            {
                id: 4,
                name: 'lower_cost_Threshold',
                apiName: 'lowerCostThreshold',
                width: 1,
                type: 'number',
                isAmount: true,
                sort: true
            },
            {
                id: 5,
                name: 'upper_cost_Threshold',
                apiName: 'upperCostThreshold',
                width: 1,
                type: 'number',
                isAmount: true,
                sort: true
            },
            {
                id: 6,
                name: 'entity',
                apiName: 'entityAgency',
                width: 1,
                type: 'list',
                fullWidth: true,
                sort: true
            },
            {
                id: 7,
                name: 'state',
                apiName: 'active',
                width: 1,
                type: 'boolean',
                defaultChecked: true,
                sort: true
            },
            {
                id: 8,
                name: 'version',
                apiName: 'count',
                width: 1,
                type: 'chip',
                optional: true,
                onclick: true

            },
        ],
        hasColor: true,
        storeName: 'costProfiles',
        orderBy: 'value',
        filename:'rtCostProfile',
        listAll: getAllCostProfiles,
        post: postCostProfiles,
        put: putCostProfiles,
        delete: deleteCostProfiles,
        export: exportRtCostProfile,
        import: importRtCostProfile,
        setSort: setAllRtCostProfileSort 
    },
    {
        id: 15,
        name: 'sale_type_search_col',
        columns: [
            {
                id: 0,
                name: 'name',
                apiName: 'name',
                width: 5,
                type: 'text'
            },
            {
                id: 1,
                name: 'code',
                apiName: 'code',
                width: 5,
                type: 'text'
            }
        ],
        hasColor: false,
        storeName: 'saleTypes',
        filename:"rtSaleTypes",
        export: exportSaleTypes,
        import:importSaleTypes,
        listAll: getAllSaleTypes,
        post: postSaleType,
        put: putSaleType,
        delete: deleteSaleType
    },
    {
        id: 16,
        name: 'entity_agency',
        columns: [
            {
                id: 0,
                name: 'name',
                apiName: 'label',
                width: 5,
                type: 'text'
            },
            {
                id: 0,
                name: 'agencies',
                apiName: 'agencies',
                width: 5,
                fullWidth: true,
                type: 'multipleSelect'
            }
        ],
        hasColor: false,
        storeName: 'entityAgencies',
        filename:"entityAgencies",
        export: exportEntityAgencies,
        import: importEntityAgencies,
        listAll: getAllEntityAgencies,
        post: postEntityAgency,
        put: putEntityAgency,
        delete: deleteEntityAgency
    }
];
