import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        border: "2px solid",
        "&:hover": {
            border: "2px solid",
        },
        textTransform: "none"
    },
    dialog: {
        width:"50%"
    },
    line: {
        position: "absolute",
        top: "-7px",
        left: "43%",
        backgroundColor: "white",
        padding:"0 11px"
    }
}));