import React, { useEffect } from 'react';
import {
    Grid,
    Input,
    FormControl,
    InputAdornment
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import useStyles from './style';
import getColorForPercent from '../../../../../../utils/getColorForPercent';
import CustomDatePicker from '../../../../../../App/components/CustomDatePicker';
import { setOperationalProjectActionsFilter } from '../../../../redux/actions/actions';
import ResponsableSelect from '../../../../../../App/components/ResponsableSelect';
import MultiSelect from '../../../../../../App/components/MultiSelect';

var searchTimeout = 0;

function ActionFilters({ loading, onChange }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const actionPriorityList = useSelector(({ operational }) => operational.rt.actionPriorities);
    const actionStatusList = useSelector(({ operational }) => operational.rt.actionStatus);
    const actionTypeList = useSelector(({ operational }) => operational.rt.actionTypes);
    const filter = useSelector(({operational}) => operational.actions.list.filter);
    const dispatchFilter = (f) => dispatch(setOperationalProjectActionsFilter(f));

    const search = (value) => {
        if(searchTimeout) clearTimeout(searchTimeout);
        
        searchTimeout = setTimeout(() => {
            dispatchFilter({ ...filter, search: value});
        }, 250);
    };

    useEffect(() => {
        if(!loading){
            onChange();
        }
    }, [filter]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid container justify="space-between" alignItems="center">
            <Grid container item xs={3}>
                <Grid item xs={9}>
                    <FormControl fullWidth>
                        <Input
                            type="text"
                            placeholder={t('search_placeholder')}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon color="primary" />
                                </InputAdornment>
                            }
                            onChange={e => search(e.target.value)}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <Grid item xs={8}>
                <Grid container spacing={6}  justify="flex-end">
                    <Grid item xs={3}>
                        <ResponsableSelect
                            usage="filter"
                            disabled={filter.search !== ""}
                            value={filter.search !== "" ? [] : filter.responsables}
                            handleValueChange={(value) => dispatchFilter({...filter, responsables: value})} 
                            checkmark={true}

                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CustomDatePicker
                            selectedDate={filter.echeance !== "" ? filter.echeance : null}
                            onChange={value => dispatchFilter({ ...filter, echeance: value ? moment(value).format("yyyy-MM-DD") : null })}
                            emptyLabel={t('echeance_before')}
                            inputVariant="standard"
                            margin="normal"
                            disabled={filter.search !== ""}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <MultiSelect
                            label={t('priorite_col')}
                            noOptionsLabel={t('no_priority_found')}
                            value={filter.search !== "" ? [] : filter.priorities}
                            onChange={e => dispatchFilter({...filter, priorities: e.target.value})}
                            disabled={filter.search !== ""}
                            list={actionPriorityList.map(p => ({id: p.id, label: p.name, orderPercent: p.orderPercent}))}
                            checkmark={true}
                            renderLabel={(p) => (
                                <>
                                    <FiberManualRecordIcon
                                        style={{ color: getColorForPercent(p.orderPercent) }}
                                        className={classes.priorityIcon}
                                    />
                                    {p.label}
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <MultiSelect
                            label={t('status_col')}
                            noOptionsLabel={t('no_status_found')}
                            value={filter.search !== "" ? [] : filter.status}
                            onChange={e => {dispatchFilter({...filter, status: e.target.value})}}
                            disabled={filter.search !== ""}
                            list={actionStatusList.map(s => ({id: s.id, label: s.name}))}
                            checkmark={true}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <MultiSelect
                            label={t('type_col')}
                            noOptionsLabel={t('no_type_found')}
                            value={filter.search !== "" ? [] : filter.types}
                            onChange={e => dispatchFilter({...filter, types: e.target.value})}
                            disabled={filter.search !== ""}
                            list={actionTypeList.map(s => ({id: s.id, label: s.name}))}
                            checkmark={true}
                        />
                        
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );

}

export default ActionFilters;