import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Button
} from '@material-ui/core';
import useStyles from './style';
import { useTranslation } from 'react-i18next';
import Rtables from './Rtables';
import OpConfig from '../../../views/Operational/components/OpConfig';
import AccessControl from './AccessControl';


function Settings() {
    const classes = useStyles();
    const { t } = useTranslation();
    const sections = {
        RTABLES: t('rtables'),
        PARAMS: t('parameters'),
        ACCESS_CONTROL: t("access_control")
    };
    const getSectionFromHash = () => {
        const hash = window.location.hash.substring(1);
        switch (hash) {
            case "params":
                return sections.PARAMS;
            case "access-control":
                return sections.ACCESS_CONTROL;
            default:
                return sections.RTABLES;
        }
    };
    const [activeSection, setActiveSection] = useState(getSectionFromHash());

    const renderActiveSerction = () => {
        switch (activeSection) {
            case sections.RTABLES:
                return <Rtables />;
            case sections.PARAMS:
                return <OpConfig />;
            case sections.ACCESS_CONTROL:
                return <AccessControl />;
            default:
                return <></>;
        }
    };

    useEffect(() => {
        if (activeSection === sections.PARAMS) {
            window.history.replaceState(null, "Params", "#params");
        } else if (activeSection === sections.ACCESS_CONTROL) {
            window.history.replaceState(null, "Access Control", "#access-control");
        }
    }, [activeSection]); //eslint-disable-line react-hooks/exhaustive-deps

    return (

        <Grid container className={classes.container}>
            <Grid container item justify="space-between" alignItems="center">
                <Grid item>
                    <Typography className={classes.title}>{t('administration')}</Typography>
                </Grid>
                <Grid item>
                    {
                        Object.keys(sections).map(k =>
                            <Button
                                key={k}
                                variant="outlined"
                                color="primary"
                                className={activeSection === sections[k] ? classes.activeButton : classes.outlinedButton}
                                disableElevation
                                disabled={activeSection === sections[k]}
                                onClick={() => { setActiveSection(sections[k]); }}
                            >
                                {sections[k]}
                            </Button>
                        )
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.sectionContainer}>
                {renderActiveSerction()}
            </Grid>
        </Grid>

    );

}

export default Settings;