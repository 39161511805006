import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        marginBottom: "1%",
        boxShadow: theme.shadows[25],
        cursor: "pointer",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.short,
        }),
        "&:hover": {
            transform: "scale(1.008)"
        },
    },
    content: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    tableCardHeader: {
        padding: theme.spacing(2)
    },
    deleteIcon: {
        color: theme.palette.error.main,
    },
    groupedActions: {
        marginTop: theme.spacing(1)
    },
    addActionButton: {
        border: "2px solid",
        "&:hover": {
            border: "2px solid",
        },
        textTransform: "none"
    },
    exportButton: {
        marginLeft: theme.spacing(2)
    },
    exportIcon: {
        color: theme.palette.success.main
    },
    skeleton: {
        borderRadius: "4px"
    },
    filtersContainer: {
        margin: theme.spacing("15px", 0, 2, 0)
    }
}));
