import React, { useEffect, useState } from 'react';
import {
    Grid,
    Button,
    Typography,
    ButtonGroup
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import useStyles from "./style";
import ResponsableSelect from '../../../../components/ResponsableSelect';
import { getAllResponsables, putCollaboratorAccessControl } from '../../../../../redux/actions/collaborators';
import ClientTransferList from './ClientTransfertList';
import ProjectTransferList from './ProjectTransfertList';
import CustomDialog from '../../../../components/CustomDialog';
import { getAllProjectStatus, getAllProjectType } from '../../../../../redux/actions/rt';

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function AccessControlDialog({ open, setOpen, collaborator, setCollaborator }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const clientsList = useSelector(({ rt }) => rt.projectClient);
    const projectsList = useSelector(({ rt }) => rt.projects);
    const isFinancialView = useSelector(({ appState }) => appState.isFinancialView);

    const [activeSection, setActiveSection] = useState("clients"); // clients, projects
    const [saving, setSaving] = useState(false);

    const [checkedClients, setCheckedClients] = useState([]);
    const [leftClients, setLeftClients] = useState([]);
    const [rightClients, setRightClients] = useState([]);

    const [checkedProjects, setCheckedProjects] = useState([]);
    const [leftProjects, setLeftProjects] = useState([]);
    const [rightProjects, setRightProjects] = useState([]);
    const [projectsWithClientIncluded, setProjectsWithClientIncluded] = useState([]);

    const save = () => {
        const updatedData = isFinancialView ?  { ...collaborator, accessFnClients: rightClients, accessFnProjects: rightProjects } : { ...collaborator, accessClients: rightClients, accessProjects: rightProjects }
        if(JSON.stringify(updatedData) !== JSON.stringify(collaborator)){
            setSaving(true);
            dispatch(putCollaboratorAccessControl(updatedData))
                .then(() => {
                    dispatch(getAllResponsables()).then(() => {
                        setSaving(false);
                        exit();
                    });
                });
        }
        else
            exit()
    };

    const cleanup = () => {
        setActiveSection("clients");
        setCollaborator(null);
        setLeftClients([]);
        setLeftProjects([]);
        setProjectsWithClientIncluded([]);
        setRightClients([]);
        setRightProjects([]);
        setCheckedProjects([]);
        setCheckedClients([]);
    };

    const exit = () => {
        cleanup();
        setOpen(false);
    };

    const renderDialogTitle = () => {
        return (
            <Typography variant="h6">{t('access_control')}</Typography>
        );
    };

    const renderDialogContent = () => {
        return (
            <Grid container direction="column">
                <Grid container item spacing={5}>
                    <Grid item xs={6}>
                        <ResponsableSelect
                            usage="create"
                            value={collaborator}
                            handleValueChange={(val) => setCollaborator(val)}
                            variant="outlined"
                            label={t('collaborator')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonGroup fullWidth style={{ height: "100%" }} disableElevation>
                            <Button
                                variant={activeSection === "clients" ? "contained" : "outlined"}
                                onClick={() => setActiveSection('clients')}
                                style={{ borderRight: 0 }}
                            >
                                {t('clients')}
                            </Button>
                            <Button
                                variant={activeSection === "projects" ? "contained" : "outlined"}
                                onClick={() => setActiveSection('projects')}
                                style={{ borderLeft: 0 }}
                            >
                                {t('projects')}
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <Grid item style={{ marginTop: "24px" }}>
                    {
                        activeSection === "clients" &&
                        <ClientTransferList
                            selectedCollab={collaborator}
                            setChecked={setCheckedClients} 
                            checked={checkedClients} 
                            setRight={setRightClients} 
                            setLeft={setLeftClients} 
                            right={rightClients} 
                            left={leftClients} 
                            leftChecked={intersection(checkedClients, leftClients)} 
                            rightChecked={intersection(checkedClients, rightClients)}
                        />
                    }
                    {
                        activeSection === "projects" &&
                        <ProjectTransferList
                            selectedCollab={collaborator}
                            setChecked={setCheckedProjects} 
                            checked={checkedProjects} 
                            setRight={setRightProjects} 
                            setLeft={setLeftProjects} 
                            right={rightProjects} 
                            left={leftProjects} 
                            leftChecked={intersection(checkedProjects, leftProjects)} 
                            rightChecked={intersection(checkedProjects, rightProjects)}
                            projectsWithClientIncluded={projectsWithClientIncluded}
                        />
                    }
                </Grid>
            </Grid>
        );
    };

    const renderDialogActions = () => {
        return (
            <>
                <Button
                    variant="contained"
                    onClick={exit}
                    disabled={saving}
                    disableElevation
                >
                    {t('cancel')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={save}
                    disabled={collaborator === null}
                    disableElevation
                >
                    {t('save')}
                </Button>
            </>
        );
    };

    useEffect(() => {
        dispatch(getAllProjectStatus());
        dispatch(getAllProjectType());
    }, []); //eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (clientsList && collaborator) {
            setRightClients(isFinancialView ? collaborator.accessFnClients : collaborator.accessClients);
            setLeftClients(clientsList.filter(c => !(isFinancialView ? collaborator.accessFnClients : collaborator.accessClients).includes(c.id)).map(c => c.id));
        } else {
            setLeftClients([]);
            setRightClients([]);
        }
    }, [clientsList, collaborator]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (projectsList && collaborator) {
            setRightProjects(isFinancialView ? collaborator.accessFnProjects : collaborator.accessProjects);
            setLeftProjects(projectsList.filter(p => !(isFinancialView ? collaborator.accessFnProjects : collaborator.accessProjects).includes(p.id)).map(p => p.id));
            setProjectsWithClientIncluded(projectsList.filter(p => clientsList.filter(c => rightClients.includes(c.id)).map(c => c.code ?? c.name).includes(p.client?.code ?? p.client?.name)).map(p => p.id));
        } else {
            setLeftProjects([]);
            setRightProjects([]);
            setProjectsWithClientIncluded([]);
        }
    }, [projectsList, collaborator]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(projectsList && collaborator) {
            setProjectsWithClientIncluded(projectsList.filter(p => clientsList.filter(c => rightClients.includes(c.id)).map(c => c.code ?? c.name).includes(p.client?.code ?? p.client?.name)).map(p => p.id));
        }
    }, [rightClients]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <CustomDialog
            open={open}
            onClose={exit}
            title={renderDialogTitle()}
            renderContent={renderDialogContent}
            renderActions={renderDialogActions}
            classes={{ paper: classes.dialogPaper }}
            saving={saving}
            disableBackdropClick={true}
        />
    );

}

export default AccessControlDialog;