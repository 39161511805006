import React, { useMemo,useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Typography, Box, Checkbox, Popper } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useStyles from './style';


// usage : create, edit, filter
function ResponsableSelect({usage, value, handleValueChange, variant = "standard",
    disabled = false, size="medium", inputStyle, inputLabel,
    checkmark = false ,popInNotif ,isRisk,error }) {


    const { t } = useTranslation();
    const classes = useStyles();
    const responsables = useSelector(({ collaborators }) => collaborators.responsables);
    const currentCollaborator = useSelector(({ collaborators }) => collaborators.currentCollaborator);
    const firstValue = useMemo(() => value, []); //eslint-disable-line react-hooks/exhaustive-deps
    const sortOptions = (options) => {
        return options && options.sort((a, b) => a.firstName.localeCompare(b.firstName));
    };

    useEffect(() => {
        if(currentCollaborator && value?.length===0) {
            value.push(currentCollaborator);
        }
    }, [currentCollaborator, isRisk]);

    const listOptions = () => {
        if(usage === "create") return sortOptions(responsables.filter(c => c.activated));
        if(usage === "edit") return sortOptions(responsables.filter(c => c.activated || (firstValue && c.id === firstValue.id)));
        if(usage === "filter") return sortOptions(responsables);
    };

    const uniSelect = () => (
        <Autocomplete
            value={value}
            options={listOptions()}
            onChange={(e, val) => handleValueChange(val)}
            autoHighlight
            getOptionLabel={(collab) => `${collab.firstName} ${collab.lastName}`}
            getOptionSelected={(option, value) => option.id === value.id}
            renderOption={(collab) =>
                !collab.activated
                    ? <>
                        <Typography color="textSecondary">
                            {`${collab.firstName} ${collab.lastName}`} <span style={{fontSize: "0.7rem", color: "Tomato"}}>[{t('collab_archived')}]</span>
                        </Typography>
                    </>
                    : `${collab.firstName} ${collab.lastName}`
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    error = {error}
                    variant={variant}
                    size={size}
                    label={t('responsable_col')}
                    style={{width: popInNotif ? '250px' : null}}
                />
            )}
            noOptionsText={t('no_collaborator_found')}
            clearText={t('clear')}
            openText={t('open')}
            closeText={t('close')}
            classes={{ paper: classes.paper }}
            disabled={disabled}
        />
    );

    const multiSelect = () => (
        <Autocomplete
            multiple={true}
            disabled={disabled}
            value={value}
            options={listOptions()}
            onChange={(e, val) => handleValueChange(val)}
            getOptionLabel={(collab) => `${collab.firstName} ${collab.lastName}`}
            getOptionSelected={(option, value) => option.id === value.id}
            renderOption={(collab) => (
                <Box style={{flexShrink: 0}} display="flex" alignItems="center" >
                    {
                        checkmark &&
                        <Checkbox 
                            size="small"
                            checked={value.map(v => v.id).includes(collab.id)}
                        />
                    }
                    {
                        collab.activated
                            ? `${collab.firstName} ${collab.lastName}`
                            : (
                                <Typography color="textSecondary">
                                    {`${collab.firstName} ${collab.lastName}`} <span style={{fontSize: "0.7rem", color: "Tomato"}}>[{t('collab_archived')}]</span>
                                </Typography>
                            )
                    }
                    
                </Box>
            )}
            renderTags={(value) => (
                <Typography
                    noWrap={true}
                >
                    {value.map(o => `${o.firstName} ${o.lastName}`).join(', ')}
                </Typography>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    error = {error}
                    variant={variant}
                    label={inputLabel || t('responsable_col')}
                    size={size}
                    style={inputStyle}
                />
            )}
            noOptionsText={t('no_collaborator_found')}
            clearText={t('clear')}
            openText={t('open')}
            closeText={t('close')}
            classes={{ paper: classes.paper, option: classes.option }}
            className={classes.root}
            PopperComponent={props => (
                <Popper 
                    {...props} 
                    style={{minWidth: props.style.width}} 
                    placement="bottom-start"
                />
            )}
        />
    );

    return usage === "filter"
        ? multiSelect()
        : uniSelect();

}

export default ResponsableSelect;