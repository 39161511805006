import {setSelectedOperationalProjectAction} from "../../../../redux/actions/actions";
import React, {useCallback, useEffect, useState} from "react";
import {Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import AddIcon from "@material-ui/icons/Add";
import {useDispatch, useSelector} from "react-redux";
import {Skeleton} from "@material-ui/lab";
import useStyles from "../RiskCard/style";
import Actions from "./Actions";
import NoData from "../../../../../../App/components/NoData";
import {getAllActionPriority, getAllActionStatus, getAllActionTypes} from "../../../../redux/actions/rt";
import {useTranslation} from 'react-i18next';

const RiskActions = ({open,riskActions,openCreateModal,loading,riskId,deleteAction,updateRiskActions,addCommentToAction,handleModalOpen, setRiskField, setActionInRiskPopUp}) => {

        
    const dispatch = useDispatch();
    const classes = useStyles();
    const statusList = useSelector(({ operational }) => operational.rt.actionStatus);
    const priorityList = useSelector(({ operational }) => operational.rt.actionPriorities);
    const actionTypesList = useSelector(({ operational }) => operational.rt.actionTypes);
    const [selectedAction,setSelectedAction] = useState({})
    const [openModal,setOpenModal] = useState(false)
    const [notify,setNotify] = useState(false);
    const { t } = useTranslation(); 


    const selectAction=(action)=>
    {
        dispatch(setSelectedOperationalProjectAction(action));
        window.history.replaceState(null, "Actions",`#action/${action.id}`);
        handleModalOpen()
    }

    const storingNoDataComponent = useCallback(()=>{
            return <NoData condition={true} loading={loading}/>
    },[])

    const handleActionsUpdate = ()=>{
        setOpenModal(false);
        setNotify(true)
    };
      
    useEffect(()=>{
        if (notify){
            updateRiskActions();
            setNotify(false);
        }
    },[notify])

    return (
        <> 
          
              <Collapse in={open} timeout="auto" unmountOnExit>
                  {!loading ? [...Array(5).keys()].map((index)=><Skeleton key={index} animation='wave'/>)  : <Box>
                      <Box display="flex" justifyContent="flex-end" marginBottom={1}>
                          <Button
                              color="primary"
                              startIcon={<AddIcon/>}
                              variant="outlined"
                              size="small"
                              onClick={() => openCreateModal()}
                              className={classes.addActionButton}
                          >
                              {t('add_action')}
                          </Button>
                      </Box>
                      <Typography variant="h6" gutterBottom component="div">
                          Actions
                      </Typography>
                      {riskActions.length === 0 ? storingNoDataComponent() :
                          <Table size="medium" aria-label="actions">
                              <TableHead>
                                  <TableRow>
                                      <TableCell align="center"
                                                 className={classes.riskActionsColumnsSize}>{t('actions_col')}</TableCell>
                                      <TableCell align="center"
                                                 className={classes.riskActionsColumnsSize}>{t('responsable_col')}</TableCell>
                                      <TableCell align="center"
                                                 className={classes.riskActionsColumnsSize}>{t('echeances_col')}</TableCell>
                                      <TableCell align="center"
                                                 className={classes.riskActionsColumnsSize}>{t('priorite_col')}</TableCell>
                                      <TableCell align="center"
                                                 className={classes.riskActionsColumnsSize}>{t('status_col')}</TableCell>
                                      <TableCell align="center"
                                                 className={classes.riskActionsColumnsSize}>{t('type_col')}</TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                 
                                  {riskActions?.map((action) => (
                               <Actions 
                                   key={action?.id}
                                   riskactionsList={true}
                                   handleSelectAction={()=>selectAction(action)}
                                   action={action}
                                   statusList={statusList}
                                   priorityList={priorityList}
                                   actionTypesList={actionTypesList}
                                   deleteAction={deleteAction}
                                   selectedAction={selectedAction}
                                   riskId={riskId}
                                   updateActions={handleActionsUpdate}
                                   addCommentToAction={addCommentToAction}
                               />
                            ))}
                              </TableBody>
                          </Table>}
                  </Box>}
            </Collapse>
        </>
    );
}
export default RiskActions;
