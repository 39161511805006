import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    container: {
        height: '35px',
    },
    disabled: {
        color: "rgba(0, 0, 0, 0.3)",
        boxShadow: "none",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        "&:hover": {
            background: "white",
            transform: "scale(1.01)",
        },
    },
    enabled: {
        cursor: "default",
        pointerEvents: "none",
    },
    buttonText: {
        margin: '0',
        fontSize: '0.7rem'
    }
}));
