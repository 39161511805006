import React, { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import ExcelIcon from "../../../App/assets/icons/excel.svg";
import PdfIcon from "../../../App/assets/icons/pdf.svg";
import DownloadIcon from "../../../App/assets/icons/download.svg";
import { useTranslation } from 'react-i18next';
import clsx from "clsx";
import useStyles from './style';
import { downloadResponsePDF, downloadResponseXLSX } from '../../../utils/export';

function Export({ action, fileName, className, format = "excel", title, onClick = () => {} }) {

    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const handleExport = () => {
        setLoading(true);
        action().then(data => {
            if (!data.error) {
                if (format === "excel" || format === "downloadModel") {
                    downloadResponseXLSX(data.action.response, fileName);
                } else if (format === "pdf") {
                    downloadResponsePDF(data.action.response, fileName);
                }
            }
            setLoading(false);
        });
    };

    const getIcon = () => {
        switch (format) {
            case "excel":
                return ExcelIcon;
            case "pdf":
                return PdfIcon;
            case "downloadModel":
                return DownloadIcon
            default:
                return;
        }
    };

    return (

        <Button
            color="primary"
            startIcon={
                loading
                    ? <CircularProgress disableShrink size="1rem" />
                    : <img src={getIcon()} width={format=="downloadModel" ? "20px":"15px"} alt="Export icon" />
            }
            variant="outlined"
            onClick={() => {
                onClick();
                action && handleExport();
            }}
            className={clsx(className, classes.root)}
        >
            {title || t('export')}
        </Button>

    );
}

export default Export;