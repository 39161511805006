import {
    GET_ALL_RT_PROJECT_CLIENT,
    SET_ALL_RT_PROJECT_CLIENT,
} from '../../constants';
import { RT_ENDPOINT } from '../../constants/endpoint';

export const getAllProjectClient = () => {
    return {
        type: GET_ALL_RT_PROJECT_CLIENT,
        request: {
            url: `${RT_ENDPOINT}/projectClient`
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllProjectClient(response.data));
                return response;
            }
        }
    };
};

export const setAllProjectClient = (data) => {
    return {
        type: SET_ALL_RT_PROJECT_CLIENT,
        payload: data
    };
};