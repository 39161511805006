import { makeStyles } from "@material-ui/core";


export default makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        marginBottom: "1%",
        boxShadow: theme.shadows[25],
        cursor: "pointer",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.short,
        }),
        "&:hover": {
            transform: "scale(1.008)"
        },
    },
    content: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    actionName: {
        fontWeight: "500"
    },
    priorityIcon: {
        fontSize: "1rem",
        marginRight: theme.spacing(1)
    },
    deleteIcon: {
        color: theme.palette.error.main,
    },
    avatar: {
        width: "30px",
        height: "30px",
        fontSize: "0.7rem",
        marginRight: theme.spacing(1)
    },
    riskActionsColumnsSize:{
        fontSize:'14px'
    }
}));