const avatarColors = ['Aquamarine', 'BlueViolet', 'Brown', 'BurlyWood', 'CadetBlue',
    'Coral', 'Chocolate', 'CornflowerBlue', 'Crimson', 'DarkGoldenRod',
    'DarkCyan', 'DarkBlue', 'DarkGreen', 'DarkGrey', 'DarkOliveGreen',
    'DarkMagenta', 'DarkKhaki', 'DarkOrange', 'DarkOrchid', 'DarkRed',
    'DarkSalmon', 'DarkSeaGreen', 'DarkSlateBlue', 'DarkSlateGrey', 'DarkViolet',
    'DimGrey', 'FireBrick', 'IndianRed', 'Indigo', 'LightSeaGreen',
    'LightCoral', 'LightSlateGrey', 'MediumAquaMarine', 'Maroon', 'MediumPurple',
    'MediumTurquoise', 'Navy', 'OliveDrab', 'PaleVioletRed', 'Peru',
    'RebeccaPurple', 'SteelBlue', 'Teal', 'Tan', 'SeaGreen'];

export const getRandomAvatarColor = () => {
    return avatarColors[Math.floor(Math.random() * avatarColors.length)];
};