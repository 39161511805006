import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({

    root:{
        display: "flex",
        justifyContent: "center", 
        alignItems: "center",
        height: "60vh",
        marginBottom: "-2%"
    },
    container:{
        width: "100%", height: "35%",display: "flex", backgroundColor: "#e6e8ee"
    },
    content:{
        margin: "auto", textAlign: "center"
    },
    alertTitle:{
        fontWeight: "600",
        marginBottom: "5px"
    },
    alertText:{
        fontWeight: "500"
    }

}));