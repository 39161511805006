import {
    GET_ALL_RT_PROJECT_TYPE,
    SET_ALL_RT_PROJECT_TYPE,
} from '../../constants';

import { RT_ENDPOINT } from '../../constants/endpoint';

export const getAllProjectType = () => {
    return {
        type: GET_ALL_RT_PROJECT_TYPE,
        request: {
            url: `${RT_ENDPOINT}/projectType`
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllProjectType(response.data));
                return response;
            }
        }
    };
};

export const setAllProjectType = (data) => {
    return {
        type: SET_ALL_RT_PROJECT_TYPE,
        payload: data
    };
};