import { makeStyles } from "@material-ui/core/styles";


export default makeStyles((theme) => ({
    tabsContainer: {
        marginTop: theme.spacing(2),
        boxShadow: theme.shadows[25],
        backgroundColor: theme.palette.background.default
    },
    tableHeader: {
        padding: theme.spacing(0, 2, 2, 2)
    },
    addButton: {
        border: "2px solid",
        "&:hover": {
            border: "2px solid"
        },
        textTransform: "none",
        marginBottom: "-10px"
    },
    skeleton: {
        borderRadius: "4px"
    },
    scrollButton: {
        backgroundColor: "#fff",
        color: theme.palette.primary.main,
        border: "1px solid #ccc",
        borderRadius: "50%",
        margin: "4px"
    }
}));