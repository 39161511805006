// NAMING CONVENTION: 
// + all action types should mention 'OPERATIONAL'
// + request actions should start with [GET, POST, PUT, DELETE] depending on http method
// + actions of type setters (modify store state) should start with 'SET'
// + actions that reset the store to its initial state should start with 'CLEAR'

/************* Collaborators **************/

export const GET_OPERATIONAL_PROJECT_COLLABORATORS = 'GET_OPERATIONAL_PROJECT_COLLABORATORS';
export const GET_OPERATIONAL_PROJECT_COLLABORATORS_SNAPSHOTS = 'GET_OPERATIONAL_PROJECT_COLLABORATORS_SNAPSHOTS';

export const SET_OPERATIONAL_PROJECT_COLLABORATORS = 'SET_OPERATIONAL_PROJECT_COLLABORATORS';
export const SET_OPERTIONAL_PROJECT_COLLABORATORS_PAGINATION = 'SET_OPERTIONAL_PROJECT_COLLABORATORS_PAGINATION';
export const SET_OPERATIONAL_PROJECT_COLLABORATORS_SORT = 'SET_OPERATIONAL_PROJECT_COLLABORATORS_SORT';
export const SET_OPERATIONAL_PROJECT_COLLABORATORS_FILTER = 'SET_OPERATIONAL_PROJECT_COLLABORATORS_FILTER';
export const SET_OPERATIONAL_PROJECT_COLLABORATORS_SNAPSHOTS = 'SET_OPERATIONAL_PROJECT_COLLABORATORS_SNAPSHOTS';

export const CLEAR_OPERATIONAL_PROJECT_COLLABORATORS_SNAPSHOTS = 'CLEAR_OPERATIONAL_PROJECT_COLLABORATORS_SNAPSHOTS';
export const CLEAR_ALL_OPERATIONAL_PROJECT_COLLABORATORS_SNAPSHOTS = 'CLEAR_ALL_OPERATIONAL_PROJECT_COLLABORATORS_SNAPSHOTS';
export const CLEAR_OPERATIONAL_MONITORING_FILTER = "CLEAR_OPERATIONAL_MONITORING_FILTER"
/************* INDICATORS **************/
export const GET_GLOBAL_OPERATIONAL_INDICATORS = 'GET_GLOBAL_OPERATIONAL_INDICATORS';
export const GET_PROJECT_OPERATIONAL_INDICATORS = 'GET_PROJECT_OPERATIONAL_INDICATORS';
export const PUT_PROJECT_OPERATIONAL_INDICATORS = 'PUT_PROJECT_OPERATIONAL_INDICATORS';

export const SET_GLOBAL_OPERATIONAL_INDICATORS = 'SET_GLOBAL_OPERATIONAL_INDICATORS';
export const SET_PROJECT_OPERATIONAL_INDICATORS = 'SET_PROJECT_OPERATIONAL_INDICATORS';

export const CLEAR_PROJECT_OPERATIONAL_INDICATORS = 'CLEAR_PROJECT_OPERATIONAL_INDICATORS';

export const TAKE_PROJECT_OPERATIONAL_SNAPSHOT = 'TAKE_PROJECT_OPERATIONAL_SNAPSHOT';

/************* PROJECTS **************/
export const GET_OPERATIONAL_PROJECTS = 'GET_OPERATIONAL_PROJECTS';
export const SEARCH_OPERATIONAL_PROJECTS = 'SEARCH_OPERATIONAL_PROJECTS';
export const EXPORT_OPERATIONAL_PROJECTS = 'EXPORT_OPERATIONAL_PROJECTS';
export const EXPORT_OPERATIONAL_PROJECT_DASHBOARD = 'EXPORT_OPERATIONAL_PROJECT_DASHBOARD';
export const GET_SELECTED_OPERATIONAL_PROJECT = 'GET_SELECTED_OPERATIONAL_PROJECT';

export const GET_OPERATIONAL_SUBPROJECTS = 'GET_OPERATIONAL_SUBPROJECTS';
export const SET_OPERATIONAL_SUBPROJECTS = 'SET_OPERATIONAL_SUBPROJECTS';
export const SET_OPERATIONAL_SUBPROJECTS_PAGINATION = 'SET_OPERATIONAL_SUBPROJECTS_PAGINATION';
export const SET_OPERATIONAL_SUBPROJECTS_SORT = 'SET_OPERATIONAL_SUBPROJECTS_SORT';

export const SET_OPERATIONAL_PROJECTS = 'SET_OPERATIONAL_PROJECTS';
export const SET_SELECTED_OPERATIONAL_PROJECT = 'SET_SELECTED_OPERATIONAL_PROJECT';
export const SET_OPERATIONAL_PROJECTS_PAGINATION = 'SET_OPERATIONAL_PROJECTS_PAGINATION';
export const SET_OPERATIONAL_PROJECTS_GRID_VIEW = 'SET_OPERATIONAL_PROJECTS_GRID_VIEW';
export const SET_OPERATIONAL_PROJECTS_FILTER = 'SET_OPERATIONAL_PROJECTS_FILTER';
export const SET_OPERATIONAL_PROJECTS_SORT = 'SET_OPERATIONAL_PROJECTS_SORT';
export const UPDATE_OPERATIONAL_PROJECT = 'UPDATE_OPERATIONAL_PROJECT';
export const UPDATE_OPERATIONAL_SUB_PROJECT = 'UPDATE_OPERATIONAL_SUB_PROJECT';

export const CLEAR_OPERATIONAL_PROJECTS_PAGINATION = 'CLEAR_OPERATIONAL_PROJECTS_PAGINATION';
export const CLEAR_OPERATIONAL_SUBPROJECTS_PAGINATION = 'CLEAR_OPERATIONAL_PROJECTS_PAGINATION';
export const CLEAR_SELECTED_OPERATIONAL_PROJECT = 'CLEAR_SELECTED_OPERATIONAL_PROJECT';
export const CLEAR_OPERATIONAL_PROJECTS_FILTER = 'CLEAR_OPERATIONAL_PROJECTS_FILTER';
export const CLEAR_OPERATIONAL_PROJECTS_SORT = 'CLEAR_OPERATIONAL_PROJECTS_SORT';
export const CLEAR_OPERATIONAL_SUBPROJECTS_SORT = 'CLEAR_OPERATIONAL_SUBPROJECTS_SORT';

export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS';
export const SET_ALL_PROJECTS = 'SET_ALL_PROJECTS';

/************* ACTIONS **************/
export const GET_OPERATIONAL_PROJECT_ACTIONS = 'GET_OPERATIONAL_PROJECT_ACTIONS';
export const SEARCH_OPERATIONAL_PROJECT_ACTIONS = 'SEARCH_OPERATIONAL_PROJECT_ACTIONS'; 
export const EXPORT_OPERATIONAL_PROJECT_ACTIONS = 'EXPORT_OPERATIONAL_PROJECT_ACTIONS';
export const GET_SELECTED_OPERATIONAL_PROJECT_ACTION = 'GET_SELECTED_OPERATIONAL_PROJECT_ACTION';
export const POST_OPERATIONAL_PROJECT_ACTION = 'POST_OPERATIONAL_PROJECT_ACTION';
export const DELETE_OPERATIONAL_PROJECT_ACTION = 'DELETE_OPERATIONAL_PROJECT_ACTION';
export const DELETE_MULTIPLE_OPERATIONAL_PROJECT_ACTIONS = 'DELETE_MULTIPLE_OPERATIONAL_PROJECT_ACTIONS';
export const PUT_OPERATIONAL_PROJECT_ACTION = 'PUT_OPERATIONAL_PROJECT_ACTION';

export const SET_OPERATIONAL_PROJECT_ACTIONS = 'SET_OPERATIONAL_PROJECT_ACTIONS';
export const SET_SELECTED_OPERATIONAL_PROJECT_ACTION = 'SET_SELECTED_OPERATIONAL_PROJECT_ACTION';
export const SET_OPERATIONAL_PROJECT_ACTIONS_PAGINATION = 'SET_OPERATIONAL_PROJECT_ACTIONS_PAGINATION';
export const SET_OPERATIONAL_PROJECT_ACTIONS_SORT = 'SET_OPERATIONAL_PROJECT_ACTIONS_SORT';
export const SET_OPERATIONAL_PROJECT_ACTIONS_FILTER = 'SET_OPERATIONAL_PROJECT_ACTIONS_FILTER'; 

export const CLEAR_OPERATIONAL_PROJECT_ACTIONS = 'CLEAR_OPERATIONAL_PROJECT_ACTIONS';
export const CLEAR_SELECTED_OPERATIONAL_PROJECT_ACTION = 'CLEAR_SELECTED_OPERATIONAL_PROJECT_ACTION';
export const CLEAR_OPERATIONAL_PROJECT_ACTIONS_PAGINATION = 'CLEAR_OPERATIONAL_PROJECT_ACTIONS_PAGINATION';
export const CLEAR_OPERATIONAL_PROJECT_ACTIONS_SORT = 'CLEAR_OPERATIONAL_PROJECT_ACTIONS_SORT';
export const CLEAR_OPERATIONAL_PROJECT_ACTIONS_FILTER = 'CLEAR_OPERATIONAL_PROJECT_ACTIONS_FILTER';

export const SET_OPERATIONAL_RISK_ACTIONS = 'SET_OPERATIONAL_RISK_ACTIONS';
export const GET_OPERATIONAL_RISK_ACTIONS = 'GET_OPERATIONAL_RISK_ACTIONS';
export const POST_OPERATIONAL_RISK_ACTION = 'POST_OPERATIONAL_RISK_ACTION';
export const DELETE_OPERATIONAL_RISK_ACTION = 'DELETE_OPERATIONAL_RISK_ACTION';
export const PUT_OPERATIONAL_RISK_ACTION = 'PUT_OPERATIONAL_RISK_ACTION';

/************* SNAPSHOTS **************/
export const GET_OPERATIONAL_PROJECT_SNAPSHOTS = 'GET_OPERATIONAL_PROJECT_SNAPSHOTS';
export const GET_ALL_OPERATIONAL_PROJECT_SNAPSHOTS = 'GET_ALL_OPERATIONAL_PROJECT_SNAPSHOTS';
export const GET_OPERATIONAL_PROJECT_SNAPSHOT = 'GET_OPERATIONAL_PROJECT_SNAPSHOT';
export const PUT_OPERATIONAL_PROJECT_SNAPSHOT = 'GET_OPERATIONAL_PROJECT_SNAPSHOT';
export const GET_MANUAL_OPERATIONAL_SNAPSHOTS = 'GET_MANUAL_OPERATIONAL_SNAPSHOTS';

export const SET_OPERATIONAL_PROJECT_SNAPSHOTS = 'SET_OPERATIONAL_PROJECT_SNAPSHOTS';
export const SET_ALL_OPERATIONAL_PROJECTS_SNAPSHOTS = 'SET_ALL_OPERATIONAL_PROJECTS_SNAPSHOTS';
export const SET_OPERATIONAL_PROJECT_SNAPSHOT = 'SET_OPERATIONAL_PROJECT_SNAPSHOT';
export const SET_OPERATIONAL_PROJECT_SNAPSHOTS_PAGINATION = 'SET_OPERATIONAL_PROJECT_SNAPSHOTS_PAGINATION';
export const SET_OPERATIONAL_PROJECT_SNAPSHOTS_SORT = 'SET_OPERATIONAL_PROJECT_SNAPSHOTS_SORT';
export const SET_MANUAL_OPERATIONAL_SNAPSHOTS = 'SET_MANUAL_OPERATIONAL_SNAPSHOTS';
export const SET_MANUAL_OPERATIONAL_SNAPSHOTS_PAGINATION = 'SET_MANUAL_OPERATIONAL_SNAPSHOTS_PAGINATION';
export const SET_MANUAL_OPERATIONAL_SNAPSHOTS_FILTER = 'SET_MANUAL_OPERATIONAL_SNAPSHOTS_FILTER';
export const SET_MANUAL_OPERATIONAL_SNAPSHOTS_SORT = 'SET_MANUAL_OPERATIONAL_SNAPSHOTS_SORT';

export const CLEAR_OPERATIONAL_PROJECT_SNAPSHOTS = 'CLEAR_OPERATIONAL_PROJECT_SNAPSHOTS';
export const CLEAR_ALL_OPERATIONAL_PROJECT_SNAPSHOTS = 'CLEAR_ALL_OPERATIONAL_PROJECT_SNAPSHOTS';
export const CLEAR_OPERATIONAL_PROJECT_SNAPSHOT = 'CLEAR_OPERATIONAL_PROJECT_SNAPSHOT';
export const CLEAR_OPERATIONAL_PROJECT_SNAPSHOTS_PAGINATION = 'CLEAR_OPERATIONAL_PROJECT_SNAPSHOTS_PAGINATION';
export const CLEAR_OPERATIONAL_PROJECT_SNAPSHOTS_SORT = 'CLEAR_OPERATIONAL_PROJECT_SNAPSHOTS_SORT';
export const CLEAR_MANUAL_OPERATIONAL_SNAPSHOTS = 'CLEAR_MANUAL_OPERATIONAL_SNAPSHOTS';
export const CLEAR_MANUAL_OPERATIONAL_SNAPSHOTS_PAGINATION = 'CLEAR_MANUAL_OPERATIONAL_SNAPSHOTS_PAGINATION';
export const CLEAR_MANUAL_OPERATIONAL_SNAPSHOTS_FILTER = 'CLEAR_MANUAL_OPERATIONAL_SNAPSHOTS_FILTER';
export const CLEAR_MANUAL_OPERATIONAL_SNAPSHOTS_SORT = 'CLEAR_MANUAL_OPERATIONAL_SNAPSHOTS_SORT';

/************* RT **************/

export const GET_OPERATIONAL_RT_PROJECT_CONSTRUCTION = 'GET_OPERATIONAL_RT_PROJECT_CONSTRUCTION';
export const GET_ALL_OPERATIONAL_RT_PROJECT_CONSTRUCTION = 'GET_ALL_OPERATIONAL_RT_PROJECT_CONSTRUCTION';
export const POST_OPERATIONAL_RT_PROJECT_CONSTRUCTION = 'POST_OPERATIONAL_RT_PROJECT_CONSTRUCTION';
export const PUT_OPERATIONAL_RT_PROJECT_CONSTRUCTION = 'PUT_OPERATIONAL_RT_PROJECT_CONSTRUCTION';
export const DELETE_OPERATIONAL_RT_PROJECT_CONSTRUCTION = 'DELETE_OPERATIONAL_RT_PROJECT_CONSTRUCTION';
export const SET_ALL_OPERATIONAL_RT_PROJECT_CONSTRUCTION = 'SET_ALL_OPERATIONAL_RT_PROJECT_CONSTRUCTION';
export const EXPORT_ALL_OPERATIONAL_RT_PROJECT_CONSTRUCTION = 'EXPORT_ALL_OPERATIONAL_RT_PROJECT_CONSTRUCTION';
export const IMPORT_ALL_OPERATIONAL_RT_PROJECT_CONSTRUCTION = 'IMPORT_ALL_OPERATIONAL_RT_PROJECT_CONSTRUCTION';

export const GET_OPERATIONAL_RT_PROJECT_PERIMETRE = 'GET_OPERATIONAL_RT_PROJECT_PERIMETRE';
export const GET_ALL_OPERATIONAL_RT_PROJECT_PERIMETRE = 'GET_ALL_OPERATIONAL_RT_PROJECT_PERIMETRE';
export const POST_OPERATIONAL_RT_PROJECT_PERIMETRE = 'POST_OPERATIONAL_RT_PROJECT_PERIMETRE';
export const PUT_OPERATIONAL_RT_PROJECT_PERIMETRE = 'PUT_OPERATIONAL_RT_PROJECT_PERIMETRE';
export const DELETE_OPERATIONAL_RT_PROJECT_PERIMETRE = 'DELETE_OPERATIONAL_RT_PROJECT_PERIMETRE';
export const SET_ALL_OPERATIONAL_RT_PROJECT_PERIMETRE = 'SET_ALL_OPERATIONAL_RT_PROJECT_PERIMTERE';
export const EXPORT_ALL_OPERATIONAL_RT_PROJECT_PERIMETRE = 'EXPORT_ALL_OPERATIONAL_RT_PROJECT_PERIMETRE';
export const IMPORT_ALL_OPERATIONAL_RT_PROJECT_PERIMETRE = 'IMPORT_ALL_OPERATIONAL_RT_PROJECT_PERIMETRE';

export const GET_OPERATIONAL_RT_PROJECT_PLANNING = 'GET_OPERATIONAL_RT_PROJECT_PLANNING';
export const GET_ALL_OPERATIONAL_RT_PROJECT_PLANNING = 'GET_ALL_OPERATIONAL_RT_PROJECT_PLANNING';
export const POST_OPERATIONAL_RT_PROJECT_PLANNING = 'POST_OPERATIONAL_RT_PROJECT_PLANNING';
export const PUT_OPERATIONAL_RT_PROJECT_PLANNING = 'PUT_OPERATIONAL_RT_PROJECT_PLANNING';
export const DELETE_OPERATIONAL_RT_PROJECT_PLANNING = 'DELETE_OPERATIONAL_RT_PROJECT_PLANNING';
export const SET_ALL_OPERATIONAL_RT_PROJECT_PLANNING = 'SET_ALL_OPERATIONAL_RT_PROJECT_PLANNING';
export const EXPORT_ALL_OPERATIONAL_RT_PROJECT_PLANNING = 'EXPORT_ALL_OPERATIONAL_RT_PROJECT_PLANNING';
export const IMPORT_ALL_OPERATIONAL_RT_PROJECT_PLANNING = 'IMPORT_ALL_OPERATIONAL_RT_PROJECT_PLANNING';

export const GET_OPERATIONAL_RT_PROJECT_STAFFING = 'GET_OPERATIONAL_RT_PROJECT_STAFFING';
export const GET_ALL_OPERATIONAL_RT_PROJECT_STAFFING = 'GET_ALL_OPERATIONAL_RT_PROJECT_STAFFING';
export const POST_OPERATIONAL_RT_PROJECT_STAFFING = 'POST_OPERATIONAL_RT_PROJECT_STAFFING';
export const PUT_OPERATIONAL_RT_PROJECT_STAFFING = 'PUT_OPERATIONAL_RT_PROJECT_STAFFING';
export const DELETE_OPERATIONAL_RT_PROJECT_STAFFING = 'DELETE_OPERATIONAL_RT_PROJECT_STAFFING';
export const SET_ALL_OPERATIONAL_RT_PROJECT_STAFFING = 'SET_ALL_OPERATIONAL_RT_PROJECT_STAFFING';
export const EXPORT_ALL_OPERATIONAL_RT_PROJECT_STAFFING = 'EXPORT_ALL_OPERATIONAL_RT_PROJECT_STAFFING';
export const IMPORT_ALL_OPERATIONAL_RT_PROJECT_STAFFING = 'IMPORT_ALL_OPERATIONAL_RT_PROJECT_STAFFING';

export const GET_OPERATIONAL_RT_RELATION_CLIENT = 'GET_OPERATIONAL_RT_RELATION_CLIENT';
export const GET_ALL_OPERATIONAL_RT_RELATION_CLIENT = 'GET_ALL_OPERATIONAL_RT_RELATION_CLIENT';
export const POST_OPERATIONAL_RT_RELATION_CLIENT = 'POST_OPERATIONAL_RT_RELATION_CLIENT';
export const PUT_OPERATIONAL_RT_RELATION_CLIENT = 'PUT_OPERATIONAL_RT_RELATION_CLIENT';
export const DELETE_OPERATIONAL_RT_RELATION_CLIENT = 'DELETE_OPERATIONAL_RT_RELATION_CLIENT';
export const SET_ALL_OPERATIONAL_RT_RELATION_CLIENT = 'SET_ALL_OPERATIONAL_RT_RELATION_CLIENT';
export const EXPORT_ALL_OPERATIONAL_RT_RELATION_CLIENT = 'EXPORT_ALL_OPERATIONAL_RT_RELATION_CLIENT';
export const IMPORT_ALL_OPERATIONAL_RT_RELATION_CLIENT = 'IMPORT_ALL_OPERATIONAL_RT_RELATION_CLIENT';

export const GET_OPERATIONAL_RT_MAITRISE_SOLUTION = 'GET_OPERATIONAL_RT_MAITRISE_SOLUTION';
export const GET_ALL_OPERATIONAL_RT_MAITRISE_SOLUTION = 'GET_ALL_OPERATIONAL_RT_MAITRISE_SOLUTION';
export const POST_OPERATIONAL_RT_MAITRISE_SOLUTION = 'POST_OPERATIONAL_RT_MAITRISE_SOLUTION';
export const PUT_OPERATIONAL_RT_MAITRISE_SOLUTION = 'PUT_OPERATIONAL_RT_MAITRISE_SOLUTION';
export const DELETE_OPERATIONAL_RT_MAITRISE_SOLUTION = 'DELETE_OPERATIONAL_RT_MAITRISE_SOLUTION';
export const SET_ALL_OPERATIONAL_RT_MAITRISE_SOLUTION = 'SET_ALL_OPERATIONAL_RT_MAITRISE_SOLUTION';
export const EXPORT_ALL_OPERATIONAL_RT_MAITRISE_SOLUTION = 'EXPORT_ALL_OPERATIONAL_RT_MAITRISE_SOLUTION';
export const IMPORT_ALL_OPERATIONAL_RT_MAITRISE_SOLUTION = 'IMPORT_ALL_OPERATIONAL_RT_MAITRISE_SOLUTION';

export const GET_RT_ACTION_PRIORITY = 'GET_RT_ACTION_PRIORITY';
export const GET_ALL_RT_ACTION_PRIORITY = 'GET_ALL_RT_ACTION_PRIORITY';
export const POST_RT_ACTION_PRIORITY = 'POST_RT_ACTION_PRIORITY';
export const PUT_RT_ACTION_PRIORITY = 'PUT_RT_ACTION_PRIORITY';
export const DELETE_RT_ACTION_PRIORITY = 'DELETE_RT_ACTION_PRIORITY';
export const SET_ALL_RT_ACTION_PRIORITY = 'SET_ALL_RT_ACTION_PRIORITY';
export const EXPORT_RT_ACTION_PRIORITY = 'EXPORT_RT_ACTION_PRIORITY';
export const IMPORT_RT_ACTION_PRIORITY='SEND_RT_ACTION_PRIORITY';

export const GET_ALL_RT_ACTION_TYPES='GET_ALL_RT_ACTION_TYPES';
export const GET_RT_ACTION_TYPES = 'GET_RT_ACTION_TYPES';
export const POST_RT_ACTION_TYPES = 'POST_RT_ACTION_TYPES';
export const PUT_RT_ACTION_TYPES = 'PUT_RT_ACTION_TYPES';
export const DELETE_RT_ACTION_TYPES= 'DELETE_RT_ACTION_TYPES';
export const SET_ALL_RT_ACTION_TYPES='SET_ALL_RT_ACTION_TYPES';
export const EXPORT_RT_ACTION_TYPES = 'EXPORT_RT_ACTION_TYPES';
export const IMPORT_RT_ACTION_TYPES='SEND_RT_ACTION_TYPES';

export const GET_RT_ACTION_STATUS = 'GET_RT_ACTION_STATUS';
export const GET_ALL_RT_ACTION_STATUS = 'GET_ALL_RT_ACTION_STATUS';
export const POST_RT_ACTION_STATUS = 'POST_RT_ACTION_STATUS';
export const PUT_RT_ACTION_STATUS = 'PUT_RT_ACTION_STATUS';
export const DELETE_RT_ACTION_STATUS = 'DELETE_RT_ACTION_STATUS';
export const SET_ALL_RT_ACTION_STATUS = 'SET_ALL_RT_ACTION_STATUS';
export const EXPORT_RT_ACTION_Status = 'EXPORT_RT_ACTION_STATUS';
export const IMPORT_RT_ACTION_STATUS='SEND_RT_ACTION_STATUS';

export const GET_RT_RISK_STATUS = 'GET_RT_RISK_STATUS';
export const GET_ALL_RT_RISK_STATUS = 'GET_ALL_RT_RISK_STATUS';
export const POST_RT_RISK_STATUS = 'POST_RT_RISK_STATUS';
export const PUT_RT_RISK_STATUS = 'PUT_RT_RISK_STATUS';
export const DELETE_RT_RISK_STATUS = 'DELETE_RT_RISK_STATUS';
export const SET_ALL_RT_RISK_STATUS = 'SET_ALL_RT_RISK_STATUS';
export const EXPORT_RT_RISK_STATUS = 'EXPORT_RT_RISK_STATUS';
export const IMPORT_RT_RISK_STATUS='IMPORT_RT_RISK_STATUS';

export const GET_RT_RISK_PRIORITY = 'GET_RT_RISK_PRIORITY';
export const GET_ALL_RT_RISK_PRIORITY = 'GET_ALL_RT_RISK_PRIORITY';
export const POST_RT_RISK_PRIORITY = 'POST_RT_RISK_PRIORITY';
export const PUT_RT_RISK_PRIORITY = 'PUT_RT_RISK_PRIORITY';
export const DELETE_RT_RISK_PRIORITY = 'DELETE_RT_RISK_PRIORITY';
export const SET_ALL_RT_RISK_PRIORITY = 'SET_ALL_RT_RISK_PRIORITY';
export const EXPORT_RT_RISK_PRIORITY = 'EXPORT_RT_RISK_PRIORITY';
export const IMPORT_RT_RISK_PRIORITY='IMPORT_RT_RISK_PRIORITY';

export const GET_RT_RISK_PROBABILITY = 'GET_RT_RISK_PROBABILITY';
export const GET_ALL_RT_RISK_PROBABILITY = 'GET_ALL_RT_RISK_PROBABILITY';
export const POST_RT_RISK_PROBABILITY = 'POST_RT_RISK_PROBABILITY';
export const PUT_RT_RISK_PROBABILITY = 'PUT_RT_RISK_PROBABILITY';
export const DELETE_RT_RISK_PROBABILITY = 'DELETE_RT_RISK_PROBABILITY';
export const SET_ALL_RT_RISK_PROBABILITY = 'SET_ALL_RT_RISK_PROBABILITY';
export const EXPORT_RT_RISK_PROBABILITY = 'EXPORT_RT_RISK_PROBABILITY';
export const IMPORT_RT_RISK_PROBABILITY = 'IMPORT_RT_RISK_PROBABILITY';

export const GET_RT_RISK_CATEGORY = 'GET_RT_RISK_CATEGORY';
export const GET_ALL_RT_RISK_CATEGORY = 'GET_ALL_RT_RISK_CATEGORY';
export const POST_RT_RISK_CATEGORY = 'POST_RT_RISK_CATEGORY';
export const PUT_RT_RISK_CATEGORY = 'PUT_RT_RISK_CATEGORY';
export const DELETE_RT_RISK_CATEGORY = 'DELETE_RT_RISK_CATEGORY';
export const SET_ALL_RT_RISK_CATEGORY = 'SET_ALL_RT_RISK_CATEGORY;'
export const EXPORT_RT_RISK_CATEGORY = 'EXPORT_RT_RISK_CATEGORY';
export const IMPORT_RT_RISK_CATEGORY = 'IMPORT_RT_RISK_CATEGORY';

/***************** CONFIG ********************/
export const GET_OPERATIONAL_CONFIG = 'GET_OPERATIONAL_CONFIG';
export const PUT_OPERATIONAL_CONFIG = 'PUT_OPERATIONAL_CONFIG';
export const SET_OPERATIONAL_CONFIG = 'SET_OPERATIONAL_CONFIG';
export const RESET_SYNCHRO = 'RESET_SYNCHRO';

/**************** PROJECT RISK_CONFIG ****************/
export const GET_ALL_OPERATIONAL_RISK_CONFIG = 'GET_ALL_OPERATIONAL_RISK_CONFIG';
export const GET_DEFAULT_OPERATIONAL_RISK_CONFIG = 'GET_DEFAULT_OPERATIONAL_RISK_CONFIG';
export const SET_ALL_OPERATIONAL_RISK_CONFIG = 'SET_ALL_OPERATIONAL_RISK_CONFIG';
export const SET_DEFAULT_OPERATIONAL_RISK_CONFIG = 'SET_DEFAULT_OPERATIONAL_RISK_CONFIG';
export const PUT_OPERATIONAL_RISK_CONFIG = 'PUT_OPERATIONAL_RISK_CONFIG';
export const PUT_DEFAULT_OPERATIONAL_RISK_CONFIG = 'PUT_DEFAULT_OPERATIONAL_RISK_CONFIG';

/**************** PROJECT RISKS ****************/
export const GET_OPERATIONAL_PROJECT_RISKS = 'GET_OPERATIONAL_PROJECT_RISKS';
export const SEARCH_OPERATIONAL_PROJECT_RISKS = 'SEARCH_OPERATIONAL_PROJECT_RISKS';
export const EXPORT_OPERATIONAL_PROJECT_RISKS = 'EXPORT_OPERATIONAL_PROJECT_RISKS';
export const GET_SELECTED_OPERATIONAL_PROJECT_RISK = 'GET_SELECTED_OPERATIONAL_PROJECT_RISK';
export const POST_OPERATIONAL_PROJECT_RISK = 'POST_OPERATIONAL_PROJECT_RISK';
export const DELETE_OPERATIONAL_PROJECT_RISK = 'DELETE_OPERATIONAL_PROJECT_RISK';
export const DELETE_MULTIPLE_OPERATIONAL_PROJECT_RISKS = 'DELETE_MULTIPLE_OPERATIONAL_PROJECT_RISKS';
export const PUT_OPERATIONAL_PROJECT_RISK = 'PUT_OPERATIONAL_PROJECT_RISK';
export const IS_RISK_HAS_ACTIONS = 'IS_RISK_HAS_ACTIONS';

export const SET_OPERATIONAL_PROJECT_RISKS = 'SET_OPERATIONAL_PROJECT_RISKS';
export const SET_SELECTED_OPERATIONAL_PROJECT_RISK = 'SET_SELECTED_OPERATIONAL_PROJECT_RISK';
export const SET_OPERATIONAL_PROJECT_RISKS_PAGINATION = 'SET_OPERATIONAL_PROJECT_RISKS_PAGINATION';
export const SET_OPERATIONAL_PROJECT_RISKS_SORT = 'SET_OPERATIONAL_PROJECT_RISKS_SORT';
export const SET_OPERATIONAL_PROJECT_RISKS_FILTER = 'SET_OPERATIONAL_PROJECT_RISKS_FILTER';

export const CLEAR_OPERATIONAL_PROJECT_RISKS = 'CLEAR_OPERATIONAL_PROJECT_RISKS';
export const CLEAR_SELECTED_OPERATIONAL_PROJECT_RISK = 'CLEAR_SELECTED_OPERATIONAL_PROJECT_RISK';
export const CLEAR_OPERATIONAL_PROJECT_RISKS_PAGINATION = 'CLEAR_OPERATIONAL_PROJECT_RISKS_PAGINATION';
export const CLEAR_OPERATIONAL_PROJECT_RISKS_SORT = 'CLEAR_OPERATIONAL_PROJECT_RISKS_SORT';
export const CLEAR_OPERATIONAL_PROJECT_RISKS_FILTER = 'CLEAR_OPERATIONAL_PROJECT_RISKS_FILTER';