import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
    dialogPaper: {
        width: "70%",
        maxHeight: "100%"
    },
    activeButtonBox: {
        width: "100%",
        border: "2px solid",
        cursor: "default",
        '&:hover': {
            border: "2px solid",
        }
    },
    inactiveButtonBox: {
        width: "100%",
    }
}));