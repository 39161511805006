import { getInitialPagination } from "../../../../../utils/common-state";
import { projectStatus } from "../../../../../utils/projectStatus";
import {
    CLEAR_SELECTED_OPERATIONAL_PROJECT,
    SET_SELECTED_OPERATIONAL_PROJECT,
    SET_OPERATIONAL_PROJECTS,
    SET_OPERATIONAL_PROJECTS_PAGINATION,
    SET_OPERATIONAL_SUBPROJECTS,
    SET_OPERATIONAL_SUBPROJECTS_PAGINATION,
    CLEAR_OPERATIONAL_PROJECTS_PAGINATION,
    SET_OPERATIONAL_PROJECTS_GRID_VIEW,
    CLEAR_OPERATIONAL_SUBPROJECTS_PAGINATION,
    SET_OPERATIONAL_PROJECTS_FILTER,
    CLEAR_OPERATIONAL_PROJECTS_FILTER,
    SET_OPERATIONAL_PROJECTS_SORT,
    CLEAR_OPERATIONAL_PROJECTS_SORT,
    SET_OPERATIONAL_SUBPROJECTS_SORT,
    CLEAR_OPERATIONAL_SUBPROJECTS_SORT,
    UPDATE_OPERATIONAL_PROJECT,
    UPDATE_OPERATIONAL_SUB_PROJECT
} from "../../constants";

const initialState = {
    config: {
        gridView: false,
        pagination: getInitialPagination(false),
        subProjectsPagination: getInitialPagination(false)
    },
    list: {
        sort: {
            col: { id: 0 },
            type: "asc"
        },
        filter: {
            search: "",
            types: [],
            status: [projectStatus.EN_COURS],
            risks: [],
            parentOnly: true,
            clients: [],
            favorites: false
        },
        page: 0,
        size: getInitialPagination(false).size,
        projects: []
    },
    selected: null,
    subProjects: {
        sort: {
            col: { id: 0 },
            type: "asc"
        },
        page: 0,
        size: getInitialPagination(false).size,
        subProjects: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_OPERATIONAL_PROJECTS:
            return setOperationalProjects(state, action.payload);
        case SET_SELECTED_OPERATIONAL_PROJECT:
            return setSelectedOperationalProject(state, action.payload);
        case SET_OPERATIONAL_SUBPROJECTS:
            return setOperationalSubProjects(state, action.payload);
        case CLEAR_OPERATIONAL_PROJECTS_PAGINATION:
            return clearOperationalProjectsPagination(state);
        case CLEAR_SELECTED_OPERATIONAL_PROJECT:
            return clearSelectedOperationalProject(state);
        case SET_OPERATIONAL_PROJECTS_PAGINATION:
            return setOperationalProjectsPagination(state, action.payload);
        case SET_OPERATIONAL_SUBPROJECTS_PAGINATION:
            return setOperationalSubProjectsPagination(state, action.payload);
        case SET_OPERATIONAL_PROJECTS_GRID_VIEW:
            return setGridView(state, action.payload.gridView);
        case UPDATE_OPERATIONAL_PROJECT:
            return updateOperationalProject(state, action.payload);
        case UPDATE_OPERATIONAL_SUB_PROJECT:
            return updateOperationalSubProject(state, action.payload);
        case CLEAR_OPERATIONAL_SUBPROJECTS_PAGINATION:
            return clearSubProjectsPagination(state);
        case SET_OPERATIONAL_PROJECTS_FILTER:
            return setProjectsFilter(state, action.payload);
        case CLEAR_OPERATIONAL_PROJECTS_FILTER:
            return clearProjectsFilter(state);
        case SET_OPERATIONAL_PROJECTS_SORT:
            return setProjectsSort(state, action.payload);
        case CLEAR_OPERATIONAL_PROJECTS_SORT:
            return clearProjectsSort(state, action.payload);
        case SET_OPERATIONAL_SUBPROJECTS_SORT:
            return setSubProjectsSort(state, action.payload);
        case CLEAR_OPERATIONAL_SUBPROJECTS_SORT:
            return clearSubProjectsSort(state);
        default:
            return state;
    }
};
const updateOperationalProject = (state, data) => {
    return {
        ...state,
        list: {
            ...state.list,
            projects: data
        }
    }
}

const updateOperationalSubProject = (state, data) => {
    return {
        ...state,
        subProjects: {
            ...state.subProjects,
            subProjects: data
        }
    }
}

const setOperationalProjects = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: {
                ...state.config.pagination,
                page: parseInt(payload.page ?? 0),
                size: parseInt(payload.size ?? payload.maxLength),
                maxLength: parseInt(payload.maxLength)
            }
        },
        list: {
            ...state.list,
            page: parseInt(payload.page ?? 0),
            size: parseInt(payload.size ?? payload.maxLength),
            projects: payload.projects
        }
    };
};

const setSelectedOperationalProject = (state, data) => {
    return {
        ...state,
        selected: data
    };
};

const setOperationalSubProjects = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            subProjectsPagination: {
                ...state.config.subProjectsPagination,
                page: parseInt(payload.page),
                size: parseInt(payload.size),
                maxLength: parseInt(payload.maxLength)
            }
        },
        subProjects: {
            ...state.subProjects,
            page: parseInt(payload.page),
            size: parseInt(payload.size),
            subProjects: payload.subProjects
        }
    };
};

const clearOperationalProjectsPagination = (state) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: getInitialPagination(state.config.gridView)
        },
        list: {
            ...state.list,
            page: initialState.list.page,
            size: initialState.list.size,
            projects: initialState.list.projects
        }
    };
};

const clearSelectedOperationalProject = (state) => {
    return {
        ...state,
        selected: initialState.selected,
        subProjects: {
            ...state.subProjects,
            page: initialState.subProjects.page,
            size: initialState.subProjects.size,
            subProjects: initialState.subProjects.subProjects
        }
    };
};

const setOperationalProjectsPagination = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: payload
        }
    };
};

const setOperationalSubProjectsPagination = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            subProjectsPagination: payload
        }
    };
};

const setGridView = (state, gridView) => {
    return {
        ...state,
        config: {
            ...state.config,
            gridView: gridView,
            pagination: getInitialPagination(gridView)
        }
    };
};

const clearSubProjectsPagination = (state) => {
    return {
        ...state,
        config: {
            ...state.config,
            subProjectsPagination: getInitialPagination(false)
        }
    };
};

const setProjectsFilter = (state, filter) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: (state.list.filter.favorites === filter.favorites) ? state.config.pagination : getInitialPagination(state.config.gridView)
        },
        list: {
            ...state.list,
            filter: filter
        }
    };
};

const clearProjectsFilter = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            filter: initialState.list.filter
        }
    };
};

const setProjectsSort = (state, sort) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: sort
        }
    };
};

const clearProjectsSort = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: initialState.list.sort
        }
    };
};

const setSubProjectsSort = (state, sort) => {
    return {
        ...state,
        subProjects: {
            ...state.subProjects,
            sort: sort
        }
    };
};

const clearSubProjectsSort = (state) => {
    return {
        ...state,
        subProjects: {
            ...state.subProjects,
            sort: initialState.subProjects.sort
        }
    };
};