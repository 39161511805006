import React from 'react';
import {
    Box,
    Grid,
    Typography,
    SwipeableDrawer,
    ButtonBase
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import RestoreIcon from '@material-ui/icons/Restore';
import { useTranslation } from 'react-i18next';
import NoData from '../NoData';
import useStyles from './style';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { clearFinancialCollaboratorCostHistory } from '../../../views/Financial/redux/actions/collaboratorCostHistory';
import { useEffect } from 'react';


export default function SwipeableTemporaryDrawer({loading, opened, setOpened , data, clearData, pagination , columns}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [state, setState] = React.useState({
        right: opened,
    });
    const dispatch = useDispatch()
    
    const toggleDrawer = (anchor, open) => () => {
        setState({ ...state, [anchor]: open });
    };
    
    
    React.useEffect(() => {
        return ()=>{            
            clearData()
        }
    }, []);

    React.useEffect(() => {
        setOpened(state.right);    
    }, [state]);

    return (
        <SwipeableDrawer
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer('right', false)}
            onOpen={toggleDrawer('right', true)}
        >
            <ButtonBase
                role="presentation"
                onClick={toggleDrawer('right', false)}
                onKeyDown={toggleDrawer('right', false)}
            /> 
            <Grid container className={clsx(classes.con,classes.list)}>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        <RestoreIcon height={5} /> { t(columns.title) }
                    </Typography>
                </Grid>
                <Grid item xs={12} >
                    {
                        loading &&
                        [...Array(6)].map((_,index) => (
                            <Skeleton key={index} animation="wave" />
                        ))
                    }
                    <NoData condition={data?.length === 0} loading={loading} />
                    
                    {!loading && data?.map((cost, index) => (
                        <Grid container alignItems="center" justify="center" key={index}>
                            <Box className={classes.boxS}>
                                {Object.keys(columns.fields).map((key, i) => (
                                    <Grid item xs={12} key={i}>
                                        <Typography variant="body1">
                                            {t(columns.fields[key]) + " : " + (Object.values(cost)[i] ?? '') }
                                        </Typography>
                                    </Grid>
                                ))}
                            </Box>
                        </Grid>
                    ))}
                    
                </Grid>
            </Grid>
        </SwipeableDrawer>
    );
}
