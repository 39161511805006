import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        display: "flex",
        marginBottom: "1%",
        width: "100%",
        boxShadow: theme.shadows[25],
    },
    content: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    chip: {
        borderRadius: "4px",
        marginRight: theme.spacing(0.5)
    },
    collabName: {
        fontWeight: 500
    }
}));