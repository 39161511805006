import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
    Button,
    Dialog,
    Tooltip,
    IconButton,
    Typography,
    Grid,
    TextField,
    MenuItem,
    Box
} from '@material-ui/core';
import useStyles from './style';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import {
    clearOperationalProjectActionsFilter,
    deleteOperationalProjectAction,
    putOperationalProjectAction
} from "../../../../redux/actions/actions";
import getColorForPercent from '../../../../../../utils/getColorForPercent';
import moment from 'moment';
import CustomDatePicker from '../../../../../../App/components/CustomDatePicker';
import ResponsableSelect from '../../../../../../App/components/ResponsableSelect';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import {getAllActionPriority, getAllActionStatus, getAllActionTypes} from "../../../../redux/actions/rt";
import AddActionComment from '../../../../../../App/components/AddActionComment';
import { STATUS_COMPLETED } from '../../../../../../App/components/ActionsOrRisks/components/actionsStatuses';
import { STATUS_CLOSED } from '../../../../../../App/components/ActionsOrRisks/components/riskStatuses';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {children}
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


function ActionModal({
    global,
    open,
    risk,
    modalOpen,
    handleModalClose,
    updateRiskActions,
    add = false,
    deleteModal,
    setDeleteModal,
    handleDeleteModalChange, riskId, riskName, addActionForRisk, deleteActionForRisk
}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const currentAction = useSelector(({ operational }) => operational.actions.selected);
    const actionPriorityList = useSelector(({ operational }) => operational.rt.actionPriorities);
    const actionStatusList = useSelector(({ operational }) => operational.rt.actionStatus);
    const actionTypeList = useSelector(({ operational }) => operational.rt.actionTypes);
    const [edit, setEdit] = useState(false);
    const [create, setCreate] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [openAddCommentDialog, setAddCommentDialog] = useState(false);
    const [actionStatus, setActionStatus] = useState('');
    const [comment, setComment] = useState('');

    const getActionPriorityDefault = () => {
        if (actionPriorityList.length > 0) {
            return actionPriorityList.sort((a, b) => a.orderPercent - b.orderPercent)[0];
        }
        return null;
    };

    const getActionTypeDefault = () => {
        if (actionTypeList.length > 0) {
            return actionTypeList.sort((a, b) => a.orderPercent - b.orderPercent)[0];
        }
        return null;
    };

    const getActionStatusDefault = () => {
        if (actionStatusList.filter(s => s.notify === true).length > 0) {
            return actionStatusList.filter(s => s.notify === true)[0];
        } else if (actionStatusList.length > 0) {
            return actionStatusList[0];
        }
        return null;
    };
    const emptyAction = {
        name: "",
        riskId: riskId,
        description: "",
        responsable: null,
        echeance: null,
        comment:null,
        priority: getActionPriorityDefault(),
        status: getActionStatusDefault(),
        type: getActionTypeDefault(),
        reminder: true
    };

    const [action, setAction] = useState(emptyAction);

    const openEdit = () => {
        setEdit(true);
        setCreate(false);
    };
    const closeEdit = () => {
        setEdit(false);
        setAction(currentAction);
        setDisabled(false);
    };

    const openCreate = () => {
        setCreate(true);
        setEdit(false);
        setAction({...currentAction, status: getActionStatusDefault() , comment: null});
        setDisabled(false)
    };
    const closeCreate = () => {
        if (create) {
            setCreate(false);
            handleModalClose();
        }
        setDisabled(false);
    };

    const createAction = () => {
        addActionForRisk(action,riskId);
        dispatch(clearOperationalProjectActionsFilter());
        closeModal();
    };

    const deleteAction = () => {
        setDeleteModal({
            ...deleteModal,
            open: true,
            type: "error",
            title: t('suppression'),
            body: <span>{t('delete_action_modal')} <b>{action.name}</b>, {t('continue_question')}</span>,
            showCloseButton: true,
            onChange: handleDeleteModalChange,
            cancel: {
                title: t('cancel'),
                onClick: null
            },
            confirm: {
                title: t('confirm'),
                onClick: () => {
                    closeModal();
                    handleDeleteModalChange(false);
                    deleteActionForRisk(action.id)
                }
            }
        });
    };

    const closeModal = () => {
        setEdit(false);
        setCreate(false);
        handleModalClose();
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleAddComment = () => {
        updateRiskActions({...action,comment:comment})
        setAddCommentDialog(false)
        setComment("")
    };

    const updateAction = () => {
        if((action.status.code === STATUS_COMPLETED && actionStatus !== STATUS_COMPLETED)){
            setAddCommentDialog(true);
        

        setActionStatus(action.status?.code);
        setComment(action.comment);
        }
        else{
            if(JSON.stringify(action) !== JSON.stringify(currentAction))
                updateRiskActions(action)
        }
        setEdit(false);
        handleModalClose()
        
    };

    const onClose = ()=>{
        updateRiskActions(action)
        setAddCommentDialog(false);
    }

    const handleResponsableChange = (value) => {
        setAction({ ...action, responsable: value });
    };

    useEffect(() => {
        if (currentAction) {
            setActionStatus(currentAction.status?.code);
            setAction(currentAction);
        }
        setCreate(add);
        if (add) {
            setAction(emptyAction);
        }
    }, [currentAction, add]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if(open)
        {
            dispatch(getAllActionPriority());
            dispatch(getAllActionStatus());
            dispatch(getAllActionTypes());
        }
    },[open])

    return (
        <>
        <Dialog
            fullWidth={true}
            maxWidth="md"
            onClose={handleModalClose}
            open={modalOpen}
            disableBackdropClick
        >
            <DialogTitle onClose={closeModal} style={{ backgroundColor: "#f5ffff" }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        {
                            create
                                ? <Typography variant="h6" color="primary"> {t('add_action')} </Typography>
                                : <Typography variant="h6" color="primary"> {action.name} </Typography>
                        }
                    </Grid>
                    {
                        !create && !edit && !global &&
                        <Grid item>
                            <Tooltip title={t('add_action')}>
                                <IconButton color="primary" onClick={openCreate}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    }
                </Grid>
            </DialogTitle>
            <DialogContent style={{ padding: 0 }}>
                <Grid container spacing={0} className={classes.actionDetails}>
                    {
                        global && !risk &&
                    <Grid container item xs={12} alignItems="center"
                            style={{borderBottom: "2px solid #eee", padding: "15px"}}>
                        <Grid item xs={2}>
                            <Typography className={classes.colName}> {t('project_col')} </Typography>
                        </Grid>
                    </Grid>}
                    <Grid container item xs={12} alignItems="center" style={{borderBottom: "2px solid #eee", padding: "0 15px"}}>
                        <Grid container item xs={6} alignItems="center" style={{padding: "15px 0",borderRight: "2px solid rgb(238, 238, 238)"}}>
                        <Grid item xs={4}>
                            <Typography className={classes.colName}> {t('actions_col')} </Typography>
                        </Grid>
                        <Grid item xs={8} style={{paddingRight:"25px"}}>
                            {
                                create || edit
                                    ? <TextField
                                        defaultValue={action.name}
                                        placeholder={t('actions_col')}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => {
                                            setAction({ ...action, name: e.target.value });
                                            !risk && setDisabled(e.target.value === "" || !action.project);
                                             risk && setDisabled(e.target.value === "")
                                        }}
                                        fullWidth
                                    />
                                    : <Typography> {action.name} </Typography>
                            }
                        </Grid>
                    </Grid>
                    <Grid container item xs={6} alignItems="center" style={{padding: "15px 0px 15px 25px"}}>
                        <Grid item xs={4}>
                            <Typography className={classes.colName}> {t("risque_col")} </Typography>
                        </Grid>
                        <Grid item xs={8} style={{paddingRight:"25px"}}>
                        {
                            <Typography> {riskName} </Typography>
                        }
                        </Grid>
                    </Grid>
                </Grid>
                    <Grid container item xs={12} alignItems="center" style={{ borderBottom: "2px solid #eee", padding: "15px" }}>
                        <Grid item xs={2}>
                            <Typography className={classes.colName}> {t('description_col')} </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            {
                                create || edit
                                    ? <TextField
                                        defaultValue={action.description}
                                        placeholder={t('description_col')}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => setAction({ ...action, description: e.target.value })}
                                        fullWidth
                                        multiline
                                    />
                                    : <Typography> {action.description} </Typography>
                            }
                        </Grid>
                    </Grid>
                    {action.comment && <Grid container item xs={12} alignItems="center"
                           style={{borderBottom: "2px solid #eee", padding: "15px"}}>
                        <Grid item xs={2}>
                            <Typography className={classes.colName}> {t('close_comment')} </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            {
                                edit
                                    ? <TextField
                                        defaultValue={action.comment}
                                        placeholder={t('comment_label')}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) =>
                                            setAction({...action, comment: e.target.value})}
                                        fullWidth
                                        multiline
                                    />
                                    : <Typography> {action.comment} </Typography>
                            }
                        </Grid>
                    </Grid>}
                    <Grid container item xs={12} alignItems="center" style={{ borderBottom: "2px solid #eee", padding: "0 15px" }}>
                        <Grid container item xs={6} alignItems="center" style={{ borderRight: "2px solid #eee", padding: "15px 0" }}>
                            <Grid item xs={4}>
                                <Typography className={classes.colName}> {t('responsable_col')} </Typography>
                            </Grid>
                            <Grid item xs={8} style={{ paddingRight: 25 }}>
                                {
                                    create || edit
                                        ? <ResponsableSelect
                                            usage={edit ? "edit" : "create"}
                                            variant="outlined"
                                            size="small"
                                            value={action.responsable}
                                            handleValueChange={handleResponsableChange}
                                        />
                                        : <Typography> {action.responsable && `${action.responsable.firstName} ${action.responsable.lastName}`} </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Grid container item xs={6} alignItems="center" style={{ padding: "15px 0 15px 25px" }}>
                            <Grid item xs={4}>
                                <Typography className={classes.colName}> {t('echeances_col')} </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {
                                    create || edit
                                        ? <CustomDatePicker
                                            selectedDate={action.echeance}
                                            onChange={value => setAction({ ...action, echeance: value ? moment(value).format("yyyy-MM-DD") : null })}
                                            emptyLabel={t('echeances_col')}
                                            inputVariant="outlined"
                                        />
                                        : <Typography> {action.echeance && moment(action.echeance).format("DD-MM-yyyy")} </Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} alignItems="center" style={{ padding: "0 15px" }}>
                        <Grid container item xs={6} alignItems="center" style={{ borderRight: "2px solid #eee", padding: "15px 0" }}>
                            <Grid item xs={4}>
                                <Typography className={classes.colName}> {t('priorite_col')} </Typography>
                            </Grid>
                            <Grid item xs={8} style={{ paddingRight: 25 }}>
                                {
                                    create || edit
                                        ? <TextField
                                            select
                                            value={action.priority ? action.priority.name : ""}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            onChange={e => setAction({
                                                ...action,
                                                priority: actionPriorityList.find(p => p.name === e.target.value)
                                            })}
                                        >
                                            {
                                                actionPriorityList.map(item =>
                                                    <MenuItem
                                                        key={item.id}
                                                        value={item.name}
                                                    >
                                                        <FiberManualRecordIcon
                                                            style={{ color: getColorForPercent(item.orderPercent) }}
                                                            className={classes.priorityIcon}
                                                        /> {item.name}
                                                    </MenuItem>)
                                            }
                                        </TextField>
                                        : <Typography>
                                            {action.priority && action.priority.name}
                                        </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Grid container item xs={6} alignItems="center" style={{ padding: "15px 0 15px 25px" }}>
                            <Grid item xs={4}>
                                <Typography className={classes.colName}> {t('status_col')} </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {
                                    create || edit
                                        ? <TextField
                                            select
                                            value={action.status ? action.status.name : ""} 
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            onChange={e => setAction({
                                                ...action,
                                                status: actionStatusList.find(s => s.name === e.target.value)
                                            })}
                                        >
                                            {
                                                actionStatusList.map(item =>
                                                    <MenuItem
                                                        key={item.id}
                                                        value={item.name}
                                                    >
                                                        {item.name}
                                                    </MenuItem>)
                                            }
                                        </TextField>
                                        : <Typography>
                                            {action.status && action.status.name}
                                        </Typography>
                                }
                            </Grid>
                        </Grid>
                    <Grid container item xs={12} alignItems="center" >

                        <Grid container item xs={6} alignItems="center"  style={{borderRight: "2px solid #eee", padding: "15px 0"}}>
                            <Grid item xs={4}>
                                <Typography className={classes.colName}> {t('action_type')} </Typography>
                            </Grid>
                            <Grid item xs={8} style={{paddingRight: 25}}>
                                {
                                    create || edit
                                        ? <TextField
                                            select
                                            value={action.type ? action.type.name : ""}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            onChange={e => setAction({
                                                ...action,
                                                type: actionTypeList.find(({name}) => name === e.target.value)
                                            })}
                                        >
                                            {
                                                actionTypeList.map(({id,name}) =>
                                                    <MenuItem
                                                        key={id}
                                                        value={name}
                                                    >
                                                        {name}
                                                    </MenuItem>)
                                            }
                                        </TextField>
                                        : <Typography>
                                            {action.type && action.type.name}
                                        </Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                        <Grid item container  xs={12} alignItems="center" >
                            {action.responsable && action.echeance &&
                                <Box display="flex" mt={2} alignItems="center">
                                    {
                                        <>
                                            {
                                                action.reminder
                                                    ? <NotificationsActiveIcon fontSize="small" color="primary" />
                                                    : <NotificationsOffIcon fontSize="small" color="error" />
                                            }
                                            <Typography variant="subtitle2" color="textSecondary" style={{ marginLeft: "8px" }}>
                                                {action.reminder ? t('action_reminders_enabled') : t('action_reminders_disabled')}
                                            </Typography>
                                        </>
                                    } {
                                        (create || edit) &&
                                        <Button
                                            variant="outlined"
                                            onClick={() => setAction({ ...action, reminder: !action.reminder })}
                                            style={{ marginLeft: "16px" }}
                                        >
                                            <Typography variant="subtitle2">
                                                {action.reminder ? t('disable') : t('enable')}
                                            </Typography>
                                        </Button>
                                    }
                                </Box>}
                        </Grid>
            
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ backgroundColor: "#f5ffff" }}>
                {
                    create
                        ? <>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={createAction}
                                disabled={disabled || action.name === "" || !action.responsable}
                            >
                                {t('save')}
                            </Button>
                            <Button variant="contained" disableElevation onClick={closeCreate}>
                                {t('cancel')}
                            </Button>
                        </>
                        : edit
                            ? <>
                                <Button variant="contained" disableElevation color="primary" onClick={updateAction} disabled={disabled}>
                                    {t('save')}
                                </Button>
                                <Button variant="contained" disableElevation onClick={closeEdit}>
                                    {t('cancel')}
                                </Button>
                                <Button variant="contained" disableElevation className={classes.deleteButton}  onClick={deleteAction}>
                                    {t('delete')}
                                </Button>
                            </>
                            : <>
                                <Button variant="contained" disableElevation color="primary" onClick={openEdit}>
                                    {t('edit')}
                                </Button>
                                <Button variant="contained" disableElevation className={classes.deleteButton} onClick={deleteAction}>
                                    {t('delete')}
                                </Button>
                            </>

                }
            </DialogActions>
        </Dialog >
        <AddActionComment
                open={openAddCommentDialog}
                actionOrRisk={action}
                onClose={onClose}
                handleCommentChange={handleCommentChange}
                handleAddComment={handleAddComment}
                dialogTitle={t('add_comment_to_action')}
            />
        </>
    );
}

export default ActionModal;
