
export const onRequest = (action) => {
    return (request, requestAction, store) => {
        if(action){
            return action({request, requestAction, store});
        }

        return request;
    };
};

export const onSuccess = (action) => {
    return (response, requestAction, store) => {
        if(action){
            return action({response, requestAction, store});
        }

        return response;
    };
};

export const onError = (action) => {
    return (error, requestAction, store) => {
        if(action) {
            return action({error, requestAction, store});
        }

        throw error;
    };
};

export const resolveHTTPAcceptHeader = (type) => {
    switch(type){
        case "pdf":
            return "application/pdf";
        case "xlsx":
            return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        default:
            return "text/html,application/xhtml+xml,application/xml";
    }
};