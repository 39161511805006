import {Draggable} from 'react-beautiful-dnd'
import React, {useState} from 'react'
import {Box, CardHeader, Chip, CircularProgress, IconButton, Typography} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Avatar from "@material-ui/core/Avatar";
import {AccessTime, Delete, FilterList} from "@material-ui/icons";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import useStyles from "../style"
import PopIn from "../PopIn";
import {deleteOperationalProjectAction,} from "../../../../views/Operational/redux/actions/actions";
import {deleteOperationalProjectRisk, isRiskHasActions} from "../../../../views/Operational/redux/actions/risks";

function Task({task, index, columnTitle,deleteModal,handleDeleteModalChange,setDeleteModal,risk,putOperationalProject}) {

    const  dispatch = useDispatch();
    const [borderLeftColors,setBorderLeftColors] = useState(
        risk
        ? {'Identifié': '#f58b07', 'En cours': '#d9116d', 'Cloturé': '#41f111'}
        : {'Ouverte': '#f58b07', 'En cours': '#d9116d', 'Traitée': '#41f111'}
    );

    const [backGroundColors,setBackGroundColors] = useState(
        risk
        ? {'Identifié': '#eff6ff', 'En cours': '#fee2f0', 'Cloturé': '#dff8cf'}
        : {'Ouverte': '#eff6ff', 'En cours': '#fee2f0', 'Traitée': '#dff8cf'}
    );


    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false)
    const { t } = useTranslation();
    const [loadingDelete, setLoadingDelete] = useState(false);

    const dateFormatter = () => {
        const date = new Date(task?.echeance);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const formatter = new Intl.DateTimeFormat('fr-FR', options);

        return formatter.format(date);
    };

    const deleteActionOrRisk = () => {
        setLoadingDelete(true)
        const deleteActionOrRiskPromise = risk ? dispatch(isRiskHasActions(task.id)) : Promise.resolve({ data: true })
        deleteActionOrRiskPromise.then(({data})=>{
            setLoadingDelete(false)
            let hasActions = !data
            
            setDeleteModal({
                ...deleteModal,
                open: true,
                type: "error",
                title: t('suppression'),
                body: risk ? <span>{t('delete_risk_modal')} <b>{task.name}</b> {hasActions && t("delete_risk_that_have_actions_modal")}, {t('continue_question')}</span> : <span>{t('delete_action_modal')}<b>{task.name}</b>, {t('continue_question')}</span>,
                showCloseButton: true,
                onChange: handleDeleteModalChange,
                cancel: {
                    title: t('cancel'),
                    onClick: null
                },
                confirm: {
                    title: t('confirm'),
                    onClick: () => {
                        handleDeleteModalChange(false);
                        if (risk){
                            dispatch(deleteOperationalProjectRisk(task?.id))
                        }else {
                            dispatch(deleteOperationalProjectAction(task?.id))
                        }
    
                    }
                }
            })})
    };

    return (
        <>
            <Draggable draggableId={'task-' + task?.id} index={index}>
                {(provided, snapshot) => (
                    <Grid
                        container
                        item
                        xs={12}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <div className={classes.taskDiv} style={{
                            backgroundColor: snapshot.isDragging ? 'rgba(255,255,255,0.7)' : '#FFFFFF',
                            borderLeft: `4px solid ${borderLeftColors[columnTitle]}`
                        }}>
                            <Grid container item xs={12} direction='column' spacing={2}>
                                <Grid container item xs={12}>
                                            <CardHeader className={classes.cardHeader}
                                                avatar={
                                                <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                                                    {task?.responsable && `${task?.responsable?.firstName?.charAt(0)}${task?.responsable?.lastName?.charAt(0)}`}
                                                </Avatar>
                                                }
                                                action={
                                                    <Box className={classes.cardAction}>
                                                        {
                                                            loadingDelete ? 
                                                                <IconButton aria-label="loading" size="small">
                                                                    <CircularProgress size={20}/>
                                                                </IconButton>
                                                            :
                                                                <IconButton aria-label="delete" size="small"
                                                                    onClick={deleteActionOrRisk}>
                                                                    <Delete color='error'/>
                                                                </IconButton>
                                                        }
                                                        
                                                        <IconButton aria-label="more infos" size="small"
                                                                    onClick={(e) => setOpenDialog(true)}>
                                                            <FilterList/>
                                                        </IconButton>
                                                    </Box>
                                                }
                                                title={task?.echeance && <Typography variant='caption'><AccessTime
                                                fontSize='small' />{dateFormatter()}</Typography>}
                                                subheader= { <Typography className={classes.responsableInfoTypography}>
                                                {task?.responsable && `${task.responsable.firstName} ${task.responsable.lastName}`}
                                                </Typography>}
                                                
                                            />
                                </Grid>
                                <Grid item xs={12} className={classes.taskElements}>
                                    <Grid item container xs={12}>
                                        <Typography color='secondary' className={classes.typographyDescription} style={{fontWeight:500}}>
                                            {task?.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item container xs={12} >
                                        <Typography className={classes.taskDescriptionTypography} style={{height:'3vh'}}>
                                            {task?.description}
                                        </Typography>
                                    </Grid>
                                    <Grid container item xs={12} className={classes.centerData}>
                                        <Grid item xs={12} className={classes.centerData}>
                                            <Grid item xs={2}>
                                                <Typography variant='caption' className={classes.taskNameTypography}>
                                                    {t('project')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Chip
                                                    label={task?.project?.name}
                                                    style={{
                                                        color: borderLeftColors[columnTitle],
                                                        bgcolor: backGroundColors[columnTitle],
                                                        maxWidth:'inherit'
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        <PopIn
                            open={openDialog}
                            onClose={setOpenDialog}
                            task={task}
                            borderLeftColor={borderLeftColors[columnTitle]}
                            projectBgColor={backGroundColors[columnTitle]}
                            columnTitle={columnTitle}
                            taskEcheance={dateFormatter()}
                            putOperationalProject={putOperationalProject}
                            risk={risk}
                        />
                    </Grid>
                )}
            </Draggable>
        </>
    )
}

export default Task
