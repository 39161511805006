import {
    GET_ALL_RT_RISK_CATEGORY,
    SET_ALL_RT_RISK_CATEGORY,
    POST_RT_RISK_CATEGORY,
    PUT_RT_RISK_CATEGORY, DELETE_RT_RISK_CATEGORY, IMPORT_RT_RISK_CATEGORY, EXPORT_RT_RISK_CATEGORY, EXPORT_RT_RISK_PRIORITY
} from '../../constants';
import {errorNotification, successNotification} from "../../../../../utils/notification";
import { OPERATIONAL_RT_ENDPOINT } from '../../constants/endpoint';
import {popUpError} from "./popUpError";
import {cloneDeep} from "lodash";
import isEmpty from "lodash/isEmpty";


const adjustFilter = (filter) => {
    let f = cloneDeep(filter);
    Object.keys(f).forEach((k) => {
        if (isEmpty(f[k])) {
            delete f[k];
        } else {
            switch (k) {
                case 'priorities':
                case 'status':
                case 'probabilities':
                case 'categories':
                    f[k] = f[k].join(',');
                    break;
                case 'projects':
                    f['projectIds'] = f[k].map((p) => p.id).join(',');
                    delete f[k];
                    break;
                case 'responsables':
                    f['responsableIds'] = f[k].map((r) => r.id).join(',');
                    delete f[k];
                    break;
                default:
                    break;
            }
        }
    });
    return f;
};

export const getAllRiskCategories = ( isCategoryView ) => {
    return {
        type: GET_ALL_RT_RISK_CATEGORY,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/riskCategory`,
        },
        meta: {
            onRequest: (request, requestAction, store) => {
                const state = store.getState();
                const filter = adjustFilter(state.operational.risks.list.filter);
                request.params = {...filter, isCategoryView : isCategoryView};
                return request;
            },
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllRiskCategories(response.data));
                return response;
            }
        }
    };
};

export const setAllRiskCategories = (data) => {
    return {
        type: SET_ALL_RT_RISK_CATEGORY,
        payload: data
    };
};

export const postRiskCategories = (riskCategories) => {
    return {
        type: POST_RT_RISK_CATEGORY,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/riskCategory`,
            method: 'post',
            data: riskCategories
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "create_success");
                store.dispatch(getAllRiskCategories(null,false));
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "create_error");
                throw error;
            }
        }
    };
};

export const putRiskCategories = (id, riskCategories) => {
    return {
        type: PUT_RT_RISK_CATEGORY,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/riskCategory/${id}`,
            method: 'put',
            data: riskCategories
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "update_success");
                store.dispatch(getAllRiskCategories(null,false));
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "update_error");
                throw error;
            }
        }
    };
};

export const deleteRiskCategories = (id) => {
    return {
        type: DELETE_RT_RISK_CATEGORY,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/riskCategory/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "delete_success");
                store.dispatch(getAllRiskCategories(null,false));
                return response;
            },
            onError: (error, requestAction, store) => {
                popUpError(error,"delete_risk_category_error");
            }
        }
    };
};


export const exportRtRiskCategory = (type) => {
    return {
        type: EXPORT_RT_RISK_CATEGORY,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/riskCategory/export`,
            responseType: 'arraybuffer'
        },
        meta: {
            onRequest: (request) => {
                if(type)
                    request.url=request.url+"?type="+type
                return request;
            },
            onSuccess: (response) => {
                return response.data;
            }
        }
    };
};

export const importRtRiskCategory = (data) => {
    return {
        type: IMPORT_RT_RISK_CATEGORY,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/riskCategory/import`,
            method:"post",
            data:data
        },
        meta: {
            onSuccess: (response,requestAction,store) => {
                store.dispatch(getAllRiskCategories(null,false));
                return response.data;
            }
        }
    };
};