import {getInitialPagination} from "../../../../../utils/common-state";
import {
    CLEAR_FINANCIAL_PROJECT_SALE_ORDERS,
    CLEAR_FINANCIAL_PROJECT_SALE_ORDERS_FILTER,
    CLEAR_FINANCIAL_PROJECT_SALE_ORDERS_PAGINATION,
    CLEAR_FINANCIAL_PROJECT_SALE_ORDERS_SORT,
    CLEAR_SELECTED_FINANCIAL_PROJECT,
    CLEAR_SELECTED_FINANCIAL_PROJECT_SALE_ORDER,
    SET_FINANCIAL_PROJECT_SALE_ORDERS,
    SET_FINANCIAL_PROJECT_SALE_ORDERS_FILTER,
    SET_FINANCIAL_PROJECT_SALE_ORDERS_PAGINATION,
    SET_FINANCIAL_PROJECT_SALE_ORDERS_SORT,
    SET_SELECTED_FINANCIAL_PROJECT_SALE_ORDER
} from "../../constants";

const initialState = {
    config: {
        pagination: getInitialPagination(false)
    },
    list: {
        sort: {
            col: { apiName: 'createdAt' },
            type: "desc"
        },
        filter: {
            search: "",
            saleTypes: [],
            saleNatures: [],
        },
        page: 0,
        size: getInitialPagination(false).size,
        saleOrders: []
    },
    selected: null,
    projects: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FINANCIAL_PROJECT_SALE_ORDERS:
            return setFinancialProjectSaleOrders(state, action.payload);
        case SET_SELECTED_FINANCIAL_PROJECT_SALE_ORDER:
            return setSelectedFinancialProjectSaleOrder(state, action.payload);
        case SET_FINANCIAL_PROJECT_SALE_ORDERS_PAGINATION:
            return setFinancialProjectSaleOrdersPagination(state, action.payload);
        case CLEAR_FINANCIAL_PROJECT_SALE_ORDERS:
            return clearFinancialProjectSaleOrders(state);
        case CLEAR_SELECTED_FINANCIAL_PROJECT_SALE_ORDER:
            return clearSelectedFinancialProjectSaleOrders(state);
        case CLEAR_FINANCIAL_PROJECT_SALE_ORDERS_PAGINATION:
            return clearFinancialProjectSaleOrdersPagination(state);
        case SET_FINANCIAL_PROJECT_SALE_ORDERS_SORT:
            return setSaleOrdersSort(state, action.payload);
        case CLEAR_FINANCIAL_PROJECT_SALE_ORDERS_SORT:
            return clearSaleOrdersSort(state);
        case SET_FINANCIAL_PROJECT_SALE_ORDERS_FILTER:
            return setSaleOrdersFilter(state, action.payload);
        case CLEAR_FINANCIAL_PROJECT_SALE_ORDERS_FILTER:
            return clearSaleOrdersFilter(state);
        case CLEAR_SELECTED_FINANCIAL_PROJECT:
            return clearSelectedProject(state);
        default: return state;
    }
};

const setFinancialProjectSaleOrders = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: {
                ...state.config.pagination,
                page: parseInt(payload.page),
                size: parseInt(payload.size),
                maxLength: parseInt(payload.maxLength)
            }
        },
        list: {
            ...state.list,
            page: parseInt(payload.page),
            size: parseInt(payload.size),
            saleOrders: payload.saleOrders
        }
    };
};

const setSelectedFinancialProjectSaleOrder = (state, payload) => {
    return {
        ...state,
        selected: payload
    };
};

const setFinancialProjectSaleOrdersPagination = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: payload
        }
    };
};

const clearFinancialProjectSaleOrders = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            page: initialState.list.page,
            size: initialState.list.size,
            saleOrders: initialState.list.saleOrders
        }
    };
};

const clearSelectedFinancialProjectSaleOrders = (state) => {
    return {
        ...state,
        selected: initialState.selected
    };
};

const clearFinancialProjectSaleOrdersPagination = (state) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: getInitialPagination(false)
        }
    };
};

const setAllProjects = (state, payload) => {
    return {
        ...state,
        projects: payload
    };
};

const setSaleOrdersSort = (state, sort) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: sort
        }
    };
};

const clearSaleOrdersSort = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: initialState.list.sort
        }
    };
};

const setSaleOrdersFilter = (state, filter) => {
    return {
        ...state,
        list: {
            ...state.list,
            filter: filter
        }
    };
};

const clearSaleOrdersFilter = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            filter: initialState.list.filter
        }
    };
};

const clearSelectedProject = (state) => {
    return {
        ...state,
        selected: null
    };
};