import { createMuiTheme } from "@material-ui/core/styles";

const shadeColor = (color, percent) => {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    let RR = ((R.toString(16).length === 1) ? "0" + R.toString(16) : R.toString(16));
    let GG = ((G.toString(16).length === 1) ? "0" + G.toString(16) : G.toString(16));
    let BB = ((B.toString(16).length === 1) ? "0" + B.toString(16) : B.toString(16));

    return {
        hex: "#" + RR + GG + BB,
        rgb: {
            r: R,
            g: G,
            b: B
        }
    };
};

const defaultThemeColor = {
    financial: {
        hex: "#3A8D98",
        rgb: { r: 58, g: 141, b: 152, a: 1 }
    },
    operational: {
        hex: "#6D91D2",
        rgb: { r: 109, g: 145, b: 210, a: 1 }
    }
};

const getTheme = (themeColor = defaultThemeColor, direction = 'ltr', isFinancialView = false) => {
    const MuiTheme = themeColor;

    const dark = shadeColor(isFinancialView ? MuiTheme.financial.hex : MuiTheme.operational.hex, -50);

    const theme = createMuiTheme({
        direction,
        palette: {
            primary: {
                main: isFinancialView ? MuiTheme.financial.hex : MuiTheme.operational.hex
            },
            secondary: {
                main: isFinancialView ? MuiTheme.financial.hex : MuiTheme.operational.hex,
                contrastText: "#FFFFFF",
            },
            background: {
                main: isFinancialView ? MuiTheme.financial.hex : MuiTheme.operational.hex,
                mainTransparent:
                    isFinancialView
                        ? `rgba(${MuiTheme.financial.rgb.r}, ${MuiTheme.financial.rgb.g}, ${MuiTheme.financial.rgb.b}, 0.7)`
                        : `rgba(${MuiTheme.operational.rgb.r}, ${MuiTheme.operational.rgb.g}, ${MuiTheme.operational.rgb.b}, 0.7)`,
                default: "#F5F4F8",
                defaultDark: "#545454",
                dark: dark.hex,
                darkTransparent: `rgba(${dark.rgb.r}, ${dark.rgb.g}, ${dark.rgb.b}, 0.2)`
            },
            success: {
                main: "#1BB2A1",
            },
            text: {
                primary: "rgba(0, 0, 0, 0.75)"
            },
        },
        typography: {
            fontFamily: ["Roboto", "sans-serif"].join(","),
        },

        props: {
            MuiCard: {
                elevation: 25,
            },
        },
        custom: {
            variables: {
                menuLeftWidth: 225,
                headerHeight: 200,
            },
            color: {
                color1: "#FF6384", // Pink
                color2: "#9d67ab", // Purple
                color3: "#2cd9c5", // Green
                bakgroundColor: "#f4f7fa",
            },
        },
    });

    theme.overrides = {
        ...theme.overrides,
        MuiCssBaseline: {
            '@global': {
                '*': {
                    fontSize: '15px',
                },
                '::-webkit-scrollbar-thumb': {
                    background: theme.palette.background.main
                },
                '::-webkit-scrollbar-thumb:hover': {
                    background: theme.palette.background.defaultDark
                },
                a: {
                    '&:hover': {
                        color: theme.palette.secondary.main,
                    }
                },
                '.rdrDateDisplayWrapper': {
                    display: 'none !important'
                },
                '.rdrStartEdge, .rdrInRange, .rdrEndEdge': {
                    color: `${theme.palette.background.main} !important`
                },
                '.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview': {
                    color: "#ffb74d !important"
                },
                '.rdrDayToday .rdrDayNumber span:after': {
                    background: `${theme.palette.background.main} !important`
                },
                '.MuiSkeleton-root': {
                    backgroundColor: "rgba(235, 235, 235) !important"
                },
                '.MuiInputBase-root > fieldset > legend > span': {
                    fontSize: "11.5px !important"
                }
            },
        },
        MuiTableHead: {
            root: {
                backgroundColor: "transparent",
            },
        },
        MuiTableCell: {
            root: {
                fontSize: "0.8rem",
                padding: `10px ${theme.spacing(2)}px`,
            },
            head: {
                color: "#555555",
            },
        },
        MuiOutlinedInput: {
            notchedOutline: {
                boxShadow: "rgba(17, 17, 26, 0.1) 0px 0.5px 0px"
            }
        }
    };
    theme.shadows[24] = 'rgba(0, 0, 0, 0.16) 0px 1px 4px';
    theme.shadows[25] = 'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px';
    return theme;
};
export default getTheme;
