import { CLEAR_PROJECT_FINANCIAL_INDICATORS, GET_GLOBAL_FINANCIAL_INDICATORS, GET_PROJECT_FINANCIAL_INDICATORS, PUT_PROJECT_FINANCIAL_INDICATORS, SET_GLOBAL_FINANCIAL_INDICATORS, SET_PROJECT_FINANCIAL_INDICATORS, TAKE_PROJECT_FINANCIAL_SNAPSHOT } from "../../constants";
import { GLOBAL_FINANCIAL_INDICATORS_ENDPOINT, PROJECT_FINANCIAL_INDICATORS_ENDPOINT } from "../../constants/endpoint";
import { getSelectedFinancialProject } from "../projects";


export const getGlobalFinancialIndicators = () => {
    return {
        type: GET_GLOBAL_FINANCIAL_INDICATORS,
        request: {
            url: GLOBAL_FINANCIAL_INDICATORS_ENDPOINT,
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setGlobalFinancialIndicators(response.data));
                return response;
            }
        }
    };
};

export const getProjectFinancialIndicators = (id, includeSubProjectsCalc) => {
    return {
        type: GET_PROJECT_FINANCIAL_INDICATORS,
        request: {
            url: `${PROJECT_FINANCIAL_INDICATORS_ENDPOINT}/${id}?includeSubProjectsCalc=${includeSubProjectsCalc}`,
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setProjectFinancialIndicators(response.data));
                return response;
            }
        }
    };
};

export const putProjectFinancialIndicators = (id, formData, includeSubProjectsCalc) => {
    return {
        type: PUT_PROJECT_FINANCIAL_INDICATORS,
        request: {
            url: `${PROJECT_FINANCIAL_INDICATORS_ENDPOINT}/${id}`,
            method: 'put',
            data: formData
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(getProjectFinancialIndicators(id, includeSubProjectsCalc));
                return response;
            }
        }
    };
};

export const setGlobalFinancialIndicators = (data) => {
    return {
        type: SET_GLOBAL_FINANCIAL_INDICATORS,
        data: data
    };
};

export const setProjectFinancialIndicators = (data) => {
    return {
        type: SET_PROJECT_FINANCIAL_INDICATORS,
        data: data
    };
};

export const clearProjectIndicators = () => {
    return {
        type: CLEAR_PROJECT_FINANCIAL_INDICATORS,
    };
};

export const takeProjectFinancialIndicatorsSnapshot = (id) => {
    return {
        type: TAKE_PROJECT_FINANCIAL_SNAPSHOT,
        request: {
            url: `${PROJECT_FINANCIAL_INDICATORS_ENDPOINT}/takeSnapshot/${id}`,
            method: 'post',
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(getSelectedFinancialProject(id));
                return response;
            }
        }
    };
};
