import {
    GET_ALL_RT_RISK_PRIORITY,
    SET_ALL_RT_RISK_PRIORITY,
    POST_RT_RISK_PRIORITY,
    PUT_RT_RISK_PRIORITY, DELETE_RT_RISK_PRIORITY, EXPORT_RT_RISK_PRIORITY, IMPORT_RT_RISK_PRIORITY
} from '../../constants';
import {errorNotification, successNotification} from "../../../../../utils/notification";
import { OPERATIONAL_RT_ENDPOINT } from '../../constants/endpoint';
import {popUpError} from "./popUpError";

export const getAllRiskPriority = () => {
    return {
        type: GET_ALL_RT_RISK_PRIORITY,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/riskPriority`
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllRiskPriority(response.data));
                return response;
            }
        }
    };
};

export const setAllRiskPriority = (data) => {
    return {
        type: SET_ALL_RT_RISK_PRIORITY,
        payload: data
    };
};

export const postRiskPriority = (riskPriority) => {
    return {
        type: POST_RT_RISK_PRIORITY,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/riskPriority`,
            method: 'post',
            data: riskPriority
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "create_success");
                store.dispatch(getAllRiskPriority());
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "create_error");
                throw error;
            }
        }
    };
};

export const putRiskPriority = (id, riskPriority) => {
    return {
        type: PUT_RT_RISK_PRIORITY,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/riskPriority/${id}`,
            method: 'put',
            data: riskPriority
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "update_success");
                store.dispatch(getAllRiskPriority());
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "update_error");
                throw error;
            }
        }
    };
};

export const deleteRiskPriority = (id) => {
    return {
        type: DELETE_RT_RISK_PRIORITY,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/riskPriority/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "delete_success");
                store.dispatch(getAllRiskPriority());
                return response;
            },
            onError: (error, requestAction, store) => {
                popUpError(error,"delete_risk_priority_error");
            }
        }
    };
};

export const exportRtRiskPriority = (type) => {
    return {
        type: EXPORT_RT_RISK_PRIORITY,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/riskPriority/export`,
            responseType: 'arraybuffer'
        },
        meta: {
            onRequest: (request) => {
                if(type)
                    request.url=request.url+"?type="+type
                return request;
            },
            onSuccess: (response) => {
                return response.data;
            }
        }
    };
};

export const importRtRiskPriority = (data) => {
    return {
        type: IMPORT_RT_RISK_PRIORITY,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/riskPriority/import`,
            method:"post",
            data:data
        },
        meta: {
            onSuccess: (response,requestAction,store) => {
                store.dispatch(getAllRiskPriority());
                return response.data;
            }
        }
    };
};
