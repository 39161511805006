import React from "react";

import useStyles from "./style.js";
import { Typography, Paper } from "@material-ui/core";

export default function ({ title, content, color = "primary" }) {
    const classes = useStyles();
    return (
        <Paper square className={classes.root} elevation={0}>
            <Typography variant="h6" color={color} align="center" className={classes.fullWidth}>
                {title}
            </Typography>
            {
                content &&
                <Typography variant="subtitle2" align="center" className={classes.fullWidth}>
                    {content}
                </Typography>
            }
        </Paper>
    );
}
