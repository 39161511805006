import React, { useEffect, useState } from 'react';
import {
    Grid,
    Tabs,
    Tab,
    Paper,
    Box,
    Button,
    TabScrollButton
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useStyles from './style';
import AddIcon from '@material-ui/icons/Add';
import { RtablesDef } from './rtablesDef';
import ListColsHeader from '../../../components/ListColsHeader';
import NotifModal from '../../../components/NotifModal';
import { getGlobalOperationalIndicators } from '../../../../views/Operational/redux/actions/indicators';
import { RtCard, RtCreateCard } from './RtCard';
import { Skeleton } from '@material-ui/lab';
import NoData from '../../../components/NoData';
import Export from '../../../components/Export';
import Import from '../../../components/Import';
import { SendExcelRtActionTypes } from '../../../../views/Operational/redux/actions/rt';
import { downloadResponseXLSX } from '../../../../utils/export';
import { getAllEntityAgencies } from '../../../../redux/actions/rt/entityAgency';
import { getAllAgencies } from '../../../../redux/actions/rt/agency';


const modal = {
    type: "error",
    open: false,
    title: null,
    body: null,
    showCloseButton: true,
    onChange: null,
    ok: {
        title: null,
        onClick: null
    },
    confirm: {
        title: null,
        onClick: null
    },
    cancel: {
        title: null,
        onClick: null
    }
};


function Rtables() {
    const hash = window.location.hash.substring(1);
    const requestedRtable = RtablesDef.find(rt => rt.storeName === hash.substring(3));
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const rtOperationalList = useSelector(({ operational }) => operational.rt);
    const rtFinancialList = useSelector(({ financial }) => financial.rt)
    const sort = useSelector(({ financial }) => financial.rt.sort)
    const rtGlobalList = useSelector(({rt}) => rt);
    const [activeTab, setActiveTab] = useState(requestedRtable?.id ?? 0);
    const [activeRtable, setActiveRtable] = useState(RtablesDef[requestedRtable?.id ?? 0]);
    const [create, setCreate] = useState(false);
    const [deleteModal, setDeleteModal] = useState(modal);
    const [loading, setLoading] = useState(false);
    const [instances, setInstances] = useState();
    const [newInstance, setNewInstance] = useState();

    const CustomTabScrollButton = (props) => {
        return <TabScrollButton
            {...props}
            className={classes.scrollButton}
        />;
    };

    const changeActiveTab = (event, value) => {
        setActiveTab(value);
        setCreate(false);
    };

    const handleModalChange = (open) => {
        setDeleteModal({
            ...deleteModal,
            open: open
        });
    };

    const resetInstances = () => {
        setInstances({...rtOperationalList, ...rtFinancialList, ...rtGlobalList}[activeRtable.storeName]);
    };

    const openCreateForm = () => {
        setCreate(true);
        setNewInstance(activeRtable.columns.reduce((obj, col) => ({ ...obj, [col.apiName]: ["radio", "boolean"].includes(col.type) ? false : col.type == "multipleSelect" ? [] : "" }), {}));
    };

    useEffect(() => {
        dispatch(getGlobalOperationalIndicators());
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setActiveRtable(RtablesDef.find(rt => rt.id === activeTab));
    }, [activeTab]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let isCanceled = false;
        fetch(isCanceled)
        window.history.replaceState(null, "Rtable", `#rt_${activeRtable.storeName}`);
        if (activeRtable.storeName == "costProfiles")
            dispatch(getAllEntityAgencies())
        else 
            if (activeRtable.storeName == "entityAgencies")
                dispatch(getAllAgencies())

        return () => {
            isCanceled = true;
        };
    }, [activeRtable]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        resetInstances();
    }, [rtOperationalList, rtFinancialList, rtGlobalList]); //eslint-disable-line react-hooks/exhaustive-deps

    const sortInstances = (a, b) => {
        return activeRtable.orderBy ? a[activeRtable.orderBy] - b[activeRtable.orderBy] : a.id - b.id;
    };

    const exportRtTables = () => {
        return dispatch(activeRtable.export());
    };

    const importFile = (data) =>
    {  
       return dispatch(activeRtable.import(data))
    }

    const downloadModel = () =>
    {
        return dispatch(activeRtable.export("model"))
    }

    const fetch = (isCanceled = false) =>{
        setLoading(true);
        dispatch(activeRtable.listAll()).then(
            (response) => {
                !isCanceled && setLoading(false);        
                setInstances(response.data);
            }
        );
    }
    return (

        <>
            <Grid container>
                <Grid item xs={12}>
                    <Paper className={classes.tabsContainer}>
                        <Tabs
                            value={activeTab}
                            onChange={changeActiveTab}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            ScrollButtonComponent={CustomTabScrollButton}
                        >
                            {RtablesDef.map(rt => <Tab label={t(rt.name)} style={{ textTransform: "none" }} key={rt.id}></Tab>)}
                        </Tabs>
                    </Paper>
                    <Box role="tabpanel" mt={4}>
                            <Grid container item xs={12} style={{justifyContent: "flex-end"}}>
                                <Grid item >
                                    <Import importFile={importFile} downloadModel = {downloadModel} fileName = {activeRtable.filename}/>
                                </Grid>
                                <Grid item >
                                    <Box style={{marginLeft:"4px"}}>
                                        <Export fileName={activeRtable.filename} action={exportRtTables}/>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box style={{marginLeft:"4px"}}>
                                        <Button
                                            color="primary"
                                            size="medium"
                                            className={classes.addButton}
                                            startIcon={<AddIcon />}
                                            onClick={openCreateForm}
                                            variant="outlined"
                                            hidden={create}
                                        >
                                            {t('add')}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <ListColsHeader
                                    type="columns"
                                    className={classes.tableHeader}
                                    columns={activeRtable.columns}
                                    onChange={fetch}
                                    getSort={ ({ financial }) => financial.rt.sort}
                                    setSort={activeRtable.setSort}

                                />
                            </Grid>
                            

                        <NoData condition={{...rtOperationalList, ...rtFinancialList, ...rtGlobalList}[activeRtable.storeName]?.length === 0 && !create} loading={loading} />

                        {
                            create && !loading
                                ? (
                                    <RtCreateCard
                                        rtable={activeRtable}
                                        setCreate={setCreate}
                                        instances={instances}
                                        setInstances={setInstances}
                                        resetInstances={resetInstances}
                                        instance={newInstance}
                                        setInstance={setNewInstance}
                                    />
                                )
                                : <></>
                        }{
                            loading
                                ? [...Array(3)].map((_, index) => (
                                    <Box key={index}>
                                        <Skeleton height={62.5} variant="rect" animation="wave" className={classes.skeleton} />
                                    </Box>
                                ))
                                : instances?.sort(sortInstances).map((item, index) =>
                                    <RtCard
                                        key={index}
                                        item={item}
                                        items={{...rtOperationalList, ...rtFinancialList, ...rtGlobalList}[activeRtable.storeName]}
                                        rtable={activeRtable}
                                        deleteModal={deleteModal}
                                        setDeleteModal={setDeleteModal}
                                        handleModalChange={handleModalChange}
                                        instances={instances}
                                        setInstances={setInstances}
                                        resetInstances={resetInstances}
                                        newInstance={newInstance}
                                        setNewInstance={setNewInstance}
                                    />)
                        }
                    </Box>
                </Grid>
            </Grid>

            <NotifModal {...deleteModal} />
        </>
    );
}

export default Rtables;