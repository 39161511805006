import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/MenuOutlined";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import clsx from "clsx";
import { toogleMenu } from "../../../appSlice.js"; 
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../style.js";
import i18n from "../../../../i18n/index.js";
import ViewSwitch from "../ViewSwitch/index.js";
import { useTranslation } from "react-i18next";
import { Avatar, CardActionArea, Grid, ListItemText } from "@material-ui/core";
import { logout } from "../../../../redux/actions/auth/index.js";
import { ArrowDropDown } from "@material-ui/icons";
import { infoNotification } from "../../../../utils/notification.js";
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TuneIcon from '@material-ui/icons/Tune';

const dataLang = [
    {
        flag: "fr",
        key: "fr",
        text: "Français",
        isImplemented: true,
    },
    {
        flag: "us",
        key: "us",
        text: "English",
        isImplemented: false
    },
];

const changeLanguage = (language, setAnchorLangEl) => {
    setAnchorLangEl(null);
    i18n.changeLanguage(language);
};

function HeaderToolbar({ setOpenMySettings }) {
    const classes = useStyles();
    const menuExpanded = useSelector(({ appState }) => appState.menuExpanded);
    const keycloak = useSelector(({ keycloak }) => keycloak);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [anchorNotifEl, setAnchorNotifEl] = React.useState(null);
    const [anchorLangEl, setAnchorLangEl] = React.useState(null);
    const [anchorProfileEl, setAnchorProfileEl] = React.useState(null);
    const headerExpanded = useSelector(({ appState }) => appState.headerExpanded);
    const { firstName, lastName } = useSelector(({ Auth }) => Auth.user);
    const avatarColor = useSelector(({ appState }) => appState.avatarColor);

    const profile = () => {
        return (
            <Grid item className="text-center">
                <IconButton onClick={(e) => setAnchorProfileEl(e.currentTarget)}>
                    <Badge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        badgeContent={<ArrowDropDown color="primary" />}
                    >
                        <Avatar style={{ backgroundColor: avatarColor }}>{`${firstName.charAt(0)}${lastName.charAt(0)}`}</Avatar>
                    </Badge>
                </IconButton>
            </Grid>
        );
    };

    return (
        <AppBar
            color="inherit"
            position={headerExpanded ? "relative" : "fixed"}
            className={clsx(
                classes.appBar,
                menuExpanded && classes.appBarShift,
                classes.appBarFolded
            )}
            elevation={24}
        >
            <Toolbar className={classes.toolbar}>
                <div className="align-items-center d-inline-flex">
                    <IconButton
                        edge="start"
                        color="primary"
                        aria-label="open Menu"
                        className={classes.menuButton}
                        onClick={() => dispatch(toogleMenu())}
                    >
                        <MenuIcon />
                    </IconButton>
                </div>
                <div className="align-items-center d-flex flex-column justify-content-center align-items-center">
                    <ViewSwitch />
                </div>
                <div className="align-items-center d-inline-flex">
                    <IconButton onClick={(e) => setAnchorLangEl(e.currentTarget)}>
                        <Typography className={`flag-icon flag-icon-${dataLang.find(({ key }) => key === i18n.language).flag} flag-icon-squared`} />
                    </IconButton>
                    <IconButton onClick={(e) => setAnchorNotifEl(e.currentTarget)}>
                        {/* <Badge badgeContent={4} color="secondary">
                            <NotificationsIcon />
                        </Badge> */}
                        <NotificationsIcon />
                    </IconButton>
                    {!headerExpanded && profile()}
                </div>
            </Toolbar>
            <Popover
                id={Boolean(anchorNotifEl) ? "notifications-popover" : undefined} // eslint-disable-line no-extra-boolean-cast
                open={Boolean(anchorNotifEl)}
                anchorEl={anchorNotifEl}
                onClose={() => setAnchorNotifEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Box p={2}>
                    <Typography variant="subtitle2" color="textSecondary">{t('no_notifications')}</Typography>
                </Box>
            </Popover>
            <Popover
                id="langage-popover"
                open={Boolean(anchorLangEl)}
                anchorEl={anchorLangEl}
                onClose={() => setAnchorLangEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {dataLang.map(({ flag, key, text, isImplemented }) => (
                    <CardActionArea
                        key={key}
                        onClick={() => isImplemented ? changeLanguage(key, setAnchorLangEl) : infoNotification("", `${t('the_language')} ${text} ${t('not_yet_implemented')}`)}
                        style={{
                            backgroundColor: key === i18n.language ? 'rgba(0,0,0,0.1)' : 'rgba(0,0,0,0)'
                        }}
                    >
                        <ListItemText
                            primary={
                                <span className="d-flex justify-content-between">
                                    <Typography className={`flag-icon flag-icon-${flag} flag-icon-squared`} />
                                    {text}
                                </span>
                            }
                            style={{
                                margin: "5px 10px",
                                width: "100px",
                                textAlign: "left",
                            }}
                        />
                    </CardActionArea>
                ))}
            </Popover>
            <Popover
                id="profile-popover"
                open={Boolean(anchorProfileEl)}
                anchorEl={anchorProfileEl}
                onClose={() => setAnchorProfileEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                PaperProps={{
                    style: {
                        padding: "6px 0"
                    }
                }}
            >
                <CardActionArea onClick={() => {
                    setAnchorProfileEl(null);
                    window.open(`${process.env.REACT_APP_NOVY_WEBAPP_URL}/collaborators/user`, '_blank');
                }}
                >
                    <ListItemText
                        primary={<Typography variant="body2"><PersonIcon fontSize="small" /> {t("my_novy_account")} </Typography>}
                        style={{
                            margin: "5px 10px",
                            minWidth: "150px",
                            textAlign: "left",
                        }}
                    />
                </CardActionArea>
                <CardActionArea onClick={() => {
                    setOpenMySettings(true);
                    setAnchorProfileEl(null);
                }}
                >
                    <ListItemText
                        primary={
                            <Typography variant="body2">
                                <TuneIcon fontSize="small" />{" "}
                                {t("my_settings")}
                            </Typography>
                        }
                        style={{
                            margin: "5px 10px",
                            minWidth: "150px",
                            textAlign: "left",
                        }}
                    />
                </CardActionArea>
                <CardActionArea onClick={() => dispatch(logout(keycloak))}>
                    <ListItemText
                        primary={<Typography variant="body2"><ExitToAppIcon fontSize="small" /> {t("logout")} </Typography>}
                        style={{
                            margin: "5px 10px",
                            minWidth: "150px",
                            textAlign: "left",
                        }}
                    />
                </CardActionArea>
            </Popover>
        </AppBar>
    );
}

export default HeaderToolbar;