import React, { useEffect } from 'react';
import { Grid, Typography, Tooltip, Menu, MenuItem, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useStyles from './style';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import SortIcon from '@material-ui/icons/Sort';

function ListColsHeader({ loading = false, type = "columns", columns, className, onChange, getSort, setSort, disableSort = false, isKanbanOrder = false }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const [sortAnchorEl, setSortAnchorEl] = React.useState(null);
    const sort = useSelector(state => (getSort && getSort(state)) || ({}));

    const dispatchSort = (s) => setSort && dispatch(setSort(s));

    const handleClick = (col) => {
        if(!sort.col){
            sort.type === "desc" ? dispatchSort({...sort, col, type: "asc"}) : dispatchSort({...sort, col, type: "desc"});
        } else if (sort.col.id === col.id) {
            sort.type === "desc" ? dispatchSort({...sort, type: "asc"}) : dispatchSort({...sort, type: "desc"});
        } else {
            dispatchSort({...sort, col: col, type: "desc"});
        }
    };

    const openSortMenu = (event) => {
        setSortAnchorEl(event.currentTarget);
    };
    
    const closeSortMenu = () => {
        setSortAnchorEl(null);
    };

    const renderColsAsColumns = () => {
        return (
            <Grid container className={className}>
                {columns.map(col =>
                    <Grid
                        key={col.id}
                        container
                        item
                        xs={col.width}
                        direction="row"
                        alignItems="center"
                        justify="center"
                        onClick={() => (!disableSort && col.sort) ? handleClick(col) : false}
                        className={(!disableSort && col.sort) ? classes.colNameContainer : ""}
                    >
                        <Grid item>
                            {
                                (!disableSort && col.sort)
                                    ? 
                                    <Tooltip
                                        title={sort.col && (sort.col.id === col.id) ? `${t('sorted_by')} ${t(col.name)} • ${sort.type.toUpperCase()}` : `${t('sort_by')} ${t(col.name)}`}
                                        placement="top"
                                    >
                                        <Typography variant="overline" color="textSecondary" className={classes.colName}>
                                            {t(col.name)}
                                        </Typography>
                                    </Tooltip>
                                    : <Typography variant="overline" color="textSecondary" className={classes.colName}>
                                        {t(col.name)}
                                    </Typography>
                            }

                        </Grid>

                        {
                            (!disableSort && col.sort) && <Grid item>
                                <Grid container direction="column" spacing={0}>
                                    <Grid item>
                                        {
                                            sort.col && (sort.col.id === col.id) && sort.type === "asc"
                                                ? <ArrowDropUpIcon className={clsx(classes.sortUpIcon, classes.activeSort)} />
                                                : <ArrowDropUpIcon className={clsx(classes.sortUpIcon, classes.inactiveSort)} />
                                        }

                                    </Grid>
                                    <Grid item>
                                        {
                                            sort.col && (sort.col.id === col.id) && sort.type === "desc"
                                                ? <ArrowDropDownIcon className={clsx(classes.sortDownIcon, classes.activeSort)} />
                                                : <ArrowDropDownIcon className={clsx(classes.sortDownIcon, classes.inactiveSort)} />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                )}

            </Grid>
        );
    };

    const renderColsAsMenu = () => {
        return (
            !disableSort && 
            <>
                <Tooltip
                    title={t("sort_by")}
                    placement="top"
                    arrow
                >
                    <IconButton 
                        className={classes.iconButtonNoHoverBG}
                        disableRipple
                        onClick={openSortMenu}
                    >
                        <SortIcon fontSize="default" />
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={sortAnchorEl}
                    keepMounted
                    open={Boolean(sortAnchorEl)}
                    onClose={closeSortMenu}
                    PaperProps={{
                        style: {
                            minWidth: '20ch',
                        },
                    }}
                >
                    {
                        columns.filter(col => col.sort).map((col) => (
                            <MenuItem key={col.id} selected={sort.col && (col.id === sort.col.id)} onClick={() => handleClick(col)}>
                                <Grid 
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justify="space-between"
                                >
                                    <Grid item>
                                        {
                                            col.sort
                                                ? 
                                                <Tooltip
                                                    title={sort.col && (sort.col.id === col.id) ? `${t('sorted_by')} ${t(col.name)} • ${sort.type.toUpperCase()}` : `${t('sort_by')} ${t(col.name)}`}
                                                    placement="top"
                                                >
                                                    <Typography variant="overline" color="textSecondary" className={classes.colName}>
                                                        {t(col.name)}
                                                    </Typography>
                                                </Tooltip>
                                                : <Typography variant="overline" color="textSecondary" className={classes.colName}>
                                                    {t(col.name)}
                                                </Typography>
                                        }

                                    </Grid>

                                    <Grid item>
                                        <Grid container direction="column" spacing={0}>
                                            <Grid item>
                                                {
                                                    sort.col && (sort.col.id === col.id) && sort.type === "asc"
                                                        ? <ArrowDropUpIcon className={classes.activeSort} style={{fontSize: "1.4rem"}} />
                                                        : <ArrowDropUpIcon className={classes.inactiveSort} style={{fontSize: "1.4rem"}} />
                                                }

                                            </Grid>
                                            <Grid item style={{marginTop: "-17px"}}>
                                                {
                                                    sort.col && (sort.col.id === col.id) && sort.type === "desc"
                                                        ? <ArrowDropDownIcon className={classes.activeSort} style={{fontSize: "1.4rem"}} />
                                                        : <ArrowDropDownIcon className={classes.inactiveSort} style={{fontSize: "1.4rem"}} />
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        ))
                    }
                </Menu>
            </>
        );
    };

    useEffect(() => {
        if(!isKanbanOrder){
            const defaultSortCol = columns.find(c => c.id === 0);
            if(sort && sort.col && sort.col.id){
                dispatchSort({...sort, col: columns.find(c => c.id === sort.col.id) ?? defaultSortCol});
            } else if(!sort || !sort.col || !sort.col.apiName) {
                dispatchSort({...sort, col: defaultSortCol});
            }
        }
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(!loading){
            onChange && onChange();
        }
    }, [sort.col, sort.type]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        type === "menu" 
            ? renderColsAsMenu()
            : renderColsAsColumns()
    );
}

export default React.memo(ListColsHeader);