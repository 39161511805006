import { cloneDeep } from "lodash-es";
import {
    CLEAR_SELECTED_OPERATIONAL_PROJECT,
    GET_SELECTED_OPERATIONAL_PROJECT,
    SET_SELECTED_OPERATIONAL_PROJECT,
    SET_OPERATIONAL_PROJECTS,
    EXPORT_OPERATIONAL_PROJECTS,
    SET_OPERATIONAL_PROJECTS_PAGINATION,
    GET_OPERATIONAL_PROJECTS,
    GET_OPERATIONAL_SUBPROJECTS,
    SET_OPERATIONAL_SUBPROJECTS,
    SET_OPERATIONAL_SUBPROJECTS_PAGINATION,
    EXPORT_OPERATIONAL_PROJECT_DASHBOARD,
    CLEAR_OPERATIONAL_PROJECTS_PAGINATION,
    SET_OPERATIONAL_PROJECTS_GRID_VIEW,
    CLEAR_OPERATIONAL_SUBPROJECTS_PAGINATION,
    SET_OPERATIONAL_PROJECTS_FILTER,
    CLEAR_OPERATIONAL_PROJECTS_FILTER,
    SET_OPERATIONAL_PROJECTS_SORT,
    CLEAR_OPERATIONAL_PROJECTS_SORT,
    SET_OPERATIONAL_SUBPROJECTS_SORT,
    CLEAR_OPERATIONAL_SUBPROJECTS_SORT,
    SET_ALL_PROJECTS,
    GET_ALL_PROJECTS,
    UPDATE_OPERATIONAL_PROJECT,
    UPDATE_OPERATIONAL_SUB_PROJECT,
} from "../../constants";
import { OPERATIONAL_DASHBOARD_PROJECTS_ENDPOINT, OPERATIONAL_PROJECT_ACTIONS_ENDPOINT } from "../../constants/endpoint";
import { store } from "../../../../..";
import { updateIsError } from "../../../../../utils/updateIsError500";

const adjustFilter = (filter) => {
    let f = cloneDeep(filter);
    Object.keys(f).forEach(k => {
        if(f[k] === "" || f[k] === null || f[k] === undefined || (Array.isArray(f[k]) && f[k].length === 0) || (typeof f[k] === "object" && Object.keys(f[k]).length === 0)){
            delete f[k];
        }else{
            switch(k){
                case 'risks':
                    f[k] = f[k].join(',');
                    break;
                case 'types':
                    f[k] = f[k].join(',');
                    break;
                case 'clients':
                    f[k] = f[k].map(c => c.name).join(',');
                    break;
                case 'status':
                    f[k] = f[k].join(',');
                    break;
                default:
                    break;
            }
        }
    });
    return f;
};

export const getOperationalProjects = () => {
    return {
        type: GET_OPERATIONAL_PROJECTS,
        request: {
            url: OPERATIONAL_DASHBOARD_PROJECTS_ENDPOINT
        },
        meta: {
            onRequest: (request, requestAction, store) => {
                const state = store.getState();
                const pagination = state.operational.projects.config.pagination;
                const sort = state.operational.projects.list.sort;
                const filter = adjustFilter(state.operational.projects.list.filter);

                if(filter.search){
                    if(filter.favorites){
                        request.url = `${request.url}/search?name=${filter.search}`;
                        request.params = {
                            favorites: true,
                            parentOnly: false
                        };
                    }else{
                        request.url = `${request.url}/search?sort=${(sort.col && sort.col.apiName) || "id"},${sort.type}&page=${pagination.page}&size=${pagination.size}&name=${filter.search}`;
                        request.params = {
                            favorites: false,
                            parentOnly: filter.parentOnly
                        };
                    }
                }else{
                    if(filter.favorites){
                        request.params = {
                            favorites: true,
                            parentOnly: false
                        };
                    }else{
                        request.url = `${request.url}?sort=${(sort.col && sort.col.apiName) || "id"},${sort.type}&page=${pagination.page}&size=${pagination.size}`;
                        request.params = filter;
                    }
                }
                
                return request;
            },
            onSuccess: (response, requestAction, store) => {
                const isFavorite = store.getState().operational.projects.list.filter.favorites;
                const favorites = store.getState().favorites.favoriteprojects;
                let projects = [];
                let payload = {};
                
                if(isFavorite){

                    favorites.forEach(({id:favoriteId}, index) => {
                        const matchingProject = response.data.projects.find(({id:projectId}) => projectId ===favoriteId);
                        if (matchingProject) {
                          projects.push({ ...matchingProject, favoriteOrder: index });
                        }
                    });

                    payload = {
                        ...response.data,
                        page: 0,
                        size: projects.length,
                        maxLength: projects.length,
                        projects: projects
                    };
                }else{
                    projects = response.data.projects.map(p => ({ ...p, favoriteOrder: favorites.findIndex(f => f.id == p.id)}));
                    payload = {
                        ...response.data,
                        projects: projects
                    };
                }

                store.dispatch(setOperationalProjects(payload));

                return {
                    ...response,
                    data: payload
                };
            }
        }
    };
};

export const updateOperationalProject = (data) => {
    return {
        type: UPDATE_OPERATIONAL_PROJECT,
        payload: data
    };
};

export const updateOperationalSubProject = (data) => {
    return {
        type: UPDATE_OPERATIONAL_SUB_PROJECT,
        payload: data
    };
};

export const exportOperationalProjects = (format, isFavoritePage, selected = null) => {
    
    return {
        type: EXPORT_OPERATIONAL_PROJECTS,
        request: {
            url: `${OPERATIONAL_DASHBOARD_PROJECTS_ENDPOINT}/export?format=${format}&isFavorite=${isFavoritePage}`,
            responseType: 'arraybuffer'
        },
        meta: {
            onRequest: (request, requestAction, store) => {
                const state = store.getState();
                const filter = adjustFilter(state.operational.projects.list.filter);
                
                if (selected) {
                    filter.selected = selected.join(",");
                }
                request.params = filter;
                return request;
            },
            onSuccess: (response, requestAction, store) => {
                return response.data;
            }
        }
    }; 
};

export const exportOperationalProjectDashboard = (projectId, activeButtons, evolutionChartBase64, evolutionChartStartDate, evolutionChartEndDate) => {
    return {
        type: EXPORT_OPERATIONAL_PROJECT_DASHBOARD,
        request: {
            url: `${OPERATIONAL_DASHBOARD_PROJECTS_ENDPOINT}/${projectId}/exportPDF`,
            responseType: 'arraybuffer',
            method: 'post',
            data: {
                base64: evolutionChartBase64,
                startDate: evolutionChartStartDate,
                endDate: evolutionChartEndDate,
                activeButtons:activeButtons
            }
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                return response.data;
            }
        }
    };
};

export const getAllProjects = (applyFilter) => {
    return {
        type: GET_ALL_PROJECTS,
        request: {
            url: `${OPERATIONAL_PROJECT_ACTIONS_ENDPOINT}/projects`
        },
        meta: {
            onRequest: (request, requestAction, store) => {
                if (applyFilter) {
                    const filter = store.getState().operational.projects.list.filter;
                    request.params = adjustFilter(filter);
                }
                return request;
            },
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllProjects(response.data));
                return response;
            }
        }
    };
};

export const setOperationalProjects = (data) => {
    return {
        type: SET_OPERATIONAL_PROJECTS,
        payload: data
    };
};

export const getSelectedOperationalProject = (id) => {
    return {
        type: GET_SELECTED_OPERATIONAL_PROJECT,
        request: {
            url: `${OPERATIONAL_DASHBOARD_PROJECTS_ENDPOINT}/${id}`,
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setSelectedOperationalProject(response.data));
                return response;
            },
            onError: (error,requestAction, store) => {
                updateIsError(error, store)
                throw error;
            }
        }
    };
};

export const setSelectedOperationalProject = (data) => {
    return {
        type: SET_SELECTED_OPERATIONAL_PROJECT,
        payload: data
    };
};

export const getOperationalSubProjects = (projectId) => {
    return {
        type: GET_OPERATIONAL_SUBPROJECTS,
        request: {
            url: OPERATIONAL_DASHBOARD_PROJECTS_ENDPOINT,
        },
        meta: {
            onRequest: (request, requestAction, store) => {
                const state = store.getState();
                const pagination = state.operational.projects.config.subProjectsPagination;
                const sort = state.operational.projects.subProjects.sort;
                request.url = `${request.url}/${projectId}/subProjects?sort=${(sort.col && sort.col.apiName) || "id"},${sort.type}&page=${pagination.page}&size=${pagination.size}`;
                return request;
            },
            onSuccess: (response, requestAction, store) => {
                let subProjects = [];
                let payload = {};
                
                const favorites = store.getState().favorites.favoriteprojects;
                subProjects = response.data.subProjects.map(p => ({ ...p, favoriteOrder: favorites.findIndex(f => f.id == p.id)}));
                payload = {
                    ...response.data,
                    subProjects
                };
                store.dispatch(setOperationalSubProjects(payload));
                return response;
            }
        }
    };
};

export const setOperationalSubProjects = (data) => {
    return {
        type: SET_OPERATIONAL_SUBPROJECTS,
        payload: data
    };
};

export const setAllProjects = (data) => {
    return {
        type: SET_ALL_PROJECTS,
        payload: data
    };
};

export const clearOperationalProjectsPagination = () => {
    return {
        type: CLEAR_OPERATIONAL_PROJECTS_PAGINATION,
    };
};

export const clearSelectedOperationalProject = () => {
    return {
        type: CLEAR_SELECTED_OPERATIONAL_PROJECT
    };
};

export const setOperationalProjectsPagination = (pagination) => {
    return {
        type: SET_OPERATIONAL_PROJECTS_PAGINATION,
        payload: pagination
    };
};

export const setOperationalProjectsGridView = (gridView) => {
    return {
        type: SET_OPERATIONAL_PROJECTS_GRID_VIEW,
        payload: {
            gridView: gridView
        }
    };
};

export const setOperationalSubProjectsPagination = (pagination) => {
    return {
        type: SET_OPERATIONAL_SUBPROJECTS_PAGINATION,
        payload: pagination
    };
};

export const clearOperationalSubProjectsPagination = () => {
    return {
        type: CLEAR_OPERATIONAL_SUBPROJECTS_PAGINATION
    };
};

export const setOperationalProjectsFilter = (filter) => {
    return {
        type: SET_OPERATIONAL_PROJECTS_FILTER,
        payload: filter
    };
};

export const clearOperationalProjectsFilter = () => {
    return {
        type: CLEAR_OPERATIONAL_PROJECTS_FILTER
    };
};

export const setOperationalProjectsSort = (sort) => {
    return {
        type: SET_OPERATIONAL_PROJECTS_SORT,
        payload: sort
    };
};

export const clearOperationalProjectsSort = () => {
    return {
        type: CLEAR_OPERATIONAL_PROJECTS_SORT
    };
};

export const setOperationalSubProjectsSort = (sort) => {
    return {
        type: SET_OPERATIONAL_SUBPROJECTS_SORT,
        payload: sort
    };
};

export const clearOperationalSubProjectsSort = () => {
    return {
        type: CLEAR_OPERATIONAL_SUBPROJECTS_SORT
    };
};