import {getInitialPagination} from "../../../../../utils/common-state";
import {
    CLEAR_FINANCIAL_PROJECT_SALE_ORDERS,
    CLEAR_FINANCIAL_SALE_ORDER_ECHANCIERS_FILTER,
    CLEAR_FINANCIAL_SALE_ORDER_ECHANCIERS_PAGINATION,
    CLEAR_FINANCIAL_SALE_ORDER_ECHANCIERS_SORT,
    CLEAR_SELECTED_FINANCIAL_SALE_ORDER_ECHANCIER,
    SET_FINANCIAL_SALE_ORDER_ECHANCIERS,
    SET_FINANCIAL_SALE_ORDER_ECHANCIERS_FILTER,
    SET_FINANCIAL_SALE_ORDER_ECHANCIERS_PAGINATION,
    SET_FINANCIAL_SALE_ORDER_ECHANCIERS_SORT,
    SET_SELECTED_FINANCIAL_SALE_ORDER_ECHANCIER
} from "../../constants";

const initialState = {
    config: {
        pagination: getInitialPagination(false)
    },
    list: {
        sort: {
            col: { apiName: 'createdAt' },
            type: "desc"
        },
        filter: {
            search: "",
            status: [],
        },
        page: 0,
        size: getInitialPagination(false).size,
        echanciers: []
    },
    selected: null,
    saleOrders: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FINANCIAL_SALE_ORDER_ECHANCIERS:
            return setFinancialSaleOrderEchanciers(state, action.payload);
        case SET_SELECTED_FINANCIAL_SALE_ORDER_ECHANCIER:
            return setSelectedFinancialSaleOrderEchancier(state, action.payload);
        case SET_FINANCIAL_SALE_ORDER_ECHANCIERS_PAGINATION:
            return setFinancialSaleOrderEchanciersPagination(state, action.payload);
        case CLEAR_FINANCIAL_PROJECT_SALE_ORDERS:
            return clearFinancialSaleOrderEchanciers(state);
        case CLEAR_SELECTED_FINANCIAL_SALE_ORDER_ECHANCIER:
            return clearSelectedFinancialSaleOrderEchanciers(state);
        case CLEAR_FINANCIAL_SALE_ORDER_ECHANCIERS_PAGINATION:
            return clearFinancialSaleOrderEchanciersPagination(state);
        case SET_FINANCIAL_SALE_ORDER_ECHANCIERS_SORT:
            return setSaleOrderEchanciersSort(state, action.payload);
        case CLEAR_FINANCIAL_SALE_ORDER_ECHANCIERS_SORT:
            return clearSaleOrderEchanciersSort(state);
        case SET_FINANCIAL_SALE_ORDER_ECHANCIERS_FILTER:
            return setSaleOrderEchanciersFilter(state, action.payload);
        case CLEAR_FINANCIAL_SALE_ORDER_ECHANCIERS_FILTER:
            return clearSaleOrderEchanciersFilter(state);
        default: return state;
    }
};

const setFinancialSaleOrderEchanciers = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: {
                ...state.config.pagination,
                page: parseInt(payload.page),
                size: parseInt(payload.size),
                maxLength: parseInt(payload.maxLength)
            }
        },
        list: {
            ...state.list,
            page: parseInt(payload.page),
            size: parseInt(payload.size),
            echanciers: payload.echanciers
        }
    };
};

const setSelectedFinancialSaleOrderEchancier = (state, payload) => {
    return {
        ...state,
        selected: payload
    };
};

const setFinancialSaleOrderEchanciersPagination = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: payload
        }
    };
};

const clearFinancialSaleOrderEchanciers= (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            page: initialState.list.page,
            size: initialState.list.size,
            echanciers: initialState.list.echanciers
        }
    };
};

const clearSelectedFinancialSaleOrderEchanciers = (state) => {
    return {
        ...state,
        selected: initialState.selected
    };
};

const clearFinancialSaleOrderEchanciersPagination = (state) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: getInitialPagination(false)
        }
    };
};

const setAllSaleOrders = (state, payload) => {
    return {
        ...state,
        saleOrders: payload
    };
};

const setSaleOrderEchanciersSort = (state, sort) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: sort
        }
    };
};

const clearSaleOrderEchanciersSort = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: initialState.list.sort
        }
    };
};

const setSaleOrderEchanciersFilter = (state, filter) => {
    return {
        ...state,
        list: {
            ...state.list,
            filter: filter
        }
    };
};

const clearSaleOrderEchanciersFilter = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            filter: initialState.list.filter
        }
    };
};