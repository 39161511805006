import { onError, onSuccess } from "../../../utils/http";
import { errorNotification, successNotification } from "../../../utils/notification";
import {
    GET_ALL_RESPONSABLES,
    GET_CURRENT_COLLABORATOR,
    SET_ALL_RESPONSABLES,
    SET_CURRENT_COLLABORATOR,
    PUT_COLLABORATOR,
    PUT_COLLABORATOR_ACCESS_CONTROL
} from "../../constants";
import { ROOT_ENDPOINT } from "../../constants/endpoint";

export const getAllResponsables = () => {
    return {
        type: GET_ALL_RESPONSABLES,
        request: {
            url: `${ROOT_ENDPOINT}/collaborators/responsables`,
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setAllResponsables(response.data));
                return response;
            })
        },
    };
};

export const getCurrentCollaborator = (email) => {
    return {
        type: GET_CURRENT_COLLABORATOR,
        request: {
            url: `${ROOT_ENDPOINT}/collaborators/get`,
            params: {
                email: email
            }
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setCurrentCollaborator(response.data));
                return response;
            })
        }
    };
};

export const putCollaborator = (data) => {
    return {
        type: PUT_COLLABORATOR,
        request: {
            url: `${ROOT_ENDPOINT}/collaborators/${data.id}`,
            method: "put",
            data: data
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "update_success");
                return response;
            }),
            onError: onError(({ response }) => {
                errorNotification('', "update_error");
                return response;
            })
        }
    };
};

export const putCollaboratorAccessControl = (data) => {
    return {
        type: PUT_COLLABORATOR_ACCESS_CONTROL,
        request: {
            url: `${ROOT_ENDPOINT}/collaborators/${data.id}/accessControl`,
            method: "put",
            data: data
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "update_success");
                return response;
            }),
            onError: onError(({ response }) => {
                errorNotification('', "update_error");
                return response;
            })
        }
    };
};

export const setAllResponsables = (data) => {
    return {
        type: SET_ALL_RESPONSABLES,
        payload: data,
    };
};

export const setCurrentCollaborator = (data) => {
    return {
        type: SET_CURRENT_COLLABORATOR,
        payload: data
    };
};