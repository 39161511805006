import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    CardActions,
    Grid,
    Typography,
    IconButton,
    TextField,
    Box,
    Tooltip,
    Button,
    Checkbox,
    Radio,
    InputAdornment,
    Chip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListItemText,
    OutlinedInput
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useDispatch , useSelector } from "react-redux";
import getColorForPercent from '../../../../../utils/getColorForPercent';
import {columns} from './columns';
import { clearSelectedCostProfiles, getCostProfilesByCodes} from "../../../../../views/Financial/redux/actions/rt";
import CostProfileHistoryDrawer from '../../../../components/HistoryDrawer';
import useStyles from './style';
import moment from "moment";
import { roundCharges } from "../../../../../utils/format";

export function RtCard({
    item,
    rtable,
    deleteModal,
    setDeleteModal,
    handleModalChange,
    instances,
    setInstances,
    resetInstances,
    newInstance,
    setNewInstance
}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const [edit, setEdit] = useState(false);
    const [instance, setInstance] = useState(item);
    const [errors, setErrors] = useState(rtable.columns.reduce((obj, col) => ({ ...obj, [col.id]: false }), {}));
    const [oldInstance, setOldInstance] = useState(item);
    const [disabled, setDisabled] = useState(false);
    const openEdit = () => setEdit(true);
    var costProfileHistory = useSelector(({ financial }) => financial.rt.selectedCostProfile);
    const entiteAgencyList = useSelector(({ rt }) => rt.entityAgencies)
    const rtAgencyList = useSelector(({ rt }) => rt.agencies)

    const devise = "€"
    const [extractedData, setExtractedData] = useState([]);
    const [opened, setOpened] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cleanInstantState, setCleanInstantState] = useState(instance);

    useEffect(() => {
        if(opened){
            setLoading(true)
            dispatch(getCostProfilesByCodes(instance.code)).then(()=>{
                setLoading(false)
            });
        }
    }, [opened]);

    useEffect(()=>{
        const extractData = costProfileHistory.map(cost => ({
            label: cost.label,
            cjm:roundCharges(cost.cjm),
            tjm:roundCharges(cost.tjm),
            tjmPlancher:roundCharges(cost.tjmPlancher),
            lowerCostThreshold:roundCharges(cost.lowerCostThreshold),
            upperCostThreshold:roundCharges(cost.upperCostThreshold),
            active: cost.active ? t("activeProfile") : t("inactiveProfile"),
            entity: cost.entityAgency?.label,
            createdAt: moment(cost.createdAt).format('DD/MM/YYYY - HH:mm:ss'),
            createdBy: cost.createdBy
        }));
        setExtractedData(extractData)
    },[costProfileHistory])

    const showCostHistory = () => {
        setOpened(prevState => !prevState);
    };

    const cancelEdit = () => {
        setInstance(cleanInstantState)
        setEdit(false);
        resetInstances();
    };

    const saveEdit = () => {
        if (rtable.columns.some(col => col.type === "radio")) {
            instances.forEach(inst => {
                dispatch(rtable.put(inst.id, inst)).then(() => {

                });
            });
        } else {
            if(JSON.stringify(instance) !== JSON.stringify(oldInstance))
                dispatch(rtable.put(item.id, instance)).then((res)=>{
                    setCleanInstantState(res.data)
            });
        }
        setEdit(false);
    };

    const handleDelete = () => {
        setDeleteModal({
            ...deleteModal,
            open: true,
            type: "error",
            title: t('suppression'),
            body: <span>{t('confirm_delete')} <b>{t(rtable.name)} {t(rtable.columns[0].percent ? `${item[rtable.columns[0].apiName] * 100}%` : item[rtable.columns[0].apiName])}</b> ?</span>,
            showCloseButton: true,
            onChange: handleModalChange,
            cancel: {
                title: t('cancel'),
                onClick: null
            },
            confirm: {
                title: t('confirm'),
                onClick: () => {
                    dispatch(rtable.delete(item.id));
                    handleModalChange(false);
                }
            }
        });
    };

    const onRadioChange = (e, col) => {
        if (e.target.checked && !!newInstance) {
            setNewInstance({ ...newInstance, [col.apiName]: false });
        }
        setInstances([...instances].map(i => ({ ...i, [col.apiName]: i.id === instance.id ? e.target.checked : (e.target.checked ? !(e.target.checked) : i[col.apiName]) })));
    };

    const clearExtractedData = () =>{
        dispatch(clearSelectedCostProfiles());
    }

    const renderRtable = (col) => {
        switch (col.type) {
            case "boolean":
                if (edit) {
                    return (
                        <Checkbox
                            checked={instance[col.apiName]}
                            color="primary"
                            onChange={e => {
                                setInstance({ ...instance, [col.apiName]: e.target.checked });
                            }}
                        />
                    );
                }

                return (
                    <Typography> {instance[col.apiName] ? "Oui" : "Non"} </Typography>
                );
            case "list":
                if (edit) {
                    return (

                            <TextField
                                id="entité"
                                select
                                fullWidth
                                label="Entité"
                                variant="outlined"
                                size="small"
                                value={instance[col.apiName] ? instance[col.apiName]?.id : ""}
                                onChange={(e) =>{setInstance({ ...instance, [col.apiName]: entiteAgencyList.find(v => v.id === e.target.value) })}}
                            >
                                {entiteAgencyList.map(v=><MenuItem value={v.id} key={v.id}>{v.label}</MenuItem>)}
                            </TextField>
                    );
                }

                return (
                    <Typography> {instance[col.apiName]?.label} </Typography>
                );
            case "radio":
                if (edit) {
                    return (
                        <Radio
                            checked={instance[col.apiName]}
                            color="primary"
                            onChange={e => onRadioChange(e, col)}
                        />
                    );
                }

                return (
                    instance[col.apiName]
                    && <CheckCircleIcon color="primary" fontSize="small" />
                );
            case "multipleSelect":
                if (edit) {
                    return (
                        <FormControl size="small" variant="outlined" className={classes.multipleSelect}>
                            <InputLabel id="multiple">{t("agencies")}</InputLabel>
                            <Select
                                multiple  
                                value={instance[col.apiName].map(v => v.id)}
                                label={t("agencies")}
                                onChange={(e) => {
                                    const selectedAgencyIds = e.target.value;
                                    const selectedAgencies = rtAgencyList.filter(agency => selectedAgencyIds.includes(agency.id));
                                    setInstance({ ...instance, [col.apiName]:selectedAgencies})
                                
                                }}
                                renderValue={(selected) => rtAgencyList.filter(agency => selected.includes(agency.id)).map(v => v.label).join(", ")}
                            >
                            {rtAgencyList.map((agency, i) => (
                                <MenuItem key={i} value={agency.id}>
                                    <Checkbox checked={instance[col.apiName]?.map(v => v.id).indexOf(agency.id) > -1 } />
                                    <ListItemText primary={agency.label} />
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    );
                }

                return (
                    <Typography> {instance[col.apiName]?.map((v, key) => v.label + ( (key != instance[col.apiName].length - 1) ? " - " : "" ))} </Typography>
                );
            case "chip":
                if(!edit){
                    return (
                        instance[col.apiName]
                        && <Chip label={"V"+instance[col.apiName]} variant="default"  size="small" style={{ opacity: 0.5 }} color="primary"  onClick={ () => col.onclick && showCostHistory()}/>
                    );
                }
                break;  
            case "number":
                if (edit) {
                    if (col.percent) {
                        return (
                            <Box mr={1}>
                                <TextField
                                    type={col.type}
                                    defaultValue={instance[col.apiName]}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    error={errors[col.id]}
                                    InputProps={{
                                        endAdornment: <InputAdornment classes={{ root: classes.endAdornment }} position="end">%</InputAdornment>,
                                    }}
                                    onChange={e => {
                                        setInstance({ ...instance, [col.apiName]: e.target.value });
                                        !col.optional && setErrors({ ...errors, [col.id]: e.target.value === "" || e.target.value < 0 || e.target.value > 100 });
                                    }}
                                />
                            </Box>
                        );
                    }

                    return (
                        <Box mr={1}>
                            <TextField
                                type={col.type}
                                defaultValue={instance[col.apiName]}
                                variant="outlined"
                                size="small"
                                fullWidth
                                error={errors[col.id]}
                                onChange={e => {
                                    setInstance({ ...instance, [col.apiName]: e.target.value });
                                    !col.optional && setErrors({ ...errors, [col.id]: e.target.value === "" });
                                }}
                            />
                        </Box>
                    );
                }

                if (col.percent) {
                    return (
                        <Typography> {instance[col.apiName]}% </Typography>
                    );
                }

                return (
                    <Typography> {col.isAmount && instance[col.apiName] ?  instance[col.apiName].toLocaleString('fr-FR')+" "+devise : instance[col.apiName]} </Typography>
                );
            default:
                if (edit) {
                    return (
                        <Box mr={1}>
                            <TextField
                                type={col.type}
                                defaultValue={instance[col.apiName]}
                                variant="outlined"
                                size="small"
                                fullWidth
                                error={errors[col.id]}
                                onChange={e => {
                                    setInstance({ ...instance, [col.apiName]: e.target.value });
                                    !col.optional && setErrors({ ...errors, [col.id]: e.target.value === "" });
                                }}
                            />
                        </Box>
                    );
                }

                return (
                    <Typography> {instance[col.apiName]} </Typography>
                );
        }
    };

    useEffect(() => {
        setInstance(item);
        setOldInstance(item)
    }, [item]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setDisabled(!Object.values(errors).every(e => e === false));
    }, [errors]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card
            style={rtable.hasColor ? { borderLeft: `5px solid ${getColorForPercent(instance.orderPercent)}` } : {}}
            className={classes.root}
            elevation={1}
        >
            <Grid container alignItems="center">
                <Grid item xs={12}>
                    <CardContent className={classes.content} style={{ padding: "8px" }}>
                        {
                            rtable.columns.map(col =>
                                <Grid container item xs={col.width} key={col.id} alignItems="center" justify="center">
                                    <Grid item xs={col.fullWidth ? 12 : undefined}>
                                        <Box textAlign="center">
                                            {renderRtable(col)}
                                        </Box>
                                    </Grid>
                                </Grid>
                            )
                        }
                        <Grid container xs={2} alignItems="center" justify="center">
                        <Grid item>
                            <Tooltip title={edit ? t('save_quick_edit') : t('edit')} placement="top">
                                {
                                    edit
                                        ? (
                                            <IconButton
                                                color="primary"
                                                onClick={saveEdit}
                                                disabled={disabled}
                                            >
                                                <DoneIcon />
                                            </IconButton>
                                        )
                                        : (
                                            <IconButton onClick={openEdit}>
                                                <EditIcon />
                                            </IconButton>
                                        )
                                }

                            </Tooltip>
                        </Grid>
                        {
                            edit &&
                            <Grid item>
                                <Tooltip title={t('cancel')} placement="top">
                                    <IconButton onClick={cancelEdit}>
                                        <ClearIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                        <Grid item>
                            <Tooltip title={t('delete')} placement="top">
                                <IconButton onClick={handleDelete}>
                                    <DeleteIcon className={classes.deleteIcon} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    </CardContent>
                    
                </Grid>
                    
            </Grid>
            {  opened &&
                <CostProfileHistoryDrawer 
                    loading={loading}
                    opened={opened}
                    setOpened={setOpened}
                    data={extractedData}
                    clearData={clearExtractedData}
                    columns={columns}
                />
            }
        </Card>
        
    );
}

export function RtCreateCard({ rtable, setCreate, instances, setInstances, resetInstances, instance, setInstance }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const [errors, setErrors] = useState(rtable.columns.reduce((obj, col) => ({ ...obj, [col.id]: !col.optional && col.type !== "boolean" && col.type !== "radio" }), {}));
    const [disabled, setDisabled] = useState(true);
    const entiteAgencyList = useSelector(({ rt }) => rt.entityAgencies)
    const rtAgencyList = useSelector(({ rt }) => rt.agencies)

    const save = () => {
        if (rtable.columns.some(col => col.type === "radio")) {
            instances.forEach(inst => {
                dispatch(rtable.put(inst.id, inst)).then(() => {
                });
            });
        }
        dispatch(rtable.post(instance));
        setCreate(false);
    };

    const onRadioChange = (e, col) => {
        setInstance({ ...instance, [col.apiName]: e.target.checked });
        if (e.target.checked) {
            setInstances([...instances].map(i => ({ ...i, [col.apiName]: false })));
        }
    };

    const onCancelClick = () => {
        resetInstances();
        setCreate(false);
    };

    const renderRtable = (col) => {
        switch (col.type) {
            case "boolean":
                return (
                    <Checkbox
                        defaultChecked={false}
                        color="primary"
                        onChange={e => {
                            setInstance({ ...instance, [col.apiName]: e.target.checked });
                        }}
                    />
                );
            case "radio":
                return (
                    <Radio
                        checked={instance[col.apiName]}
                        color="primary"
                        onChange={e => onRadioChange(e, col)}
                    />
                );
            case "number":
                if (col.percent) {
                    return (
                        <TextField
                            type={col.type}
                            placeholder={t(col.name)}
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment classes={{ root: classes.endAdornment }} position="end">%</InputAdornment>
                            }}
                            onChange={e => {
                                setInstance({ ...instance, [col.apiName]: e.target.value });
                                !col.optional && setErrors({ ...errors, [col.id]: e.target.value === "" || e.target.value < 0 || e.target.value > 100 });
                            }}
                        />
                    );
                }

                return (
                    <TextField
                        type={col.type}
                        placeholder={t(col.name)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={e => {
                            setInstance({ ...instance, [col.apiName]: e.target.value });
                            !col.optional && setErrors({ ...errors, [col.id]: e.target.value === "" });
                        }}
                    />
                );

            case "chip":
                return(
                    <TextField
                        hidden
                        size="small"
                        value={1}
                        onChange={e => {
                            setInstance({ ...instance, [col.apiName]: e.target.value });
                            !col.optional && setErrors({ ...errors, [col.id]: e.target.value === "" });
                        }}
                    />);

            case "list":
                return(
                    <TextField
                        id="entité"
                        select
                        fullWidth
                        label="Entité"
                        variant="outlined"
                        size="small"
                        value={instance[col.apiName] ? instance[col.apiName]?.id : ""}
                        onChange={(e) =>{
                            setInstance({ ...instance, [col.apiName]: entiteAgencyList.find(v => v.id === e.target.value) });
                            !col.optional && setErrors({ ...errors, [col.id]: e.target.value === "" });
                        }}
                    >
                        {entiteAgencyList.map(v=><MenuItem value={v.id} key={v.id}>{v.label}</MenuItem>)}
                    </TextField>
                )
            case "multipleSelect":
                return (
                    <FormControl size="small" variant="outlined" className={classes.multipleSelect}>
                        <InputLabel id="multiple">{t("agencies")}</InputLabel>
                        <Select
                            multiple  
                            value={instance[col.apiName] || []}
                            label={t("agencies")}
                            onChange={(e) => setInstance({ ...instance, [col.apiName]: e.target.value })}
                            renderValue={(selected) => selected.map(v => v.label).join(", ")}
                        >
                        {rtAgencyList.map((agency, i) => (
                            <MenuItem key={i} value={agency}>
                                <Checkbox checked={instance[col.apiName]?.map(v => v.label).indexOf(agency.label) > -1 } />
                                <ListItemText primary={agency.label} />
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    );
                
            default:
                return (
                    <TextField
                        type={col.type}
                        placeholder={t(col.name)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={e => {
                            setInstance({ ...instance, [col.apiName]: e.target.value });
                            !col.optional && setErrors({ ...errors, [col.id]: e.target.value === "" });
                        }}
                    />
                );
        }
    };

    useEffect(() => {
        setDisabled(!Object.values(errors).every(e => e === false));
    }, [errors]);

    useEffect(() => {
        return () => {
            setInstance(null);
        };
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        instance &&
        <Card
            className={classes.root}
            elevation={1}
        >
            <Grid container alignItems="center">
                <Grid item xs={12}>
                    <CardContent className={classes.content} style={{ padding: "8px" }}>
                        {
                            rtable.columns.map(col =>
                                <Grid container item key={col.id} xs={col.width} alignItems="center" justify="center">
                                    <Grid item xs={col.fullWidth ? 12 : undefined}>
                                        <Box mr={1} textAlign="center">
                                            {renderRtable(col)}
                                        </Box>
                                    </Grid>
                                </Grid>
                            )
                        }
                        <Grid container alignItems="center" justify="center" xs={2}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        onClick={save}
                                        disabled={disabled}
                                    >
                                        {t('save')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Tooltip title={t('cancel')} placement="top">
                                        <IconButton onClick={onCancelClick}>
                                            <ClearIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                        </Grid>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
}


