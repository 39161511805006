import {
    CLEAR_PROJECT_FINANCIAL_INDICATORS,
    SET_GLOBAL_FINANCIAL_INDICATORS,
    SET_PROJECT_FINANCIAL_INDICATORS
} from "../../constants";


const initialState = {
    global: {
        CAVAMensuel: 0,
        MBthMensuel: 0,
        MBthCumule: 0
    },
    project: {
        id: 0,
        type: "---",
        startDate: "JJ-MM-AAAA",
        endDate: "JJ-MM-AAAA",
        caProduit: 0,
        caVendu: 0,
        coutConsomme: 0,
        coutActualise:0,
        caFacture:0,
        risques: 0,
        fnNotes: "",
        tjmProjet: 0,
        opChargeVendue: 0,
        opChargeConsommee: 0,
        opChargeProduite: 0,
        opChargeActualisee: 0,
        opRaf: 0,
        marge: 0,
        margePercent: 0,
        margeMensuel: 0,
        margeMensuelPercent: 0
    }
};

export default (state = initialState, action) => {
    switch(action.type){
        case SET_GLOBAL_FINANCIAL_INDICATORS:
            return setGlobalFinancialIndicators(state, action.data);
        case SET_PROJECT_FINANCIAL_INDICATORS:
            return setProjectFinancialIndicators(state, action.data);
        case CLEAR_PROJECT_FINANCIAL_INDICATORS:
            return clearProjectIndicators(state);
        default:
            return state;
    }
};

const setGlobalFinancialIndicators = (state, data) => {
    return {
        ...state,
        global: data
    };
};

const setProjectFinancialIndicators = (state, data) => {
    return {
        ...state,
        project: data
    };
};

const clearProjectIndicators = (state) => {
    return {
        ...state,
        project: initialState.project
    };
};
