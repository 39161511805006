import { makeStyles } from "@material-ui/core/styles";


export default makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(3),
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    title: {
        fontSize: "1.8rem",
        color: theme.palette.primary.main,
        fontWeight: "500",
    },
    activeButton: {
        backgroundColor: theme.palette.primary.main,
        color: "white !important",
        cursor: "default",
        pointerEvents: "none",
        marginLeft: theme.spacing(0.5),
        textTransform: "none",
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        }
    },
    outlinedButton: {
        backgroundColor: "white",
        border: "2px solid",
        marginLeft: theme.spacing(0.5),
        textTransform: "none",
        "&:hover": {
            border: "2px solid",
        }
    },
    sectionContainer: {
        paddingTop: theme.spacing(2)
    }
}));