import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    colName: {
        fontWeight: "bold",
        color: theme.palette.primary.dark
    },
    colNameContainer: {
        cursor: "pointer"
    },
    activeSort: {
        color: theme.palette.primary.dark
    },
    inactiveSort: {
        color: "#d1d1d1"
    },
    sortUpIcon: {
        marginBottom: "-13px",
        fontSize: "1.4rem"
    },
    sortDownIcon: {
        marginTop: "-18px",
        fontSize: "1.4rem"
    }
}));
