import { createSlice } from "@reduxjs/toolkit";
import { getRandomAvatarColor } from "../utils/getRandomAvatarColor";

const appSlice = createSlice({
    name: "appState",
    initialState: {
        screenMedia: "lg",
        menuExpanded: false,
        headerExpanded: true,
        isFinancialView: window.location.pathname.startsWith("/financial"),
        pageTitle: null,
        activeSection: null,
        includeSubProjectsCalc: false,
        isError500: false,
        isError403: false,
        themeColor: {
            financial: {
                hex: "#266A74",
                rgb: { r: 38, g: 106, b: 116, a: 1 }
            },
            operational: {
                hex: "#375777",
                rgb: { r: 55, g: 87, b: 119, a: 1 }
            }
        },
        avatarColor: getRandomAvatarColor(),
        yearInvoicingType: "months"
    },
    reducers: {
        setScreenMedia: (state, action) => ({ ...state, screenMedia: action.payload}),
        toogleMenu: (state) => ({ ...state, menuExpanded: !state.menuExpanded }),
        toggleView: (state) => ({ ...state, isFinancialView: !state.isFinancialView }),
        setIsFinancialView: (state, action) => ({ ...state, isFinancialView: Boolean(action.payload)}),
        setHeaderExpanded: (state, action) => ({
            ...state,
            headerExpanded: action.payload,
        }),
        setYearInvoicingType: (state, action) => ({ ...state, yearInvoicingType: action.payload }),
        clearPageTitle: (state) => ({ ...state, pageTitle: null }),
        setPageTitle: (state, action) => ({ ...state, pageTitle: action.payload }),
        setThemeColor: (state, action) => ({ ...state, themeColor: action.payload }),
        setActiveSection: (state, action) => ({ ...state, activeSection: action.payload }),
        setIncludeSubProjectsCalc: (state, action) => ({...state, includeSubProjectsCalc: action.payload}),
        clearIncludeSubProjectsCalc: (state) => ({ ...state, includeSubProjectsCalc: false }),
        clearActiveSection: (state) => ({ ...state, activeSection: 1 }),
        clearSnapshotID: (state) => ({ ...state, snapshotID: null }),
        setIsError500: (state) => ({ ...state, isError500: true}),
        clearIsError500: (state) => ({...state, isError500: false}),
        setIsError403: (state) => ({ ...state, isError403: true}),
        clearIsError403: (state) => ({...state, isError403: false})
    },
});

export const {
    setScreenMedia,
    toogleMenu,
    setHeaderExpanded,
    toggleView,
    setIsFinancialView,
    clearPageTitle,
    setPageTitle,
    setThemeColor,
    setActiveSection,
    clearActiveSection,
    setIncludeSubProjectsCalc,
    clearIncludeSubProjectsCalc,
    clearSnapshotID,
    setYearInvoicingType,
    setIsError500,
    clearIsError500,
    setIsError403,
    clearIsError403
} = appSlice.actions;

export default appSlice.reducer;
