import {
    GET_ALL_OPERATIONAL_RT_PROJECT_PERIMETRE,
    POST_OPERATIONAL_RT_PROJECT_PERIMETRE,
    SET_ALL_OPERATIONAL_RT_PROJECT_PERIMETRE,
    PUT_OPERATIONAL_RT_PROJECT_PERIMETRE,
    DELETE_OPERATIONAL_RT_PROJECT_PERIMETRE,
    EXPORT_ALL_OPERATIONAL_RT_PROJECT_PERIMETRE,
    IMPORT_ALL_OPERATIONAL_RT_PROJECT_PERIMETRE
} from '../../constants';
import {errorNotification, successNotification} from "../../../../../utils/notification";
import { OPERATIONAL_RT_ENDPOINT } from '../../constants/endpoint';
import {popUpError} from "./popUpError";

export const getAllOperationalProjectPerimetre = () => {
    return {
        type: GET_ALL_OPERATIONAL_RT_PROJECT_PERIMETRE,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/projectPerimetre`
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllOperationalProjectPerimetre(response.data));
                return response;
            }
        }
    };
};

export const setAllOperationalProjectPerimetre = (data) => {
    return {
        type: SET_ALL_OPERATIONAL_RT_PROJECT_PERIMETRE,
        payload: data
    };
};

export const postOperationalProjectPerimetre = (projectPerimetre) => {
    return {
        type: POST_OPERATIONAL_RT_PROJECT_PERIMETRE,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/projectPerimetre`,
            method: 'post',
            data: projectPerimetre
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "create_success");
                store.dispatch(getAllOperationalProjectPerimetre());
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "create_error");
                throw error;
            }
        }
    };
};

export const putOperationalProjectPerimetre = (id, projectPerimetre) => {
    return {
        type: PUT_OPERATIONAL_RT_PROJECT_PERIMETRE,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/projectPerimetre/${id}`,
            method: 'put',
            data: projectPerimetre
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "update_success");
                store.dispatch(getAllOperationalProjectPerimetre());
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "update_error");
                throw error;
            }
        }
    };
};

export const deleteOperationalProjectPerimetre = (id) => {
    return {
        type: DELETE_OPERATIONAL_RT_PROJECT_PERIMETRE,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/projectPerimetre/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "delete_success");
                store.dispatch(getAllOperationalProjectPerimetre());
                return response;
            },
            onError: (error, requestAction, store) => {
              popUpError(error,"delete_project_perimetre_error");
            }
        }
    };
};
export const exportprojectPerimetre = (type) => {
    return {
        type: EXPORT_ALL_OPERATIONAL_RT_PROJECT_PERIMETRE,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/projectPerimetre/export`,
            responseType: 'arraybuffer'
        },
        meta: {
            onRequest: (request) => {
                if(type)
                    request.url=request.url+"?type="+type
                return request;
            },
            onSuccess: (response) => {
                return response.data;
            }
        }
    };
};
export const importprojectPerimetre = (data) => {
    return {
        type: IMPORT_ALL_OPERATIONAL_RT_PROJECT_PERIMETRE,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/projectPerimetre/import`,
            method:"post",
            data:data
        },
        meta: {
            onSuccess: (response,requestAction,store) => {
                store.dispatch(getAllOperationalProjectPerimetre());
                return response.data;
            }
        }
    };
};
