import {
    GET_ALL_RT_PROJECT_STATUS,
    SET_ALL_RT_PROJECT_STATUS,
} from '../../constants';

import { RT_ENDPOINT } from '../../constants/endpoint';

export const getAllProjectStatus = () => {
    return {
        type: GET_ALL_RT_PROJECT_STATUS,
        request: {
            url: `${RT_ENDPOINT}/projectStatus`
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllProjectStatus(response.data));
                return response;
            }
        }
    };
};

export const setAllProjectStatus = (data) => {
    return {
        type: SET_ALL_RT_PROJECT_STATUS,
        payload: data
    };
};