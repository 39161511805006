import { CLEAR_FAVORITE_PROJECTS_SORT, SET_ALL_FAVORITE_PROJECTS, SET_FAVORITE_PROJECTS_SORT} from "../../constants";

const initialState = {
    favoriteprojects: [],
    sort: {
        type: "asc"
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_FAVORITE_PROJECTS:
            return setAllFavoriteProjects(state, action.payload);
        case SET_FAVORITE_PROJECTS_SORT:
            return setFavoriteProjectsSort(state, action.payload);
        case CLEAR_FAVORITE_PROJECTS_SORT:
            return clearFavoriteProjectsSort(state, action.payload);
        default:
            return state;
    }
};

const setAllFavoriteProjects = (state, data) => {
    return {
        ...state,
        favoriteprojects: data
    };
};


const setFavoriteProjectsSort = (state, sort) => {
    return {
        ...state,
        sort: sort
    }
    
};

const clearFavoriteProjectsSort = (state) => {
    return {
        ...state,
        sort: initialState.sort
    }
};
