import {
    GET_ALL_OPERATIONAL_RT_MAITRISE_SOLUTION,
    SET_ALL_OPERATIONAL_RT_MAITRISE_SOLUTION,
    POST_OPERATIONAL_RT_MAITRISE_SOLUTION,
    PUT_OPERATIONAL_RT_MAITRISE_SOLUTION,
    DELETE_OPERATIONAL_RT_MAITRISE_SOLUTION,
    EXPORT_ALL_OPERATIONAL_RT_MAITRISE_SOLUTION,
    IMPORT_ALL_OPERATIONAL_RT_MAITRISE_SOLUTION
} from '../../constants';
import {errorNotification, successNotification} from "../../../../../utils/notification";
import { OPERATIONAL_RT_ENDPOINT } from '../../constants/endpoint';
import {popUpError} from "./popUpError";

export const getAllOperationalMaitriseSolution = () => {
    return {
        type: GET_ALL_OPERATIONAL_RT_MAITRISE_SOLUTION,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/maitriseSolution`
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllOperationalMaitriseSolution(response.data));
                return response;
            }
        }
    };
};

export const setAllOperationalMaitriseSolution = (data) => {
    return {
        type: SET_ALL_OPERATIONAL_RT_MAITRISE_SOLUTION,
        payload: data
    };
};

export const postOperationalMaitriseSolution = (maitriseSolution) => {
    return {
        type: POST_OPERATIONAL_RT_MAITRISE_SOLUTION,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/maitriseSolution`,
            method: 'post',
            data: maitriseSolution
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "create_success");
                store.dispatch(getAllOperationalMaitriseSolution());
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "create_error");
                throw error;
            }
        }
    };
};

export const putOperationalMaitriseSolution = (id, maitriseSolution) => {
    return {
        type: PUT_OPERATIONAL_RT_MAITRISE_SOLUTION,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/maitriseSolution/${id}`,
            method: 'put',
            data: maitriseSolution
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "update_success");
                store.dispatch(getAllOperationalMaitriseSolution());
                return response;
            },
            onError: (error, requestAction, store) => {
                errorNotification('', "update_error");
                throw error;
            }
        }
    };
};

export const deleteOperationalMaitriseSolution = (id) => {
    return {
        type: DELETE_OPERATIONAL_RT_MAITRISE_SOLUTION,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/maitriseSolution/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "delete_success");
                store.dispatch(getAllOperationalMaitriseSolution());
                return response;
            },
            onError: (error, requestAction, store) => {
                popUpError(error,"delete_solution_maitrise_error");
            }
        }
    };
};

export const exportMaitriseSolution = (type) => {
    return {
        type: EXPORT_ALL_OPERATIONAL_RT_MAITRISE_SOLUTION,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/maitriseSolution/export`,
            responseType: 'arraybuffer'
        },
        meta: {
            onRequest: (request) => {
                if(type)
                    request.url=request.url+"?type="+type
                return request;
            },
            onSuccess: (response) => {
                return response.data;
            }
        }
    };
};

export const importMaitriseSolution = (data) => {
    return {
        type: IMPORT_ALL_OPERATIONAL_RT_MAITRISE_SOLUTION,
        request: {
            url: `${OPERATIONAL_RT_ENDPOINT}/maitriseSolution/import`,
            method:"post",
            data:data
        },
        meta: {
            onSuccess: (response,requestAction,store) => {
                store.dispatch(getAllOperationalMaitriseSolution());
                return response.data;
            }
        }
    };
};
