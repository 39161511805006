import {getInitialPagination} from "../../../../../utils/common-state";
import { projectStatus } from "../../../../../utils/projectStatus";
import {
    CLEAR_FINANCIAL_PROJECT_SNAPSHOT,
    CLEAR_FINANCIAL_PROJECT_SNAPSHOTS,
    CLEAR_FINANCIAL_PROJECT_SNAPSHOTS_PAGINATION,
    CLEAR_FINANCIAL_PROJECT_SNAPSHOTS_SORT,
    CLEAR_MANUAL_FINANCIAL_SNAPSHOTS,
    CLEAR_MANUAL_FINANCIAL_SNAPSHOTS_FILTER,
    CLEAR_MANUAL_FINANCIAL_SNAPSHOTS_PAGINATION,
    CLEAR_MANUAL_FINANCIAL_SNAPSHOTS_SORT,
    SET_ALL_FINANCIAL_PROJECTS_SNAPSHOTS,
    SET_FINANCIAL_PROJECT_SNAPSHOT,
    SET_FINANCIAL_PROJECT_SNAPSHOTS,
    SET_FINANCIAL_PROJECT_SNAPSHOTS_PAGINATION,
    SET_FINANCIAL_PROJECT_SNAPSHOTS_SORT,
    SET_MANUAL_FINANCIAL_SNAPSHOTS,
    SET_MANUAL_FINANCIAL_SNAPSHOTS_FILTER,
    SET_MANUAL_FINANCIAL_SNAPSHOTS_PAGINATION,
    SET_MANUAL_FINANCIAL_SNAPSHOTS_SORT,
} from "../../constants";


const initialState = {
    config: {
        pagination: getInitialPagination(false)
    },
    selected: null,
    list: {
        sort: {
            col: { id: 0 },
            type: "desc"
        },
        page: 0,
        size: getInitialPagination(false).size,
        snapshots: []
    },
    snapshots: [],
    manual: {
        config: {
            pagination: getInitialPagination(false)
        },
        list: {
            sort: {
                col: { id: 0 },
                type: "desc"
            },
            filter: {
                types: [],
                status: [projectStatus.EN_COURS],
                clients: [],
            },
            page: 0,
            size: getInitialPagination(false).size,
            snapshots: []
        }
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FINANCIAL_PROJECT_SNAPSHOTS:
            return setFinancialProjectSnapshots(state, action.payload);
        case SET_FINANCIAL_PROJECT_SNAPSHOT:
            return setFinancialProjectSnapshot(state, action.payload);
        case SET_FINANCIAL_PROJECT_SNAPSHOTS_PAGINATION:
            return setFinancialProjectSnapshotsPagination(state, action.payload);
        case CLEAR_FINANCIAL_PROJECT_SNAPSHOTS:
            return clearFinancialProjectSnapshots(state);
        case CLEAR_FINANCIAL_PROJECT_SNAPSHOT:
            return clearFinancialProjectSnapshot(state);
        case CLEAR_FINANCIAL_PROJECT_SNAPSHOTS_PAGINATION:
            return clearFinancialProjectSnapshotsPagination(state);
        case SET_FINANCIAL_PROJECT_SNAPSHOTS_SORT:
            return setSnapshotsSort(state, action.payload);
        case CLEAR_FINANCIAL_PROJECT_SNAPSHOTS_SORT:
            return clearSnapshotsSort(state, action.payload);
        case SET_MANUAL_FINANCIAL_SNAPSHOTS:
            return setManualFinancialSnapshots(state, action.payload);
        case SET_MANUAL_FINANCIAL_SNAPSHOTS_FILTER:
            return setManualFinancialSnapshotsFilter(state, action.payload);
        case SET_MANUAL_FINANCIAL_SNAPSHOTS_PAGINATION:
            return setManualFinancialSnapshotsPagination(state, action.payload);
        case SET_MANUAL_FINANCIAL_SNAPSHOTS_SORT:
            return setManualFinancialSnapshotsSort(state, action.payload);
        case SET_ALL_FINANCIAL_PROJECTS_SNAPSHOTS:
            return setAllFinancialProjectsSnapshots(state,action.payload)
        case CLEAR_MANUAL_FINANCIAL_SNAPSHOTS:
            return clearManualFinancialSnapshots(state);
        case CLEAR_MANUAL_FINANCIAL_SNAPSHOTS_FILTER:
            return clearManualFinancialSnapshotsFilter(state);
        case CLEAR_MANUAL_FINANCIAL_SNAPSHOTS_PAGINATION:
            return clearManualFinancialSnapshotsPagination(state);
        case CLEAR_MANUAL_FINANCIAL_SNAPSHOTS_SORT:
            return clearManualFinancialSnapshotsSort(state);
        default: return state;
    }
};

const setFinancialProjectSnapshots = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: {
                ...state.config.pagination,
                page: parseInt(payload.page),
                size: parseInt(payload.size),
                maxLength: parseInt(payload.maxLength)
            }
        },
        list: {
            ...state.list,
            page: parseInt(payload.page),
            size: parseInt(payload.size),
            snapshots: payload.snapshots
        }
    };
};

const setFinancialProjectSnapshot = (state, payload) => {
    return {
        ...state,
        selected: payload
    };
};

const setFinancialProjectSnapshotsPagination = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: payload
        }
    };
};

const clearFinancialProjectSnapshots = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            page: initialState.list.page,
            size: initialState.list.size,
            snapshots: initialState.list.snapshots
        }
    };
};

const clearFinancialProjectSnapshot = (state) => {
    return {
        ...state,
        selected: null,
    };
};

const clearFinancialProjectSnapshotsPagination = (state) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: getInitialPagination(false)
        }
    };
};

const setSnapshotsSort = (state, sort) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: sort
        }
    };
};

const clearSnapshotsSort = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: initialState.list.sort
        }
    };
};

const setManualFinancialSnapshots = (state, payload) => {
    return {
        ...state,
        manual: {
            ...state.manual,
            config: {
                ...state.manual.config,
                pagination: {
                    ...state.manual.config.pagination,
                    page: parseInt(payload.page ?? 0),
                    size: parseInt(payload.size ?? payload.maxLength),
                    maxLength: parseInt(payload.maxLength)
                }
            },
            list: {
                ...state.manual.list,
                page: parseInt(payload.page ?? 0),
                size: parseInt(payload.size ?? payload.maxLength),
                snapshots: payload.snapshots,
            }
        }
    };
};

const setAllFinancialProjectsSnapshots = (state,payload) => {
    return {
        ...state,
        snapshots: payload
    }
}
const setManualFinancialSnapshotsPagination = (state, payload) => {
    return {
        ...state,
        manual: {
            ...state.manual,
            config: {
                ...state.manual.config,
                pagination: payload
            }
        }
    };
};

const setManualFinancialSnapshotsFilter = (state, payload) => {
    return {
        ...state,
        manual: {
            ...state.manual,
            list: {
                ...state.manual.list,
                filter: payload,
            }
        }
    };
};

const setManualFinancialSnapshotsSort = (state, payload) => {
    return {
        ...state,
        manual: {
            ...state.manual,
            list: {
                ...state.manual.list,
                sort: payload,
            }
        }
    };
};


const clearManualFinancialSnapshots = (state) => {
    return {
        ...state,
        manual: {
            ...state.manual,
            list: {
                ...state.manual.list,
                snapshots: [],
            }
        }
    };
};

const clearManualFinancialSnapshotsPagination = (state) => {
    return {
        ...state,
        manual: {
            ...state.manual,
            config: {
                ...state.manual.config,
                pagination: initialState.manual.config.pagination
            }
        }
    };
};

const clearManualFinancialSnapshotsFilter = (state) => {
    return {
        ...state,
        manual: {
            ...state.manual,
            list: {
                ...state.manual.list,
                filter: initialState.manual.list.filter,
            }
        }
    };
};

const clearManualFinancialSnapshotsSort = (state) => {
    return {
        ...state,
        manual: {
            ...state.manual,
            list: {
                ...state.manual.list,
                sort: initialState.manual.list.sort,
            }
        }
    };
};