import { SET_OPERATIONAL_CONFIG } from "../../constants";

const initialState = {
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_OPERATIONAL_CONFIG:
            return setOperationalConfig(state, action.payload);
        default:
            return state;
    }
};

const setOperationalConfig = (state, data) => {
    return data;
};