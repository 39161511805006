import { CLEAR_PROJECT_OPERATIONAL_INDICATORS, SET_GLOBAL_OPERATIONAL_INDICATORS, SET_PROJECT_OPERATIONAL_INDICATORS } from "../../constants";


const initialState = {
    global: {
        raf: 0,
        derive: 0,
        risqueMoyen: 0
    },
    project: {
        id: -1,
        parentProject: null,
        updatedAt: "JJ-MM-AAAA",
        startDate: "JJ-MM-AAAA",
        endDate: "JJ-MM-AAAA",
        client: null,
        type: null,
        status: null,
        responsable: null,
        opChargeVendue: 0,
        chargeInitiale: 0,
        opChargeConsommee: 0,
        opRaf: 0,
        opChargeActualisee: 0,
        opChargeProduite: 0,
        avancement: 0,
        derive: 0,
        construction: null,
        perimetre: null,
        planning: null,
        staffing: null,
        risk: null,
        opNotes: "",
        maitriseSolution: null,
        relationClient: null
    }
};

export default (state = initialState, action) => {
    switch(action.type){
        case SET_GLOBAL_OPERATIONAL_INDICATORS:
            return setGlobalOperationalIndicators(state, action.data);
        case SET_PROJECT_OPERATIONAL_INDICATORS:
            return setProjectOperationalIndicators(state, action.data);
        case CLEAR_PROJECT_OPERATIONAL_INDICATORS:
            return clearProjectIndicators(state);
        default:
            return state;
    }
};

const setGlobalOperationalIndicators = (state, data) => {
    return {
        ...state,
        global: data
    };
};

const setProjectOperationalIndicators = (state, data) => {
    return {
        ...state,
        project: data
    };
};

const clearProjectIndicators = (state) => {
    return {
        ...state,
        project: initialState.project
    };
};