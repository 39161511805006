import { getInitialPagination } from "../../../../../utils/common-state";
import {
    SET_ALL_PROJECTS,
    CLEAR_SELECTED_OPERATIONAL_PROJECT,
    SET_OPERATIONAL_PROJECT_RISKS,
    SET_SELECTED_OPERATIONAL_PROJECT_RISK,
    SET_OPERATIONAL_PROJECT_RISKS_PAGINATION,
    CLEAR_OPERATIONAL_PROJECT_RISKS,
    CLEAR_SELECTED_OPERATIONAL_PROJECT_RISK,
    CLEAR_OPERATIONAL_PROJECT_RISKS_PAGINATION,
    SET_OPERATIONAL_PROJECT_RISKS_SORT,
    CLEAR_OPERATIONAL_PROJECT_RISKS_SORT,
    SET_OPERATIONAL_PROJECT_RISKS_FILTER, CLEAR_OPERATIONAL_PROJECT_RISKS_FILTER
} from "../../constants";


const initialState = {
    config: {
        pagination: getInitialPagination(false)
    },
    list: {
        sort: {
            col: { apiName: 'createdAt' },
            type: "desc"
        },
        filter: {
            projects: [],
            search: "",
            responsables: [],
            echeance: "",
            priorities: [],
            probabilities: [],
            categories: [],
            status: []
        },
        page: 0,
        size: getInitialPagination(false).size,
        risks: []
    },
    selected: null,
    projects: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_OPERATIONAL_PROJECT_RISKS:
            return setOperationalProjectRisks(state, action.payload);
        case SET_SELECTED_OPERATIONAL_PROJECT_RISK:
            return setSelectedOperationalProjectRisk(state, action.payload);
        case SET_OPERATIONAL_PROJECT_RISKS_PAGINATION:
            return setOperationalProjectRisksPagination(state, action.payload);
        case CLEAR_OPERATIONAL_PROJECT_RISKS:
            return clearOperationalProjectRisks(state);
        case CLEAR_SELECTED_OPERATIONAL_PROJECT_RISK:
            return clearSelectedOperationalProjectRisks(state);
        case CLEAR_OPERATIONAL_PROJECT_RISKS_PAGINATION:
            return clearOperationalProjectRisksPagination(state);
        case SET_ALL_PROJECTS:
            return setAllProjects(state, action.payload);
        case SET_OPERATIONAL_PROJECT_RISKS_SORT:
            return setRisksSort(state, action.payload);
        case CLEAR_OPERATIONAL_PROJECT_RISKS_SORT:
            return clearRisksSort(state);
        case SET_OPERATIONAL_PROJECT_RISKS_FILTER:
            return setRisksFilter(state, action.payload);
        case CLEAR_OPERATIONAL_PROJECT_RISKS_FILTER:
            return clearRisksFilter(state);
        default: return state;
    }
};

const setOperationalProjectRisks = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: {
                ...state.config.pagination,
                page: parseInt(payload.page),
                size: parseInt(payload.size),
                maxLength: parseInt(payload.maxLength)
            }
        },
        list: {
            ...state.list,
            page: parseInt(payload.page),
            size: parseInt(payload.size),
            risks: payload.risks
        }
    };
};

const setSelectedOperationalProjectRisk = (state, payload) => {
    return {
        ...state,
        selected: payload
    };
};

const setOperationalProjectRisksPagination = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: payload
        }
    };
};

const clearOperationalProjectRisks = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            page: initialState.list.page,
            size: initialState.list.size,
            risks: initialState.list.risks
        }
    };
};

const clearSelectedOperationalProjectRisks = (state) => {
    return {
        ...state,
        selected: initialState.selected
    };
};

const clearOperationalProjectRisksPagination = (state) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: getInitialPagination(false)
        }
    };
};

const setAllProjects = (state, payload) => {
    return {
        ...state,
        projects: payload
    };
};

const setRisksSort = (state, sort) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: sort
        }
    };
};

const clearRisksSort = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: initialState.list.sort
        }
    };
};

const setRisksFilter = (state, filter) => {
    return {
        ...state,
        list: {
            ...state.list,
            filter: filter
        }
    };
};

const clearRisksFilter = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            filter: initialState.list.filter
        }
    };
};
