import { 
    SET_ALL_RT_PROJECT_CLIENT, 
    SET_ALL_RT_PROJECT_STATUS, 
    SET_ALL_RT_PROJECT_TYPE, 
    SET_ALL_RT_PROJECT_RISK,
    SET_ALL_PROJECTS,
    SET_ALL_RT_AGENCIES,
    SET_ALL_RT_JOBS,
    SET_ALL_RT_ENTITY_AGENCIES
} from "../../constants";

const initialState = {
    projectType: [],
    projectStatus: [],
    projectClient: [],
    projectRisk: [],
    projects: [],
    agencies: [],
    jobs: [],
    entityAgencies: []
};

export default (state = initialState, action) => {
    switch(action.type){
        case SET_ALL_RT_PROJECT_TYPE:
            return setAllProjectType(state, action.payload);
        case SET_ALL_RT_PROJECT_STATUS:
            return setAllProjectStatus(state, action.payload);
        case SET_ALL_RT_PROJECT_CLIENT:
            return setAllProjectClient(state, action.payload);
        case SET_ALL_RT_PROJECT_RISK:
            return setAllProjectRisk(state, action.payload); 
        case SET_ALL_PROJECTS:
            return setAllProjects(state, action.payload);
        case SET_ALL_RT_AGENCIES:
            return setAllRtAgencies(state, action.payload);
        case SET_ALL_RT_JOBS:
            return setAllRtJobs(state, action.payload);
        case SET_ALL_RT_ENTITY_AGENCIES:
            return setAllRtEntityAgencies(state, action.payload);
        default:
            return state;
    }
};

const setAllProjectType = (state, payload) => {
    return {
        ...state,
        projectType: payload
    };
};

const setAllProjectStatus = (state, payload) => {
    return {
        ...state,
        projectStatus: payload
    };
};

const setAllProjectClient = (state, payload) => {
    return {
        ...state,
        projectClient: payload
    };
};

const setAllProjectRisk = (state, payload) => {
    return {
        ...state,
        projectRisk: payload
    };
};

const setAllProjects = (state, payload) => {
    return {
        ...state,
        projects: payload
    };
};

const setAllRtAgencies = (state, payload) => {
    return {
        ...state,
        agencies : payload
    }
}

const setAllRtJobs = (state, payload) => {
    return {
        ...state,
        jobs : payload
    }
}

const setAllRtEntityAgencies = (state, payload) => {
    return {
        ...state,
        entityAgencies : payload
    }
}
