import React, {useEffect, useState} from "react";
import { DragDropContext } from 'react-beautiful-dnd'
import {useTranslation} from "react-i18next";
import {
    Typography,
    Grid,
} from "@material-ui/core";
import {useDispatch} from "react-redux";
import Column from "../Column";
import useStyles from "../style";
import AddActionComment from "../../AddActionComment";
import {STATUS_COMPLETED} from "../../ActionsOrRisks/components/actionsStatuses";
import {STATUS_CLOSED} from "../../ActionsOrRisks/components/riskStatuses";

function MotherContainer({gridView,tasks,taskStatusList,getUpdatedTask,handleDeleteModalChange,deleteModal,setDeleteModal,risk,putOperationalProject}) {

    const dispatch = useDispatch();
    const [columnsData, setcolumnsData] = useState([])
    const { t } = useTranslation();
    const classes = useStyles();
    const [comment, setComment] = useState('');
    const [open, setOpen] = useState(false);
    const [action, setAction] = useState(null);

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleAddComment = () => {
        dispatch(putOperationalProject({...action, comment:comment},true))
        setOpen(false)
        setComment("")
    };

    const onClose = ()=>{
        setOpen(false);
        dispatch(putOperationalProject(action,true))

    }

    const getTaskStatusyId = (id) => {
        const taskStatus = taskStatusList?.filter((item) => item?.id === id)
        return taskStatus[0]
    }
    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result

        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) return

        const destinationTaskStatusId = parseInt(destination.droppableId.split('-')[1])
        const destinationTaskStatus = getTaskStatusyId(destinationTaskStatusId)

        let taskIndex;
      const newColumns = columnsData?.map((item, index) => {
            const dragabbleSlicedId = parseInt(draggableId.split('-')[1])
            if (item?.id === dragabbleSlicedId) {
                item.status = destinationTaskStatus
                taskIndex = index
            }
            return item
        })
         setcolumnsData(newColumns)

        if (destinationTaskStatus.code === STATUS_COMPLETED || destinationTaskStatus.code === STATUS_CLOSED){
            setOpen(true)
            setAction(newColumns[taskIndex]);
        }
        else
            getUpdatedTask(newColumns[taskIndex]);
    }

        useEffect(() => {
            setcolumnsData(tasks)
        }, [tasks])


    let columns ;
    if (gridView) {
        columns = taskStatusList?.map((item) => {
            const filteredTasks = tasks?.filter((task) => task?.status?.id === item?.id)
            return <Column key={'column-' + item?.id} column={item} tasks={filteredTasks}
                           deleteModal={deleteModal}
                           setDeleteModal={setDeleteModal}
                           handleDeleteModalChange={handleDeleteModalChange}
                           putOperationalProject={putOperationalProject}
                           risk={risk}
                  />
        })
    }

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                {tasks.length > 0 && <Grid
                    item
                    xs={12}
                >
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography
                                className={classes.viewKanban}
                                variant='h6'
                            >
                                {t('viewKanban')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.motherContainer}>
                        {columns}
                    </Grid>
                </Grid>}
            </DragDropContext>
            <AddActionComment
                open={open}
                actionOrRisk={action}
                handleAddComment={handleAddComment}
                handleCommentChange={handleCommentChange}
                onClose={onClose}
                dialogTitle={risk ? t('add_comment_to_risk') : t('add_comment_to_action')}
            />
        </>
    )
}

export default MotherContainer
