import React from 'react';
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './style';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Export from '../Export';
import { useState } from 'react';
import { sendFile } from '../../../utils/sendFile';

function Import({ importFile, downloadModel, fileName}) {

    const { t } = useTranslation();
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const sendimportedFile = (e) =>
    {
        setLoading(true)
        sendFile(e, importFile, updateImportStates)
    }

    const updateImportStates = () =>
    {
        setLoading(false)
        setOpenDialog(false)
    }

    return (
        <>
            <Button
                className={classes.root}
                variant="outlined"
                component="label"
                startIcon={<CloudUploadIcon />}
                onClick={()=>setOpenDialog(true)} 
            >
            {t('import')}
            </Button>

            <Dialog
                open={openDialog}
                keepMounted
                onClose={()=>setOpenDialog(false)}            
            >
                <DialogTitle>{t('import_title')}</DialogTitle>
                <DialogContent >
                    <Box style={{position: "relative"}}>
                        <Box style={{textAlign:"center",margin:"21px 0"}}>
                            <Button
                                className={classes.root}
                                variant="outlined"
                                component="label"
                                startIcon={
                                loading ? 
                                    <CircularProgress disableShrink size="1rem" />
                                :
                                    <CloudUploadIcon />}
                            >
                                {t('import')}
                                <input type="file"  style={{ display: "none" }} value={""} onChange={sendimportedFile} />
                            </Button>
                        </Box>
                        <Box style={{position:"relative"}}>
                        <Divider></Divider>
                            <Typography className={classes.line} variant="body2" color="textSecondary" gutterBottom>
                                {t('or')}
                            </Typography>
                        </Box>
                        <Box style={{textAlign:"center",margin:"21px 0"}}>
                            <Export title={t('download')} format='downloadModel' action={downloadModel} fileName={fileName}/>
                        </Box>
                        
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default Import;