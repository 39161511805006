import { getInitialPagination } from "../../../../../utils/common-state";
import { projectStatus } from "../../../../../utils/projectStatus";
import {
    SET_OPERATIONAL_PROJECT_SNAPSHOTS,
    SET_OPERATIONAL_PROJECT_SNAPSHOT,
    SET_OPERATIONAL_PROJECT_SNAPSHOTS_PAGINATION,
    CLEAR_OPERATIONAL_PROJECT_SNAPSHOTS,
    CLEAR_OPERATIONAL_PROJECT_SNAPSHOT,
    CLEAR_OPERATIONAL_PROJECT_SNAPSHOTS_PAGINATION,
    SET_ALL_OPERATIONAL_PROJECTS_SNAPSHOTS,
    CLEAR_ALL_OPERATIONAL_PROJECT_SNAPSHOTS,
    SET_OPERATIONAL_PROJECT_SNAPSHOTS_SORT,
    CLEAR_OPERATIONAL_PROJECT_SNAPSHOTS_SORT,
    SET_MANUAL_OPERATIONAL_SNAPSHOTS,
    SET_MANUAL_OPERATIONAL_SNAPSHOTS_FILTER,
    SET_MANUAL_OPERATIONAL_SNAPSHOTS_PAGINATION,
    SET_MANUAL_OPERATIONAL_SNAPSHOTS_SORT,
    CLEAR_MANUAL_OPERATIONAL_SNAPSHOTS,
    CLEAR_MANUAL_OPERATIONAL_SNAPSHOTS_FILTER,
    CLEAR_MANUAL_OPERATIONAL_SNAPSHOTS_PAGINATION,
    CLEAR_MANUAL_OPERATIONAL_SNAPSHOTS_SORT,
} from "../../constants";


const initialState = {
    config: {
        pagination: getInitialPagination(false)
    },
    selected: null,
    list: {
        sort: {
            col: { id: 0 },
            type: "desc"
        },
        page: 0,
        size: getInitialPagination(false).size,
        snapshots: []
    },
    snapshots: [],
    manual: {
        config: {
            pagination: getInitialPagination(false)
        },
        list: {
            sort: {
                col: { id: 0 },
                type: "desc"
            },
            filter: {
                types: [],
                status: [projectStatus.EN_COURS],
                clients: [],
            },
            page: 0,
            size: getInitialPagination(false).size,
            snapshots: []
        }
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_OPERATIONAL_PROJECT_SNAPSHOTS:
            return setOperationalProjectSnapshots(state, action.payload);
        case SET_OPERATIONAL_PROJECT_SNAPSHOT:
            return setOperationalProjectSnapshot(state, action.payload);
        case SET_OPERATIONAL_PROJECT_SNAPSHOTS_PAGINATION:
            return setOperationalProjectSnapshotsPagination(state, action.payload);
        case SET_ALL_OPERATIONAL_PROJECTS_SNAPSHOTS:
            return setAllOperationalProjectSnapshots(state, action.payload);
        case SET_MANUAL_OPERATIONAL_SNAPSHOTS:
            return setManualOperationalSnapshots(state, action.payload);
        case SET_MANUAL_OPERATIONAL_SNAPSHOTS_FILTER:
            return setManualOperationalSnapshotsFilter(state, action.payload);
        case SET_MANUAL_OPERATIONAL_SNAPSHOTS_PAGINATION:
            return setManualOperationalSnapshotsPagination(state, action.payload);
        case SET_MANUAL_OPERATIONAL_SNAPSHOTS_SORT:
            return setManualOperationalSnapshotsSort(state, action.payload);
        case CLEAR_OPERATIONAL_PROJECT_SNAPSHOTS:
            return clearOperationalProjectSnapshots(state);
        case CLEAR_OPERATIONAL_PROJECT_SNAPSHOT:
            return clearOperationalProjectSnapshot(state);
        case CLEAR_OPERATIONAL_PROJECT_SNAPSHOTS_PAGINATION:
            return clearOperationalProjectSnapshotsPagination(state);
        case CLEAR_ALL_OPERATIONAL_PROJECT_SNAPSHOTS:
            return clearAllOperationalProjectSnapshots(state);
        case SET_OPERATIONAL_PROJECT_SNAPSHOTS_SORT:
            return setSnapshotsSort(state, action.payload);
        case CLEAR_OPERATIONAL_PROJECT_SNAPSHOTS_SORT:
            return clearSnapshotsSort(state);
        case CLEAR_MANUAL_OPERATIONAL_SNAPSHOTS:
            return clearManualOperationalSnapshots(state);
        case CLEAR_MANUAL_OPERATIONAL_SNAPSHOTS_FILTER:
            return clearManualOperationalSnapshotsFilter(state);
        case CLEAR_MANUAL_OPERATIONAL_SNAPSHOTS_PAGINATION:
            return clearManualOperationalSnapshotsPagination(state);
        case CLEAR_MANUAL_OPERATIONAL_SNAPSHOTS_SORT:
            return clearManualOperationalSnapshotsSort(state);
        default: return state;
    }
};

const setOperationalProjectSnapshots = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: {
                ...state.config.pagination,
                page: parseInt(payload.page),
                size: parseInt(payload.size),
                maxLength: parseInt(payload.maxLength)
            }
        },
        list: {
            ...state.list,
            page: parseInt(payload.page),
            size: parseInt(payload.size),
            snapshots: payload.snapshots
        }
    };
};

const setAllOperationalProjectSnapshots = (state, payload) => {
    return {
        ...state,
        snapshots: payload
    };
};

const setOperationalProjectSnapshot = (state, payload) => {
    return {
        ...state,
        selected: payload
    };
};

const setOperationalProjectSnapshotsPagination = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: payload
        }
    };
};

const setManualOperationalSnapshots = (state, payload) => {
    return {
        ...state,
        manual: {
            ...state.manual,
            config: {
                ...state.manual.config,
                pagination: {
                    ...state.manual.config.pagination,
                    page: parseInt(payload.page ?? 0),
                    size: parseInt(payload.size ?? payload.maxLength),
                    maxLength: parseInt(payload.maxLength)
                }
            },
            list: {
                ...state.manual.list,
                page: parseInt(payload.page ?? 0),
                size: parseInt(payload.size ?? payload.maxLength),
                snapshots: payload.snapshots,
            }
        }
    };
};

const setManualOperationalSnapshotsPagination = (state, payload) => {
    return {
        ...state,
        manual: {
            ...state.manual,
            config: {
                ...state.manual.config,
                pagination: payload
            }
        }
    };
};

const setManualOperationalSnapshotsFilter = (state, payload) => {
    return {
        ...state,
        manual: {
            ...state.manual,
            list: {
                ...state.manual.list,
                filter: payload,
            }
        }
    };
};

const setManualOperationalSnapshotsSort = (state, payload) => {
    return {
        ...state,
        manual: {
            ...state.manual,
            list: {
                ...state.manual.list,
                sort: payload,
            }
        }
    };
};

const clearOperationalProjectSnapshots = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            page: initialState.list.page,
            size: initialState.list.size,
            snapshots: initialState.list.snapshots
        }
    };
};

const clearAllOperationalProjectSnapshots = (state) => {
    return {
        ...state,
        snapshots: initialState.snapshots
    };
};

const clearOperationalProjectSnapshot = (state) => {
    return {
        ...state,
        selected: null,
    };
};

const clearOperationalProjectSnapshotsPagination = (state) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: getInitialPagination(false)
        }
    };
};

const setSnapshotsSort = (state, sort) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: sort
        }
    };
};

const clearSnapshotsSort = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: initialState.list.sort
        }
    };
};

const clearManualOperationalSnapshots = (state) => {
    return {
        ...state,
        manual: {
            ...state.manual,
            list: {
                ...state.manual.list,
                snapshots: [],
            }
        }
    };
};

const clearManualOperationalSnapshotsPagination = (state) => {
    return {
        ...state,
        manual: {
            ...state.manual,
            config: {
                ...state.manual.config,
                pagination: initialState.manual.config.pagination
            }
        }
    };
};

const clearManualOperationalSnapshotsFilter = (state) => {
    return {
        ...state,
        manual: {
            ...state.manual,
            list: {
                ...state.manual.list,
                filter: initialState.manual.list.filter,
            }
        }
    };
};

const clearManualOperationalSnapshotsSort = (state) => {
    return {
        ...state,
        manual: {
            ...state.manual,
            list: {
                ...state.manual.list,
                sort: initialState.manual.list.sort,
            }
        }
    };
};