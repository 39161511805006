import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import MultiSelect from '../../../../components/MultiSelect';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`,
        boxShadow: "none"
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        height: 400,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
    filterBox: {
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
        paddingTop: theme.spacing(0.5)
    },
    checkBoxColor: {
        color: "#375777"
    }
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

function ProjectTransferList({
    selectedCollab,
    setChecked,
    checked,
    setRight,
    setLeft,
    right,
    left,
    leftChecked,
    rightChecked,
    projectsWithClientIncluded
}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [projectStatus, setProjectStatus] = React.useState(["En cours"]);
    const [projectType, setProjectType] = React.useState([]);
    const projectsList = useSelector(({ rt }) => rt.projects);
    const projectStatusList = useSelector(({ rt }) => rt.projectStatus);
    const projectTypeList = useSelector(({ rt }) => rt.projectType);
    const [selectedProjectByFilter, setSelectedProjectByFilter] = React.useState(false);
    const [filtredItems, setFiltredItems] = React.useState([]);

    useEffect(()=>{

        const filterdItems = getFiltredItems(left)
        setFiltredItems(filterdItems)
        
    },[projectStatus, projectType])

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const getFiltredItems = (items) =>{
        const filtredItemsList = items.reduce((result, itemId) => {
            const project = projectsList.find(p => p.id === itemId);

            if (project && 
                (projectStatus.length === 0 || projectStatus.includes(project.status.name)) &&
                (projectType.length === 0 || projectType.includes(project.type.name)) &&
                !projectsWithClientIncluded.includes(project.id)) {
              result.push(project.id);
            }
          
            return result;
        }, []);

        return filtredItemsList;
    }
    const handleToggleAllByFiltre = (items)=> {
        const filtredItemsList = getFiltredItems(items)
        
        setFiltredItems(filtredItemsList)
        
        if (numberOfChecked(filtredItemsList) === filtredItemsList.length) {
            setChecked(not(checked, filtredItemsList));
        } else {
            setChecked(union(checked, filtredItemsList));
        }
        setSelectedProjectByFilter(!selectedProjectByFilter)

    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setFiltredItems(not(filtredItems, leftChecked))
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setFiltredItems(filtredItems.concat(rightChecked))
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };


    const customList = (title, items, filter) => (
        <Card className={classes.card}>
            <Grid container>
                <Grid item xs={12}>
                    <CardHeader
                        className={classes.cardHeader}
                        avatar={
                            <Checkbox
                                onClick={handleToggleAll(items)}
                                checked={numberOfChecked(items) === items.length && items.length !== 0}
                                indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                                disabled={items.length === 0}
                                inputProps={{ 'aria-label': 'all items selected' }}
                                size="small"
                            />
                        }
                        title={<Typography variant="subtitle1" color='primary' style={{ fontWeight: 500 }}>{title}</Typography>}
                        subheader={`${numberOfChecked(items)} sélectionnés`}
                    />
                </Grid>
                {
                    filter &&
                    <Grid container item xs={12} justify="space-around" className={classes.filterBox}>
                        <Grid item xs={5}>
                            <MultiSelect
                                label={t('status_col')}
                                noOptionsLabel={t('no_status_found')}
                                value={projectStatus}
                                onChange={e => setProjectStatus(e.target.value)}
                                list={projectStatusList.map(s => ({ id: s.id, label: s.name }))}
                                checkmark={true}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <MultiSelect
                                label={t('type_col')}
                                noOptionsLabel={t('no_type_found')}
                                value={projectType}
                                onChange={e => setProjectType(e.target.value)}
                                list={projectTypeList.map(t => ({ id: t.id, label: t.name }))}
                                checkmark={true}
                            />
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Divider />
            <List className={classes.list} component="div" role="list">
            { title === t('available_projects') &&
                <ListItem  role="listitem" button onClick={() => handleToggleAllByFiltre(items)} disabled={filtredItems.length === 0}>
                    <ListItemIcon>
                        <Checkbox
                            checked={numberOfChecked(filtredItems) === filtredItems.length && filtredItems.length !== 0}
                            indeterminate={numberOfChecked(filtredItems) !== filtredItems.length && numberOfChecked(filtredItems) !== 0}
                            tabIndex={1}
                            disableRipple
                            size="small"
                            className={classes.checkBoxColor}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="subtitle1" color='primary' style={{ fontWeight: 500 }}>{t("Sélectionner tout")}</Typography>}
                            
                    />
                </ListItem>
            }
                {
                    selectedCollab
                        ? items.map(i => projectsList.find(p => p.id === i))
                            .filter(p => filter && projectStatus.length > 0 ? projectStatus.includes(p.status.name) : true)
                            .filter(p => filter && projectType.length > 0 ? projectType.includes(p.type.name) : true)
                            .map(project => {
                                const clientSelected = projectsWithClientIncluded.includes(project.id);
                                const parentProjectSelected = right.includes(project?.parentProjectId);

                                return (
                                    <ListItem key={project.id} role="listitem" button onClick={handleToggle(project.id)} disabled={clientSelected || parentProjectSelected}>
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={checked.indexOf(project.id) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                disabled={clientSelected || parentProjectSelected}
                                                size="small"
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={project.name}
                                            secondary={clientSelected
                                                ? `(${t("client_already_assigned", { name: project.client.name })})`
                                                : (parentProjectSelected
                                                    ? `(${t("parent_project_already_assigned", { name: project.parentProjectId?.name })})`
                                                    : ""
                                                )
                                            }
                                        />
                                    </ListItem>
                                );
                            })
                        : <Box my={2}>
                            <Typography variant="body2" align="center">{t('no_collaborator_is_selected')}</Typography>
                        </Box>
                }
                <ListItem />
            </List>
        </Card >
    );

    return (
        <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.root}
        >
            <Grid item xs={5}>{customList(t('available_projects'), left, true)}</Grid>
            <Grid item xs={2}>
                <Grid container direction="column" alignItems="center">
                    <Button
                        variant="outlined"
                        color='primary'
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                    >
                        &gt;
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={5}>{customList(t('assigned_projects'), right, false)}</Grid>
        </Grid>
    );
}

export default ProjectTransferList;