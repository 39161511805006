import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    dialogPaper: {
        width: "50%",
        marginTop: theme.spacing(6)
    },
    topScrollPaper: {
        alignItems: 'flex-start',
    },
    topPaperScrollBody: {
        verticalAlign: 'top',
    },
    dayButton: {
        marginLeft: theme.spacing(1),
        padding: theme.spacing(0.5, 1),
        textTransform: "none"
    },
}));