import { makeStyles } from "@material-ui/core";



export default makeStyles((theme) => ({
    colorPicker: {
        zIndex: 1000,
        position: "fixed",
        right: "0",
        top: "90%",
        backgroundColor: theme.palette.background.main,
        height: "45px",
        width: "40px",
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.05)',
            transformOrigin: '100% 50%'
        }
    }
}));