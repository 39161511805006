import React from 'react';
import { t } from '../../../i18n';
import { Container, Row, Col, Alert } from 'reactstrap';

function AccessDeniedWarning() {

    return (
        <Container>
            <Row>
                <Col>
                    <Alert color="danger" className="text-center">
                        {t('you_dont_have_access_to_this_application')}
                    </Alert>
                </Col>
            </Row>
        </Container>
    );
}

export default AccessDeniedWarning;
